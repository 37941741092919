import React from 'react';


const TooltipArrow = () => (
    <svg
        width= "38"
        height= "24"
        viewBox= "0 0 38 24"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M1.20402 10.5788L38 0V24L1.18206 12.4944C0.235134 12.1985 0.250562 10.853 1.20402 10.5788Z"
            fill= "#011523"
        />
    </svg>
);

export default TooltipArrow;
