import React from 'react';

import { Colors } from '../../utilities/common-styles';

interface CloseIconProps {
    color?: string;
}

const ModalInfoIcon = ({ color }: CloseIconProps) => (
    <svg
        width= "15"
        height= "14"
        viewBox= "0 0 15 14"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M7.99512 0C4.13512 0 0.995117 3.14 0.995117 7C0.995117 10.86 4.13512 14 7.99512 14C11.8551 14 14.9951 10.86 14.9951 7C14.9951 3.14 11.8551 0 7.99512 0ZM8 6C7.67 6 7.4 6.225 7.4 6.5V10.5C7.4 10.775 7.67 11 8 11C8.33 11 8.6 10.775 8.6 10.5V6.5C8.6 6.225 8.33 6 8 6ZM7.4 3.5C7.4 3.225 7.67 3 8 3C8.33 3 8.6 3.225 8.6 3.5V4.5C8.6 4.775 8.33 5 8 5C7.67 5 7.4 4.775 7.4 4.5V3.5Z"
            fill={color || Colors.purpleHover}
        />
    </svg>
);

export default ModalInfoIcon;
