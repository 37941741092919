import OptionalQuestionsStepper from "./QuestionsStepper";
import React from "react";
import { PlaceTypes } from "../../../utilities/gqlUtilities";
import { NonObjectReport } from "../../../models/NonObjectReport";

import { useLocalStorage } from "../../../hooks";
import { nonObjectStorageName } from "../../../constants/storageNames";
import { useParams } from "react-router-dom";

const NonObjectsOptionalQuestions = () => {
  // eslint-disable-next-line no-unused-vars
  const [nonObjectReports, _] = useLocalStorage<NonObjectReport[]>(nonObjectStorageName, []);

  const { id } = useParams<{ country: string; id: string }>();
  const report = nonObjectReports?.find((report) => report.id === id);

  return report ? (
    <OptionalQuestionsStepper placeType={PlaceTypes.NewLocationPlaceType} latitude={report.position.latitude} longitude={report.position.longitude} />
  ) : (
    <></>
  );
};

export default NonObjectsOptionalQuestions;
