import styled from "styled-components";
import { Devices } from "../../../utilities/common-styles";

export const ContentWrapper = styled.div`
  margin: 80px auto auto auto;
  max-width: 436px;
  width: 100%;
  padding-top: 24px;
  @media ${Devices.mobileS} {
    margin: 8px auto auto auto;
  }
`;
