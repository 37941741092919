import React from 'react';

const DragAndDropIcon = () => (
    <svg
        width= "24"
        height= "24"
        viewBox= "0 0 24 24"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M9.375 7.5C9.99632 7.5 10.5 6.99632 10.5 6.375C10.5 5.75368 9.99632 5.25 9.375 5.25C8.75368 5.25 8.25 5.75368 8.25 6.375C8.25 6.99632 8.75368 7.5 9.375 7.5Z"
            fill= "#7599BF"
        />
        <path
            d= "M14.625 7.5C15.2463 7.5 15.75 6.99632 15.75 6.375C15.75 5.75368 15.2463 5.25 14.625 5.25C14.0037 5.25 13.5 5.75368 13.5 6.375C13.5 6.99632 14.0037 7.5 14.625 7.5Z"
            fill= "#7599BF"
        />
        <path
            d= "M9.375 12.75C9.99632 12.75 10.5 12.2463 10.5 11.625C10.5 11.0037 9.99632 10.5 9.375 10.5C8.75368 10.5 8.25 11.0037 8.25 11.625C8.25 12.2463 8.75368 12.75 9.375 12.75Z"
            fill= "#7599BF"
        />
        <path
            d= "M14.625 12.75C15.2463 12.75 15.75 12.2463 15.75 11.625C15.75 11.0037 15.2463 10.5 14.625 10.5C14.0037 10.5 13.5 11.0037 13.5 11.625C13.5 12.2463 14.0037 12.75 14.625 12.75Z"
            fill= "#7599BF"
        />
        <path
            d= "M9.375 18C9.99632 18 10.5 17.4963 10.5 16.875C10.5 16.2537 9.99632 15.75 9.375 15.75C8.75368 15.75 8.25 16.2537 8.25 16.875C8.25 17.4963 8.75368 18 9.375 18Z"
            fill= "#7599BF"
        />
        <path
            d= "M14.625 18C15.2463 18 15.75 17.4963 15.75 16.875C15.75 16.2537 15.2463 15.75 14.625 15.75C14.0037 15.75 13.5 16.2537 13.5 16.875C13.5 17.4963 14.0037 18 14.625 18Z"
            fill= "#7599BF"
        />
    </svg>
);

export default DragAndDropIcon;
