import { css } from "styled-components";

const Colors = {
  white: "#FFF",
  background: "#FAFAFA",
  backgroundDarkMode: "#011523",
  orangeDarkMode: "#FFAB4A",
  darkOrange: "#EB803A",
  border: "#EEE",
  blue: "#2DABE2",
  lightBlue: "#BCEAE6",
  darkBlue: "#031F30",
  black: "#626366",
  black15: "#BBBDBF",
  black45: "#9C9EA1",
  black75: "#626366",
  darkGrey: "#404346",
  mediumGrey: "#646D82",
  ghostGrey: "#F5F5F5",
  ghostWhite: "#EBEBEB",
  ghostGraph: "#D7D8D6",
  borderGrey: "#E5E5E4",
  yellow: "#FFCD6A",
  red: "#EB5A46",
  red25: "#F39C90",
  red50: "#F9CEC7",
  redAlert: "#FC273F",
  redGraph: "#FE718E",
  limeGreen: "#A7BD45",
  green: "#61BD4F",
  orange: "#FFAB4A",
  orangeCategory: "#FFA552",
  transparent: "tranparent",
  riskLevel1: "#48BD5B",
  riskLevel2: "#9CDB9B",
  riskLevel3: "#DAC867",
  riskLevel4: "#FFA000",
  riskLevel5: "#EE6343",
  primaryPurple: "#8D5FFF",
  pieChartRed: "#FF8DA3",
  purpleHover: "#9977FE",
  accentPurple: "#6C47CF",
  accentPurple2: "#C2B4FC",
  secondaryPurple: "#55419E",
  navyBlue: "#2E4869",
  successGreen: "#14A665",
  cardBackgroundPrimary: "#0E283D",
  cardBackgroundSecondary: "#031F30",
  cardSelected: "#172F48",
  cardSelection: "#587CAA",
  text: "#F6F6F6",
  borderDarkMode: "#7599BF",
  borderDarkModeSelected: "#ACC2DD",
  navyBlueSecond: "#42638C",
  redDarkMode: "#FD566C",
  redDarkModeSelection: "#D31B3D",
  greyLabel: "#BABCBC",
  grey: "#F6F6F6",
  riskDarkGreen: "#47BD5B",
  tooltipDarkMode: "##233C59",
  riskGreen: "#9BDB9A",
  greenDarkMode: "#77CA96",
  riskYellow: "#DAC967",
  riskOrange: "#FFA200",
  riskRed: "#EE6344",
};

const Gradients = {
  atRiskBar: "repeating-linear-gradient(-45deg, rgb(235, 90, 70), rgb(235, 90, 70) 10px, rgb(235, 128, 58) 10px, rgb(235, 128, 58) 20px)",
};

const Fonts = {
  Noto: "Noto",
  SecuritasPro: "SecuritasPro",
  SecuritasPro2: "SecuritasPro2",
};

const Sizes = {
  header: 40,
  headerWithActions: 100,
  mobileMenu: 75,
  mobileMenuMargin: 180,
  mobileS: 420,
  mobile: 768,
  mobileL: 1023,
  tablet: 1279,
  dektop: 1280,
};

const Devices = {
  mobileS: "(max-width: " + Sizes.mobileS + "px)",
  mobile: "(max-width: " + Sizes.mobile + "px)",
  mobileL: "(max-width: " + Sizes.mobileL + "px)",
  tablet: "(max-width: " + Sizes.tablet + "px)",
  desktop: "(min-width: " + Sizes.dektop + "px)",
};

const RISK_COLORS = [Colors.green, Colors.limeGreen, Colors.orangeDarkMode, Colors.darkOrange, Colors.red];

const TOP_RISK_MAP_COLORS = [Colors.riskDarkGreen, Colors.riskGreen, Colors.riskYellow, Colors.riskOrange, Colors.riskRed];

const SmallBorderRadius = css`
  border-radius: 2px;
`;

export { Colors, Gradients, Fonts, Devices, Sizes, RISK_COLORS, SmallBorderRadius, TOP_RISK_MAP_COLORS };
