import { Colors, Fonts } from '../../../utilities/common-styles';
import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';
import { GraphContainer } from './styled';
export interface IPick {
    timestamp: number;
    risk?: number;
    diff?: number;
    name: string;
    range?: number[];
}

const HourlyGraph = ({ data, xTicks, xTickFormater, width, labelSize }) => {
    const singleBarWidth = width / data.length - 3;

    const padding = {
        bottom: 30,
        left: 10
    };

    return (
        <GraphContainer>
            <VictoryChart
                domainPadding={{ x: [0, 10] }}
                padding={padding}
                width={400}
                height={200}
            >
                <VictoryBar
                    style={{
                        data: {
                            fill: Colors.primaryPurple,
                            strokeLinejoin: 'round',
                            strokeWidth: 1
                        }
                    }}
                    cornerRadius={{ topLeft: 2, topRight: 2 }}
                    alignment= "middle"
                    barWidth={singleBarWidth}
                    data={data}
                    x= "hour"
                    y= "value"
                    animate={{ duration: 500 }}
                />
                <VictoryAxis
                    tickFormat={xTickFormater}
                    tickValues={xTicks}
                    tickCount={xTicks.length || 0}
                    offsetY={28}
                    style={{
                        tickLabels: {
                            fill: Colors.borderDarkMode,
                            fontSize: labelSize,
                            padding: 2,
                            fontFamily: Fonts.SecuritasPro,
                            lineHeight: '18px',
                            letterSpacing: '0.3px'
                        }
                    }}
                />
            </VictoryChart>
        </GraphContainer>
    );
};
export default HourlyGraph;
