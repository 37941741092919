import { useCallback } from "react";

import { embedApp, preventBackParameter, embedInRiskPredictionParameter } from "./generalSettings";
import { NonObjectType } from "./types";
import { HomeUrl, FaqUrl, QuenstionaireUrl, OrderReport } from "./../constants/urls";
import { NoAccessUrl } from "./../constants/urls";
import { PlaceProperties } from "./placeCapabilitiesUtils";

const QuestionnaireUrl = "/questionnaire";
const OptionalQuestionsUrl = "/optionalQuestions";
const NonObjectRoutePath = `${HomeUrl}/non-object/[id]`;
const ObjectRoutePath = `${HomeUrl}/[country]/[oid]`;
const QuestionnaireRoutePath = (placeType) => `${QuestionnaireUrl}/[country]/${placeType}/[oid]`;
const getNonObjectUrl = (id: string) => `${HomeUrl}/non-object/${id}`;
const getObjectUrl = (country: string, id: string) => `${HomeUrl}/${country}/${id}`;

const getReportUrl = (type: string, country: string, id: string) => {
  const url = type === NonObjectType ? getNonObjectUrl(id) : getObjectUrl(country ?? "SE", id);
  return url;
};

const getRoutePath = (type: string) => {
  const path = type === NonObjectType ? NonObjectRoutePath : ObjectRoutePath;
  return path;
};

const getOptionalQuestionsPath = (country: string, id: string, placeType: string) => `${OptionalQuestionsUrl}/${country}/${placeType}/${id}`;

const getSegmentQuestionPath = (country, objectNo, placeType) => `${QuestionnaireUrl}/${country}/${placeType}/${objectNo}`;

const getSegmentQuestionRoutePath = (placeType: string) => QuestionnaireRoutePath(placeType);

const isFaqUrl = (url: string) => url === FaqUrl;
const isHomeUrl = (url: string) => url === HomeUrl;
const isEssential = (url: string) => url.includes(HomeUrl);

const shouldPreventBack = (url: string) => url.includes(QuenstionaireUrl) || url.includes(OrderReport) || url.includes(OptionalQuestionsUrl);

const getQueryParameters = (query, filteredParams: string[]) =>
  Object.keys(query).reduce(
    (acc, prop) =>
      (filteredParams || []).includes(prop) ? `${acc}${acc.length > 0 ? "&" : ""}${prop}${query[prop].length > 0 ? "=" + query[prop] : ""}` : acc,
    ""
  );

const getPermanentQueryParameters = (query) => getQueryParameters(query, [preventBackParameter, embedApp, embedInRiskPredictionParameter]);

const redirectToMissingCapabilities = (missingCapabilities: {}, query) => {
  const queryParams = getPermanentQueryParameters(query);
  const urlBuilder = [`${NoAccessUrl}?`];
  missingCapabilities[PlaceProperties.BUSINESS_SEGMENT] && urlBuilder.push("segmentMissing=true&");
  missingCapabilities[PlaceProperties.GEO_POSITION] && urlBuilder.push("locationMissing=true&");
  urlBuilder.push(queryParams);
  window.location.replace(urlBuilder.join(""));
};

const isObjectPath = (path: string) => path === NonObjectRoutePath || path === ObjectRoutePath;

export {
  getRoutePath,
  getNonObjectUrl,
  getReportUrl,
  getObjectUrl,
  redirectToMissingCapabilities,
  isFaqUrl,
  isHomeUrl,
  isEssential,
  isObjectPath,
  shouldPreventBack,
  getQueryParameters,
  getPermanentQueryParameters,
  getOptionalQuestionsPath,
  getSegmentQuestionPath,
  getSegmentQuestionRoutePath,
};
