import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

// Consider using namespaces once it makes sense: https://www.i18next.com/principles/namespaces

enum LANGUAGE_CODES {
  DUTCH = "nl",

  ENGLISH = "en",

  NORWEGIAN_BOKMAL = "nb",
  SWEDISH = "sv",
}

const LANGUAGES_LIST = [LANGUAGE_CODES.ENGLISH, LANGUAGE_CODES.SWEDISH, LANGUAGE_CODES.NORWEGIAN_BOKMAL, LANGUAGE_CODES.DUTCH];

const LANGUAGES = {
  [LANGUAGE_CODES.DUTCH]: "Nederlands",
  [LANGUAGE_CODES.ENGLISH]: "English",
  [LANGUAGE_CODES.NORWEGIAN_BOKMAL]: "Norsk",
  [LANGUAGE_CODES.SWEDISH]: "Svenska",
};

const COUNTRY_LANGUAGES = {
  GB: LANGUAGE_CODES.ENGLISH,
  NL: LANGUAGE_CODES.DUTCH,
  NO: LANGUAGE_CODES.NORWEGIAN_BOKMAL,
  SE: LANGUAGE_CODES.SWEDISH,
  US: LANGUAGE_CODES.ENGLISH,
};

const supportedLanguageKeys = Object.keys(LANGUAGES);

const defaultLanguage = LANGUAGE_CODES.ENGLISH;

const i18NextOptions = {
  appNameForLocalization: "essentialreports",
  localizationStorage: window.localStorage,
  cachedLocalizationPrefix: "i18next_essentialreports_res_",
  timestampInCacheProp: "i18nStamp",
};

const i18nextInit = (lng = defaultLanguage) => {
  return i18next
    .use(detector)
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(
      {
        debug: process.env.NODE_ENV === "development",
        fallbackLng: lng,

        interpolation: {
          escapeValue: false,
        },
        //Note: we're using ''(empty string) as default namespace, while starting to use namespaces the useAppTranslationSync appHook needs to be modified
        defaultNS: "",
        ns: [""],
        load: "languageOnly",
        lng: lng,
        supportedLngs: LANGUAGES_LIST,
        backend: {
          backends: [
            LocalStorageBackend, // primary backend, local storage serving as a cache,
            HttpBackend,
          ],
          backendOptions: [
            {
              prefix: i18NextOptions.cachedLocalizationPrefix,
              expirationTime: 31 * 24 * 60 * 60 * 1000, //31 days of cache,
              store: i18NextOptions.localizationStorage,
            },
            {
              loadPath:
                process.env.REACT_APP_API_ENDPOINT + "/localization/translations/" + i18NextOptions.appNameForLocalization + "/{{lng}}/{{ns}}?format=json",
            },
          ],
        },
      },
      (err, t) => {
        if (err) {
          console.warn("Translation error:", err);
        }
      }
    );
};

export { i18nextInit, LANGUAGES, supportedLanguageKeys, i18next, defaultLanguage, COUNTRY_LANGUAGES, LANGUAGE_CODES, i18NextOptions };
