import React from 'react';

const Question11 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M14 4C8.49 4 4 8.49 4 14C4 19.51 8.49 24 14 24C19.51 24 24 19.51 24 14C24 8.49 19.51 4 14 4ZM14 22C9.59 22 6 18.41 6 14C6 9.59 9.59 6 14 6C18.41 6 22 9.59 22 14C22 18.41 18.41 22 14 22Z"
            fill= "#0E283D"
        />
        <path
            d= "M23.7103 22.2886C23.3203 21.8986 22.6903 21.8986 22.3003 22.2886C21.9103 22.6786 21.9103 23.3086 22.3003 23.6986L26.3003 27.6986C26.4903 27.8986 26.7403 27.9986 27.0003 27.9986C27.2603 27.9986 27.5103 27.8986 27.7103 27.7086C28.1003 27.3186 28.1003 26.6886 27.7103 26.2986L23.7103 22.2886Z"
            fill= "#0E283D"
        />
    </svg>
);

export default Question11;
