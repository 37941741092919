import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';

export const extractErrorCodes = (gqlErrors: Array<ApolloError>): string => {
    return (gqlErrors || [])
        .reduce(
            (acc: GraphQLError[], error: ApolloError) =>
                acc.concat(error?.graphQLErrors || []),
            []
        )
        .reduce(
            (acc: string, gqlError) =>
                `${gqlError?.extensions?.code || ''} ${acc}`,
            ''
        );
};
