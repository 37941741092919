import React from 'react';

const FAQHeaderIcon = () => (
    <svg
        width= "20"
        height= "20"
        viewBox= "0 0 20 20"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M14.8799 14.5437L14 15.1386V15.8257L13.449 15.3827L12.456 15.6634C11.6854 15.8812 10.8607 16 10 16C5.3783 16 2 12.673 2 9C2 5.32701 5.3783 2 10 2C14.6217 2 18 5.32701 18 9C18 11.1978 16.8307 13.225 14.8799 14.5437ZM16 17.5391V17.4337V16.2007C18.4289 14.5587 20 11.9445 20 9C20 4.02944 15.5228 0 10 0C4.47715 0 0 4.02944 0 9C0 13.9706 4.47715 18 10 18C11.0452 18 12.053 17.8557 13 17.588L14 18.392L14.0821 18.458L14.702 18.9564L15.1867 19.3461C15.5139 19.6092 16 19.3763 16 18.9564V18.3345V17.5391ZM6 10C6.55228 10 7 9.55229 7 9C7 8.44771 6.55228 8 6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10ZM11 9C11 9.55229 10.5523 10 10 10C9.44771 10 9 9.55229 9 9C9 8.44771 9.44771 8 10 8C10.5523 8 11 8.44771 11 9ZM14 10C14.5523 10 15 9.55229 15 9C15 8.44771 14.5523 8 14 8C13.4477 8 13 8.44771 13 9C13 9.55229 13.4477 10 14 10Z"
            fill= "#031F30"
        />
    </svg>
);

export default FAQHeaderIcon;
