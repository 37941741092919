import React, { useEffect, useState } from "react";
import { DarkWrapper, Description, DownloadButtonWrapper, DowloadIconWrapper, DownloadTitle } from "./styled";

import ArrowDown from "./../../icons/ArrowDown";
import useWindowSize from "./../../../hooks/useWindowSize";

import { Sizes } from "./../../../utilities/common-styles";
import { FlexWrapper, PageWrapper } from "./../../../utilities/wrappers";
import { SolidButtonLink } from "./../../../utilities/buttons";

import DownloadPdfIcon from "../../icons/DownloadPdfIcon";

type Props = {
  title: string;
  description?: string;
  label: string;
  url: string;
  showMore: string;
  showLess: string;
};

const DownloadBanner = ({ title, description, url, label, showMore, showLess }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();

  return (
    <DarkWrapper>
      <PageWrapper>
        <FlexWrapper data-cy="essential-report-download">
          {width > Sizes.mobileL ? (
            <>
              <div>
                <h1 data-cy="essential-report-download-title">{title}</h1>
                <Description data-cy="essential-report-download-desc">{description}</Description>
              </div>
              <div className="side">
                <SolidButtonLink data-cy="essential-report-download-download " href={url} target="_blank">
                  <DownloadPdfIcon />
                  {label}
                </SolidButtonLink>
              </div>
            </>
          ) : (
            <>
              {isOpen ? (
                <>
                  <DownloadTitle data-cy="essential-report-download-title">{title}</DownloadTitle>
                  <Description data-cy="essential-report-download-desc">{description}</Description>
                  <DownloadButtonWrapper>
                    <SolidButtonLink data-cy="essential-report-download-download" href={url} target="_blank">
                      <DownloadPdfIcon />
                      {label}
                    </SolidButtonLink>
                  </DownloadButtonWrapper>
                  <DowloadIconWrapper
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <div>{showLess}</div>
                    <ArrowDown flip={isOpen}></ArrowDown>
                  </DowloadIconWrapper>
                </>
              ) : (
                <>
                  <DownloadTitle data-cy="essential-report-download-title">{title}</DownloadTitle>
                  <Description data-cy="essential-report-download-desc">{description.slice(0, 80)}...</Description>
                  <DowloadIconWrapper
                    data-cy="essential-report-download-download"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <div>{showMore}</div>
                    <ArrowDown></ArrowDown>
                  </DowloadIconWrapper>
                </>
              )}
            </>
          )}
        </FlexWrapper>
      </PageWrapper>
    </DarkWrapper>
  );
};

export default DownloadBanner;
