import { useEffect, MutableRefObject } from 'react';

const useOnClickOutside = (
    ref: MutableRefObject<HTMLDivElement>,
    handler: (event?: DocumentEventMap) => void
) => {
    const listener = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            handler(event);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
