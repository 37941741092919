import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ErrorWrapper } from '../../utilities/wrappers';

interface GraphQlError {
    message: string;
    originalError?: Error;
    extensions?: { [key: string]: any };
}

interface ApolloError extends Error {
    message: string;
    graphQLErrors?: ReadonlyArray<GraphQlError>;
    networkError?: Error | null;
    extraInfo?: any;
}
const GqlErrorTypes = {
    UNAUTHORIZED: 'UNAUTHORIZED'
} as const;

interface IErrorMessages {
    errorTitle: string;
    noPermission: string;
    genericMessage: string;
}
interface IProp {
    errors: Array<ApolloError>;
    messages: IErrorMessages;
}

const extractErrorCodes = (gqlErrors: Array<ApolloError>): string => {
    return (gqlErrors || [])
        .reduce(
            (acc: any[], error: ApolloError) =>
                acc.concat(error?.graphQLErrors || []),
            []
        )
        .reduce(
            (acc: string, gqlError) =>
                `${gqlError?.extensions?.code || ''} ${acc}`,
            ''
        );
};

const GraphQlErrorView = ({ errors, messages }: IProp) => {
    useEffect(() => {
        console.error('There was a problem with loading data');
        (errors || []).forEach(error => console.warn(error));
    }, []);

    const errorCodes = extractErrorCodes(errors);

    if (errorCodes.includes(GqlErrorTypes.UNAUTHORIZED)) {
        return (
            <>
                <Helmet>
                    <title>{messages.errorTitle}</title>
                </Helmet>
                <ErrorWrapper>{messages.noPermission}</ErrorWrapper>
            </>
        );
    } else {
        return <ErrorWrapper>{messages.genericMessage}</ErrorWrapper>;
    }
};

export default GraphQlErrorView;
