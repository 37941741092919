import React from 'react';

const SecuritasLogo = () => (
    <svg
        width= "100"
        height= "54"
        viewBox= "0 0 100 54"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <g clipPath= "url(#clip0_3916:218)">
            <path
                d= "M85.2916 29.4167C93.4148 29.4167 99.9999 22.8315 99.9999 14.7083C99.9999 6.58514 93.4148 0 85.2916 0C77.1684 0 70.5833 6.58514 70.5833 14.7083C70.5833 22.8315 77.1684 29.4167 85.2916 29.4167Z"
                fill= "#FC273F"
            />
            <path
                d= "M50.0001 29.4167C58.1233 29.4167 64.7084 22.8315 64.7084 14.7083C64.7084 6.58514 58.1233 0 50.0001 0C41.8769 0 35.2917 6.58514 35.2917 14.7083C35.2917 22.8315 41.8769 29.4167 50.0001 29.4167Z"
                fill= "#FC273F"
            />
            <path
                d= "M14.7083 29.4167C22.8315 29.4167 29.4167 22.8315 29.4167 14.7083C29.4167 6.58514 22.8315 0 14.7083 0C6.58514 0 0 6.58514 0 14.7083C0 22.8315 6.58514 29.4167 14.7083 29.4167Z"
                fill= "#FC273F"
            />
            <g className= "securitas-text-logo">
                <path
                    d= "M1.84155 47.7156H4.54155C4.69989 49.7406 6.26655 50.799 8.29155 50.799C9.94989 50.799 11.4249 50.0906 11.4249 48.4823C11.4249 47.424 10.7832 46.7156 9.01655 46.3656L6.81655 45.9156C4.18322 45.374 2.18322 44.4156 2.18322 41.4906C2.18322 38.6656 4.74989 36.7656 7.93322 36.7656C11.3166 36.7656 13.7916 38.699 13.9082 41.7573H11.2082C11.0499 40.0323 9.64155 39.0573 7.84989 39.0573C6.26655 39.0573 4.94155 39.9656 4.94155 41.2823C4.94155 42.2156 5.49155 42.8906 7.03322 43.2323L10.0082 43.874C13.1832 44.5573 14.1916 46.1406 14.1916 48.1656C14.1916 51.299 11.4916 53.1073 8.25822 53.1073C4.63322 53.124 1.88322 51.0573 1.84155 47.7156Z"
                    fill= "#031F30"
                />
                <path
                    d= "M27.1 47.7167H18.275C18.4833 49.5333 19.5667 50.875 21.5417 50.875C23.0583 50.875 23.95 50.125 24.2166 49.1083H26.9667C26.6417 51.3833 24.5333 53.1083 21.4917 53.1083C17.8583 53.1083 15.5667 50.475 15.5667 47.025C15.5667 43.575 17.8916 41 21.5083 41C24.8916 41 27.125 43.4333 27.125 46.6417C27.1416 47.0583 27.1167 47.4917 27.1 47.7167ZM18.4 45.7417H24.4416C24.0333 43.975 22.9833 43.2417 21.5083 43.2417C19.8333 43.2417 18.7583 44.2667 18.4 45.7417Z"
                    fill= "#031F30"
                />
                <path
                    d= "M28.575 47.0417C28.575 43.6083 30.9166 41 34.5916 41C37.6083 41 39.9249 42.8417 40.1583 45.475H37.4C37.15 44.2083 36.125 43.2917 34.5583 43.2917C32.4416 43.2917 31.2166 44.95 31.2166 47.0417C31.2166 49.1333 32.4583 50.8333 34.575 50.8333C36.1416 50.8333 37.1833 49.9 37.4166 48.6083H40.1583C39.7499 51.2667 37.6166 53.125 34.5916 53.125C30.9083 53.125 28.575 50.45 28.575 47.0417Z"
                    fill= "#031F30"
                />
                <path
                    d= "M41.6084 47.7413V41.2246H44.2001V47.4746C44.2001 49.5913 45.0001 50.8829 46.8167 50.8829C48.7917 50.8829 49.7251 49.2496 49.7251 47.1329V41.2246H52.3167V52.9163H49.7501V51.5996C48.8751 52.6246 47.5751 53.1996 46.2251 53.1413C43.0417 53.1246 41.6084 51.0829 41.6084 47.7413Z"
                    fill= "#031F30"
                />
                <path
                    d= "M54.4167 52.9172V41.2422H57.0084V42.9255C57.8501 41.4922 59.2167 41.2422 60.6417 41.2422H60.9917V43.7089H60.7417C58.4917 43.7089 56.9917 44.4172 56.9917 47.1839V52.9172H54.4167Z"
                    fill= "#031F30"
                />
                <path
                    d= "M62.3418 38.1587C62.3835 37.267 63.1418 36.5754 64.0335 36.617C64.8668 36.6587 65.5335 37.3254 65.5751 38.1587C65.6168 39.0504 64.9251 39.8087 64.0335 39.8504C63.1418 39.892 62.3835 39.2004 62.3418 38.3087C62.3418 38.2587 62.3418 38.2087 62.3418 38.1587ZM62.6585 52.9004V41.2254H65.2585V52.917L62.6585 52.9004Z"
                    fill= "#031F30"
                />
                <path
                    d= "M72.8334 52.9167C70.4668 52.9167 68.8084 51.7083 68.8084 49.0083V43.45H66.7834V41.225H68.8084V38H71.4001V41.225H74.3084V43.45H71.4001V48.8083C71.4001 49.5833 71.7501 50.625 73.0084 50.625H74.4834V52.9167H72.8334Z"
                    fill= "#031F30"
                />
                <path
                    d= "M75.6499 49.6255C75.6499 46.9922 77.7666 45.9255 80.6249 45.9255H83.4832V45.3339C83.4832 43.9922 82.4832 43.0589 81.0332 43.0589C79.7582 43.0589 78.7832 43.7172 78.5666 44.7172H75.9332C76.2082 42.2505 78.3999 40.9922 81.1582 40.9922C84.1332 40.9922 86.0416 42.7172 86.0416 45.6255V49.6922C86.0416 50.3255 86.3582 50.6922 86.9749 50.6922H87.2499V52.9172H86.1916C85.0999 52.9172 84.0749 52.6422 83.8749 51.3505C82.8999 52.6672 81.4666 53.1422 79.9416 53.1422C77.4916 53.1255 75.6499 51.9922 75.6499 49.6255ZM83.4832 48.2672V47.8089H80.3499C79.3332 47.8089 78.2832 48.2922 78.2832 49.4922C78.2832 50.6922 79.2332 51.1505 80.3749 51.1505C82.3332 51.1505 83.4832 50.0089 83.4832 48.2672Z"
                    fill= "#031F30"
                />
                <path
                    d= "M88.1418 49.0837L90.6418 49.0587C90.8502 50.3087 91.8669 51.0587 93.3669 51.0587C94.3669 51.0587 95.5752 50.7087 95.5752 49.5421C95.5752 48.7421 95.0085 48.4087 93.7335 48.1837L91.6918 47.8337C90.2585 47.5837 88.3752 46.9921 88.3752 44.7504C88.3752 42.7254 90.2168 41.0254 93.1252 41.0254C96.5335 41.0254 97.9419 43.2504 97.9585 44.8421H95.4585C95.2252 43.7254 94.2752 43.0754 93.0252 43.0754C91.6835 43.0754 90.9085 43.7837 90.9085 44.5254C90.9085 45.1837 91.4085 45.4587 92.2002 45.5837L94.7669 46.0587C96.9752 46.4671 98.1502 47.5337 98.1502 49.3004C98.1502 51.9587 95.7668 53.1171 93.2918 53.1171C90.7502 53.1254 88.3918 51.9004 88.1418 49.0837Z"
                    fill= "#031F30"
                />
            </g>
        </g>
        <defs>
            <clipPath id= "clip0_3916:218">
                <rect width= "100" height= "53.1417" fill= "white" />
            </clipPath>
        </defs>
    </svg>
);

export default SecuritasLogo;
