import styled from 'styled-components';

interface SpaceProps {
    mb?: number;
    ml?: number;
    mr?: number;
    mt?: number;
}

const getMargins = (mt: number, mr: number, mb: number, ml: number) => {
    const marginTop = mt || 0;
    const marginRight = mr || 0;
    const marginBottom = mb || 0;
    const marginLeft = ml || 0;

    return `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`;
};

const Space = styled.div<SpaceProps>`
    width: 100%;
    display: block;
    margin: ${props => getMargins(props.mt, props.mr, props.mb, props.ml)};
`;

export default Space;
