import styled from "styled-components";
import { Boxes } from "./../Common/styled";

import { Text } from "../../utilities/typography";
import { Colors, Devices } from "../../utilities/common-styles";
import { FlexWrapper, ShadowWrapper } from "../../utilities/wrappers";
const { BorderBox } = Boxes;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LegendWrapper = styled.div`
  display: none;

  @media ${Devices.mobileS} {
    display: inherit;
    margin-bottom: 16px;
  }
`;

export const Description = styled(Text).attrs(() => ({
  size: "large",
  margin: "24px",
}))`
  min-height: 72px;
`;

export const MapWrapper = styled.div`
  max-height: 707px;

  > div:last-child {
    border-radius: 8px;
    height: 488px;
    overflow: hidden;
  }

  @media ${Devices.mobile} {
    flex: 1 1 auto;
  }

  @media ${Devices.mobile} {
    > div:last-child {
      border-radius: 0;
    }
  }
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TopRiskMapWrapper = styled.div`
  max-height: 707px;
  width: 100%;

  > div:last-child {
    border-radius: 4px;
    height: 488px;
    overflow: hidden;
  }

  @media ${Devices.mobile} {
    flex: 1 1 auto;
  }

  @media ${Devices.mobileS} {
    > div:last-child {
      border-radius: 0;
    }
  }
`;

export const RiskWrapper = styled(FlexColumn)`
  flex: 0 1 38%;

  @media ${Devices.mobile} {
    flex: 1 1 auto;
  }
`;

export const AddressWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2rem;

  @media ${Devices.mobile} {
    display: none;
  }
`;

export const Wrapper = styled(FlexWrapper)`
  margin: auto;
  padding: 0 40px 10px;
  max-width: ${(props) => props.theme.maxWidth};
  background-color: ${Colors.cardBackgroundPrimary};
  @media ${Devices.mobileS} {
    padding: 0;
  }
`;

export const TitleWrapper = styled(BorderBox)`
  .desktop-title {
    margin: 0 0 0 8px;
  }

  @media ${Devices.mobileS} {
    margin: 0;
    padding: 8px 0;
    margin: 0 16px 16px 16px;

    .desktop-title {
      display: none;
    }
  }
`;

export const TitleWrapperTopRisks = styled.div`
  padding: 24px;

  @media ${Devices.mobileS} {
    padding: 16px 24px;
  }
`;

export const RiskContainer = styled(ShadowWrapper)`
  box-shadow: none;
  border: 0px;
  background: ${Colors.cardBackgroundPrimary};
`;

export const OverallRiskBar = styled.div<{ backgroundColor?: string }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.backgroundColor};
`;

export const RiskLevels = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  > div {
    margin-right: 0.5rem;
  }
`;

export const StyledList = styled.div`
  position: absolute;
  z-index: 1000;
  top: 110px;
  right: 20px;
  background-color: ${Colors.darkBlue};
  padding: 18px 16px;
  border-radius: 4px;

  @media ${Devices.mobile} {
    top: 155px;
  }
`;

export const TopRiskBottom = styled.div`
  position: absolute;
  min-width: 100%;
  bottom: 20px;
  z-index: 800;
  display: flex;
  justify-content: center;
`;

export const TopRiskIconWrapper = styled.div`
  flex-direction: column;
  align-self: center;
  margin-right: 17.5px;
`;
export const MapCon = styled.div`
  height: 1000px;
  width: 100%;
`;

export const TopRiskList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const RiskList = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Devices.mobile} {
    position: absolute;
    top: 80;
    right: 0;
  }
`;

export const DragAndDropColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const RowRisks = styled.div`
  z-index: 1000;
  width: 100%;
  @media ${Devices.mobile} {
    margin-right: 10px;
  }

  @media ${Devices.mobileS} {
    margin-right: 0px;
  }
`;

export const MenuComponent = styled.div`
  width: 100px;
  height: 78px;
  display: flex;
  justify-content: center;
  @media ${Devices.mobile} {
    position: relative;
    top: 85px;
    left: 0;
    right: 0;
  }
`;

export const StyledItem = styled.div<{
  isSelected: boolean;
  isLongText: boolean;
}>`
  width: 100%;
  height: 78px;
  background: ${(props) => (props.isSelected ? Colors.cardSelection : Colors.cardBackgroundPrimary)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 8px 24px;
  margin: 4px;

  @media ${Devices.mobile} {
    width: 100%;
    padding: 8px;

    ${Text}: first-of-type {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0px;
      max-width: ${(props) => (props.isLongText ? "60px" : "90px")};
    }
  }
`;

export const StyledColumnItem = styled.div`
  width: 184px;
  height: 48px;
  background: ${Colors.cardBackgroundPrimary};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 4px 8px;
  margin: 4px;
  display: flex;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const TopRiskListPadding = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  z-index: 9;
  padding: 24px;
`;

export const BottomBarPadding = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

export const BottomBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 8px;
  background: ${Colors.cardBackgroundPrimary};
  border-radius: 4px;
  color: white;
`;

export const ShowMoreContainer = styled.div`
  color: ${Colors.primaryPurple};
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  div {
    margin-right: 1rem;
  }

  @media ${Devices.mobileL} {
    dispplay: none;
  }
`;
