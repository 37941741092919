import { Text } from "../../utilities/typography";
import { ModalWrapper } from "./../Common/Modal/styled";
import CloseIcon from "./../icons/CloseIcon";
import ContactFinalStepIcon from "./../icons/ContactFinalStepIcon";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import React, { useRef, useState } from "react";
import { ModalItems } from "./ContactUsModal";
import EmailModalComponent from "./EmailModalComponent";
import { ContactButton, Content, FinalStepContent, FormContent, ModalHeader } from "./styled";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean;
  onCloseClick: () => void;
};

const FAQEmailComponent = ({ visible, onCloseClick }: Props) => {
  const modalRef = useRef();
  const { t } = useTranslation();
  const [index, setIndex] = useState(ModalItems.Start);

  useOnClickOutside(modalRef, () => {
    onCloseClick();
    setIndex(ModalItems.Start);
  });

  const closeAndCleanup = () => {
    onCloseClick();
    setIndex(ModalItems.Start);
  };
  return visible ? (
    <ModalWrapper>
      <Content ref={modalRef}>
        <FormContent>
          <ModalHeader isContentPage={false}>
            <div onClick={closeAndCleanup}>
              <CloseIcon size={16} color="white" />
            </div>
          </ModalHeader>
          {index === ModalItems.Start && <EmailModalComponent objectAddress={"-"} setIndex={setIndex}></EmailModalComponent>}

          {index === ModalItems.End && (
            <FinalStepContent>
              <ContactFinalStepIcon />
              <Text weight="700" size="16px" lineHeight="24px" margin="20px 0 0 0">
                {t("common.contactUs.finalPageTitle")}
              </Text>
              <ContactButton onClick={closeAndCleanup}>{t("common.contactUs.finalPageButton")}</ContactButton>
            </FinalStepContent>
          )}
          {index === ModalItems.Error && (
            <FinalStepContent>
              <Text weight="700" size="16px" lineHeight="24px" margin="20px 0 0 0">
                {t("common.contactUs.errorPageTitle")}
              </Text>
              <ContactButton onClick={closeAndCleanup}>{t("common.contactUs.finalPageButton")}</ContactButton>
            </FinalStepContent>
          )}
        </FormContent>
      </Content>
    </ModalWrapper>
  ) : null;
};

export default FAQEmailComponent;
