import React from 'react';

const DownloadIcon = () => (
    <svg
        width= "30"
        height= "36"
        viewBox= "0 0 30 36"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M15 0.667969C15.9205 0.667969 16.6667 1.41416 16.6667 2.33464V21.6443L20.4882 17.8228C21.139 17.1719 22.1943 17.1719 22.8452 17.8228C23.4961 18.4737 23.4961 19.5289 22.8452 20.1798L16.1785 26.8465L16.1604 26.8643C15.8604 27.1554 15.4511 27.3346 15 27.3346C14.5452 27.3346 14.133 27.1525 13.8323 26.8572L13.8215 26.8465L7.15482 20.1798C6.50395 19.5289 6.50395 18.4737 7.15482 17.8228C7.8057 17.1719 8.86097 17.1719 9.51184 17.8228L13.3333 21.6443V2.33464C13.3333 1.41416 14.0795 0.667969 15 0.667969ZM3.33333 27.3346C3.33333 26.4142 2.58714 25.668 1.66667 25.668C0.746192 25.668 0 26.4142 0 27.3346V34.0013C0 34.9218 0.746192 35.668 1.66667 35.668H28.3333C29.2538 35.668 30 34.9218 30 34.0013V27.3346C30 26.4142 29.2538 25.668 28.3333 25.668C27.4129 25.668 26.6667 26.4142 26.6667 27.3346V32.3346H3.33333V27.3346Z"
            fill= "#031F30"
        />
    </svg>
);

export default DownloadIcon;
