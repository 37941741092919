import styled from "styled-components";
import { Devices, Colors } from "../../../utilities/common-styles";
import Space from "../../Common/Space";

export const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const GraphContainer = styled.div`
  min-height: 300px;
  height: 300px;
  cursor: pointer;
  @media ${Devices.mobile} {
    .VictoryContainer {
      touch-action: auto !important;
    }
  }
`;

export const HourlyGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0 20px;

  @media ${Devices.mobile} {
    margin: 0;
  }
`;

export const DistributionWrapper = styled.div`
  overflow-x: auto;

  @media ${Devices.desktop} {
    max-width: 100%;
    overflow-x: hidden;
  }

  @media ${Devices.mobileS} {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media ${Devices.mobileL} {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: ${Colors.mediumGrey};
    }
  }
`;

export const HeaderWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;
  max-width: 786px;
`;

export const LegendElement = styled.div`
  margin-right: 4px;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

export const CheckboxField = styled.div`
  input {
    margin-right: 12px;
    flex: 0 0 32px;
    width: 16px;
    height: 16px;
    position: relative;
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  width: 42px;
  height: 24px;
  background: ${Colors.cardBackgroundPrimary};
  border: 2px solid ${Colors.borderDarkMode};
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    border: 1px solid ${Colors.backgroundDarkMode};
    background: ${Colors.borderDarkMode};
  }
`;
export const CheckBox = styled.input`
  &:checked + ${CheckBoxLabel} {
    background: ${Colors.primaryPurple};
    border: none;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 20px;
      margin-top: 2px;
      transition: 0.2s;
      background: ${Colors.backgroundDarkMode};
    }
  }
`;

export const LegendLine = styled(Space)<{
  color?: string;
}>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color || props.theme.red};
  border-radius: 1px;
  margin-left: 8px;
`;

export const LegendTag = styled.div<{
  selected: boolean;
}>`
  background-color: ${(props) => (props.selected ? Colors.purpleHover : "none")};
  padding: 4px 16px;
  border-radius: 36px;
  cursor: pointer;
`;

export const Legend = styled.div`
  display: flex;
`;

export const ChartLegend = styled.div`
  display: flex;
  padding: 2px;
  border: 2px solid ${Colors.purpleHover};
  border-radius: 24px;
  align-items: center;
  justify-content: flex-start;
`;
export const ChartLegendElement = styled.div`
  display: flex;
  margin-right: 10px;
  @media ${Devices.mobile} {
    margin-bottom: 10px;
  }
`;
export const TooltipContent = styled.div`
  background-color: ${Colors.secondaryPurple};
  overflow: visible;

  color: ${Colors.white};
  border-radius: 2px;
  padding: 8px;
  z-index: 100000;
  opacity: 0.95;
  div {
    font-size: 0.5 !important;
  }
`;

export const TooltipContainer = styled.foreignObject`
  overflow: visible;
  z-index: 100000;
`;

export const RiskDistributionContainer = styled.div`
  background-color: ${Colors.cardBackgroundPrimary};
  border-radius: 4px;
`;
export const SwitchWrapper = styled.div`
  display: flex;
`;

export const Switcher = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div<{
  shouldBeVisible: boolean;
}>`
  height: 15px;
  cursor: pointer;
  pointer-events: ${(props) => (props.shouldBeVisible ? "auto" : "none")};
  svg {
    visibility: ${(props) => (props.shouldBeVisible ? "visible" : "hidden")};
  }
`;
export const DayWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
`;

export const BottomBar = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;
export const TextWrapper = styled.div`
  position: absolute;
  left: 20px;
  cursor: pointer;
`;
