import styled from 'styled-components';
import { Colors, Devices } from '../../../utilities/common-styles';
export const DarkWrapper = styled.div`
    margin: 0 0 32px 0;
    padding: 24px 0px;
    background: ${Colors.darkBlue};
    color: ${Colors.white};
    transition: all 0.3s ease;

    .side {
        display: flex;
        align-items: center;
    }
    h1 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.5em;
    }
    img.download {
        margin-right: 1.5rem;
    }

    @media ${Devices.mobile} {
        margin: 0 0 24px 0;
    }
`;

export const Description = styled.div`
    max-width: 90%;
    white-space: pre-wrap;
    font-size: 16px;
`;

export const DownloadButtonWrapper = styled.div`
    width: 100%;
    cursor: pointer;
    a {
        display: flex;
        justify-content: center;
    }
`;

export const DowloadIconWrapper = styled.div`
    width: 100%;
    color: ${Colors.primaryPurple};
    font-size: 14px;
    display: flex;
    justify-content: flex-end;

    div {
        margin-right: 1rem;
    }
`;

export const DownloadTitle = styled.h3`
    margin: 0;
`;
