import React from 'react';

const QuestionConnector = () => (
    <svg
        width= "34"
        height= "15"
        viewBox= "0 0 34 15"
        fill= "#6C47CE"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M32.7795 8.21937C33.1794 7.82596 33.1794 7.17403 32.7795 6.78063L26.1867 0.295054C25.7868 -0.0983514 25.124 -0.0983514 24.7241 0.295054C24.3242 0.68846 24.3242 1.34039 24.7241 1.7338L30.5857 7.5L24.7241 13.2662C24.3242 13.6596 24.3242 14.3115 24.7241 14.7049C25.124 15.0984 25.7868 15.0984 26.1867 14.7049L32.7795 8.21937ZM2.76315 7.5C2.76315 6.79187 3.33445 6.22986 4.0543 6.22986V4.19539C2.19184 4.19539 0.683594 5.66785 0.683594 7.51124H2.76315V7.5ZM4.0543 6.22986C4.77414 6.22986 5.34545 6.79187 5.34545 7.5H7.41357C7.41357 5.66785 5.91675 4.18415 4.04287 4.18415V6.21862L4.0543 6.22986ZM5.34545 7.5C5.34545 8.20813 4.77414 8.77014 4.0543 8.77014V10.8046C5.91675 10.8046 7.425 9.33215 7.425 7.48876H5.35687L5.34545 7.5ZM4.0543 8.77014C3.33445 8.77014 2.76315 8.20813 2.76315 7.5H0.69502C0.69502 9.33215 2.19184 10.8158 4.06572 10.8158V8.78138L4.0543 8.77014ZM6.38522 8.51161H32.0483V6.47714H6.39665V8.51161H6.38522Z"
            fill= "#6C47CE"
        />
    </svg>
);

export default QuestionConnector;
