import { useEffect, useState } from "react";
import useSessionStorage from "./useSessionStorage";

interface UsePagination {
  page: number;
  size: number;
  pageChangeHandler({ selected }: { selected: number }): void;
  getNumberOfPages(total: number): number;
}

const usePagination = (pageSize = 10): UsePagination => {
  const [storedPage, setStoredPage] = useSessionStorage<number>(`storedPage$1`, 0);

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    setSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    setPage(storedPage);
  }, []);

  const pageChangeHandler = ({ selected }: { selected: number }) => {
    if (selected > -1) {
      setPage(selected);
      setStoredPage(selected);
    }
  };

  const getNumberOfPages = (total: number): number => {
    return Math.ceil(total / size);
  };

  return {
    pageChangeHandler,
    getNumberOfPages,
    page,
    size,
  };
};

export default usePagination;
