import SegmentForm from "./SegmentForm";
import React from "react";
import { PlaceTypes, getPlaceNamespace } from "../../../utilities/gqlUtilities";

import { OBJECT_PLACE_PROPERTIES_DETAILS } from "../../../queries/objects";

import { isSegmentLocked } from "../../../utilities/placePropertiesUtils";

import useCheckBusinessSegmentIsSet from "../../../hooks/useCheckBusinessSegmentIsSet";
import { PlaceProperties } from "../../../utilities/placeCapabilitiesUtils";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GraphQlErrorView from "../../Common/GraphQlErrorView";
import WrappedLoader from "../../WrappedLoader";
import { useQuery } from "@apollo/client";

const Questionnaire = () => {
  const { t } = useTranslation();

  const { country, id } = useParams<{ country: string; id: string }>();

  const useQueryCb = () =>
    useQuery(OBJECT_PLACE_PROPERTIES_DETAILS, {
      variables: {
        objectNo: id,
        propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
        placeNamespace: getPlaceNamespace(country),
        placeType: PlaceTypes.ObjectPlaceType,
        accessVerb: "riskobject.view",
        country,
      },
    });
  const isBusinessSegmentLocked = (data) => isSegmentLocked(data?.object?.placeProperties);

  const { loading, error } = useCheckBusinessSegmentIsSet({
    invokeQuery: useQueryCb,
    isBusinessSegmentLocked,
    country,
    placeId: id,
    placeType: PlaceTypes.ObjectPlaceType,
  });

  if (error) {
    return (
      <GraphQlErrorView
        errors={[error]}
        messages={{
          errorTitle: t("common.error"),
          genericMessage: t("common.noDataError"),
          noPermission: t("common.no-permissions"),
        }}
      />
    );
  }

  return loading ? <WrappedLoader /> : <SegmentForm country={country} objectId={id} placeType={PlaceTypes.ObjectPlaceType} />;
};

export default Questionnaire;
