import React from 'react';
import { StyledText } from '../../styles/Common';

export const Dashboard: React.FC = () => {
    return (
        <>
            <StyledText color= "white">Dashboard</StyledText>
        </>
    );
};
