import React from "react";
import ColumnItem from "./ColumnItem";
import { Droppable } from "react-beautiful-dnd";
import { StyledList } from "./styled";

import { useTranslation } from "react-i18next";

import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";

const RiskColumn = ({ col: { list, id } }) => {
  const { t } = useTranslation();
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <StyledList {...provided.droppableProps} ref={provided.innerRef}>
          <Text data-cy="customize-categories" size="14px" mobileFontSize="12px" bold lineHeight="21px" color={Colors.text}>
            {t("reportObject.topRisk.customizeCategories")}
          </Text>
          <Text data-cy="drag-and-drop" size="14px" mobileFontSize="12px" lineHeight="21px" color={Colors.borderDarkMode} margin="0 0 18px 0">
            {t("reportObject.topRisk.dragAndDrop")}
          </Text>
          {list.map((elem, index) => (
            <ColumnItem data-cy={`question-contaner-${elem.category}`} key={elem.category} text={elem.category} risk={elem.risk} index={index} />
          ))}
          {provided.placeholder}
        </StyledList>
      )}
    </Droppable>
  );
};

export default RiskColumn;
