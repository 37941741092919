import { useEffect, useState } from "react";
import { QueryResult } from "react-apollo";
import { useNavigate } from "react-router-dom";
import { PlaceProperties } from "../utilities/placeCapabilitiesUtils";
import { getSegmentQuestionPath } from "./../utilities/urlUtils";

export interface UseAccessGuard {
  loading: boolean;
  canAccess: boolean;
}

interface PlaceProperty {
  locked: boolean;
  name: string;
  value: string;
}

export interface UseAccessGuardProp {
  placeId: string;
  placeType: string;
  redirectUrl?: string;
  useDefinedQuery: () => QueryResult | { data?; loading?; error? };
  getPlaceProperties: (data: { object?: { placeProperties: [] }; nonObject?: { allPlaceProperties: [] } }) => PlaceProperty[];
  country: string;
}

const redirectToMissingCapabilities = (missingCapabilities: {}, navigate) => {
  missingCapabilities[PlaceProperties.BUSINESS_SEGMENT] && navigate("/noaccess/segmentMissing");
  missingCapabilities[PlaceProperties.GEO_POSITION] && navigate("/noaccess/locationMissing");
};

const useAccessGuard = ({ placeId, placeType, redirectUrl, useDefinedQuery, getPlaceProperties, country }: UseAccessGuardProp): UseAccessGuard => {
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data, loading: dataLoading, error } = useDefinedQuery();
  useEffect(() => {
    if (!dataLoading) {
      const placeProperties = getPlaceProperties(data);

      if (placeProperties?.length > 0) {
        const segmentProperty = placeProperties[0];
        const locked = segmentProperty?.locked;
        const value = segmentProperty?.value;
        //segment could be selected
        if (locked === false) {
          redirectUrl = redirectUrl || getSegmentQuestionPath(country, placeId, placeType);
          navigate(redirectUrl);
          setCanAccess(true);
        } else {
          //segment is locked but with empty value
          if (value === "") {
            setCanAccess(false);
            redirectToMissingCapabilities(PlaceProperties.BUSINESS_SEGMENT, navigate);
          } else {
            setCanAccess(true);
          }
        }
      } else {
        setCanAccess(false);
      }
    }
  }, [dataLoading, data]);

  useEffect(() => {
    error && console.error(error);
  }, [error]);

  return {
    loading: dataLoading,
    canAccess,
  };
};

export default useAccessGuard;
