import styled from 'styled-components';
import { Colors } from '../../../utilities/common-styles';

export const InputWrapper = styled.div<{
    text?: string;
}>`
    position: relative;
    height: 120px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-top: 16px;
    border: 2px solid ${Colors.borderDarkMode};
    box-sizing: border-box;
    border-radius: 24px;

    svg {
        circle {
            fill: ${Colors.purpleHover};
        }
    }

    textarea {
        background: none;
        color: ${props => (props.text ? Colors.text : Colors.greyLabel)};
        resize: none;
        font-family: SecuritasPro;
    }

    label {
        background: ${Colors.navyBlue};
        border-radius: 11px;
        color: ${Colors.borderDarkMode};
        padding: 2px 8px;
    }
`;

export const StyledInput = styled.textarea`
    width: 100%;
    font-size: 16px;
    border: none;
    background: none;
    height: 100%;
    max-length: 10;

    :focus {
        outline: none;
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${Colors.mediumGrey};
    }
`;

export const InputLabel = styled.label<{ hidden?: boolean }>`
    position: absolute;
    left: 16px;
    top: -11px;
    font-size: 1.3rem;
    background-color: ${props => props.theme.white};
    border-radius: 2px;
    padding: 0 8px;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

export const InputContent = styled.div`
    padding: 16px 0 16px 16px;
    width: 100%;
    height: 100%;
`;

export const TextAreaIconWrapper = styled.div`
    align-self: flex-end;
    margin-bottom: 1px;
    margin-right: 3px;
`;
