import React from 'react';

const GeographicalPin = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M15.8 2C12.9373 2.00529 10.1933 3.14482 8.16908 5.16907C6.14483 7.19332 5.00529 9.93727 5 12.8C5.00082 15.566 5.87582 18.261 7.5 20.5L15 29.6C15.207 29.829 15.4926 29.9718 15.8 30C15.9565 30.0078 16.1124 29.9751 16.2525 29.905C16.3927 29.835 16.5124 29.7299 16.6 29.6L24.1 20.4C25.7242 18.161 26.5992 15.466 26.6 12.7C26.5736 9.85298 25.4242 7.13162 23.4016 5.12781C21.3791 3.12399 18.6471 1.99988 15.8 2ZM15.8 17C15.0089 17 14.2355 16.7654 13.5777 16.3259C12.9199 15.8863 12.4072 15.2616 12.1045 14.5307C11.8017 13.7998 11.7225 12.9955 11.8769 12.2196C12.0312 11.4437 12.4122 10.731 12.9716 10.1716C13.531 9.61216 14.2437 9.23118 15.0196 9.07684C15.7956 8.9225 16.5998 9.00169 17.3307 9.30444C18.0617 9.60719 18.6864 10.1199 19.1259 10.7777C19.5654 11.4355 19.8 12.2089 19.8 13C19.8 14.0609 19.3786 15.0783 18.6284 15.8284C17.8783 16.5786 16.8609 17 15.8 17Z"
            fill= "#031F30"
        />
    </svg>
);

export default GeographicalPin;
