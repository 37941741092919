import React from 'react';

const Question2 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M24.2422 7.02344H7.74219C6.2234 7.02344 4.99219 8.36658 4.99219 10.0234V24.0234C4.99219 25.6803 6.2234 27.0234 7.74219 27.0234H24.2422C25.761 27.0234 26.9922 25.6803 26.9922 24.0234V10.0234C26.9922 8.36658 25.761 7.02344 24.2422 7.02344Z"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M21.6193 18H11.5C9 18 9 19.1193 9 20.5C9 21.8807 9 23 11.5 23H21.6193C23 23 23 21.8807 23 20.5C23 19.1193 23 18 21.6193 18Z"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M18.5 18V23"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M13.5 18V23"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M29.9922 13.0234L29.9922 2.02344"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M1.99219 13.0234L1.99219 2.02344"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M10.5 15C11.3284 15 12 14.3284 12 13.5C12 12.6716 11.3284 12 10.5 12C9.67157 12 9 12.6716 9 13.5C9 14.3284 9.67157 15 10.5 15Z"
            fill= "#F7F7F7"
        />
        <path
            d= "M21.5 15C22.3284 15 23 14.3284 23 13.5C23 12.6716 22.3284 12 21.5 12C20.6716 12 20 12.6716 20 13.5C20 14.3284 20.6716 15 21.5 15Z"
            fill= "#F7F7F7"
        />
        <rect
            x= "26.9922"
            y= "12.0234"
            width= "3"
            height= "9"
            rx= "1.5"
            stroke= "#F7F7F7"
            strokeWidth= "2"
        />
        <rect
            x= "1.99219"
            y= "12.0234"
            width= "3"
            height= "9"
            rx= "1.5"
            stroke= "#F7F7F7"
            strokeWidth= "2"
        />
    </svg>
);

export default Question2;
