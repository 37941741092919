import { useQuery } from '@apollo/client';

import {
    transformTopRisks,
    transformWeeklyRisks
} from './../components/utils';
import { getAbbreviatedLocation } from './../utilities/addressFormatter';
import { PlaceProperties } from './../utilities/placeCapabilitiesUtils';

import {
    NON_OBJECT,
    NON_OBJECT_STATIC_RISK,
    NON_OBJECT_WEEKLY_RISK
} from '../queries/nonObjects';
import { LIST_TOP_RISK } from '../queries/objects';
import { getPlaceNamespace, PlaceTypes } from '../utilities/gqlUtilities';
import { getSegment } from '../utilities/placePropertiesUtils';

const useNonObjectBaldrView = ({
    objectNumber,
    country,
    report
}: {
    objectNumber: string;
    country: string;
    report: any;
}) => {
    const latitude = report?.position?.latitude;
    const longitude = report?.position?.longitude;
    const objectPosition = getAbbreviatedLocation([
        report?.position?.latitude,
        report?.position?.longitude
    ]);
    const { data, loading, error } = useQuery(NON_OBJECT, {
        variables: {
            latitude,
            longitude,
            country,
            nonObjectId: objectNumber,
            propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
            placeNamespace: getPlaceNamespace(country),
            placeType: PlaceTypes.NewLocationPlaceType
        },
        ssr: false
    });
    const segment = getSegment(data?.nonObject?.placeProperties);

    const { data: currentTopRisks } = useQuery(LIST_TOP_RISK, {
        variables: {
            objectNumber,
            segment,
            placeType: PlaceTypes.NewLocationPlaceType,
            objectCountry: country.toLocaleUpperCase()
        }
    });

    const listOfCurrentSelectedTopRisks =
        currentTopRisks?.listTopRisks?.riskCategories?.map(risk =>
            risk.riskCategory.toLowerCase()
        ) || [];

    const { data: staticRisk } = useQuery(NON_OBJECT_STATIC_RISK, {
        variables: {
            latitude,
            longitude,
            country,
            nonObjectId: objectNumber,
            segment
        }
    });

    const { data: weeklyRiskData, loading: weeklyRiskDataLoading } = useQuery(
        NON_OBJECT_WEEKLY_RISK,
        {
            variables: {
                latitude,
                longitude,
                country,
                nonObjectId: objectNumber,
                segment
            }
        }
    );
    const weeklyRisks = transformWeeklyRisks(
        weeklyRiskData?.nonObject?.hourlyStatistics
    );

    const topRisks = transformTopRisks(staticRisk?.nonObject?.staticRisk);

    return {
        objectLoading: loading,
        listOfCurrentSelectedTopRisks,
        weeklyRiskDataLoading,
        topRisks,
        segment,
        weeklyRisks,
        objectPosition,
        objectName: report?.name,
        errors: [error],
        objectAddress: null
    };
};
export default useNonObjectBaldrView;
