import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { FilterContainer, FilterContent, IconWrapperFilter, ShowAllContainer } from "./styled";
import ArrowDownIcon from "../icons/ArrowDownIcon";

const EventsFilter = ({ showFilter, setShowFilter, showAllIncidents, setShowAllIncidents }) => {
  const { t } = useTranslation();

  return (
    <FilterContainer expanded={showFilter}>
      <ShowAllContainer onClick={() => setShowFilter(!showFilter)}>
        <Text size="16px" mobileFontSize="12px" lineHeight="24px" weight="700" textAlign="left" margin="0">
          {showAllIncidents ? t("reportObject.geoRisk.allIncidents") : t("reportObject.geoRisk.objectEvents")}
        </Text>

        <IconWrapperFilter expanded={showFilter}>
          <ArrowDownIcon />
        </IconWrapperFilter>
      </ShowAllContainer>
      {showFilter && (
        <FilterContent>
          <Text
            size="16px"
            mobileFontSize="12px"
            lineHeight="24px"
            weight="700"
            textAlign="left"
            margin="0"
            color={showAllIncidents ? Colors.purpleHover : Colors.white}
            onClick={() => setShowAllIncidents(true)}
          >
            {t("reportObject.geoRisk.allIncidents")}
          </Text>
          <Text
            size="16px"
            mobileFontSize="12px"
            lineHeight="24px"
            weight="700"
            textAlign="left"
            margin="0"
            color={showAllIncidents ? Colors.white : Colors.purpleHover}
            onClick={() => setShowAllIncidents(false)}
          >
            {t("reportObject.geoRisk.objectEvents")}
          </Text>
        </FilterContent>
      )}
    </FilterContainer>
  );
};

export default EventsFilter;
