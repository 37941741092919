import styled from 'styled-components';
import { Colors, Devices } from '../../utilities/common-styles';

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    margin-bottom: 20px;

    border: 4px solid ${Colors.secondaryPurple};
    box-sizing: border-box;
    border-radius: 24px;
`;

const CircleIcon = styled.img`
    margin-right: 5px;
`;

const ContentWrapper = styled.div`
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
`;

const IconWrapper = styled.div``;

const FooterWrapper = styled.div`
    margin-top: 40px;
    text-align: center;
`;

const LeftWrapper = styled.div`
    position: relative;
    flex: 1 1 50%;
    background-color: ${Colors.secondaryPurple};
    min-height: 80vh;

    @media ${Devices.mobile} {
        flex: 0.3 1 50%;
        height: 160px;
        flex-basis: auto;
        min-height: unset;
        max-width: unset;
    }
`;

const MySecuritasHeader = styled.div`
    cursor: pointer;
    font-size: 22px;
    margin: 20px 0px 40px 0px;
    color: ${Colors.primaryPurple};

    @media ${Devices.mobile} {
        margin: 5px 0px 10px 0px;
    }
`;

const RightContent = styled.div`
    max-width: 700px;
`;

const RightWrapper = styled.div`
    display: flex;
    flex: 1 1 50%;
    padding: 20px min(45px, 7%);
    justify-content: center;
`;

const Wrapper = styled.div`
    min-height: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    color: white;

    a {
        color: ${Colors.primaryPurple};
    }

    @media ${Devices.mobile} {
        flex-flow: column;
    }
`;

const MailAddress = styled.a`
    font-weight: bold;
    color: ${props => props.theme.purple};
    cursor: pointer;
`;

const ImageWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${require('../../static/icons/problem_icon.svg')});
    background-size: 130%;
    background-repeat: no-repeat;
    background-position-y: min(-10vw, 50%);
    background-position-x: 50%;

    @media ${Devices.mobile} {
        background-size: 430px;
        background-position-x: 50%;
        background-repeat: no-repeat;
        background-position-y: 50%;
    }
`;

export {
    Wrapper,
    MailAddress,
    ImageWrapper,
    ContentWrapper,
    Card,
    IconWrapper,
    LeftWrapper,
    RightWrapper,
    CircleIcon,
    FooterWrapper,
    RightContent,
    MySecuritasHeader
};
