import styled from "styled-components";

import { Colors } from "../../../utilities/common-styles";

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  background-color: ${Colors.navyBlue};
  border-radius: 8px;
  min-height: 216px;
  padding: 24px 16px;
  margin: 0 16px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;
