import { Text } from "../../../utilities/typography";
import { Colors } from "../../../utilities/common-styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChartLegend, Legend, LegendTag } from "./styled";

const RisksLegend = ({ risksTypes, activeIndex, setActiveIndex }) => {
  const { t } = useTranslation();
  const legend = risksTypes.map((type) => t(`common.risk.${type}`));

  return (
    <Legend>
      <ChartLegend data-cy="essential-report-risk-distribution-graph-legend">
        {legend.map((risk, index) => (
          <LegendTag key={`${risk}${index}`} selected={index === activeIndex} onClick={() => setActiveIndex(index)} data-cy="legend-item">
            <Text size="16px" lineHeight="24px" color={Colors.white} weight="700" data-cy="legend-item-text">
              {risk}
            </Text>
          </LegendTag>
        ))}
      </ChartLegend>
    </Legend>
  );
};

export default RisksLegend;
