import React from 'react';

const Question8 = () => (
    <svg
        width= "33"
        height= "32"
        viewBox= "0 0 33 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M14.5 22L10.5 26L6.5 22"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M10.5 6V21"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M18.5 10L22.5 6L26.5 10"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M22.5 26V11"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default Question8;
