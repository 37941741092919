export const PlaceProperties = {
    BUSINESS_SEGMENT: 'BUSINESS_SEGMENT',
    GEO_POSITION: 'GEO_POSITION',
    CONSTRUCTION_PROJECT_DURATION: 'CONSTRUCTION_PROJECT_DURATION'
} as const;


interface PropertyCapability {
    name: string;
    value: string;
    supported: boolean;
    modifiable: boolean;
}

export interface PlaceCapability {
    name: string;
    supported: boolean;
    properties: PropertyCapability[];
}

const RequiredAnsweredCapabilities = [PlaceProperties.BUSINESS_SEGMENT];

export const isAnyRequiredCapabilityModifiable = (
    capabilities: PlaceCapability[]
): boolean =>
    (capabilities || [])
        .reduce((acc, cur) => acc.concat(cur.properties || []), [])
        .filter(pc =>
            RequiredAnsweredCapabilities.some(rac =>
                pc?.name?.toUpperCase().includes(rac)
            )
        )
        .some(pc => pc?.modifiable);

const RequiredPresentCapabilities = [
    PlaceProperties.BUSINESS_SEGMENT.toUpperCase(),
    PlaceProperties.GEO_POSITION.toUpperCase()
];

export const getMissingCapabilities = (capabilities: PlaceCapability[]): {} =>
    (capabilities || [])
        .reduce((acc, cur) => acc.concat(cur.properties || []), [])
        .reduce((aggr, cp) => {
            return RequiredPresentCapabilities.reduce(
                (nestedAcc, requiredCapability) => {
                    if (
                        cp?.name?.toUpperCase().indexOf(requiredCapability) !==
                            -1 &&
                        !cp?.supported
                    ) {
                        nestedAcc[requiredCapability] = true;
                    }
                    return nestedAcc;
                },
                aggr
            );
        }, {});

