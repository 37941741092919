import React, { forwardRef } from 'react';
import { ModalWrapper, Header, ModalContent } from './styled';
import CloseIcon from '../../icons/CloseIcon';
import { Props } from './type';

const ModalView = forwardRef<HTMLDivElement, Props>(
    ({ children, onCloseClick, hideHeader }: Props, ref) => (
        <ModalWrapper>
            <ModalContent ref={ref}>
                {!hideHeader && (
                    <Header>
                        <div onClick={onCloseClick}>
                            <CloseIcon large />
                        </div>
                    </Header>
                )}
                {children}
            </ModalContent>
        </ModalWrapper>
    )
);

ModalView.displayName = 'ModalView';

export default ModalView;
