import React, { useState } from "react";
import { TopRiskIconWrapper, BottomBarPadding, BottomBarWrapper, ShowMoreContainer } from "./styled";
import { Text } from "../../utilities/typography";
import { Sizes } from "../../utilities/common-styles";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import TopRiskInfoIcon from "../icons/InfoIcon";

export const TopRiskBottomLabel = ({ topRisk }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { width } = useWindowSize();

  return (
    <BottomBarPadding>
      <BottomBarWrapper>
        <TopRiskIconWrapper>
          <TopRiskInfoIcon />
        </TopRiskIconWrapper>
        <div>
          <Text data-cy="top-risk-category" bold size="16px" weight="700" lineHeight="21px" mobileFontSize="14px" mobileLineHeight="21px" color="white">
            {t(`common.risk.${topRisk.toLowerCase()}`)}
          </Text>
          {width > Sizes.mobileL ? (
            <Text data-cy="top-risk-description" size="14px" mobileFontSize="12px" mobileLineHeight="18px" lineHeight="24px">
              {t(`reportObject.topRisk.${topRisk.toLowerCase()}`)}
            </Text>
          ) : (
            <>
              {isOpen ? (
                <Text size="14px" mobileFontSize="12px" mobileLineHeight="18px" lineHeight="24px">
                  {t(`reportObject.topRisk.${topRisk.toLowerCase()}`)}
                </Text>
              ) : (
                <Text size="14px" mobileFontSize="12px" mobileLineHeight="18px" lineHeight="24px">
                  {t(`reportObject.topRisk.${topRisk.toLowerCase()}`).slice(0, 120)}
                  ...
                </Text>
              )}
              <ShowMoreContainer
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <Text data-cy="read-more" size="14px" mobileFontSize="12px" mobileLineHeight="18px" lineHeight="24px">
                  {isOpen ? t("common.download.showLess") : t("common.download.showMore")}
                </Text>
              </ShowMoreContainer>
            </>
          )}
        </div>
      </BottomBarWrapper>
    </BottomBarPadding>
  );
};
