import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../utilities/common-styles";
import { Text } from "../../../utilities/typography";
import { Views } from "../types";
import DaysSwitcher from "./DaysSwitcher";
import HourlyGraph from "./HourlyGraph";
import RisksLegend from "./RisksLegend";
import { BottomBar, HourlyGraphWrapper, RiskDistributionContainer, TextWrapper } from "./styled";

const DailyGraphWrapper = ({ risksTypes, weeklyRisks, text, setActiveView }) => {
  const { t } = useTranslation();
  const today = new Date().getDay();
  const initialDay = today === 0 ? 6 : new Date().getDay() - 1;
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedDay, setSelectedDay] = useState(initialDay);
  const formatHours = (hour: number) => (hour < 10 ? `0${hour}` : hour);

  const selectRisksData = () => {
    const riskByCategory = weeklyRisks[risksTypes[activeIndex]];
    if (riskByCategory) {
      // first 24 records is for Monday, second 24 records for Tuesday ...
      return riskByCategory?.slice(selectedDay * 24, 24 * (selectedDay + 1));
    }

    return null;
  };

  return (
    <>
      <HourlyGraphWrapper>
        <RisksLegend activeIndex={activeIndex} setActiveIndex={setActiveIndex} risksTypes={risksTypes} data-cy="graph-risk-legend" />
        <Text
          data-cy="risk-distribution-text"
          size="16px"
          lineHeight="24px"
          mobileFontSize="14px"
          mobileLineHeight="18px"
          weight="400"
          margin="20px 0 20px 0"
          color={Colors.white}
        >
          {t("reportObject.graph.subdescription")}
        </Text>
        <RiskDistributionContainer data-cy="essential-report-risk-distribution-graph">
          {selectRisksData() && <HourlyGraph width={400} xTicks={selectRisksData()?.map((hour) => hour.hour)} xTickFormater={formatHours} data={selectRisksData()} labelSize={"12px"} />}
        </RiskDistributionContainer>
      </HourlyGraphWrapper>
      <BottomBar>
        <TextWrapper>
          <Text
            size="14px"
            lineHeight="21px"
            mobileFontSize="12px"
            mobileLineHeight="18px"
            weight="700"
            margin="0 0 0 5px"
            color={Colors.primaryPurple}
            onClick={() => setActiveView(Views.WeekGraph)}
            data-cy="daily-graph-view"
          >
            {text}
          </Text>
        </TextWrapper>

        <DaysSwitcher selectedDay={selectedDay} setSelectedDay={setSelectedDay} initialDay={initialDay} />
      </BottomBar>
    </>
  );
};

export default DailyGraphWrapper;
