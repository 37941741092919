import { useOnHoverOutside } from './../../../hooks';
import React, { useRef, useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from 'victory';
import { RiskTooltip } from './RiskTooltip';
import { GraphContainer } from './styled';
import { Colors, Fonts } from './../../../utilities/common-styles';

export interface IPick {
    timestamp: number;
    risk?: number;
    diff?: number;
    name: string;
    range?: number[];
}
interface GraphProps {
    tick?: number;
}

const WeeklyGraph = ({ data, xTicks, xTickFormater, labelSize }) => {
    const contentRef = useRef();
    const singleBarWidth = 0.01;
    const padding = {
        bottom: 30,
        left: 5
    };

    const max = Math.max(...data.map(e => e.value));

    const [selected, setSelected] = useState(-1);

    useOnHoverOutside(contentRef, () => setSelected(-1));

    const currentDay = new Date().getDay() - 1;

    return (
        <GraphContainer ref={contentRef}>
            <VictoryChart
                domainPadding={{ x: [0, 10] }}
                padding={padding}
                width={400}
                height={200}
            >
                <VictoryBar
                    style={{
                        data: {
                            fill: ({ datum }) => {
                                return selected === datum._x
                                    ? Colors.accentPurple2
                                    : Colors.primaryPurple;
                            },
                            strokeLinejoin: 'round',
                            strokeWidth: 1
                        }
                    }}
                    alignment= "middle"
                    barWidth={singleBarWidth}
                    data={data}
                    y= "value"
                    animate={{ duration: 1000 }}
                />
                <VictoryBar
                    style={{
                        data: {
                            visibility: 'hidden'
                        }
                    }}
                    data={data.map(elem => ({
                        value: max,
                        hour: elem.hour,
                        label: ' '
                    }))}
                    y= "value"
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <RiskTooltip setSelected={setSelected} />
                            }
                        />
                    }
                />
                <VictoryAxis
                    tickFormat={xTickFormater}
                    tickValues={xTicks}
                    tickCount={xTicks.length || 0}
                    offsetY={28}
                    style={{
                        tickLabels: {
                            fill: ({ tick }: GraphProps) => {
                                return Math.floor(tick / 24) === currentDay
                                    ? Colors.accentPurple2
                                    : Colors.primaryPurple;
                            },
                            fontSize: labelSize,
                            padding: 2,
                            fontFamily: Fonts.SecuritasPro,
                            lineHeight: '18px',
                            letterSpacing: '0.3px'
                        }
                    }}
                />
            </VictoryChart>
        </GraphContainer>
    );
};
export default WeeklyGraph;
