import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from "./../../icons/ClearIcon";
import { InputWrapper, InputLabel, RightIconWrapper, ClearIconWrapper, StyledInput, ErrorMessage } from "./styled";

type Props = {
  placeholder?: string;
  value?: string;
  type?: string;
  renderIcon?: ReactNode;
  withoutLabel?: boolean;
  onInputClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  errorMessage?: string;
  min?: number;
  maxLength?: number;
};

const Input = ({ placeholder, value, renderIcon, withoutLabel, onInputClick, onChange, onClear, errorMessage, type, min, maxLength }: Props) => {
  const empty = !value;
  const { t } = useTranslation();

  return (
    <InputWrapper data-cy="input" className="input" onClick={onInputClick} error={errorMessage?.length > 0}>
      {!withoutLabel && (
        <InputLabel data-cy="input-label" className="input-label" hidden={empty}>
          {placeholder}
        </InputLabel>
      )}
      {!!renderIcon && <RightIconWrapper data-cy="essential-order-search-icon">{renderIcon}</RightIconWrapper>}
      <StyledInput data-cy="input-text" type={type} value={value} onChange={onChange} placeholder={placeholder} min={min} maxLength={maxLength} />
      <ClearIconWrapper data-cy="input-search-clear" onClick={onClear} hidden={empty} title={t("clear")}>
        <ClearIcon />
      </ClearIconWrapper>
      {errorMessage && <ErrorMessage data-cy="input-error-message">{errorMessage}</ErrorMessage>}
    </InputWrapper>
  );
};

Input.defaultProps = {
  placeholder: "",
  value: "",
  type: "text",
  withoutLabel: false,
  min: null,
  maxLength: null,
};

export default Input;
