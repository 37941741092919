import React from 'react';

const ObjectIcon = () => (
    <svg
        width= "40"
        height= "40"
        viewBox= "0 0 40 40"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M31.875 16.6641C31.875 21.2413 28.8571 25.8961 25.2314 29.7232C23.4825 31.5693 21.7229 33.0856 20.3971 34.1413C20.2596 34.2508 20.1271 34.3551 20 34.4541C19.8729 34.3551 19.7404 34.2508 19.6029 34.1413C18.2771 33.0856 16.5175 31.5693 14.7686 29.7232C11.1429 25.8961 8.125 21.2413 8.125 16.6641C8.125 10.1057 13.4416 4.78906 20 4.78906C26.5584 4.78906 31.875 10.1057 31.875 16.6641ZM35 16.6641C35 28.3307 20 38.3307 20 38.3307C20 38.3307 5 28.3307 5 16.6641C5 8.37979 11.7157 1.66406 20 1.66406C28.2843 1.66406 35 8.37979 35 16.6641ZM20 8.75C16.5482 8.75 13.75 11.5482 13.75 15C13.75 18.4518 16.5482 21.25 20 21.25C23.4518 21.25 26.25 18.4518 26.25 15C26.25 11.5482 23.4518 8.75 20 8.75ZM16.25 15C16.25 12.9289 17.9289 11.25 20 11.25C22.0711 11.25 23.75 12.9289 23.75 15C23.75 17.0711 22.0711 18.75 20 18.75C17.9289 18.75 16.25 17.0711 16.25 15Z"
            fill= "#031F30"
        />
    </svg>
);

export default ObjectIcon;
