import WrappedLoader from "../../WrappedLoader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Content, Description, FormWrapper, Header, Wrapper } from "../styled";
import { getFormattedAnswers } from "./formatQuestionData";
import QuestionAnswers from "./QuestionAnswers";
import QuestionContent from "./QuestionContent";
import useRedirectToReport from "./useRedirectToReport";
import { useNotifications } from "../../Common/Notifications";
import SecuritasEmail from "../../Common/SecuritasEmail";
import { parseGqlError } from "../../../utilities/gqlUtilities";
import { BackButton } from "../../Order/styled";
import BackNavigationIcon from "../../icons/BackNavigationIcon";
import useQuestionnaireUpdate from "../../../hooks/useQuestionnaireUpdate";
import GraphQlErrorView from "../../Common/GraphQlErrorView";
import { useParams } from "react-router-dom";

interface IQuestionsStepperProp {
  placeType: string;
  latitude?: number;
  longitude?: number;
  segment?: string;
}

const QuestionsStepper = (payload: IQuestionsStepperProp) => {
  const { t } = useTranslation();

  const { country, id } = useParams<{ country: string; id: string }>();

  const { questions, allQuestions, setAnswer, initialAnswers, error, loading, segmentQuestion, isAnyQuestionAvailable } = useQuestionnaireUpdate({
    country: country,
    objectId: id,
    latitude: payload.latitude,
    longitude: payload.longitude,
    placeType: payload.placeType,
  });

  const notify = useNotifications();

  const START = -1;
  const numberOfQuestions = questions.length;
  const FINISH = numberOfQuestions;

  const [answers, setAnswers] = useState(initialAnswers);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [index, setIndex] = useState(isAnyQuestionAvailable ? START : FINISH);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const handleDependencies = (update: number, newIndex: number) => {
    let shouldContinue = true;
    if (questions[newIndex] && !questions[newIndex].locked && questions[newIndex].dependenciesOk) {
      shouldContinue = false;
    } else {
      newIndex = newIndex + update;
    }

    return { shouldContinue, newIndex };
  };

  const goToNextAvailableQuestion = (newIndex) => {
    let shouldContinue = true;
    while (newIndex < FINISH && shouldContinue) {
      const result = handleDependencies(1, newIndex);
      shouldContinue = result.shouldContinue;
      newIndex = result.newIndex;
    }
    setCurrentQuestion(questions[newIndex]);
    setIndex(newIndex);
    return Promise.resolve();
  };

  const goTopreviousAvailableQuestion = (newIndex) => {
    let shouldContinue = true;
    while (newIndex > START && shouldContinue) {
      const result = handleDependencies(-1, newIndex);
      shouldContinue = result.shouldContinue;
      newIndex = result.newIndex;
    }
    setCurrentQuestion(questions[newIndex]);
    setIndex(newIndex);
  };

  useEffect(() => {
    if (!loading && !error) {
      goToNextAvailableQuestion(0);
      isAnyQuestionAvailable && setAnswers(initialAnswers);
    }
  }, [loading, error]);

  const redirectToReport = useRedirectToReport(country, id, payload.placeType);

  if (loading) return <WrappedLoader />;

  if (!!error) {
    return (
      <GraphQlErrorView
        errors={[error]}
        messages={{
          errorTitle: t("common.error"),
          genericMessage: t("common.noDataError"),
          noPermission: t("common.no-permissions"),
        }}
      />
    );
  }

  const updateAnswers = (value, name?: string) => {
    setAnswers((prev) => {
      const updatedValue = {};
      updatedValue[name || questions[index].name] = value;
      return { ...prev, ...updatedValue };
    });
  };

  const handleQuestionUpdate = (previousQuestion, newIndex) => {
    const value = answers[previousQuestion.name];
    return previousQuestion.value !== value ? setAnswer(previousQuestion, value) : goToNextAvailableQuestion(newIndex);
  };

  const updateIndex = (newIndex) => {
    const previousQuestion = questions[newIndex - 1];
    return previousQuestion && !previousQuestion.locked ? handleQuestionUpdate(previousQuestion, newIndex) : goToNextAvailableQuestion(newIndex);
  };

  const handleNextButtonClick = async (newIndex) => {
    setNextButtonDisabled(true);
    try {
      await updateIndex(newIndex);
      goToNextAvailableQuestion(index + 1);
    } catch (err) {
      notify(
        {
          description: parseGqlError(err, t),
          title: t("common.error"),
        },
        10,
        4
      );
    } finally {
      setNextButtonDisabled(false);
    }
  };

  const isBackButtonEnabled = () => index > 0;

  return (
    <FormWrapper>
      <></>
      {index > START && index < FINISH && currentQuestion && (
        <Wrapper data-cy="essential-order-question">
          <Header>
            <h2 data-cy="essential-order-question-name">{currentQuestion.displayName}</h2>
          </Header>
          <Content>
            <Description data-cy="essential-order-question-desc">{currentQuestion.description}</Description>
            <QuestionContent
              currentQuestion={currentQuestion}
              currentValue={answers[currentQuestion.name]}
              updateAnswers={updateAnswers}
              setValidationError={setValidationError}
              validationError={validationError}
            ></QuestionContent>
          </Content>
          <Button
            data-cy="essential-order-next-btn"
            onClick={() => handleNextButtonClick(index + 1)}
            disabled={(!answers[currentQuestion.name] && currentQuestion.lockValueOnUpdate) || nextButtonDisabled || validationError}
          >
            {t("optionalQuestions.startStep.actionButton")}
          </Button>
          {isBackButtonEnabled() && (
            <BackButton data-cy="essential-order-back-btn" onClick={() => goTopreviousAvailableQuestion(index - 1)}>
              <BackNavigationIcon></BackNavigationIcon>
              {t("common.back")}
            </BackButton>
          )}
        </Wrapper>
      )}

      {index === FINISH && (
        <Wrapper>
          <Header>
            <h2> {t("optionalQuestions.finishStep.title")}</h2>
          </Header>
          <Content>
            <QuestionAnswers data={getFormattedAnswers(answers, allQuestions, segmentQuestion, t)} noAnswers={t("optionalQuestions.finishStep.noAnswer")} />
            <Description data-cy="summary-description">
              {t("optionalQuestions.finishStep.description")}
              <span data-cy="securitas-email">
                <SecuritasEmail />
              </span>
            </Description>
          </Content>
          <Button onClick={redirectToReport} data-cy="continue-to-essential-btn">
            {t("optionalQuestions.finishStep.actionButton")}
          </Button>
          {isAnyQuestionAvailable && (
            <BackButton data-cy="essential-order-back-btn" onClick={() => goTopreviousAvailableQuestion(index - 1)}>
              <BackNavigationIcon></BackNavigationIcon>
              {t("common.back")}
            </BackButton>
          )}
        </Wrapper>
      )}
    </FormWrapper>
  );
};

export default QuestionsStepper;
