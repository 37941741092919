import React from 'react';

export const BackIcon = () => (
    <svg
        width= "10"
        height= "18"
        viewBox= "0 0 10 18"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M9 17L0.999999 9L9 1"
            stroke= "#031F30"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);
