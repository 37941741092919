import React from 'react';
import styled from 'styled-components';

interface IconProps {
    flip?: boolean;
}
const IconCon = styled.span<IconProps>`
    path {
        transform: ${props => (props.flip ? 'rotate(180deg)' : 'rotate(0deg)')};
        transform-origin: 50% 50%;
    }
`;

const ArrowPagination = ({ flip }: IconProps) => (
    <IconCon flip={flip}>
        <svg
            width= "4"
            height= "8"
            viewBox= "0 0 4 8"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <path
                d= "M3.84745 0.988505C4.04335 0.770078 4.05086 0.407146 3.86423 0.177874C3.67759 -0.0513975 3.36749 -0.0601883 3.17159 0.158239C3.17159 0.158239 0.148543 3.58119 0.140298 3.59113C-0.0528963 3.82767 -0.0456707 4.20271 0.156436 4.42881L3.17156 7.8411C3.36715 8.05991 3.67727 8.05173 3.86423 7.82282C4.05119 7.59391 4.0442 7.23097 3.84861 7.01216L1.51731 4.32815C1.35398 4.14012 1.35395 3.86055 1.51723 3.67248L3.84745 0.988505Z"
                fill= "#031F30"
            />
        </svg>
    </IconCon>
);

export default ArrowPagination;
