import React from 'react';

const EssentialListIcon = () => (
    <svg
        width= "9"
        height= "16"
        viewBox= "0 0 9 16"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M1.0153 15.4927C0.820303 15.4927 0.632803 15.4177 0.482803 15.2752C0.190303 14.9827 0.190303 14.5102 0.482803 14.2177L6.6928 8.00023L0.482803 1.78273C0.190303 1.49023 0.190303 1.01773 0.482803 0.725234C0.775303 0.432734 1.2478 0.432734 1.5403 0.725234L8.2753 7.46773C8.5678 7.76023 8.5678 8.23273 8.2753 8.52523L1.5403 15.2677C1.3978 15.4177 1.2028 15.4927 1.0153 15.4927Z"
            fill= "#031F30"
        />
    </svg>
);

export default EssentialListIcon;
