import gql from 'graphql-tag';

export const CALL_ME = gql`
    mutation CALL_ME(
        $firstname: String
        $objectAddress: String!
        $phoneNumber: String!
    ) {
        callMe(
            firstname: $firstname
            objectAddress: $objectAddress
            phoneNumber: $phoneNumber
        ) {
            success
        }
    }
`;

export const EMAIL_US = gql`
    mutation EMAIL_US(
        $message: String
        $objectAddress: String!
        $phoneNumber: String
        $emailAddress: String
        $firstName: String
    ) {
        emailUs(
            objectAddress: $objectAddress
            message: $message
            phoneNumber: $phoneNumber
            emailAddress: $emailAddress
            firstName: $firstName
        ) {
            success
        }
    }
`;

export const HELP_ME = gql`
    mutation HELP_ME(
        $segment: String
        $objectAddress: String!
        $risks: [IncomingRiskModelInput]
        $emailAddress: String
    ) {
        helpMe(
            objectAddress: $objectAddress
            segment: $segment
            risks: $risks
            emailAddress: $emailAddress
        ) {
            success
        }
    }
`;
