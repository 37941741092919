import gql from 'graphql-tag';

export const NON_OBJECT = gql`
    query NON_OBJECT(
        $latitude: Float!
        $longitude: Float!
        $country: String!
        $nonObjectId: String
        $propertyNames: [String]!
        $placeNamespace: String
        $placeType: String
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            riskAreaId

            placeProperties(
                propertyNames: $propertyNames
                placeNamespace: $placeNamespace
                placeType: $placeType
            ) {
                name
                value
                locked
            }
        }
    }
`;


export const NON_OBJECT_WEEKLY_RISK = gql`
    query NON_OBJECT_WEEKLY_RISK(
        $latitude: Float!
        $longitude: Float!
        $country: String!
        $nonObjectId: String!
        $segment: String!
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            hourlyStatistics(segment: $segment) {
                value
                hour
                category
            }
        }
    }
`;

export const NON_OBJECT_STATIC_RISK = gql`
    query NON_OBJECT_STATIC_RISK(
        $latitude: Float!
        $longitude: Float!
        $country: String!
        $nonObjectId: String!
        $segment: String!
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            staticRisk(segment: $segment) {
                value
                category
                timeStamp
            }
        }
    }
`;

export const NON_OBJECT_INSIGHTS = gql`
    query NON_OBJECT_INSIGHTS(
        $latitude: Float!
        $longitude: Float!
        $objectProperties: [NonObjectPropsQueryInput]
    ) {
        nonObject(latitude: $latitude, longitude: $longitude) {
            NonObjectPlaceInsights(objectProperties: $objectProperties) {
                name
                displayName
                description
                value
            }
        }
    }
`;

export const NON_OBJECT_EVENTS_PAGINATED = gql`
    query NON_OBJECT_EVENTS_PAGINATED(
        $latitude: Float!
        $longitude: Float!
        $country: String!
        $nonObjectId: String!
        $pageNumber: Int!
        $pageSize: Int!
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            incidentsListPaginated(
                pageNumber: $pageNumber
                pageSize: $pageSize
            ) {
                ts
                eventCategories
                isNearby
                hadSeen
                isNotableEvent
            }
        }
    }
`;

export const NON_OBJECT_YEARLY_EVENTS_COMPARISON = gql`
    query NON_OBJECT_YEARLY_EVENTS_COMPARISON(
        $country: String!
        $latitude: Float!
        $longitude: Float!
        $nonObjectId: String!
        $endDate: DateTime!
        $startDate: DateTime!
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            incidentsCount(startDate: $startDate, endDate: $endDate) {
                categoryEvents {
                    eventsCount
                    nonLocalizedCategory
                }
            }
        }
    }
`;
export const NON_OBJECT_TWO_WEEKS_OBJECT_EVENTS = gql`
    query NON_OBJECT_TWO_WEEKS_OBJECT_EVENTS(
        $country: String!
        $latitude: Float!
        $longitude: Float!
        $nonObjectId: String!
        $endDate: DateTime!
        $startDate: DateTime!
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            incidentsList(startDate: $startDate, endDate: $endDate) {
                ts
                eventCategories
                isNearby
                hadSeen
                isNotableEvent
            }
        }
    }
`;

export const NON_OBJECT_INCIDENTS_COUNT_BY_MONTH = gql`
    query NON_OBJECT_INCIDENTS_COUNT_BY_MONTH(
        $country: String!
        $latitude: Float!
        $longitude: Float!
        $nonObjectId: String!
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            incidentsCountByMonth {
                monthlyNearbyEventCounts {
                    month
                    year
                    totalEventsCount
                    nearbyEventCounts {
                        eventsCount
                        nonLocalizedCategory
                    }
                }
            }
        }
    }
`;
