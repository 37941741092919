import React from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../utilities/common-styles";
import { monthString } from "../../utilities/dateUtils";
import { Text } from "../../utilities/typography";
import { LineElement, TooltipContainer, TooltipContent } from "./styled";

export const RiskTooltip = (props) => {
  const { x, thisYear, previousYear, datum, t, isMobile } = props;
  const { i18n } = useTranslation();

  const currentX = isMobile ? 90 : x;

  const currentYear = thisYear[datum.i - 1];
  const lastYear = previousYear[datum.i - 1];

  const lastEvents = lastYear?.nearbyEventCounts;
  const currentEvents = currentYear?.nearbyEventCounts;

  const comparison = lastEvents?.reduce((acc, obj) => {
    const category = obj.nonLocalizedCategory;
    if (!acc[category]) {
      const current = currentEvents.find((elem) => elem.nonLocalizedCategory === category);
      acc[category] = [obj.eventsCount, current?.eventsCount || 0];
    }
    return acc;
  }, {});

  currentEvents?.forEach((elem) => {
    const current = elem.nonLocalizedCategory;
    const findInRisks = comparison[current];
    if (!findInRisks) {
      comparison[current] = [0, elem.eventsCount];
    }
  });

  const compareNumbers = (a: number, b: number) => {
    if (a === 0 || b === 0) {
      return (
        <Text size="12px" data-cy="event-category" color="red" margin="0" textAlign="right">
          {a > b ? (
            <Text size="12px" data-cy="event-category" color={Colors.greenDarkMode} margin="0" textAlign="right">
              {`-${a - b}`}
            </Text>
          ) : (
            <Text size="12px" data-cy="event-category" color={Colors.redDarkMode} margin="0" textAlign="right">
              {`+${b - a}`}
            </Text>
          )}
        </Text>
      );
    }
    if (a === b) {
      return (
        <Text size="12px" data-cy="event-category" color={Colors.orangeCategory} margin="0" textAlign="right">
          0%
        </Text>
      );
    }
    if (a > b) {
      const num = (((a - b) * 100) / a).toString();
      return (
        <Text size="12px" data-cy="event-category" color={Colors.greenDarkMode} margin="0" textAlign="right">
          {`-${parseInt(num)}%`}
        </Text>
      );
    }

    const num = (((b - a) * 100) / a).toString();
    return (
      <Text size="12px" data-cy="event-category" color={Colors.redDarkMode} margin="0" textAlign="right">
        {`+${parseInt(num)}%`}
      </Text>
    );
  };

  return (
    <g>
      <TooltipContainer x={currentX} y={0} width={270} height="251">
        {lastYear && currentYear && (
          <TooltipContent
            data-cy="essential-report-segment-risk-graph-tooltip"
            style={{
              fontSize: 12,
            }}
          >
            <Text size="12px" data-cy="event-category" color="white" margin="0 0 8px 0" textAlign="left">
              {monthString(thisYear[datum.i - 1].month - 1, i18n.language, "MMM")}
            </Text>
            <Text size="12px" data-cy="event-category" color="white" margin="0" textAlign="right">
              {currentYear?.year}
            </Text>
            <Text size="12px" data-cy="event-category" color="white" margin="0" textAlign="right">
              {lastYear?.year}
            </Text>
            <div></div>
            <Text size="12px" data-cy="event-category" color="white" margin="0 0 8px 0" textAlign="left">
              {t("segmentRisk.tooltipTotal")}
            </Text>

            <Text size="12px" data-cy="event-category" color="white" margin="0" textAlign="right">
              {currentYear?.totalEventsCount}
            </Text>
            <Text size="12px" data-cy="event-category" color="white" margin="0" textAlign="right">
              {lastYear?.totalEventsCount}
            </Text>
            <>{compareNumbers(lastYear?.totalEventsCount, currentYear?.totalEventsCount)}</>
            <LineElement />
            {Object.entries(comparison)?.map(([key, value]) => (
              <>
                <Text size="12px" data-cy="event-category" color="white" margin="0 0 4px 0" textAlign="left">
                  {t(`categorization.${key?.toLowerCase()}`)}
                </Text>

                <Text size="12px" data-cy="event-category" color="white" margin="0" textAlign="right">
                  {value[1]}
                </Text>
                <Text size="12px" data-cy="event-category" color="white" margin="0" textAlign="right">
                  {value[0]}
                </Text>
                <>{compareNumbers(value[0], value[1])}</>
              </>
            ))}
          </TooltipContent>
        )}
      </TooltipContainer>
    </g>
  );
};
