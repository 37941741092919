const sortAscByName = (a, b) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};

const getDistinct = collection => {
    const newSet = new Set(collection);
    const uniqueItems = [];
    newSet.forEach(item => uniqueItems.push(item));
    return uniqueItems;
};

export { getDistinct, sortAscByName };
