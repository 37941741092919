import React from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../utilities/common-styles";

import ImageTile from "./ImageTile";
import { Container, ShadowWrapperContainer, CancelButton } from "./styled";
import { useStore } from "react-context-hook";
import { OrderCanceledEvent } from "../../constants/trackedEvents";
import { ContentWrapper, PageWrapper } from "../../utilities/wrappers";
import { useNavigate } from "react-router";
// import { useMixpanelContextV2 } from "../BaldrView/MixpanelContextProviderV2";
import { Header } from "../Common";
const SelectOrderType = ({ isInternalUser }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { track } = useMixpanelContextV2();
  const [_index, setIndex] = useStore("index", 0);

  const cancelAction = () => {
    // track(OrderCanceledEvent, {
    //   IsInternalUser: isInternalUser,
    //   Step: window.location.pathname,
    // });
    navigate("/essential");
  };

  return (
    <Container>
      <div>
        <title>{t("order.page-title")}</title>
      </div>

      <PageWrapper data-cy="essential-order-type-screen" color="white">
        <Header title={t("order.title-order")} description={t("order.subtitle-description")}>
          {!isInternalUser && (
            <CancelButton data-cy="essential-order-cancel-btn" dangerous onClick={cancelAction}>
              {t("common.cancel")}
            </CancelButton>
          )}
        </Header>
        <ContentWrapper row background={Colors.backgroundDarkMode}>
          <ShadowWrapperContainer width="50%" margin="0 12px 0 0">
            <ImageTile
              label={t("order.order-new")}
              title={t("order.from-object")}
              description={t("order.from-object-description")}
              buttonText={t("order.lets-start")}
              buttonAction={() => setIndex(1)}
            />
          </ShadowWrapperContainer>
        </ContentWrapper>
      </PageWrapper>
    </Container>
  );
};

export default SelectOrderType;
