import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useStore } from "react-context-hook";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "../components/Dashboard/Dashboard";
import LoadingIndicator from "../components/shared/LoadingIndicator";
import { useSearchParam } from "../hooks/useSearchParam";
import { Fullscreen } from "../styles/PagesStyled";
import { PlaceTypes } from "../utilities/gqlUtilities";
import { getIsEmbedded, getIsEmbeddedInCorporate, getIsEmbeddedInMobile } from "../utilities/queryUtils";
import { getUserData, getUserDataById, hasVerbs, isUserInternal, getUserVerbs } from "../utilities/userUtils";
import { createApolloClient } from "../utils/newApolloClient";
import NewFaqView from "./../components/FAQ/NewFaqView";
import BaldrListContainer from "./BaldrList/BaldrListContainer";
import BaldrHeader from "./BaldrView/BaldrHeader";
import { CategoryModalDescriptionContext, ObjectPropertiesContext, SucceedModalContext, TopRiskUserSelectionContext } from "./Common";
import NewBaldr from "./NewBaldr";
import NewNonObjectBaldr from "./NewNonObjectBaldr";
import NoAccessView from "./NoAccessView/NoAccessView";
import Order from "./Order/Order";
import NonObjectsOptionalQuestions from "./Questions/OptionalQuestions/NonObjectsOptionalQuestions";
import QuestionsStepper from "./Questions/OptionalQuestions/QuestionsStepper";
import NonObjectQuestionnaire from "./Questions/SegmentQuestionnaire/NonObjectQuestionnaire";
import Questionnaire from "./Questions/SegmentQuestionnaire/Questionnaire";
import i18next from "i18next";
import { getPreferredLanguage } from "../utilities/preferencesUtilities";
import { Main } from "./styled";

const { SucceedModalContextProvider } = SucceedModalContext;
const { ObjectPropertiesContextProvider } = ObjectPropertiesContext;
const { TopRiskUserSelectionContextProvider } = TopRiskUserSelectionContext;
const { CategoryModalDescriptionContextProvider } = CategoryModalDescriptionContext;

const AuthenticatedApp: React.FC = () => {
  const { user } = useAuth0();
  const [_userData, setUserData] = useStore("userData", {
    name: "",
    isInternal: false,
    preferences: [],
    isEmbedded: true,
    isEmbeddedInMobile: true,
    roles: [],
    hasOrderReportAccess: false,
  });
  const [userToken, _setUserToken] = useStore("userToken", "");
  const [client, setClient] = React.useState(null);
  const [userId, setUserId] = useStore("userId", "");

  const a = useSearchParam("a");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = user && user.email ? await getUserData(userToken, user.email) : await getUserDataById(userToken, userId);
        const userVerbs = await getUserVerbs(userData?.id, userToken);
     
        const isInternalUser = await isUserInternal(userVerbs);
        const hasOrderReportAccess = await hasVerbs(userVerbs, {
          serviceVerbs: ["riskplaces.create"],
        });
        const language = getPreferredLanguage(userData?.preferences);

        if (userToken) {
          const apolloClient = createApolloClient(userToken, language);
          setClient(apolloClient);
        }

        if (language && language !== i18next.language) {
          i18next.changeLanguage(language);
        }

        const userProperties = {
          name: userData?.emails[0].address,
          isInternal: isInternalUser,
          preferences: userData?.preferences,
          isEmbedded: getIsEmbedded(a),
          isEmbeddedInMobile: getIsEmbeddedInMobile(a),
          roles: userVerbs.roles || [],
          hasOrderReportAccess: hasOrderReportAccess,
        };

        setUserData(userProperties);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    if (!!userToken) {
      fetchData();
    }
  }, [userToken, user, userId]);

  if (!client) {
    return <div>Loading...</div>;
  }

  return (
    <ApolloProvider client={client}>
      <LoadingIndicator paddingTop="40vh">
        <Main>
          <SucceedModalContextProvider>
            <CategoryModalDescriptionContextProvider>
              <ObjectPropertiesContextProvider>
                <TopRiskUserSelectionContextProvider>
                  <Fullscreen>
                    {!getIsEmbeddedInCorporate(a) && <BaldrHeader></BaldrHeader>}

                    <Routes>
                      <Route path="/" element={<BaldrListContainer />}></Route>
                      <Route path="/faq" element={<NewFaqView />}></Route>
                      <Route path="/dashboard" element={<Dashboard />}></Route>
                      <Route path="/essential" element={<BaldrListContainer />}></Route>
                      <Route path="/essential/:country/:id/" element={<NewBaldr />}></Route>
                      <Route path="/essential/:country/:id/:category" element={<NewBaldr />}></Route>
                      <Route path="/essential/non-object/:id" element={<NewNonObjectBaldr />}></Route>

                      <Route path="/noaccess/segmentMissing" element={<NoAccessView segmentMissing={true} locationMissing={false} />}></Route>
                      <Route path="/noaccess/locationMissing" element={<NoAccessView segmentMissing={false} locationMissing={true} />}></Route>
                      <Route path="/optionalQuestions/:country/custobj/:id" element={<QuestionsStepper placeType={PlaceTypes.ObjectPlaceType} />}></Route>
                      <Route path="/optionalQuestions/:country/newloc/:id" element={<NonObjectsOptionalQuestions />}></Route>
                      <Route path="/questionnaire/:country/custobj/:id" element={<Questionnaire />}></Route>
                      <Route path="/questionnaire/:country/newloc/:id" element={<NonObjectQuestionnaire />}></Route>
                      <Route path="/order" element={<Order />}></Route>
                    </Routes>
                  </Fullscreen>
                </TopRiskUserSelectionContextProvider>
              </ObjectPropertiesContextProvider>
            </CategoryModalDescriptionContextProvider>
          </SucceedModalContextProvider>
        </Main>
      </LoadingIndicator>
    </ApolloProvider>
  );
};

export default AuthenticatedApp;
