import { Colors } from "./common-styles";

const Theme = {
    maxWidth: '1920px',
    gutter: 8,
    white: Colors.text,
    blue: Colors.blue,
    lightBlue: Colors.lightBlue,
    black: Colors.darkBlue,
    black15: Colors.black15,
    black45: Colors.black45,
    black75: Colors.black75,
    ghostWhite: Colors.ghostWhite,
    ghostGrey: Colors.ghostGrey,
    yellow: Colors.yellow,
    red: Colors.red,
    limeGreen: Colors.limeGreen,
    orange: Colors.orange,
    background: Colors.background,
    purple: Colors.primaryPurple
};

export default Theme;
