import React from 'react';

const Question6 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M23 16H17V9C17 8.45 16.55 8 16 8C15.45 8 15 8.45 15 9V17C15 17.55 15.45 18 16 18H23C23.55 18 24 17.55 24 17C24 16.45 23.55 16 23 16Z"
            fill= "#0E283D"
        />
        <path
            d= "M15.9902 2C8.27023 2 1.99023 8.28 1.99023 16C1.99023 23.72 8.27023 30 15.9902 30C23.7102 30 29.9902 23.72 29.9902 16C29.9902 8.28 23.7102 2 15.9902 2ZM15.9902 28C9.37023 28 3.99023 22.62 3.99023 16C3.99023 9.38 9.37023 4 15.9902 4C22.6102 4 27.9902 9.38 27.9902 16C27.9902 22.62 22.6002 28 15.9902 28Z"
            fill= "#0E283D"
        />
    </svg>
);

export default Question6;
