import styled from "styled-components";
import CouchPeople from "../../icons/CouchPeople";
import { Text } from "../../../utilities/typography";
import { Colors, Devices } from "../../../utilities/common-styles";
import { FlexWrapper, PageWrapper, ShadowWrapper } from "../../../utilities/wrappers";

export const ConfirmationWrapper = styled(PageWrapper)`
  background-color: white;
  color: white;
  @media ${Devices.mobileS} {
    .header {
      display: none;
    }
  }
`;

export const Card = styled(ShadowWrapper)`
  padding: 32px;
  max-width: 668px;
  width: 100%;
  border-radius: 4px;
  background-color: ${Colors.cardBackgroundPrimary};

  margin: 64px auto;
  display: flex;
  flex-direction: column;
  column-gap: 24px;
  border: none;

  @media ${Devices.mobile} {
    height: 100%;
    box-shadow: none;
    margin: 32px 0;
    padding: 10px;
    justify-items: center;
    text-align: center;
  }
`;

export const Picture = styled(CouchPeople)`
  grid-area: picture;
`;

export const ContentHeader = styled.div`
  margin-bottom: 48px;

  div:first-of-type {
    margin-bottom: 16px;
  }

  @media ${Devices.mobile} {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export const ContentDescription = styled.div`
  @media ${Devices.mobile} {
    margin-bottom: 32px;
  }
`;

export const ButtonsSection = styled(FlexWrapper)`
  justify-content: space-between;
  height: 48px;
  height: 48px;
  align-items: flex-start;
  button {
    width: auto;
  }

  @media ${Devices.mobile} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: auto;
  }
`;

export const ReportName = styled(Text).attrs(() => ({
  size: "medium",
}))`
  margin: 0 0 16px 0;

  @media ${Devices.mobile} {
    margin: 0 0 8px 0;
  }
`;
