import styled from 'styled-components';
import { Text } from '../../../utilities/typography';
import { Colors, Devices } from '../../../utilities/common-styles';
import { FlexWrapper } from '../../../utilities/wrappers';

const HeaderOverlay = styled.div`
    z-index: 1100;
    position: relative;
    background: #fff;
    & + h1 {
        margin-top: -40px;
    }
`;

const HeaderContent = styled(FlexWrapper)`
    @media ${Devices.mobile} {
        height: 50px;
    }
`;

const InteractiveText = styled(Text)`
    a {
        color: ${Colors.primaryPurple};
    }
`;
export default {
    HeaderOverlay,
    HeaderContent,
    InteractiveText
};
