export const getGraphData = (data, t) =>
  data || {
    displayName: t("common.insights.graphTitle"),
    isEmpty: true,
  };

export const getCardData = (data, t) =>
  data || {
    displayName: t("common.insights.cardTitle"),
    isEmpty: true,
  };
