import useOnClickOutside from './../../../hooks/useOnClickOutside';
import React, { useRef } from 'react';
import ModalView from './ModalView';
import { Props } from './type';

type ModalProps = Props & { visible?: boolean };

const ModalContainer = ({
    children,
    visible,
    onCloseClick,
    hideHeader
}: ModalProps) => {
    const modalRef = useRef();

    useOnClickOutside(modalRef, () => {
        onCloseClick && onCloseClick();
    });

    return visible ? (
        <ModalView
            ref={modalRef}
            onCloseClick={onCloseClick}
            hideHeader={hideHeader}
        >
            {children}
        </ModalView>
    ) : null;
};

export default ModalContainer;
