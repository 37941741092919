import React from 'react';

const TextAreaIcon = () => (
    <svg
        width= "20"
        height= "21"
        viewBox= "0 0 20 21"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <g clipPath= "url(#clip0_94_20700)">
            <rect
                x= "-33"
                y= "-30"
                width= "52"
                height= "50"
                rx= "19"
                stroke= "#7599BF"
                strokeWidth= "2"
            />
        </g>
        <defs>
            <clipPath id= "clip0_94_20700">
                <rect width= "20" height= "21" fill= "white" />
            </clipPath>
        </defs>
    </svg>
);

export default TextAreaIcon;
