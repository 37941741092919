import styled from "styled-components";

import { ModalContent } from "./../Common/Modal/styled";
import { InputWrapper } from "./../Common/Input/styled";
import { Colors, Devices } from "../../utilities/common-styles";
import { SolidButton } from "../../utilities/buttons";
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px;
  @media ${Devices.mobile} {
    padding: 2rem 0;
  }
`;

export const H1 = styled.h1`
  margin: 0;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  color: white;
  @media ${Devices.mobile} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const AddressWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: white;
`;

export const DateWrapper = styled.div`
  background-color: ${Colors.text};
  font-size: 14px;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 1rem;
`;

export const Content = styled(ModalContent)`
  max-width: 650px;
  padding: 0px;
`;

export const FormContent = styled.div<{ text?: string }>`
  padding: 28px 32px 24px 32px;
  border-radius: 16px;
  @media ${Devices.mobileS} {
    padding: 16px;
  }
`;
export const InputComponent = styled.div<{ text?: string }>`
  margin-bottom: 24px;
  ${InputWrapper} {
    border: 2px solid ${Colors.borderDarkMode};
    box-sizing: border-box;
    border-radius: 24px;

    svg {
      circle {
        fill: ${Colors.purpleHover};
      }
    }

    input {
      background: none;
      color: ${(props) => (props.text ? Colors.text : Colors.greyLabel)};
      caret-color: ${Colors.purpleHover};
    }

    label {
      background: ${Colors.borderDarkMode};
      border-radius: 11px;
      color: black;
      padding: 2px 8px;
    }

    &:hover {
      border: 2px solid ${Colors.purpleHover};
      label {
        background: ${Colors.purpleHover};
        border-radius: 11px;
        color: ${Colors.text};
      }
    }
  }
`;
export const ModalHeader = styled.div<{ isContentPage?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isContentPage ? "space-between" : "flex-end")};
  svg {
    cursor: pointer;
  }

  display: flex;
`;

export const DescriptionContent = styled.div`
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${Colors.darkBlue};
  padding: 16px;
  border: 2px solid ${Colors.borderDarkMode};
  display: flex;
  justify-content: space-between;
  width: 550px;
  @media ${Devices.mobile} {
    width: auto;
  }
`;

export const CallUsContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Devices.mobile} {
    margin-right: 10px;
  }
`;

export const CallUsNumberContent = styled.div`
  display: flex;
  align-items: center;

  @media ${Devices.mobile} {
    min-width: 100px;
  }

  a {
    color: ${Colors.text};
  }
`;

export const CallUsDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const DateLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SendButton = styled(SolidButton)`
  padding: 12px auto;
  height: 48px;
  margin: 0;
  background-color: ${Colors.purpleHover};
  &:hover {
    background-color: ${Colors.primaryPurple};
  }
  transition: none;

  @media ${Devices.mobile} {
    min-width: 100px;
    padding: 0;
  }
`;

export const ContactButton = styled(SendButton)`
  width: 100%;

  &:disabled {
    background-color: ${Colors.ghostGraph};
    color: ${Colors.greyLabel};
    cursor: not-allowed;
    opacity: 1;

    &:hover {
      box-shadow: none;
    }
  }
`;

export const RiskWrapper = styled.div`
  border-radius: 4px;
  padding: 16px;
  border: 2px solid ${Colors.borderDarkMode};
  background-color: ${Colors.cardSelected};
`;

export const RisksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 36px;

  @media ${Devices.mobileS} {
    display: flex;
    flex-direction: column;
  }
`;

export const CloseButton = styled(SolidButton)`
  display: flex;
  align-items: center;
  padding: 12px auto;
  width: 250px;
  height: 48px;
  justify-content: center;

  svg {
    margin-right: 12px;
  }
  @media ${Devices.mobileS} {
    width: 100%;
  }
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const FinalStepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  button {
    margin-top: 130px;
  }

  @media ${Devices.mobileS} {
    padding-top: 10px;

    button {
      margin-top: 40px;
    }
  }
`;

export const ContentPage = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 32px;

  @media ${Devices.mobileS} {
    padding-top: 8px;
    padding-bottom: 20px;
  }
`;

export const BackArrow = styled.div`
  cursor: pointer;
`;

export const DescriptionWrapper = styled.div`
  color: ${Colors.text};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: ${Colors.accentPurple};
  border-radius: 24px 24px 24px 0;
  margin-bottom: 32px;

  @media ${Devices.mobileS} {
    margin-bottom: 16px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  align-items: center;
  svg {
    margin-right: 9px;
  }

  @media ${Devices.mobileS} {
    margin: 16px 0;
  }
`;
