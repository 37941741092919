import { Header } from "./../Common";

import { useReports } from "../../hooks";
import React, { useEffect, useState } from "react";
import { NonObjectType, Type } from "../../utilities/types";
import { useTranslation } from "react-i18next";

import AddReport from "../icons/AddReport";
import WrappedLoader from "../WrappedLoader";
import { ErrorWrapper } from "./../Common";
import BaldrItem from "./BaldrItem/BaldrItem";
import ListSkeleton from "./ListSkeleton";
import { ListContainer, PaginationWrapper } from "./styled";
import { ContentWrapper, PageWrapper } from "../../utilities/wrappers";
import { Colors } from "../../utilities/common-styles";
import { SolidButtonLink } from "../../utilities/buttons";
import PaginationBar from "./../PaginationBar";
import { useNavigate } from "react-router-dom";
import { getObjectUrlFromNotification } from "../../utilities/queryUtils";
import { useSearchParam } from "../../hooks/useSearchParam";
import { useStore } from "react-context-hook";

const BaldrListViewContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userData, _setUserData] = useStore("userData", null);
  const c = useSearchParam("c");
  const [currentPage, setCurrentPage] = useState(0);
  const { reports, loading, error, pageChangeHandler, nmbOfPages, allReportsCount, page } = useReports();

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    if (c) {
      const url = getObjectUrlFromNotification(c);
      navigate(url);
    }
  }, [c]);

  const hadnleBaldrItemClick = (id: string, country: string, type?: Type) => {
    navigate(type === NonObjectType ? `/essential/non-object/${id}` : `/essential/${country}/${id}`);
  };

  const navigateToOrder = () => {
    navigate("/order");
  };

  return (
    <ListContainer>
      <div>
        <title>{t("baldrList.header")}</title>
      </div>
      <PageWrapper>
        <Header
          title={t("baldrList.header")}
          description={t("baldrList.description", {
            nmbOfReports: allReportsCount || 0,
          })}
          loading={loading}
        >
          {userData?.hasOrderReportAccess && (
            <SolidButtonLink fontSize="1.3rem" onClick={navigateToOrder}>
              {t("baldrList.createReport")} <AddReport />
            </SolidButtonLink>
          )}
        </Header>
        {loading ? (
          <ListSkeleton />
        ) : (
          <ContentWrapper background={Colors.backgroundDarkMode}>
            {!!error ? (
              <ErrorWrapper error={error} />
            ) : (
              <>
                {reports?.map((report) => (
                  <BaldrItem
                    key={report.id}
                    id={report.id}
                    name={report.name}
                    address={report.address}
                    loading={loading}
                    country={report.country || ""}
                    onClick={hadnleBaldrItemClick}
                    type={report.type}
                    status={report.status}
                    pickSegmentText={t("baldrList.pickSegment")}
                    longitude={report.longitude}
                    latitude={report.latitude}
                  />
                ))}
              </>
            )}
          </ContentWrapper>
        )}
      </PageWrapper>
      {!!nmbOfPages && !error && nmbOfPages > 1 && (
        <PaginationWrapper>
          <PaginationBar pageCount={nmbOfPages} onPageChange={pageChangeHandler} page={currentPage} isLightMode={false} />
        </PaginationWrapper>
      )}
    </ListContainer>
  );
};

export default BaldrListViewContainer;
