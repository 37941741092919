import Risk from 'NewBaldrView/components/GeographicalRisk/RiskBars/risk';
import { createContext, useContext } from 'react';

type TopRiskUserSelectionContextType = {
    topRisk: string;
    setTopRisk: (category: string) => void;
    topRiskList: Risk[];
    updateTopRiskList: (list: Risk[]) => void;
};

export const TopRiskUserSelectionContext = createContext<
    TopRiskUserSelectionContextType
>({
    topRisk: null,
    setTopRisk: () => {},
    updateTopRiskList: () => {},
    topRiskList: null
});

export const useActions = () => useContext(TopRiskUserSelectionContext);
