import React, { ReactNode, useState } from 'react';
import { ObjectPropertiesContext } from './ObjectPropertiesContext';

const ObjectPropertiesContextProvider = ({
    children
}: {
    children: ReactNode;
}) => {
    const [objectProperties, setObjectProperties] = useState(null);
    return (
        <ObjectPropertiesContext.Provider
            value={{ objectProperties, setObjectProperties }}
        >
            {children}
        </ObjectPropertiesContext.Provider>
    );
};

export default ObjectPropertiesContextProvider;
