import { Colors, Fonts, Sizes } from "../../utilities/common-styles";
import { monthString } from "../../utilities/dateUtils";
import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryLine, VictoryTooltip } from "victory";
import { RiskTooltip } from "./RiskTooltip";
import { GraphContainer, WeeklyCompare, GraphTooltip } from "./styled";
import { transformGraphData } from "./utils";

import { useTranslation } from "react-i18next";
import TooltipContainer from "../Common/Tooltip/TooltipContainer";
import useWindowSize from "../../hooks/useWindowSize";

export interface IPick {
  timestamp: number;
  risk?: number;
  diff?: number;
  name: string;
  range?: number[];
}

const ComparisonGraph = ({ t, graphData }) => {
  const singleBarWidth = 10;
  const { thisYear, previousYear, max, isEnoughDataAvailable } = transformGraphData(graphData);
  const { i18n } = useTranslation();

  const { width } = useWindowSize();

  const isMobile = width <= Sizes.mobileL;
  const isTablet = width <= Sizes.tablet;

  return (
    <WeeklyCompare>
      <GraphContainer data-cy="event-comparison-graph">
        <GraphTooltip>
          <TooltipContainer text={t("segmentRisk.graphTooltip")} />
        </GraphTooltip>

        <VictoryChart
          domainPadding={{ x: [10, 10] }}
          padding={{
            right: 20,
            left: 20,
            bottom: 25,
            top: 25,
          }}
          height={isMobile ? 150 : 170}
        >
          <VictoryLegend
            x={isMobile ? 170 : 200}
            y={0}
            orientation="horizontal"
            style={{
              labels: { fontSize: 10 },
            }}
            data={[
              {
                name: t("segmentRisk.recentYear"),
                symbol: {
                  fill: Colors.primaryPurple,
                  type: "square",
                },
                labels: { fill: "white" },
              },
              {
                name: t("segmentRisk.previousYear"),
                symbol: {
                  fill: Colors.borderDarkMode,
                  type: "square",
                },
                labels: { fill: "white" },
              },
            ]}
          />
          <VictoryAxis
            domain={[1, 6.5]}
            tickValues={[1, 2, 3, 4, 5, 6]}
            tickFormat={(str) => monthString(thisYear[str - 1].month - 1, i18n.language, "MMM")}
            standalone={false}
            style={{
              axis: {
                stroke: Colors.borderDarkMode,
                strokeWidth: 1,
              },
              tickLabels: {
                fill: Colors.white,
                fontSize: "8px",
                padding: 2,
                fontFamily: Fonts.SecuritasPro,
                letterSpacing: "0.3px",
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            domain={[0, max]}
            standalone={false}
            style={{
              grid: { stroke: "#233C59" },
              tickLabels: {
                fill: Colors.white,
                fontSize: "8px",
                padding: 2,
                fontFamily: Fonts.SecuritasPro,
                letterSpacing: "0.3px",
              },
            }}
          />

          <VictoryGroup animate={{ duration: 200 }}>
            <VictoryLine
              interpolation="catmullRom"
              data={thisYear.map((e) => ({
                _y: e.totalEventsCount,
                _x: e.i - 0.2,
              }))}
              style={{
                data: {
                  stroke: isEnoughDataAvailable ? Colors.primaryPurple : "none",
                  strokeWidth: 1,
                  strokeDasharray: "2",
                },
              }}
            />
          </VictoryGroup>

          <VictoryGroup offset={20} colorScale={"qualitative"} animate={{ duration: 100 }}>
            <VictoryBar
              barWidth={singleBarWidth}
              data={thisYear}
              style={{
                data: {
                  fill: Colors.primaryPurple,
                  strokeLinejoin: "round",
                  strokeWidth: 1,
                },
              }}
              labels={() => " "}
              y="totalEventsCount"
              x="i"
              labelComponent={<VictoryTooltip flyoutComponent={<RiskTooltip thisYear={thisYear} previousYear={previousYear} t={t} isMobile={isTablet} />} />}
            />
            <VictoryBar
              barWidth={singleBarWidth}
              data={previousYear}
              style={{
                data: {
                  fill: Colors.borderDarkMode,
                  strokeLinejoin: "round",
                  strokeWidth: 1,
                  opacity: "0.5",
                },
              }}
              labels={() => " "}
              y="totalEventsCount"
              x="i"
              labelComponent={<VictoryTooltip flyoutComponent={<RiskTooltip thisYear={thisYear} previousYear={previousYear} t={t} isMobile={isTablet} />} />}
            />
          </VictoryGroup>
        </VictoryChart>
      </GraphContainer>
    </WeeklyCompare>
  );
};
export default ComparisonGraph;
