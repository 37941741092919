import React, { FC } from 'react';
import styled from 'styled-components';
import { ApolloError } from 'apollo-client';


import ErrorIcon from '../ErrorIcon';
import { useTranslation } from 'react-i18next';

interface WrapperProps {
    padding?: string;
}

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    justify-content: flex-start;
    padding: ${props => props.padding || '16px'};
`;

const InfoWrapper = styled.div`
    margin-left: 8px;
    font-size: 14px;
    line-height: 10px;
`;

interface ErrorTabProps {
    message?: string;
    error?: ApolloError;
}

const ErrorTab: FC<ErrorTabProps & WrapperProps> = ({
    children,
    message,
    error,
    padding
}) => {
    const [t] = useTranslation();

    return !!error ? (
        <Wrapper padding={padding}>
            <ErrorIcon />
            <InfoWrapper>
                <strong>{message || t('noDataError')}</strong>
                <p>{error}</p>
            </InfoWrapper>
        </Wrapper>
    ) : (
        <>{children}</>
    );
};

export default ErrorTab;
