import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    transform: translate(-21px, -42px);
`;

const ObjectPin = () => (
    <Wrapper>
        <svg
            width= "40"
            height= "52"
            viewBox= "0 0 40 52"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <mask
                id= "path-1-outside-1_3976:15497"
                maskUnits= "userSpaceOnUse"
                x= "0"
                y= "0"
                width= "40"
                height= "52"
                fill= "black"
            >
                <rect fill= "white" width= "40" height= "52" />
                <path
                    fillRule= "evenodd"
                    clipRule= "evenodd"
                    d= "M2 19.2573C2 9.71649 10.0486 2 20 2C29.9514 2 38 9.71649 38 19.2573C38 29.5377 26.6343 43.7134 21.98 49.1125C20.9514 50.2958 19.0743 50.2958 18.0457 49.1125C13.3657 43.7134 2 29.5377 2 19.2573ZM13.5714 19.2571C13.5714 22.6592 16.4513 25.4204 19.9999 25.4204C23.5485 25.4204 26.4285 22.6592 26.4285 19.2571C26.4285 15.8549 23.5485 13.0938 19.9999 13.0938C16.4513 13.0938 13.5714 15.8549 13.5714 19.2571Z"
                />
            </mask>
            <path
                fillRule= "evenodd"
                clipRule= "evenodd"
                d= "M2 19.2573C2 9.71649 10.0486 2 20 2C29.9514 2 38 9.71649 38 19.2573C38 29.5377 26.6343 43.7134 21.98 49.1125C20.9514 50.2958 19.0743 50.2958 18.0457 49.1125C13.3657 43.7134 2 29.5377 2 19.2573ZM13.5714 19.2571C13.5714 22.6592 16.4513 25.4204 19.9999 25.4204C23.5485 25.4204 26.4285 22.6592 26.4285 19.2571C26.4285 15.8549 23.5485 13.0938 19.9999 13.0938C16.4513 13.0938 13.5714 15.8549 13.5714 19.2571Z"
                fill= "#626366"
            />
            <path
                d= "M21.98 49.1125L23.4895 50.4245L23.4948 50.4183L21.98 49.1125ZM18.0457 49.1125L16.5344 50.4225L16.5362 50.4245L18.0457 49.1125ZM20 0C9.02426 0 0 8.53333 0 19.2573H4C4 10.8996 11.0729 4 20 4V0ZM40 19.2573C40 8.53333 30.9757 0 20 0V4C28.9271 4 36 10.8996 36 19.2573H40ZM23.4948 50.4183C25.8522 47.6837 29.9323 42.7012 33.4337 37.0717C36.8809 31.5293 40 25.0005 40 19.2573H36C36 23.7946 33.4363 29.4938 30.037 34.9591C26.692 40.3373 22.7621 45.1422 20.4652 47.8066L23.4948 50.4183ZM16.5362 50.4245C18.3621 52.5252 21.6636 52.5252 23.4895 50.4245L20.4705 47.8004C20.2392 48.0665 19.7865 48.0665 19.5552 47.8004L16.5362 50.4245ZM0 19.2573C0 25.0007 3.11922 31.5295 6.56999 37.0724C10.0749 42.7023 14.1618 47.6853 16.5344 50.4225L19.557 47.8025C17.2496 45.1406 13.3137 40.3362 9.96572 34.9584C6.56363 29.4936 4 23.7944 4 19.2573H0ZM19.9999 23.4204C17.4757 23.4204 15.5714 21.4761 15.5714 19.2571H11.5714C11.5714 23.8424 15.427 27.4204 19.9999 27.4204V23.4204ZM24.4285 19.2571C24.4285 21.4761 22.5242 23.4204 19.9999 23.4204V27.4204C24.5728 27.4204 28.4285 23.8424 28.4285 19.2571H24.4285ZM19.9999 15.0938C22.5242 15.0938 24.4285 17.0381 24.4285 19.2571H28.4285C28.4285 14.6718 24.5728 11.0938 19.9999 11.0938V15.0938ZM15.5714 19.2571C15.5714 17.0381 17.4757 15.0938 19.9999 15.0938V11.0938C15.427 11.0938 11.5714 14.6718 11.5714 19.2571H15.5714Z"
                fill= "#FDFDFD"
                mask= "url(#path-1-outside-1_3976:15497)"
            />
        </svg>
    </Wrapper>
);

export default ObjectPin;
