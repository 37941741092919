export const IsPeabUser = (username: string) => {
  const peabDomainRegExp = new RegExp(`.*@peab.se`);
  return peabDomainRegExp.test(username);
};

export async function getUserData(token: string, email: string) {
  const requestUrl = `${process.env.REACT_APP_MYSACCESS_BASE_URL}/users/byEmail/${email}`;

  const response = await fetch(requestUrl, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.status !== 200) {
    return Promise.reject(new Error("failed to fetch user id for logged in user: " + response.statusText));
  }
  const user = await response.json();
  return user;
}

export async function getUserDataById(token: string, id: string) {
  const requestUrl = `${process.env.REACT_APP_MYSACCESS_BASE_URL}/users/byId/${id}`;

  const response = await fetch(requestUrl, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.status !== 200) {
    return null;
  }
  const user = await response.json();
  return user;
}

const getDistinct = (collection) => {
  const newSet = new Set(collection);
  const uniqueItems = [];
  newSet.forEach((item) => uniqueItems.push(item));
  return uniqueItems;
};
export const fetchFromAccessService = async (accessToken: string, endpoint: string) => {
  const requestUrl = `${process.env.REACT_APP_MYSACCESS_BASE_URL}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 200) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }

  return await response.json();
};

export const getUserRoles = async (userId: string, accessToken: string): Promise<string[]> => {
  type RoleDefinition = {
    roleName: string;
    isDeleted: boolean;
  };
  const endpoint = `/authorization/records/byUserId/${userId}`;
  const response: RoleDefinition[] = await fetchFromAccessService(accessToken, endpoint);
  return getDistinct(response.filter((role) => !role.isDeleted).map((rs) => rs.roleName));
};

export type RoleVerbs = {
  verbs?: string[];
  serviceVerbs?: string[];
  roles?: string[];
};

export const newRoleVerbs = () => ({
  verbs: [],
  serviceVerbs: [],
});

export const getRoleVerbs = async (accessToken: string, endpoint: string): Promise<RoleVerbs> => {
  const roleInfo = await fetchFromAccessService(accessToken, endpoint);
  return roleInfo?.isDeleted ? newRoleVerbs() : { verbs: roleInfo?.verbs, serviceVerbs: roleInfo?.serviceVerbs };
};

export const getUserVerbs = async (userId: string, userToken: string): Promise<RoleVerbs> => {
  try {
    const roles = await getUserRoles(userId, userToken);
    const result = newRoleVerbs();
    for (const roleName of roles) {
      const endpoint = `/authorization/roles/byName/${roleName}`;
      const roleVerbs = await getRoleVerbs(userToken, endpoint);
      result.verbs.push(...(roleVerbs.verbs || []));
      result.serviceVerbs.push(...(roleVerbs.serviceVerbs || []));
    }
    return {
      verbs: getDistinct(result.verbs),
      serviceVerbs: getDistinct(result.serviceVerbs),
      roles,
    };
  } catch (error) {
    console.log(`Verb verification failed`);
    return newRoleVerbs();
  }
};

export const allVerbsPresent = (userVerbs: string[], requiredVerbs: string[]): boolean => {
  if (!userVerbs || !requiredVerbs) {
    return true;
  }
  for (const requiredVerb of requiredVerbs) {
    if (!userVerbs?.includes(requiredVerb)) {
      console.log("Required verb not found: ", requiredVerb);
      return false;
    }
  }
  return true;
};

export const hasVerbs = (userVerbs: RoleVerbs, requiredVerbs: RoleVerbs): boolean => {
  if (!userVerbs || !requiredVerbs) {
    return false;
  }
  return allVerbsPresent(userVerbs.verbs, requiredVerbs.verbs) && allVerbsPresent(userVerbs.serviceVerbs, requiredVerbs.serviceVerbs);
};

export const isUserInternal = async (userVerbs: RoleVerbs): Promise<boolean> => {
  return await hasVerbs(userVerbs, {
    serviceVerbs: [
      "admin.role.edit",
      "admin.user.view",
      "admin.user.create",
      "admin.user.edit",
      "organization.view",
      "organization.browse",
      "admin.permission.view",
      "notification.edit",
      "basic.dailyreports.view",
    ],
  });
};
