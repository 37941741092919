import React from "react";
import { ContentWrapper } from "../../utilities/wrappers";
import BaldrItem from "./BaldrItem/BaldrItem";
import { Colors } from "../../utilities/common-styles";

const ListSkeleton = () => (
  <ContentWrapper background={Colors.backgroundDarkMode}>
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
    <BaldrItem loading={true} />
  </ContentWrapper>
);

export default ListSkeleton;
