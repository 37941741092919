import React, { useState } from "react";

import LocationIcon from "../icons/LocationIcon";
import { Wrapper, H1, AddressWrapper, DateWrapper } from "./styled";

import { formatLocale } from "../../utilities/dateUtils";
import { SolidButtonWrapper, ContactUsWrapper } from "../styled";
import ContactUsModal from "./ContactUsModal";

import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import { FlexWrapper, PageWrapper } from "../../utilities/wrappers";
import { useTranslation } from "react-i18next";
import CallUsIcon from "../icons/CallUsIcon";
interface IObjectDetailsProps {
  title: string;
  objectName: string;
  address: string;
  buttonText: string;
  onDownloadClick?: () => void;
  onPrintClick?: () => void;
}

const ObjectDetails = ({ title, objectName, address, buttonText }: IObjectDetailsProps) => {
  const { i18n } = useTranslation();

  const date = formatLocale(new Date(), i18n.language, "EEE, LLL dd");
  const [visible, setVisible] = useState(false);
  return (
    <Wrapper>
      <PageWrapper>
        <FlexWrapper data-cy="essential-report-object-header">
          <div>
            <FlexWrapper>
              <DateWrapper>
                <Text
                  data-cy="essential-report-object-date"
                  size="16px"
                  mobileFontSize="14px"
                  lineHeight="24px"
                  mobileLineHeight="21px"
                  color={Colors.darkBlue}
                  weight="700"
                >
                  {date}
                </Text>
              </DateWrapper>
            </FlexWrapper>

            <H1 data-cy="essential-report-essential-title">{title}</H1>
            <AddressWrapper data-cy="essential-report-object-address">
              <LocationIcon />
              <Text size="17px" mobileFontSize="16px" lineHeight="27px" mobileLineHeight="24px" margin="0">
                {objectName}, {address}
              </Text>
            </AddressWrapper>
          </div>

          <ContactUsWrapper>
            <SolidButtonWrapper
              onClick={() => {
                setVisible(true);
              }}
            >
              <CallUsIcon />
              <Text size="16px" lineHeight="24px" color={Colors.text} margin="0 0 0 10.5px" data-cy="contact-us-button">
                {buttonText}
              </Text>
            </SolidButtonWrapper>
          </ContactUsWrapper>
        </FlexWrapper>
        <ContactUsModal
          onCloseClick={() => {
            setVisible(false);
          }}
          visible={visible}
          objectAddress={`${objectName}, ${address}`}
        ></ContactUsModal>
      </PageWrapper>
    </Wrapper>
  );
};

export default ObjectDetails;
