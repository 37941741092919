import styled from "styled-components";
import { Colors } from "../../../utilities/common-styles";

export const IconWrapper = styled.div`
  svg {
    min-width: 16px;
    margin-right: 1rem;
    path {
      fill: ${Colors.primaryPurple};
    }
  }
`;

export const SelectedSegment = styled.div`
  align-items: flex-start;
  margin-bottom: 1rem;
  align-self: flex-start;
`;

export const ListComponent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Notification = styled.div`
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  color: white;
`;
