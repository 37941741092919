import { Text, H2 } from "../../utilities/typography";
import React from "react";
import { LinkContainer } from "../styled";
import { useNavigate } from "react-router-dom";

const InsightsHeader = ({ t, url }) => {
  const navigate = useNavigate();

  const openOptionalQuestions = () => {
    navigate(url);
  };

  return (
    <>
      <H2 data-cy="section-header" size="24px" color="white">
        {t("reportObject.insights.title")}
      </H2>
      <Text data-cy="section-header-description" size="large" maxWidth="1100px" margin="0px" color="white">
        {t("reportObject.insights.description")}

        <LinkContainer onClick={openOptionalQuestions}>{" " + t("reportObject.insights.objectProperties") + " "}</LinkContainer>

        {t("reportObject.insights.description2")}
      </Text>
    </>
  );
};

export default InsightsHeader;
