import styled, { css, keyframes } from "styled-components";
import { SolidButton } from "../../utilities/buttons";
import { Devices, Sizes, Colors, SmallBorderRadius, Gradients } from "../../utilities/common-styles";

interface ColumnProps {
  span?: number;
  noPadding?: boolean;
}

export const Column = styled.div<ColumnProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 2rem;
  flex: 1 1 ${(props) => (props.span ? `calc(${(props.span - 1) * props.theme.gutter}px + ${props.span * 25}%)` : "100%")};

  ${(props) =>
    !props.noPadding &&
    css`
      padding-left: ${`${props.theme.gutter}px`};
      padding-right: 8px;
    `}
`;

export const Row = styled.div`
  margin: 1rem 0 0 0;
`;

export const Questions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const DetailedQuestionWrapper = styled.div`
  margin-top: 24px;
  grid-gap: 24px;

  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;

  @media ${Devices.mobileL} {
    grid-template-columns: 49% 49%;
    margin-bottom: 10px;
  }
`;
export const DetailedQuestionContent = styled.div<{
  background: string;
}>`
  display: flex;
  height: 100%;

  padding: 24px 16px;
  gap: 10px;
  flex-direction: column;

  background: ${(props) => (props.background ? props.background : Colors.borderDarkModeSelected)};
  border-radius: 24px 24px 24px 0px;
  white-space: normal;
`;

export const QuestionTitle = styled.div<{ isInternalDataComponent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  height: 80px;
  justify-content: space-between;
`;
export const DetailedQuestionContainer = styled.div`
  position: relative;
`;
export const IconWrapperConnector = styled.div<{ isFirst?: boolean }>`
  position: absolute;
  display: ${(props) => (props.isFirst ? "none" : "auto")};
  left: -33px;
  top: 68px;
  svg {
    @media ${Devices.mobileL} {
      display: none;
    }
  }
`;

export const MaxWidthWrapper = styled.div<{ full?: boolean }>`
  max-width: ${(props) => (props.full ? "100%" : props.theme.maxWidth)};
  margin: 0 auto;
  width: 100%;
  padding: ${(props) => (props.full ? 0 : "0 40px")};
  margin-top: 3rem;
  color: white;
  @media ${Devices.mobileL} {
    max-width: 100vw;
    padding: ${(props) => (props.full ? 0 : "0 16px")};
  }

  @media ${Devices.mobile} {
    padding-bottom: 120px;
  }
`;

export const FaqQuestionWrapper = styled.div`
  width: 80%;
  @media ${Devices.mobileL} {
    width: 100%;
  }
  button {
    background-color: ${Colors.purpleHover};
    &:hover {
      background-color: ${Colors.primaryPurple};
    }
  }
`;

export const AddQuestionSection = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;
export interface BorderBoxProps {
  border?: boolean;
  separator?: boolean;
  key?: number;
  index?: number;
}

export interface FAQBorderBoxProps {
  data: {
    atRisk?: boolean;
    riskLevel: number;
    description: string;
    incidentPercentage: number;
    incidentPercentageText: string;
    riskSpreadPercentage: number;
    riskSpreadPercentageText: string;
    title: string;
    color: string;
  };
}

export const BorderBox = styled.div<BorderBoxProps>`
  border: ${(props) => (props.border ? `2px solid ${Colors.border}` : "none")};
  background-color: ${Colors.cardBackgroundPrimary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 16px 24px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  height: auto;
  width: 100%;
  white-space: normal;
  background: ${(props) =>
    props.separator ? `no-repeat linear-gradient(${props.theme.ghostWhite}, ${props.theme.ghostWhite}) left / 1px 50%, ${props.theme.white}` : ""};
  margin-bottom: 5px;
`;

export const RiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

export const FAQSectionWrapper = styled.div<{
  active: boolean;
}>`
  width: 80%;

  @media ${Devices.mobileL} {
    width: 100%;
  }
  padding: 6px 0px;
  height: 100%;
  border-radius: 4px;

  margin-bottom: 1rem;

  border: 2px solid ${Colors.borderDarkMode};
`;

export const FAQSectionTitle = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 12px 24px;
  @media ${Devices.mobileL} {
    margin: 0 8px;
  }
  svg {
    margin-right: 1rem;
    width: 16px;
    height: 16px;
  }
`;

export const FAQSectionContent = styled.div`
  padding: 16px 24px 38px 24px;
  line-height: 2rem;
  white-space: pre-wrap;
`;

const animate = (value: number) => keyframes`
  0% {
    width: 0%
  }
  100% {
      width: ${value}%
  }
`;

export const PercentageGutter = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: ${Colors.navyBlue};
  display: flex;
`;
export const PercentageBar = styled.div<{
  value: number;
  color: string;
  delay: number;
}>`
  ${SmallBorderRadius}
  width: ${(props) => (props.value ? `${props.value}%` : "0%")};
  background: ${(props) => (props.color ? props.color : Gradients.atRiskBar)};
  animation: ${(props) => css`
    ${animate(props.value)} 1s linear ${props.delay}s normal both;
  `};
`;

export const ToolTipDescriptions = styled.div<{ offset: number }>`
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.black75};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.2em;
  height: 101px;
  padding: 12px;
  position: absolute;
  left: ${(props) => `calc(50% + ${props.offset}px)`};
  width: 327px;
  top: -190px;

  .title {
    font-weight: bold;
  }
`;

export const ToolTip = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.black75};
  display: flex;
  flex-direction: column;
  height: 35px;
  padding: 2px;
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
  top: 50px;
  font-size: 16px;
  line-height: 26px;
`;

export const ToolTipShdwCon = styled.div<{
  top: number;
  left: number;
}>`
  background: none;
  height: 11px;
  width: 18px;
  overflow: hidden;
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: calc(50% - 9px);
`;

export const ToolTipTipShdw = styled.div<{ flip?: boolean }>`
  background: ${(props) => props.theme.white};
  top: ${(props) => (props.flip ? "-6px" : "5px")};
  left: 3px;
  position: absolute;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.66);
  display: block;
  height: 12px;
  width: 12px;
  transform: rotate(45deg);
  z-index: 1;
`;

export const ToolTipWrapper = styled.div`
  position: absolute;
  height: 150px;
  width: 100%;
`;

export const RiskAssessmentGraph = styled.div`
  display: flex;
  height: 15px;
  margin-bottom: 100px;
`;

export const BarGutter = styled.div`
  background: ${(props) => props.theme.ghostWhite};
  position: absolute;
  width: 100%;
`;

export const Bar = styled.div<{ value: number; color: string }>`
  background: ${(props) => props.color};
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.white};
  position: relative;
  width: ${(props) => `${props.value}%`};
`;

export const ArrowLine = styled.div`
  display: flex;
  position: relative;
  top: -40px;
  width: 100%;
  padding: 0 2px;
`;

export const DashedLine = styled.div<{ color: string }>`
  width: auto;
  flex: 1;
  height: 13px;
  border-bottom: dashed 1px ${(props) => props.color};
`;

export const RiskAccuracyCon = styled.div`
  display: flex;
  align-items: center;
  @media ${Devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RiskAccuracyNoCon = styled.div`
  h2 {
    margin: 0;
    line-height: 3.3rem;
    margin-right: 24px;
  }
`;

export const RiskAccuracyDescription = styled.div`
  padding: 10px 0 10px 24px;
  line-height: 2.2rem;
  border-left: 1px solid ${(props) => props.theme.ghostWhite};
`;

interface RowProps {
  height?: number;
  marginBottom?: string;
  full?: boolean;
}

export const RowTopSection = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex: 1 1 ${(props) => (props.full ? "100%" : "auto")};
  align-items: stretch;
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  margin-bottom: ${(props) => props.marginBottom || "24px"};

  @media ${Devices.mobileL} {
    flex-wrap: wrap;
  }
`;
export const TopSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 17px;
  justify-items: center;
  @media ${Devices.mobileL} {
    grid-template-columns: 49% 49%;
  }
  @media ${Devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TopSectionLine = styled.div`
  grid-column: 1 / -1;
  height: 2px;
  border-bottom: 1px solid ${Colors.ghostWhite};
  width: 66%;
`;

export const Line = styled.div`
  position: absolute;
  height: 15px;
  width: 1px;
  background: ${(props) => props.theme.black75};
  top: 20px;
  left: -1px;
`;
export const Headline = styled.h4`
  color: ${(props) => props.theme.black75};
  margin: 0 0 0.5em 0;
`;

export const Description = styled.p`
  color: ${(props) => props.theme.black75};
  margin: 0.2em 0 2em 0;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
`;

export const IconWrapper = styled.div`
  svg {
    min-width: 24px;
    margin-right: 2rem;
    path {
      fill: none;
    }
  }
`;

export const BarTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`;

export const HeaderContainer = styled.div`
  width: 60%;
  @media ${Devices.tablet} {
    width: 100%;
  }
`;

export const SendButton = styled(SolidButton)`
  border-radius: 25px;
  margin-bottom: 0px;
  background-color: ${Colors.purpleHover};
  &:hover {
    background-color: ${Colors.primaryPurple};
  }
  transition: none;
`;

export const BarElement = styled.div<{ backgroundColor?: string }>`
  border-radius: 2px;
  height: 8px;
  flex: 0 1 19%;
  background-color: ${(props) => props.backgroundColor || Colors.ghostWhite};
`;

export const ProgressBarElement = styled.div<{
  backgroundColor?: string;
  width?: string;
}>`
  ${SmallBorderRadius}
  height: 8px;
  width: ${(props) => props.width || "100%"};
  background-color: ${(props) => props.backgroundColor};
`;

export const AvarageBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8px;
  margin-bottom: 2rem;
`;

export const TitleSection = styled.div`
  display: flex;

  @media ${Devices.mobileS} {
    display: inline;
  }
`;
