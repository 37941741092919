import React from "react";

import { BarTitle, BorderBox, BorderBoxProps, BoxDescription, FAQBorderBoxProps, IconWrapper, RiskWrapper } from "./styled";
import { Text } from "../../utilities/typography";

import AverageBar from "./AverageBar";
import WarningTriangleFaq from "./../icons/WarningTriangleFaq";
import { RISK_COLORS } from "../../utilities/common-styles";

const getRiskColor = (level: number) => RISK_COLORS[level - 1];

const FAQBorderBox = ({ data, border, key, index }: BorderBoxProps & FAQBorderBoxProps) => {
  return (
    <BorderBox border={border} key={key}>
      <RiskWrapper>
        <BarTitle>
          <Text lineHeight="24px" mobileLineHeight="16px" size="16px" mobileFontSize="12px" weight="700" margin="0 0 16px 0">
            {data.title}
          </Text>
          <div>{data.color}</div>
        </BarTitle>
        <AverageBar average={data.riskLevel}></AverageBar>
      </RiskWrapper>

      <BoxDescription data-cy="BoxDescription">
        {data.riskLevel !== 0 && (
          <IconWrapper>
            <WarningTriangleFaq fill={getRiskColor(data.riskLevel)}></WarningTriangleFaq>
          </IconWrapper>
        )}
        <div data-cy={`top-risk-description-${index}`}>{data.description}</div>
      </BoxDescription>
    </BorderBox>
  );
};

export default FAQBorderBox;
