import { Text } from "../../utilities/typography";
import TooltipContainer from "./../Common/Tooltip/TooltipContainer";

import { IntercomTopic, useIntercom, AppsEnum } from "../../hooks/useIntercom";
import React, { useEffect, useState } from "react";
import { formatLocale } from "../../utilities/dateUtils";
import {
  CategoryHeader,
  EventsCard,
  EventsCategory,
  WeeklyCompare,
  TextWrapper,
  Badge,
  CriticalEvents,
  IconMobileGuardingWrapper,
  LinkContent,
} from "./styled";
import { isMobileGuardingUser } from "./utils";
import { Colors } from "../../utilities/common-styles";
import { useTranslation } from "react-i18next";
import GoToMobileGuardingIcon from "../icons/GoToMobileGuardingIcon";
import { useStore } from "react-context-hook";

const TwoWeeksComparison = ({ t, last2weeksIncidents }) => {
  const [userData, _setUserData] = useStore("userData", null);
  const { postMessage } = useIntercom();
  const isEmbedded = userData?.isEmbedded;
  const hasMobileGuardingRole = isMobileGuardingUser(userData?.roles);
  const { i18n } = useTranslation();

  const [notSeenEvents, setNotSeenEvents] = useState(0);
  const [notableEvents, setNotableEvents] = useState([]);
  const [otherEvents, setOtherEvents] = useState([]);
  const [lastNotableEvent, setLastNotableEvent] = useState(null);

  useEffect(() => {
    setNotableEvents(last2weeksIncidents.filter((elem) => elem.isNotableEvent));

    setOtherEvents(last2weeksIncidents.filter((elem) => !elem.isNotableEvent && !elem.isNearby));
  }, []);

  useEffect(() => {
    if (notableEvents.length > 0) {
      setLastNotableEvent(notableEvents[0]);
      setNotSeenEvents(notableEvents?.reduce((acc, cur) => (!cur.hadSeen ? ++acc : acc), 0));
    }
  }, [notableEvents]);

  return (
    <>
      <WeeklyCompare>
        <EventsCard marginBottom="8px">
          <CategoryHeader>
            <EventsCategory color={Colors.redDarkMode} data-cy="notable-events-header">
              <Text size="12px" mobileFontSize="11px" lineHeight="18px" weight="700" color={Colors.backgroundDarkMode} textAlign="left" uppercase>
                {t("segmentRisk.criticalRisks")}
              </Text>
            </EventsCategory>
            <TooltipContainer text={t("segmentRisk.criticalRisksTooltip")} />
          </CategoryHeader>
          <Text
            data-cy="notable-events-info"
            size="14px"
            mobileFontSize="13px"
            mobileLineHeight="20px"
            lineHeight="21px"
            color={Colors.white}
            textAlign="left"
            margin="auto"
          >
            {t("segmentRisk.lastTwoWeeks")}
          </Text>
          <TextWrapper>
            <CriticalEvents>
              <Text
                data-cy="notable-events-count"
                size="48px"
                mobileFontSize="40px"
                lineHeight="72px"
                mobileLineHeight="54px"
                color={Colors.white}
                textAlign="left"
                margin="0"
              >
                {notableEvents.length}
              </Text>
              {notSeenEvents > 0 && (
                <Badge>
                  <Text data-cy="new-notable-events-badge" size="12px" mobileFontSize="11px" weight="700" color={Colors.backgroundDarkMode} uppercase>
                    {notSeenEvents}
                  </Text>
                </Badge>
              )}
            </CriticalEvents>

            <div>
              {lastNotableEvent && (
                <>
                  <Text
                    data-cy="last-notable-event-header"
                    size="12px"
                    mobileFontSize="11px"
                    weight="700"
                    color={Colors.borderDarkMode}
                    textAlign="right"
                    margin="0"
                    lineHeight="18px"
                    uppercase
                  >
                    {t("segmentRisk.lastEvent")}
                  </Text>
                  <Text
                    data-cy="last-notable-event-category"
                    size="16px"
                    mobileFontSize="14px"
                    mobileLineHeight="21px"
                    lineHeight="24px"
                    weight="400"
                    color="white"
                    margin="0"
                    textAlign="right"
                  >
                    {t(`categorization.${lastNotableEvent.eventCategories[0]?.toLowerCase()}`)}
                  </Text>
                  <Text
                    data-cy="last-notable-event-date"
                    size="12px"
                    mobileFontSize="11px"
                    lineHeight="24px"
                    mobileLineHeight="16px"
                    textAlign="right"
                    color={Colors.white}
                  >
                    {formatLocale(new Date(lastNotableEvent.ts), i18n.language, "dd MMM yyyy HH:mm")}
                  </Text>
                </>
              )}
            </div>
          </TextWrapper>
        </EventsCard>
        <EventsCard>
          <CategoryHeader>
            <EventsCategory color={Colors.orangeCategory}>
              <Text
                data-cy="other-events-header"
                size="12px"
                mobileFontSize="11px"
                lineHeight="18px"
                weight="700"
                color={Colors.backgroundDarkMode}
                textAlign="left"
                uppercase
              >
                {t("segmentRisk.otherRisks")}
              </Text>
            </EventsCategory>
            <TooltipContainer text={t("segmentRisk.otherRisksTooltip")} />
          </CategoryHeader>

          <Text
            data-cy="other-events-info"
            size="14px"
            mobileFontSize="13px"
            mobileLineHeight="20px"
            lineHeight="21px"
            color={Colors.white}
            textAlign="left"
            margin="auto"
          >
            {t("segmentRisk.lastTwoWeeks")}
          </Text>

          <TextWrapper>
            <CriticalEvents>
              <Text
                data-cy="other-events-count"
                size="48px"
                mobileFontSize="40px"
                lineHeight="72px"
                mobileLineHeight="54px"
                color={Colors.white}
                textAlign="left"
                margin="auto"
              >
                {otherEvents.length}
              </Text>
            </CriticalEvents>

            <LinkContent>
              {isEmbedded && hasMobileGuardingRole && (
                <>
                  <Text
                    data-cy="mobile-guarding-link"
                    size="14px"
                    mobileFontSize="12px"
                    weight="400"
                    color={Colors.white}
                    textAlign="right"
                    margin="0 0 0 15px"
                    lineHeight="21px"
                  >
                    {otherEvents.length > 0 ? t("segmentRisk.linkToGuarding") : t("segmentRisk.linkToGuardingNoEvents")}
                  </Text>
                  <IconMobileGuardingWrapper
                    data-cy="mobile-guarding-icon"
                    onClick={() => {
                      postMessage(IntercomTopic.REDIRECT, AppsEnum.MobileGuarding, userData?.isEmbeddedInMobile);
                    }}
                  >
                    <Text
                      data-cy="mobile-guarding-text"
                      size="14px"
                      mobileFontSize="12px"
                      weight="400"
                      color={Colors.primaryPurple}
                      textAlign="right"
                      margin="0"
                      lineHeight="21px"
                    >
                      {t("segmentRisk.guarding")}
                    </Text>
                    <GoToMobileGuardingIcon />
                  </IconMobileGuardingWrapper>
                </>
              )}
            </LinkContent>
          </TextWrapper>
        </EventsCard>
      </WeeklyCompare>
    </>
  );
};

export default TwoWeeksComparison;
