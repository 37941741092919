import React from "react";

const MapLine = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 43.5H37.5" stroke="#031F30" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M24 25.5C27.3137 25.5 30 22.8137 30 19.5C30 16.1863 27.3137 13.5 24 13.5C20.6863 13.5 18 16.1863 18 19.5C18 22.8137 20.6863 25.5 24 25.5Z"
      stroke="#031F30"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39 19.5C39 33 24 43.5 24 43.5C24 43.5 9 33 9 19.5C9 15.5218 10.5804 11.7064 13.3934 8.8934C16.2064 6.08035 20.0218 4.5 24 4.5C27.9782 4.5 31.7936 6.08035 34.6066 8.8934C37.4196 11.7064 39 15.5218 39 19.5V19.5Z"
      stroke="#031F30"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MapLine;
