import React from 'react';

const Question4 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M11 29H21"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M7.01801 17.4102C6.35313 16.0566 6.00504 14.5694 6.00011 13.0614C5.97511 7.6364 10.3376 3.1239 15.7626 2.9989C17.8622 2.94913 19.9242 3.56166 21.6561 4.74961C23.388 5.93756 24.7019 7.64064 25.4115 9.6173C26.1211 11.594 26.1904 13.7439 25.6094 15.7621C25.0285 17.7803 23.827 19.5644 22.1751 20.8614C21.8102 21.1435 21.5146 21.5052 21.311 21.919C21.1073 22.3328 21.001 22.7877 21.0001 23.2489V23.9989C21.0001 24.2641 20.8948 24.5185 20.7072 24.706C20.5197 24.8935 20.2653 24.9989 20.0001 24.9989H12.0001C11.7349 24.9989 11.4805 24.8935 11.293 24.706C11.1055 24.5185 11.0001 24.2641 11.0001 23.9989V23.2489C10.997 22.7912 10.8909 22.34 10.6896 21.9289"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M17.0117 7.10156C18.2339 7.30875 19.3617 7.89019 20.2394 8.76564C21.1171 9.64109 21.7014 10.7674 21.9117 11.9891"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default Question4;
