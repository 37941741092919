export enum CalendarDayType {
    Normal,
    Risk,
    Predicted
}

export enum Views {
    DayGraph,
    WeekGraph
}

export type CalendarDayProps = {
    date: Date;
    type: CalendarDayType;
    risks: {
        category: string;
        date: Date;
    }[];
};

export type WeekRisk = {
    category: string;
    hour: number;
    value: number;
};

export type WeekRisks = {
    [key: string]: WeekRisk[];
};
