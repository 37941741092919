import { PlaceProperties } from './placeCapabilitiesUtils';

type PlaceProperty = {
    name: string;
    value: string;
    locked: boolean;
};

export const isSegmentLocked = (placeProperties: PlaceProperty[]) => {
    const segmentProperty = (placeProperties || []).find(cp =>
        cp.name.includes(PlaceProperties.BUSINESS_SEGMENT)
    );
    return segmentProperty?.locked || false;
};

export const getSegment = (
    placeProperties: PlaceProperty[],
    defaultValue = '-/-'
) => {
    const segmentProperty = (placeProperties || []).find(cp =>
        cp.name.includes(PlaceProperties.BUSINESS_SEGMENT)
    );
    return segmentProperty?.value || defaultValue;
};
