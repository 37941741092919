import React from 'react';
import { Colors, RISK_COLORS } from '../../utilities/common-styles';
import { BarElement, AvarageBarWrapper, ProgressBarElement } from './styled';

interface IAvarageBar {
    average: number;
    reverse?: boolean;
}

const AverageBar = ({ average, reverse }: IAvarageBar) => (
    <AvarageBarWrapper>
        {RISK_COLORS.map((color, index) => {
            const integerPart = Math.trunc(average);
            const percentage = (average - integerPart) * 100;
            const condition = reverse
                ? index >= integerPart
                : index <= integerPart;
            const backgroundColor = condition ? color : Colors.navyBlue;

            return (
                <BarElement key={`${index}`} backgroundColor={Colors.navyBlue}>
                    <ProgressBarElement
                        width={
                            index === integerPart ? `${percentage}%` : '100%'
                        }
                        backgroundColor={backgroundColor}
                    ></ProgressBarElement>
                </BarElement>
            );
        })}
    </AvarageBarWrapper>
);

export default AverageBar;
