import { useState, useEffect } from 'react';
import { isBrowser } from './../utilities/windowUtilities';

export interface IWindowSize {
    width: number;
    height: number;
}

const useWindowSize = (): IWindowSize => {
    const isClient = isBrowser();

    const getSize = (): IWindowSize => ({
        width: isClient ? window.innerWidth : 0,
        height: isClient ? window.innerHeight : 0
    });

    const [windowSize, setWindowSize] = useState<IWindowSize>(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
