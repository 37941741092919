import styled from "styled-components";
import { Devices, Sizes } from "../../../utilities/common-styles";

export const NavigationControlWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  bottom: auto;

  @media ${Devices.mobile} {
    top: auto;
    bottom: ${Sizes.mobileMenu};
  }
`;

export const HiddenMap = styled.div`
  visibility: hidden;
  height: 0px;
  display: none;
`;
