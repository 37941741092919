import React, { Suspense } from "react";
import { withStore } from "react-context-hook";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import AuthenticatedApp from "./components/AuthenticatedApp";
import LoadingIndicator from "./components/shared/LoadingIndicator";
import AuthGate from "./context/AuthGate";
import { ObjectPropsContextProvider } from "./context/ObjectProps";
import { i18nextInit } from "./i18next";
import { syncI18nCultures } from "./i18nextSync";
import GlobalStyles from "./styles/GlobalStyles";
import { flexColumn } from "./styles/templates/Flex";
import Theme from "./utilities/Theme";
import { Text } from "./utilities/typography";

i18nextInit();
syncI18nCultures();

export const AppContainer = styled.div`
  ${flexColumn}
`;

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={Theme}>
        <Suspense fallback={<LoadingIndicator loading={true} paddingTop="40vh" />}>
          <AppContainer>
            <ObjectPropsContextProvider>
              <AuthGate>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/health"
                      element={
                        <Text size="16px" lineHeight="24px" mobileFontSize="14px" mobileLineHeight="21px" color={"white"}>
                          healthy
                        </Text>
                      }
                    ></Route>
                    <Route path="*" element={<AuthenticatedApp />}></Route>
                  </Routes>
                </BrowserRouter>
              </AuthGate>
            </ObjectPropsContextProvider>
          </AppContainer>
        </Suspense>
      </ThemeProvider>
    </>
  );
};

export default withStore(App);
