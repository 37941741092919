import { useNavigate } from "react-router-dom";
import { ApolloError } from "apollo-client";
import { useEffect, useState } from "react";

import { getOptionalQuestionsPath } from "./../utilities/urlUtils";

interface IProp {
  invokeQuery: any;
  isBusinessSegmentLocked: (arg) => boolean;
  country: string;
  placeId: string;
  placeType: string;
}

interface IResult {
  loading: boolean;
  error: ApolloError;
}

const useCheckBusinessSegmentIsSet = ({ invokeQuery, isBusinessSegmentLocked, country, placeId, placeType }: IProp): IResult => {
  const [checkFinished, setCheckFinished] = useState(false);
  const { data, loading, error } = invokeQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && data && !checkFinished) {
      if (!!isBusinessSegmentLocked(data)) {
        // business segment property is locked - can't set it again -> redirecting to report page
        const url = getOptionalQuestionsPath(country, placeId, placeType);
        navigate(url);
      } else {
        setCheckFinished(true);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    error && console.error(error);
  }, [error]);

  return { loading: !checkFinished, error };
};

export default useCheckBusinessSegmentIsSet;
