import React from 'react';
import {
    FillerComponent,
    ProgressBarComponent,
    ProgressElem,
    InfoTooltip,
    ProgressCircle,
    ProgressElem0,
    InfoTooltip0
} from '../styled';
export const ProgressBar = ({ percentage }) => {
    const info = `${Math.trunc(percentage)}%`;
    return (
        <ProgressBarComponent>
            <FillerComponent style={{ width: `${percentage}%` }}>
                {percentage ? (
                    <ProgressElem
                    data-cy= "progress-width">
                        <InfoTooltip
                        data-cy= "progress-percentage">{info}</InfoTooltip>
                        <ProgressCircle
                        data-cy= "progress-circle"/>
                    </ProgressElem>
                ) : (
                    <ProgressElem0>
                        <InfoTooltip0
                        data-cy= "progress-percentage">{info}</InfoTooltip0>
                        <ProgressCircle 
                        top= "-2px"
                        data-cy= "progress-circle"/>
                    </ProgressElem0>
                )}
            </FillerComponent>
        </ProgressBarComponent>
    );
};
