import React from 'react';

const WarningImage = () => (
    <svg
        width= "110"
        height= "72"
        viewBox= "0 0 110 72"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <g clipPath= "url(#clip0)">
            <rect width= "110" height= "72" fill= "white" />
            <path
                d= "M100.333 71.789L97.1914 62.2176C97.1914 62.2176 100.647 65.7062 100.647 68.5687L100.027 61.9492C100.027 61.9492 101.799 64.3644 101.622 68.032C101.445 71.6995 100.333 71.789 100.333 71.789Z"
                fill= "#E6E6E6"
            />
            <path
                d= "M32.8631 63.072L29.7969 53.7307C29.7969 53.7307 33.1695 57.1354 33.1695 59.9291L32.5642 53.4688C32.5642 53.4688 34.2937 55.8259 34.1208 59.4053C33.9478 62.9846 32.8631 63.072 32.8631 63.072Z"
                fill= "#E6E6E6"
            />
            <path
                d= "M80.7209 67.3801C80.7261 73.525 68.6536 71.6348 53.874 71.6477C39.0944 71.6606 27.2045 73.5716 27.1992 67.4267C27.194 61.2818 39.2621 59.9348 54.0417 59.922C68.8213 59.9091 80.7156 61.2352 80.7209 67.3801Z"
                fill= "#E6E6E6"
            />
            <path d= "M110 71.6484H0V71.9296H110V71.6484Z" fill= "#3F3D56" />
            <path
                d= "M70.3635 59.0844H68.4313L67.5117 51.5605H70.3635V59.0844Z"
                fill= "#A0616A"
            />
            <path
                d= "M66.8348 58.5234H70.7759V60.8921H64.7031V60.6754C64.7031 60.1047 64.9277 59.5573 65.3275 59.1537C65.7272 58.7502 66.2694 58.5234 66.8348 58.5234Z"
                fill= "#2F2E41"
            />
            <path
                d= "M84.5666 70.186H82.6344L81.7148 62.6621H84.5666V70.186Z"
                fill= "#A0616A"
            />
            <path
                d= "M81.0379 69.6328H84.979V72.0015H78.9062V71.7848C78.9063 71.214 79.1308 70.6667 79.5306 70.2631C79.9304 69.8595 80.4726 69.6328 81.0379 69.6328Z"
                fill= "#2F2E41"
            />
            <path
                d= "M93.4791 47.8368C93.6127 47.6733 93.7091 47.4821 93.7616 47.2769C93.8141 47.0716 93.8214 46.8572 93.783 46.6488C93.7445 46.4404 93.6613 46.243 93.5391 46.0706C93.417 45.8982 93.2588 45.7549 93.0759 45.6509L88.5881 27.1953L85.7188 28.4416L91.0566 46.2866C90.9038 46.6085 90.8733 46.9757 90.9708 47.3188C91.0683 47.6619 91.2872 47.9569 91.5858 48.148C91.8844 48.3391 92.242 48.4129 92.591 48.3556C92.9399 48.2982 93.2559 48.1137 93.4791 47.8368Z"
                fill= "#A0616A"
            />
            <path
                d= "M89.9764 10.537C89.8163 10.3679 89.6214 10.2364 89.4054 10.1517C89.1895 10.0669 88.9577 10.031 88.7265 10.0465C88.4953 10.0619 88.2703 10.1283 88.0673 10.2411C87.8643 10.3538 87.6882 10.5101 87.5515 10.699L80.6055 11.504L81.6621 14.2087L87.9792 13.0152C88.3087 13.226 88.7033 13.3075 89.0883 13.2442C89.4733 13.1808 89.822 12.9772 90.0682 12.6717C90.3144 12.3662 90.441 11.9802 90.4241 11.5867C90.4072 11.1933 90.2479 10.8198 89.9764 10.537Z"
                fill= "#A0616A"
            />
            <path
                d= "M78.5312 45.923L81.5622 68.8881L85.3347 68.104C85.3347 68.104 84.78 51.3791 86.6662 49.3628C88.5525 47.3466 84.2401 43.8145 84.2401 43.8145L78.5312 45.923Z"
                fill= "#2F2E41"
            />
            <path
                d= "M79.7861 39.5977L78.3937 41.7062C78.3937 41.7062 67.6722 37.2081 67.6722 44.377C67.6722 51.5459 67.0586 56.4109 66.8367 56.747C66.6148 57.083 69.404 57.955 70.7355 57.731C70.7355 57.731 72.3781 46.1736 72.2671 45.5016C72.2671 45.5016 82.7844 50.7072 84.7816 50.2592C86.7788 49.8111 87.2507 48.225 87.5836 47.3289C87.9164 46.4328 87.4443 39.3166 87.4443 39.3166L80.7872 37.2656L79.7861 39.5977Z"
                fill= "#2F2E41"
            />
            <path
                d= "M79.9709 26.936L79.5741 26.4552C79.5741 26.4552 75.129 16.3585 76.8753 13.634C78.6215 10.9095 86.2417 10.5088 86.4798 10.6691C86.7179 10.8294 85.911 12.9101 86.4666 13.8717C86.4666 13.8717 80.5637 15.1637 80.1668 15.324C79.7699 15.4843 83.2254 21.4069 83.2254 21.4069L82.8285 25.0128L79.9709 26.936Z"
                fill= "#CCCCCC"
            />
            <path
                d= "M87.7505 21.9691C87.7505 21.9691 83.6229 22.4499 83.4642 22.4499C83.3054 22.4499 83.2261 21.4082 83.2261 21.4082L79.5748 26.4566C79.5748 26.4566 77.7759 41.1025 78.2521 40.8621C78.7284 40.6217 87.5124 42.0023 87.5917 41.3613C87.6711 40.7202 87.5124 37.5149 87.7505 37.3546C87.9886 37.1944 91.878 27.2579 91.878 27.2579C91.878 27.2579 91.4812 25.1744 90.1318 24.4532C88.7824 23.7321 87.7505 21.9691 87.7505 21.9691Z"
                fill= "#CCCCCC"
            />
            <path
                d= "M90.6088 25.8965L91.8788 27.2587C91.8788 27.2587 93.7658 42.9124 92.7339 43.3932C91.702 43.874 89.6706 43.8149 89.6706 43.8149L87.6719 36.3939L90.6088 25.8965Z"
                fill= "#CCCCCC"
            />
            <path
                d= "M83.7863 20.9782C85.8041 20.9782 87.4398 19.3269 87.4398 17.2899C87.4398 15.2529 85.8041 13.6016 83.7863 13.6016C81.7685 13.6016 80.1328 15.2529 80.1328 17.2899C80.1328 19.3269 81.7685 20.9782 83.7863 20.9782Z"
                fill= "#A0616A"
            />
            <path
                d= "M82.9269 17.8655C82.8776 17.1002 81.9212 17.074 81.1615 17.0667C80.4019 17.0595 79.4937 17.0869 79.0638 16.4545C78.7798 16.0366 78.834 15.4564 79.0687 15.0084C79.3034 14.5603 79.6894 14.2157 80.0691 13.8842C81.0496 13.0283 82.0601 12.1792 83.2372 11.6314C84.4143 11.0835 85.7937 10.8622 87.0144 11.3019C88.5141 11.8422 90.5413 14.623 90.7163 16.2207C90.8914 17.8183 90.2589 19.4471 89.1928 20.6409C88.1267 21.8348 85.6865 21.3531 84.1508 21.7779C85.0844 20.4439 84.469 18.0201 82.9734 17.3972L82.9269 17.8655Z"
                fill= "#2F2E41"
            />
            <path
                d= "M50.3644 65.5738C55.2083 65.5738 59.1351 61.6096 59.1351 56.7195C59.1351 51.8294 55.2083 47.8652 50.3644 47.8652C45.5205 47.8652 41.5938 51.8294 41.5938 56.7195C41.5938 61.6096 45.5205 65.5738 50.3644 65.5738Z"
                fill= "#F62B42"
            />
            <path
                d= "M44.5076 47.2353C43.2921 47.2353 42.1026 46.8797 41.0831 46.2115C40.0635 45.5432 39.2578 44.591 38.7632 43.47L24.8458 11.8293C24.3848 10.7812 24.141 9.649 24.1293 8.50228C24.1177 7.35555 24.3385 6.21853 24.7781 5.16111C25.2178 4.10369 25.867 3.14822 26.6859 2.35341C27.5048 1.55861 28.476 0.941266 29.5399 0.539334C30.6038 0.137403 31.7379 -0.0406278 32.8725 0.0161877C34.007 0.0730033 35.1181 0.363463 36.1374 0.869715C37.1566 1.37597 38.0626 2.08731 38.7995 2.96003C39.5365 3.83275 40.0888 4.84839 40.4227 5.94454L50.5005 39.0359C50.7891 39.9831 50.8535 40.9854 50.6887 41.9623C50.5239 42.9392 50.1345 43.8635 49.5517 44.6609C48.9689 45.4582 48.2089 46.1065 47.3329 46.5536C46.4569 47.0007 45.4892 47.2342 44.5076 47.2353Z"
                fill= "#F62B42"
            />
        </g>
        <defs>
            <clipPath id= "clip0">
                <rect width= "110" height= "72" fill= "white" />
            </clipPath>
        </defs>
    </svg>
);

export default WarningImage;
