import React, { useEffect, useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import usePrevious from "./../../hooks/usePrevious";
import DragAndDrop from "./DragAndDrop";
import { TopRiskUserSelectionContext } from "./../Common";
import { TopRiskListPadding, MapCon, TopRiskList } from "./styled";
import { TopRiskBottomLabel } from "./TopRiskBottomLabel";
import GeographicalPin from "./../icons/GeographicalPin";
import { Countries } from "./../../constants/countries";
import Position from "./../../models/Position";
interface Props {
  position: Position;
  width?: string;
  height?: string;
  segment: string;
  country?: string;
}

enum LayersVisibility {
  VISIBLE = "visible",
  NOT_VISIBLE = "none",
}

const { useActions } = TopRiskUserSelectionContext;

const getDynamicLayer = (risk: string, segment: string) => {
  return `${segment.substr(0, 4).toLocaleLowerCase()}-${risk.toLocaleLowerCase().substr(0, 5)}`;
};

const GeographicalMap = ({ width, height, position, segment, country }: Props) => {
  const mapRef: React.RefObject<ReactMapGL> = React.useRef();
  const { topRisk, topRiskList } = useActions();
  const [category, setCategory] = useState(null);
  const [layers, setLayers] = useState([]);
  const [tilesetKey, setTilesetKey] = useState(null);
  const prevCategory = usePrevious(category);
  const viewport = {
    zoom: 15,
    latitude: position.latitude,
    longitude: position.longitude,
  };

  const setLayersVisiblity = (risk, type, map) => {
    const layerName = getDynamicLayer(risk, segment);
    layers.filter((e) => e.id.includes(layerName)).forEach((e) => map.setLayoutProperty(e.id, "visibility", type));
  };

  const cleanUpLoadedLayers = (risk, map) => {
    const layerName = getDynamicLayer(risk, segment);
    layers
      .filter((e) => e.id.includes(segment.substr(0, 4).toLocaleLowerCase()) && !e.id.includes(layerName))
      .forEach((e) => map.setLayoutProperty(e.id, "visibility", LayersVisibility.NOT_VISIBLE));
  };

  const onLoad = () => {
    const map = mapRef.current.getMap();
    if (map && map.loaded()) {
      const mapLayers = map.getStyle().layers;
      setLayers(mapLayers);
    }
  };

  useEffect(() => {
    if (layers?.length > 0) {
      const map = mapRef.current.getMap();
      setLayersVisiblity(topRisk, LayersVisibility.VISIBLE, map);
      cleanUpLoadedLayers(topRisk, map);
    }
  }, [layers, topRisk]);

  useEffect(() => {
    const map = mapRef.current.getMap();
    const isCategoryChanged = category && category !== prevCategory && prevCategory;
    if (isCategoryChanged && map && map.isStyleLoaded()) {
      setLayersVisiblity(prevCategory, LayersVisibility.NOT_VISIBLE, map);
      setLayersVisiblity(topRisk, LayersVisibility.VISIBLE, map);
    }
  }, [category, layers, prevCategory, topRisk]);

  useEffect(() => {
    if (topRisk) {
      setCategory(topRisk.toLowerCase());
    }
  }, [topRisk]);

  useEffect(() => {
    const key =
      country.toLocaleUpperCase() === Countries.NO
        ? process.env[`REACT_APP_MSF_MAPBOX_NO_${segment}`]
        : country.toLocaleUpperCase() === Countries.SE
        ? process.env[`REACT_APP_MSF_MAPBOX_SE_${segment}`]
        : process.env[`REACT_APP_MSF_MAPBOX_NL_${segment}`];

    if (!key) {
      setTilesetKey(process.env.REACT_APP_MSF_MAP_STYLE_REPORT);
      console.log(`Missed tileset for segment: ${segment}`);
    } else {
      setTilesetKey(key);
    }
  }, [segment, country]);

  return (
    <MapCon data-cy="essential-report-geographical-map" className="singleObjMap">
      <ReactMapGL
        dragPan={false}
        touchAction="pan-y"
        getCursor={() => "default"}
        ref={mapRef}
        width={width}
        height={height}
        onLoad={onLoad}
        mapStyle={tilesetKey}
        mapboxApiAccessToken={process.env.REACT_APP_MSF_MAPBOX_TOKEN}
        {...viewport}
        attributionControl={false}
      >
        <Marker latitude={position.latitude} longitude={position.longitude} offsetTop={-28}>
          <GeographicalPin />
        </Marker>
        <TopRiskListPadding>
          {topRiskList?.length > 0 && (
            <TopRiskList>
              <DragAndDrop risks={topRiskList}></DragAndDrop>
            </TopRiskList>
          )}
        </TopRiskListPadding>

        {topRisk && <TopRiskBottomLabel topRisk={topRisk}></TopRiskBottomLabel>}
      </ReactMapGL>
    </MapCon>
  );
};

export default GeographicalMap;
