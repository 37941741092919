import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import { PlaceTypes } from "../../utilities/gqlUtilities";
import React, { useEffect, useState } from "react";
import { ComparisonElement, ComparisonList, ComparisonContainer, TextRow, YearCompare } from "./styled";

const YearlyComparison = ({ yearlyEventsData, t, placeType }) => {
  const [comparisonData, setComparisonData] = useState(null);

  useEffect(() => {
    const dataPath =
      placeType === PlaceTypes.NewLocationPlaceType
        ? yearlyEventsData?.nonObject?.incidentsCount?.categoryEvents
        : yearlyEventsData?.object?.incidentsCount?.categoryEvents;
    const data = dataPath
      ?.filter((elem) => elem.eventsCount > 0)
      .sort((a, b) => b.eventsCount - a.eventsCount)
      .slice(0, 3)
      .map((elem) => ({
        count: elem.eventsCount,
        category: (elem.nonLocalizedCategory || "").replace("REPORT_CLASS_", "").toLocaleLowerCase(),
      }));
    setComparisonData(data);
  }, [yearlyEventsData]);

  return (
    <div>
      {comparisonData?.length > 0 && (
        <YearCompare>
          <Text
            data-cy="year-compare-info"
            size="12px"
            mobileFontSize="11px"
            weight="700"
            color={Colors.borderDarkMode}
            textAlign="left"
            margin="0 0 16px 0"
            uppercase
          >
            {t("segmentRisk.yearlyEventsTitle")}
          </Text>
          <ComparisonContainer data-cy="comparison-container">
            <ComparisonList data-cy="comparison-list">
              {comparisonData.map((elem) => (
                <ComparisonElement key={elem.category} data-cy="comparison-element">
                  <TextRow>
                    <Text
                      size="12px"
                      mobileFontSize="10px"
                      color={Colors.borderDarkMode}
                      textAlign="left"
                      uppercase
                      margin="0"
                      lineHeight="18px"
                      data-cy="activities-category-header"
                    >
                      {t("segmentRisk.category")}
                    </Text>
                    <Text
                      size="12px"
                      mobileFontSize="10px"
                      color={Colors.borderDarkMode}
                      textAlign="left"
                      uppercase
                      margin="0"
                      lineHeight="18px"
                      data-cy="activities-count-header"
                    >
                      {t("segmentRisk.count")}
                    </Text>
                  </TextRow>
                  <TextRow>
                    <Text
                      data-cy="activities-category"
                      size="16px"
                      mobileFontSize="14px"
                      color={Colors.white}
                      textAlign="left"
                      lineHeight="24px"
                      weight="700"
                      margin="0"
                    >
                      {t(`categorization.${elem?.category}`)}
                    </Text>
                    <Text
                      size="16px"
                      mobileFontSize="14px"
                      color={Colors.white}
                      textAlign="left"
                      lineHeight="24px"
                      weight="700"
                      margin="0"
                      uppercase
                      data-cy="activities-count"
                    >
                      {elem.count}
                    </Text>
                  </TextRow>
                </ComparisonElement>
              ))}
            </ComparisonList>
          </ComparisonContainer>
        </YearCompare>
      )}
    </div>
  );
};

export default YearlyComparison;
