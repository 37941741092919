import styled from "styled-components";
import { Devices } from "../../../utilities/common-styles";
export const Wrapper = styled.div`
  margin: 32px 100px;
  .side {
    align-items: center;
    margin-top: 2rem;
  }

  @media ${Devices.tablet} {
    margin: 24px 24px;
  }

  @media ${Devices.mobile} {
    margin: 0;
    .side {
      margin-top: 0;
    }
  }
`;

export const Description = styled.div`
  margin-top: 8px;
  max-width: 786px;
  white-space: pre-wrap;
  font-size: 24px;
  color: white;

  @media ${Devices.mobile} {
    font-size: 16px;
  }
`;
