import { useMutation, useQuery } from '@apollo/client';

import {
    GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES,
    SET_OBJECT_PLACE_PROPERTIES
} from './../queries/placeProperties';
import { getPlaceNamespace } from './../utilities/gqlUtilities';

import { NON_OBJECT } from './../queries/nonObjects';
import { IQuestionnairePayload } from './../models/QuestionnairePayload';
import { PlaceProperties } from './../utilities/placeCapabilitiesUtils';
import {     
    getInitialAnswers,
    getQuestionsData,
    checkAnyQuestionAvailable,
    getSegmentQuestion,
    getOptionalQuestions } from '../components/Questions/OptionalQuestions/formatQuestionData';

const useVirtualObjectQuestionnaireUpdate = ({
    objectId,
    country,
    placeType,
    latitude,
    longitude
}: IQuestionnairePayload) => {
    const queryVariables = {
        placeNamespace: getPlaceNamespace(country),
        placeType,
        country: country.toLowerCase(),
        nonObjectId: objectId,
        latitude: Number(latitude),
        longitude: Number(longitude)
    };

    const { data, loading, error } = useQuery(
        GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES,
        {
            variables: queryVariables,
            ssr: false
        }
    );

    const allQuestions = getQuestionsData(data?.nonObject?.allPlaceProperties);
    const optionalQuestions = getOptionalQuestions(allQuestions);

    const segmentQuestion = getSegmentQuestion(allQuestions);

    const isAnyQuestionAvailable = !!checkAnyQuestionAvailable(
        optionalQuestions
    );

    const initialAnswers = getInitialAnswers(optionalQuestions);

    const [
        updateValue,
        { loading: updateLoading, error: updateError, called: updateCalled }
    ] = useMutation(SET_OBJECT_PLACE_PROPERTIES, {
        refetchQueries: [
            {
                query: GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES,
                variables: queryVariables
            },
            {
                query: NON_OBJECT,
                variables: {
                    ...queryVariables,
                    propertyNames: [PlaceProperties.BUSINESS_SEGMENT]
                }
            }
        ],
        awaitRefetchQueries: true
    });

    const setAnswer = (question, value) => {
        const updatedProperties = [
            {
                nextVersion: question.valueVersion + 1,
                propertyName: question.name,
                value
            }
        ];
        return updateValue({
            variables: {
                ...queryVariables,
                objectNo: objectId,
                updatedProperties
            }
        });
    };

    return {
        questions: optionalQuestions,
        allQuestions,
        isAnyQuestionAvailable,
        segmentQuestion,
        setAnswer,
        error,
        loading,
        updateCalled,
        updateLoading,
        updateError,
        initialAnswers,
        setSegmentAnswer: setAnswer
    };
};

export default useVirtualObjectQuestionnaireUpdate;
