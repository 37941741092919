import React from 'react';

const Question3 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M28 26H4V6"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M28 12L20 20L12 14L4 21"
            stroke= "#F7F7F7"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default Question3;
