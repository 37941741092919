import { withAuthenticationRequired } from "@auth0/auth0-react";
import i18next from "i18next";
import React, { createContext, useContext } from "react";
import { useStore } from "react-context-hook";
import { useAuth } from "../hooks/UseAuth";
import { useSearchParam } from "../hooks/useSearchParam";
interface IAuthContextValue {
  token: string | any;
}

const AuthContext = createContext({} as IAuthContextValue);

export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider: React.FC = ({ children }) => {
  const token = useAuth();

  return (
    <AuthContext.Provider
      value={{
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthGate: React.FC = ({ children }) => {
  const t = useSearchParam("t");
  const token = useSearchParam("token");
  let WrappedComponent: React.ComponentType = AuthContextProvider;
  const [userToken, _setUserToken] = useStore("userToken", "");
  const location = window.location;

  if (!userToken && !t && !token) {
    WrappedComponent = withAuthenticationRequired(AuthContextProvider, {
      returnTo: () => {
        return location.pathname;
      },
      loginOptions: {
        language: i18next.language,
      },
    });
  }

  return <WrappedComponent>{children}</WrappedComponent>;
};

export default AuthGate;
