export enum Spacing {
    XXXLarge = 72,
    XXLarge = 64,
    XLarge = 48,
    Large = 32,
    Medium = 24,
    Small = 16,
    Mini = 8,
    Micro = 4
}
