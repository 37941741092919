import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const bounceDelay = keyframes`
    0%, 80%, 100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
`;

interface SpinnerProps {
    marginTop?: string;
}

const Spinner = styled.div<SpinnerProps>`
    margin: ${props =>
            typeof props.marginTop !== 'undefined'
                ? `${props.marginTop}px`
                : '100px'}
        auto 0;
    width: 70px;
    text-align: center;

    > div {
        width: 18px;
        height: 18px;
        margin: 2px;
        background-color: ${props => props.theme.purple};
        border-radius: 100%;
        display: inline-block;
        animation: ${bounceDelay} 1.4s infinite ease-in-out both;
    }
`;

const Dot = styled.div`
    &:nth-child(1) {
        animation-delay: -0.32s;
    }

    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

const LoadingIndicator = ({ marginTop = '100' }: SpinnerProps) => (
    <Spinner marginTop={marginTop}>
        <Dot />
        <Dot />
        <Dot />
    </Spinner>
);

export default LoadingIndicator;
