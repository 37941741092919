import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { Spacing } from './../../styles/templates/Spacing';

const bounceDelay = keyframes`
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
`;

const Dot = styled.div`
    margin: 2px;
    display: inline-block;
    border-radius: 100%;
    animation: ${bounceDelay} 1.4s infinite ease-in-out both;
    &:nth-child(1) {
        animation-delay: -0.32s;
    }
    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

interface SpinnerProps {
    paddingTop?: string;
    loading?: boolean;
    size?: string;
    textAlign?: string;
    color?: string;
    loadingInfo?: string;
}

const Spinner = styled.div<SpinnerProps>`
    padding: ${props =>
            typeof props.paddingTop !== 'undefined'
                ? `${props.paddingTop}`
                : '100px'}
        0px;
    text-align: ${props =>
        typeof props.textAlign !== 'undefined'
            ? `${props.textAlign}`
            : 'center'};

    ${Dot} {
        width: ${props =>
            typeof props.size !== 'undefined' ? `${props.size}` : '18px'};
        height: ${props =>
            typeof props.size !== 'undefined' ? `${props.size}` : '18px'};
        background-color: ${props => `var(--securitas-${props.color})`};
    }
`;

const Info = styled.div`
    margin-top: ${Spacing.Micro + Spacing.Mini}px;
`;

const LoadingIndicator: FC<SpinnerProps> = ({
    paddingTop = '100px' as string,
    size,
    textAlign,
    children,
    loading,
    color = 'blue',
    loadingInfo
}) => (
    <>
        {loading ? (
            <Spinner
                paddingTop={paddingTop}
                size={size}
                textAlign={textAlign}
                role= "loader"
                color={color}
            >
                <Dot />
                <Dot />
                <Dot />
                {!!loadingInfo && <Info>{loadingInfo}</Info>}
            </Spinner>
        ) : (
            <>{children}</>
        )}
    </>
);

export default LoadingIndicator;
