import React from 'react';

const ArrowDownIcon = () => (
    <svg
        width= "20"
        height= "11"
        viewBox= "0 0 20 11"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M10.0003 11.0001C9.74031 11.0001 9.49031 10.9001 9.29031 10.7101L0.300312 1.73008C-0.0896875 1.34008 -0.0896875 0.710077 0.300312 0.320077C0.690312 -0.0699226 1.32031 -0.0699226 1.71031 0.320077L10.0003 8.59008L18.2903 0.310078C18.6803 -0.0799219 19.3103 -0.0799219 19.7003 0.310078C20.0903 0.700078 20.0903 1.33008 19.7003 1.72008L10.7103 10.7001C10.5103 10.9001 10.2603 11.0001 10.0003 11.0001Z"
            fill= "#031F30"
        />
    </svg>
);

export default ArrowDownIcon;
