import { useState } from 'react';
import { isBrowser } from './../utilities/windowUtilities';

const useSessionStorage = <T>(
    key: string,
    initialValue: T
): [T, (value: T | ((value: T) => T)) => void] => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (!isBrowser()) {
            return initialValue;
        }

        try {
            const item = window.sessionStorage.getItem(key);

            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            if (!isBrowser()) {
                return;
            }
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;

            setStoredValue(valueToStore);

            window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

export default useSessionStorage;
