import React from 'react';

const DayGraphIcon = ({ isActive }) => {
    return (
        <svg
            width= "32"
            height= "32"
            viewBox= "0 0 32 32"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <rect
                width= "32"
                height= "32"
                rx= "16"
                fill={isActive ? '#9977FE' : '#6C47CF'}
            />
            <path
                d= "M21.25 16H16.75V10.75C16.75 10.3375 16.4125 10 16 10C15.5875 10 15.25 10.3375 15.25 10.75V16.75C15.25 17.1625 15.5875 17.5 16 17.5H21.25C21.6625 17.5 22 17.1625 22 16.75C22 16.3375 21.6625 16 21.25 16Z"
                fill= "#F7F7F7"
            />
            <path
                d= "M15.9922 5.5C10.2022 5.5 5.49219 10.21 5.49219 16C5.49219 21.79 10.2022 26.5 15.9922 26.5C21.7822 26.5 26.4922 21.79 26.4922 16C26.4922 10.21 21.7822 5.5 15.9922 5.5ZM15.9922 25C11.0272 25 6.99219 20.965 6.99219 16C6.99219 11.035 11.0272 7 15.9922 7C20.9572 7 24.9922 11.035 24.9922 16C24.9922 20.965 20.9497 25 15.9922 25Z"
                fill= "#F7F7F7"
            />
        </svg>
    );
};

export default DayGraphIcon;
