import { Colors, Devices, Sizes } from "../../utilities/common-styles";

import styled from "styled-components";
import { TextButton } from "../../utilities/buttons";
import { PageWrapper, ShadowWrapper } from "../../utilities/wrappers";
import { SolidButtonWrapper } from "../BaldrView/styled";

export const TileWrapper = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 0.5rem;

  > h3 {
    text-align: center;
    span {
      text-transform: lowercase;
      font-variant: small-caps;
      display: block;
    }
  }
  button {
    width: 100%;
  }
`;

export const Container = styled.div`
  background-color: ${Colors.backgroundDarkMode};
  flex: 1;
  @media ${Devices.mobile} {
    padding-bottom: ${Sizes.mobileMenuMargin}px;
  }

  ${PageWrapper} {
    background-color: ${Colors.backgroundDarkMode};
  }
`;

export const ShadowWrapperContainer = styled(ShadowWrapper)`
  border-radius: 16px;
  background-color: ${Colors.cardBackgroundPrimary};
  border: none;
`;

export const CancelButton = styled(TextButton)`
  background-color: none;
  border: 2px solid ${Colors.borderDarkMode};
  color: ${Colors.borderDarkMode};
  border-radius: 32px;
  padding: 8px 56px;
`;

export const InputWrapper = styled.div<{
  text?: string;
}>`
  position: relative;

  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-top: 16px;
  border: 2px solid ${Colors.borderDarkMode};
  box-sizing: border-box;
  border-radius: 24px;

  div {
    border: none;
  }

  svg {
    circle {
      fill: ${Colors.purpleHover};
    }
  }

  input {
    background: none;
    border: none;
    color: ${Colors.text};
    resize: none;
    font-family: SecuritasPro;
  }

  label {
    background: ${Colors.backgroundDarkMode};
    border-radius: 11px;
    color: ${Colors.borderDarkMode};
    padding: 2px 8px;
  }
`;

export const BackButton = styled(SolidButtonWrapper)`
  width: auto;
  background: none;
  display: inline-flex;
  padding: 16px 0px;
  color: ${Colors.text};
  font-size: 16px;
  svg {
    margin-right: 4px;
  }
`;
