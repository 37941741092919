import React from 'react';

const Question5 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M15.8 2C9.9 2 5 6.8 5 12.8C5 15.6 5.9 18.3 7.5 20.5L15 29.6C15.2 29.8 15.5 30 15.8 30C16.1 30 16.4 29.9 16.6 29.6L24.1 20.4C25.7 18.2 26.6 15.5 26.6 12.7C26.6 6.8 21.7 2 15.8 2ZM22.5 19.2L15.8 27.4L9.1 19.3C7.8 17.4 7 15.1 7 12.8C7 8 10.9 4 15.8 4C20.7 4 24.6 7.9 24.6 12.8C24.6 15.1 23.8 17.4 22.5 19.2Z"
            fill= "#0E283D"
        />
        <path
            d= "M15.8008 9C13.6008 9 11.8008 10.8 11.8008 13C11.8008 15.2 13.6008 17 15.8008 17C18.0008 17 19.8008 15.2 19.8008 13C19.8008 10.8 18.0008 9 15.8008 9ZM15.8008 15C14.7008 15 13.8008 14.1 13.8008 13C13.8008 11.9 14.7008 11 15.8008 11C16.9008 11 17.8008 11.9 17.8008 13C17.8008 14.1 16.9008 15 15.8008 15Z"
            fill= "#0E283D"
        />
    </svg>
);

export default Question5;
