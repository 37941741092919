import React, { forwardRef } from "react";
import { SearchWrapper, Ul, Li, InputComponent } from "./styled";
import Result from "./Result";
import SearchIcon from "../../icons/SearchIcon";
import MarkerOutlined from "../../icons/MarkerOutlined";
import { Location } from "../types";
import { Input } from "../../Common";

type Props = {
  placeholder?: string;
  searchQuery?: string;
  results?: Location[];
  selectedResult?: Location;
  expanded?: boolean;
  onInputClick?: () => void;
  onQueryChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResultClick?: (result: Location) => void;
  onClear?: () => void;
  onResultClear?: () => void;
};

const SearchView = forwardRef<HTMLDivElement, Props>(
  ({ placeholder, searchQuery, results, selectedResult, expanded, onInputClick, onQueryChange, onClear, onResultClick, onResultClear }: Props, ref) => {
    const haveResults = !!results?.length;
    const resultSelected = !!selectedResult;
    const handleResultClick = (result: Location) => {
      !!onResultClick && onResultClick(result);
    };

    return (
      <SearchWrapper inputBordered={expanded && haveResults} ref={ref}>
        {resultSelected ? (
          <Result
            address={selectedResult.address}
            location={`${Number(selectedResult.center[1]).toFixed(5)}, ${Number(selectedResult.center[0]).toFixed(5)}`}
            onClearClick={onResultClear}
          />
        ) : (
          <>
            <InputComponent text={searchQuery} haveResults={expanded && haveResults}>
              <Input
                placeholder={placeholder}
                value={searchQuery}
                onChange={onQueryChange}
                onClear={onClear}
                renderIcon={<SearchIcon />}
                onInputClick={onInputClick}
                withoutLabel={true}
              />
            </InputComponent>

            {expanded && haveResults && (
              <Ul>
                {results.map(
                  (result) =>
                    result && (
                      <Li key={result.id} onClick={() => handleResultClick(result)}>
                        <MarkerOutlined />
                        {result.address}
                      </Li>
                    )
                )}
              </Ul>
            )}
          </>
        )}
      </SearchWrapper>
    );
  }
);

SearchView.defaultProps = {
  placeholder: "Search coordinates, area or nearby location",
};

SearchView.displayName = "SearchInput";

export default SearchView;
