import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";

import React, { useEffect, useState } from "react";

import {
  AllQuestionSetContainer,
  CheckAllQuestionsContainer,
  CheckList,
  CheckListContainer,
  CheckListHeader,
  CheckToggle,
  CollapsedPanel,
  CollapsedPanelTopMost,
  QuestionContainer,
} from "../styled";
import { ProgressBar } from "./Progressbar";
import { useTranslation } from "react-i18next";
import ArrowDown from "../icons/ArrowDown";
import { CheckListIcon } from "../icons/CheckListIcon";

export const CheckListComponent = ({ checkedList, singleUpdate, data, updateValue }) => {
  const { t } = useTranslation();

  const [questions, setQuestions] = useState(checkedList);
  const [checked, setChecked] = useState(checkedList?.filter((singleQuestion) => singleQuestion).length * singleUpdate);

  const [collapse, setCollapse] = useState(true);

  const updateList = (index) => {
    const updatedAreas = questions;
    const singleQuestionCurrent = questions[index];
    updatedAreas[index] = !singleQuestionCurrent;
    const variables = {
      name: data[index].name,
      checkState: !singleQuestionCurrent,
    };

    updateValue({
      variables,
    });
    setQuestions(updatedAreas);
    setChecked(updatedAreas.filter((singleQuestion) => singleQuestion).length * singleUpdate);
  };

  const selectAll = () => {
    const updatedAreas = questions;
    setQuestions(updatedAreas.fill(true));
    data.map((question) => {
      const variables = {
        name: question.name,
        checkState: true,
      };

      updateValue({
        variables,
      });
    });
    setChecked(100);
  };

  const unselectAll = () => {
    const updatedAreas = questions;
    setQuestions(updatedAreas.fill(false));
    data.map((question) => {
      const variables = {
        name: question.name,
        checkState: false,
      };

      updateValue({
        variables,
      });
    });
    setChecked(0);
    setCollapse(false);
  };

  const changeSelection = () => {
    if (checked === 100) {
      unselectAll();
    } else {
      selectAll();
    }
  };
  useEffect(() => {
    if (checked === 100) {
      setCollapse(true);
    }
  }, [checked]);

  return (
    <>
      {data && questions?.length > 0 && (
        <CheckListContainer>
          <CheckListHeader data-cy="checklist-header">
            <Text size="16px" lineHeight="24px" mobileFontSize="14px" mobileLineHeight="21px" color={Colors.text} bold margin="0">
              {t("reportObject.checkList.title")}
            </Text>
            <div onClick={() => setCollapse(!collapse)}>
              <ArrowDown data-cy="checklist-expand" flip={!collapse}></ArrowDown>
            </div>
          </CheckListHeader>
          <Text
            margin="0 0 56px 0"
            size="16px"
            lineHeight="24px"
            mobileFontSize="14px"
            mobileLineHeight="21px"
            color={Colors.text}
            data-cy="checklist-description"
          >
            {t("reportObject.checkList.description")}
          </Text>
          <ProgressBar percentage={checked} />
          <CheckAllQuestionsContainer onClick={changeSelection}>
            <CheckListIcon stroke="1" color={Colors.primaryPurple} />
            <Text size="16px" lineHeight="24px" mobileFontSize="14px" mobileLineHeight="21px" color={Colors.primaryPurple} data-cy="check-uncheck-all">
              {checked === 100 ? t("reportObject.checkList.uncheckAll") : t("reportObject.checkList.checkAll")}
            </Text>
          </CheckAllQuestionsContainer>

          {collapse ? (
            <>
              {checked === 100 ? (
                <AllQuestionSetContainer onClick={() => setCollapse(false)} marginBottom="0" checked={true} data-cy="all-questions-set-container">
                  <CheckListIcon stroke="1" color="green" />
                  <Text size="16px" lineHeight="24px" mobileFontSize="14px" mobileLineHeight="21px" color={Colors.text}>
                    {t("reportObject.checkList.setAll")}
                  </Text>
                </AllQuestionSetContainer>
              ) : (
                <QuestionContainer onClick={() => setCollapse(false)} marginBottom="0" checked={questions && questions[0]} data-cy="wrapped-question-contianer">
                  <CheckToggle off={questions && questions[0]} color={Colors.primaryPurple} onClick={() => updateList(0)} data-cy="wrapped-toggle">
                    {questions && questions[0] && <CheckListIcon />}
                  </CheckToggle>

                  <Text
                    size="16px"
                    lineHeight="24px"
                    mobileFontSize="14px"
                    mobileLineHeight="21px"
                    color={Colors.borderDarkMode}
                    margin="0"
                    data-cy="wrapped-description"
                  >
                    {(data && data[0].title) || data[0].contents}
                  </Text>
                </QuestionContainer>
              )}

              <CollapsedPanel data-cy="collapsed-panel" />
              <CollapsedPanelTopMost data-cy="collapsed-panel" />
            </>
          ) : (
            <></>
          )}
          <CheckList expanded={!collapse}>
            {data?.map((singleQuestion) => {
              return (
                <QuestionContainer
                  key={singleQuestion.orderIndex}
                  marginBottom="8px"
                  checked={questions[singleQuestion.orderIndex - 1]}
                  data-cy={`question-contaner-${singleQuestion.orderIndex - 1}`}
                >
                  <CheckToggle
                    data-cy="question-checkbox"
                    off={questions[singleQuestion.orderIndex - 1]}
                    color={Colors.primaryPurple}
                    onClick={() => updateList(singleQuestion.orderIndex - 1)}
                  >
                    {questions[singleQuestion.orderIndex - 1] && <CheckListIcon />}
                  </CheckToggle>
                  <div>
                    <Text
                      size="16px"
                      lineHeight="24px"
                      mobileFontSize="14px"
                      mobileLineHeight="21px"
                      color={Colors.borderDarkMode}
                      margin="0"
                      data-cy="wrapped-description"
                    >
                      {singleQuestion.title}
                    </Text>
                    <Text
                      size="16px"
                      lineHeight="24px"
                      mobileFontSize="14px"
                      mobileLineHeight="21px"
                      color={Colors.text}
                      margin="0"
                      data-cy="question-description"
                    >
                      {singleQuestion.contents}
                    </Text>
                  </div>
                </QuestionContainer>
              );
            })}
          </CheckList>
        </CheckListContainer>
      )}
    </>
  );
};
