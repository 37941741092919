import React, { ReactNode } from 'react';
import { BarWrapper, LoadingIndicator } from './styled';

type Props = {
    value?: number;
    max?: number;
    renderIndicator?: ReactNode;
};

const ProgressBar = ({ value, max, renderIndicator }: Props) => {
    const left = `${value - 5}%`;

    return (
        <BarWrapper>
            {!!renderIndicator && (
                <LoadingIndicator style={{ left }}>
                    {renderIndicator}
                </LoadingIndicator>
            )}
            <progress value={value} max={max} />
        </BarWrapper>
    );
};

ProgressBar.defaultProps = {
    value: 25,
    max: 100
};

export default ProgressBar;
