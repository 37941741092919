import styled from "styled-components";
import { SolidButton } from "../../utilities/buttons";
import { Colors, Devices, Sizes } from "../../utilities/common-styles";
export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 35%;
  margin-top: 2rem;

  @media ${Devices.tablet} {
    width: 45%;
  }

  @media ${Devices.mobile} {
    width: 90%;
    padding-bottom: ${Sizes.mobileMenuMargin}px;
  }
`;

export const Content = styled.div`
  margin-bottom: 1rem;
`;
export const Description = styled.div`
  margin-bottom: 2rem;
  color: white;
`;

export const Button = styled(SolidButton)`
  width: 100%;
`;

export const Header = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  margin-bottom: 2em;
  h2 {
    color: white;
  }
`;

export const Option = styled.div`
  position: relative;
  line-height: 1;
  margin-bottom: 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${Colors.cardBackgroundPrimary};
  div {
    margin-bottom: 0.75rem;
  }
  strong {
    color: white;
  }
  div:nth-child(2) {
    color: white;
  }
`;
export const OptionTitle = styled.div`
  color: ${Colors.borderDarkMode};
`;
