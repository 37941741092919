import useOptionalQuestionsUpdate from './useOptionalQuestionsUpdate';
import useVirtualObjectQuestionnaireUpdate from './useVirtualObjectQuestionnaireUpdate';
import { PlaceTypes } from './../utilities/gqlUtilities';
import { IQuestionnairePayload } from './../models/QuestionnairePayload';

const useQuestionnaireUpdate = (payload: IQuestionnairePayload) => {
  let hookToUse;

  switch (payload.placeType) {
    case PlaceTypes.NewLocationPlaceType:
      hookToUse = useVirtualObjectQuestionnaireUpdate;
      break;
    case PlaceTypes.ObjectPlaceType:
    default:
      hookToUse = useOptionalQuestionsUpdate;
  }

  const questionnaireUpdate = hookToUse(payload);

  return questionnaireUpdate;
};

export default useQuestionnaireUpdate;