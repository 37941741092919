import React from 'react';
import styled from 'styled-components';

interface HorizontalArrowIconProps {
    flip?: boolean;
    small?: boolean;
    fill?: string;
    onClick?: () => void;
}

const IconCon = styled.span<HorizontalArrowIconProps>`
    g {
        path {
            transform: ${props =>
                props.flip ? 'rotate(45deg)' : 'rotate(0deg)'};
            transform-origin: 50% 50%;
            transition: fill 1s ease;
            cursor: pointer;
        }
    }
`;

const OpenCategories = (props: HorizontalArrowIconProps) => {
    return (
        <IconCon data-cy= "horizontal-arrow" flip={props.flip} fill={props.fill}>
            <svg
                width= "64"
                height= "65"
                viewBox= "0 0 64 65"
                fill= "none"
                xmlns= "http://www.w3.org/2000/svg"
            >
                <g filter= "url(#filter0_d_7515_46141)">
                    <rect
                        x= "4"
                        y= "3.06543"
                        width= "56"
                        height= "56"
                        rx= "28"
                        fill= "#9977FE"
                    />
                    <path
                        d= "M41 30.3154H32.75V22.0654C32.75 21.8665 32.671 21.6758 32.5303 21.5351C32.3897 21.3944 32.1989 21.3154 32 21.3154C31.8011 21.3154 31.6103 21.3944 31.4697 21.5351C31.329 21.6758 31.25 21.8665 31.25 22.0654V30.3154H23C22.8011 30.3154 22.6103 30.3944 22.4697 30.5351C22.329 30.6758 22.25 30.8665 22.25 31.0654C22.25 31.2643 22.329 31.4551 22.4697 31.5958C22.6103 31.7364 22.8011 31.8154 23 31.8154H31.25V40.0654C31.25 40.2643 31.329 40.4551 31.4697 40.5958C31.6103 40.7364 31.8011 40.8154 32 40.8154C32.1989 40.8154 32.3897 40.7364 32.5303 40.5958C32.671 40.4551 32.75 40.2643 32.75 40.0654V31.8154H41C41.1989 31.8154 41.3897 31.7364 41.5303 31.5958C41.671 31.4551 41.75 31.2643 41.75 31.0654C41.75 30.8665 41.671 30.6758 41.5303 30.5351C41.3897 30.3944 41.1989 30.3154 41 30.3154Z"
                        fill= "white"
                    />
                </g>
                <defs>
                    <filter
                        id= "filter0_d_7515_46141"
                        x= "0"
                        y= "0.0654297"
                        width= "64"
                        height= "64"
                        filterUnits= "userSpaceOnUse"
                        colorInterpolationFilters= "sRGB"
                    >
                        <feFlood floodOpacity= "0" result= "BackgroundImageFix" />
                        <feColorMatrix
                            in= "SourceAlpha"
                            type= "matrix"
                            values= "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result= "hardAlpha"
                        />
                        <feOffset dy= "1" />
                        <feGaussianBlur stdDeviation= "2" />
                        <feColorMatrix
                            type= "matrix"
                            values= "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                        />
                        <feBlend
                            mode= "normal"
                            in2= "BackgroundImageFix"
                            result= "effect1_dropShadow_7515_46141"
                        />
                        <feBlend
                            mode= "normal"
                            in= "SourceGraphic"
                            in2= "effect1_dropShadow_7515_46141"
                            result= "shape"
                        />
                    </filter>
                </defs>
            </svg>
        </IconCon>
    );
};

export default OpenCategories;
