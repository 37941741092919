import React, { ReactNode, useState } from 'react';
import { TopRiskUserSelectionContext } from './TopRiskUserSelectionContext';

const TopRiskUserSelectionContextProvider = ({
    children
}: {
    children: ReactNode;
}) => {
    const [topRisk, setTopRisk] = useState(null);
    const [topRiskList, setTopRiskList] = useState(null);

    const updateTopRiskList = value => {
        setTopRiskList(value);
    };

    return (
        <TopRiskUserSelectionContext.Provider
            value={{ topRisk, setTopRisk, topRiskList, updateTopRiskList }}
        >
            {children}
        </TopRiskUserSelectionContext.Provider>
    );
};

export default TopRiskUserSelectionContextProvider;
