import React from 'react';
import { Text } from '../../../utilities/typography';
import { InsightsCard, FrequencyChartWrapper, HeaderCard } from '../../styled';
import FrequencyChart from '../../FrequencyChart';

import EmptyInsightsDescription from './EmptyInsightsDescription';

interface IFrequencyChartCardProps {
    placeInsight;
    t: any;
    url: string;
}

const FrequencyChartCard = ({
    placeInsight,
    t,
    url
}: IFrequencyChartCardProps) => {
    const getPercentageValue = (): number => {
        if (placeInsight.isEmpty) {
            return 0.5;
        }
        const numberValue = Number(placeInsight.value);
        const percentageValue =
            numberValue < 1 ? numberValue * 100 : numberValue;
        return Math.round(percentageValue);
    };

    return (
        <InsightsCard>
            <HeaderCard data-cy= "frequency-card-header">
                {placeInsight.displayName}
            </HeaderCard>
            <div>
                <FrequencyChartWrapper
                    data-cy= "insights-frequency-chart"
                    isEmpty={placeInsight.isEmpty}
                >
                    <FrequencyChart
                        data={getPercentageValue()}
                        isEmpty={placeInsight.isEmpty}
                        data-cy= "frequency-chart"
                    />
                    {placeInsight.isEmpty ? (
                        <EmptyInsightsDescription
                            t={t}
                            url={url}
                            data-cy= "frequency-card-description"
                        />
                    ) : (
                        <Text
                            size= "16px"
                            mobileFontSize= "14px"
                            margin= "0 0 0 24px"
                            color= "white"
                            lineHeight= "24px"
                            weight= "400"
                            data-cy= "frequency-card-description"
                        >
                            {placeInsight.description}
                        </Text>
                    )}
                </FrequencyChartWrapper>
            </div>
        </InsightsCard>
    );
};

export default FrequencyChartCard;
