import React from "react";
import { SolidButton } from "../../../utilities/buttons";
import { Text } from "../../../utilities/typography";
import WarningImage from "../../icons/WarningImage";
import { ContentWrapper, ButtonsWrapper } from "./styled";

type Props = {
  title: string;
  description: string;
  okText: string;
  cancelText: string;
  onOkClick: () => void;
  onCancelClick: () => void;
};

const ConfirmationModalView = ({ title, description, okText, cancelText, onOkClick, onCancelClick }: Props) => (
  <ContentWrapper data-cy="esssential-order-confirmation">
    <WarningImage />
    <Text margin="16px 0 16px 0" size="large" bold>
      {title}
    </Text>
    <Text margin="0 0 24px 0" size="large">
      {description}
    </Text>
    <ButtonsWrapper>
      <SolidButton dangerous onClick={onOkClick}>
        {okText}
      </SolidButton>
      <SolidButton onClick={onCancelClick}>{cancelText}</SolidButton>
    </ButtonsWrapper>
  </ContentWrapper>
);

export default ConfirmationModalView;
