import React from 'react';

const ClearIcon = ({ outlined }: { outlined?: boolean }) =>
    outlined ? (
        <svg
            width= "16"
            height= "16"
            viewBox= "0 0 16 16"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <circle cx= "8" cy= "8" r= "7.5" stroke= "#2DABE2" />
            <path
                d= "M11.1853 4.82091C11.0908 4.72623 10.9626 4.67303 10.8288 4.67303C10.6951 4.67303 10.5668 4.72623 10.4723 4.82091L7.99984 7.28837L5.52733 4.81586C5.43286 4.72118 5.30461 4.66797 5.17086 4.66797C5.03712 4.66797 4.90887 4.72118 4.8144 4.81586C4.61721 5.01305 4.61721 5.3316 4.8144 5.52879L7.28691 8.0013L4.8144 10.4738C4.61721 10.671 4.61721 10.9895 4.8144 11.1867C5.01159 11.3839 5.33014 11.3839 5.52733 11.1867L7.99984 8.71423L10.4723 11.1867C10.6695 11.3839 10.9881 11.3839 11.1853 11.1867C11.3825 10.9895 11.3825 10.671 11.1853 10.4738L8.71277 8.0013L11.1853 5.52879C11.3774 5.33665 11.3774 5.01305 11.1853 4.82091Z"
                fill= "#2DABE2"
            />
        </svg>
    ) : (
        <svg
            width= "16"
            height= "16"
            viewBox= "0 0 16 16"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
            className= "clear-icon"
        >
            <circle cx= "8" cy= "8" r= "8" fill= "#626366" />
            <path
                d= "M11.1853 4.82091C11.0908 4.72623 10.9626 4.67303 10.8288 4.67303C10.6951 4.67303 10.5668 4.72623 10.4723 4.82091L7.99984 7.28837L5.52733 4.81586C5.43286 4.72118 5.30461 4.66797 5.17086 4.66797C5.03712 4.66797 4.90887 4.72118 4.8144 4.81586C4.61721 5.01305 4.61721 5.3316 4.8144 5.52879L7.28691 8.0013L4.8144 10.4738C4.61721 10.671 4.61721 10.9895 4.8144 11.1867C5.01159 11.3839 5.33014 11.3839 5.52733 11.1867L7.99984 8.71423L10.4723 11.1867C10.6695 11.3839 10.9881 11.3839 11.1853 11.1867C11.3825 10.9895 11.3825 10.671 11.1853 10.4738L8.71277 8.0013L11.1853 5.52879C11.3774 5.33665 11.3774 5.01305 11.1853 4.82091Z"
                fill= "white"
            />
        </svg>
    );

export default ClearIcon;
