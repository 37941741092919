import React from 'react';

import { useTranslation } from 'react-i18next';
import WrappedLoader from '../../WrappedLoader';
import { Button } from '../styled';
import { ApolloError } from 'apollo-client';
import GraphQlErrorView from '../../Common/GraphQlErrorView';

interface MorphSaveButtonProp {
    currentValue: string;
    title: string;
    updateSegment: () => {};
    updateCalled: boolean;
    updateLoading: boolean;
    updateError: ApolloError;
}

const MorphSaveButton = ({
    currentValue,
    title,
    updateSegment,
    updateCalled,
    updateLoading,
    updateError
}: MorphSaveButtonProp) => {
    const { t } = useTranslation();

    if (!updateCalled) {
        return (
            <Button
                data-cy= "essential-order-save-btn"
                onClick={updateSegment}
                disabled={!currentValue}
            >
                {title}
            </Button>
        );
    }

    if (updateCalled && updateLoading) {
        return <WrappedLoader />;
    }
    if (updateCalled && updateError) {
        return (
            <GraphQlErrorView
                errors={[updateError]}
                messages={{
                    errorTitle: t('common:error'),
                    genericMessage: t('common:noDataError'),
                    noPermission: t('common:no-permissions')
                }}
            />
        );
    }

    return <></>;
};

export default MorphSaveButton;
