import React from 'react';

const GuardIcon = () => (
    <svg
        width= "40"
        height= "40"
        viewBox= "0 0 40 40"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M24.8538 12.4012L26.8913 8.1397L20.0626 5.7659C19.9863 5.73936 19.9032 5.7395 19.827 5.76629L13.0284 8.15489L14.926 12.4012H24.8538ZM12.8525 16.16C12.8525 15.5476 12.9296 14.9531 13.0746 14.3858L10.7459 9.17489C10.1495 7.84024 10.8205 6.2808 12.1997 5.79623L18.9983 3.40762C19.6082 3.19333 20.2728 3.19223 20.8835 3.4045L27.7121 5.77831C29.1203 6.26781 29.7898 7.87307 29.1467 9.21805L26.8274 14.0693C27.0295 14.7305 27.1382 15.4326 27.1382 16.16C27.1382 20.1049 23.9403 23.3029 19.9954 23.3029C16.0505 23.3029 12.8525 20.1049 12.8525 16.16ZM15.3525 16.16C15.3525 15.7485 15.4055 15.3511 15.5049 14.9733H24.4858C24.5852 15.3511 24.6382 15.7485 24.6382 16.16C24.6382 18.7242 22.5595 20.8029 19.9954 20.8029C17.4312 20.8029 15.3525 18.7242 15.3525 16.16ZM19.9954 24.651C15.916 24.651 12.9379 25.4998 10.8217 26.929C8.68692 28.3707 7.54315 30.3265 7.00749 32.3062C6.67346 33.5407 7.06783 34.692 7.8203 35.491C8.55519 36.2714 9.6327 36.7293 10.7676 36.7189C15.192 36.6782 19.5881 36.7021 24.0054 36.7261H24.0057H24.0059H24.0069C25.7593 36.7356 27.515 36.7452 29.2771 36.7507C30.4037 36.7542 31.4682 36.2934 32.1931 35.515C32.9344 34.719 33.3209 33.5784 32.997 32.3574C32.4696 30.3695 31.3327 28.4005 29.1965 26.9476C27.0787 25.5072 24.0924 24.651 19.9954 24.651ZM9.42071 32.9592C9.82447 31.467 10.657 30.0569 12.2209 29.0008C13.8033 27.9321 16.2461 27.151 19.9954 27.151C23.761 27.151 26.2086 27.9389 27.7905 29.0148C29.354 30.0781 30.1824 31.4975 30.5806 32.9984C30.6613 33.3026 30.5828 33.5758 30.3636 33.8112C30.128 34.0642 29.7364 34.2521 29.285 34.2507C27.5355 34.2452 25.7872 34.2357 24.0389 34.2262H24.0389H24.0388H24.0388C19.6139 34.2022 15.189 34.1781 10.7446 34.219C10.2836 34.2232 9.88164 34.0334 9.64029 33.7771C9.41653 33.5395 9.33788 33.2653 9.42071 32.9592ZM20.102 10.1351C20.891 10.1351 21.5305 9.49552 21.5305 8.70654C21.5305 7.91756 20.891 7.27797 20.102 7.27797C19.313 7.27797 18.6734 7.91756 18.6734 8.70654C18.6734 9.49552 19.313 10.1351 20.102 10.1351Z"
            fill= "#031F30"
        />
    </svg>
);

export default GuardIcon;
