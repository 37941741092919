import { createContext, useContext } from 'react';

type NotificationsContextType = (
    content: { title?: string; description?: string },
    duration?: number,
    maxCount?: number
) => void;

export const NotificationsContex = createContext<NotificationsContextType>(
    () => {}
);

const useNotifications = () => useContext(NotificationsContex);

export default useNotifications;
