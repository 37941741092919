import React from "react";

import { InsightsCard, HeaderCard, CostSection } from "../../styled";
import EmptyInsightsDescription from "./EmptyInsightsDescription";

import { Text } from "../../../utilities/typography";
import { Colors } from "../../../utilities/common-styles";
interface ICostCardProps {
  placeInsight;
  t: any;
  url: string;
}

const CostCard = ({ placeInsight, t, url }: ICostCardProps) => {
  const { value, isEmpty, displayName, description } = placeInsight;
  const getValue = () => {
    if (isEmpty) {
      return t("common.insights.noData");
    }
    const numberValue = Number(value);
    return numberValue.toLocaleString("fi-FI");
  };

  return (
    <InsightsCard margin="0 0 0 36px">
      <HeaderCard data-cy="cost-card-header">{displayName}</HeaderCard>
      <CostSection>
        {isEmpty ? (
          <Text size="24px" mobileFontSize="20px" margin="0 8px 16px 0" color={Colors.redDarkMode} lineHeight="36px" data-cy="cost-card-value">
            {getValue()}
          </Text>
        ) : (
          <Text size="48px" mobileFontSize="32px" margin="0 4px 16px 0" color="white" lineHeight="72px" data-cy="cost-card-value">
            {getValue()}
          </Text>
        )}

        <Text size="12px" mobileFontSize="11px" margin="0 0 16px 0" color={Colors.borderDarkMode} lineHeight="18px" data-cy="cost-card-currency">
          EUR
        </Text>
      </CostSection>

      {isEmpty ? (
        <EmptyInsightsDescription t={t} url={url} data-cy="cost-card-description" />
      ) : (
        <Text size="16px" mobileFontSize="14px" margin="15px 0 0 0" color="white" lineHeight="24px" data-cy="cost-card-description">
          {description}
        </Text>
      )}
    </InsightsCard>
  );
};

export default CostCard;
