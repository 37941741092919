import React from 'react';
import { Text } from '../../utilities/typography';

import GuardIcon from './../icons/GuardIcon';
import ObjectIcon from './../icons/ObjectIcon';
import ReportIcon from './../icons/ReportIcon';
import DownloadIcon from './../icons/DownloadIcon';
import { DescriptionWrapper, DataWrapper } from '../BaldrView/styled';
import SectionHeader from './../Common/SectionHeader';

interface IDataExplenationProps {
    title: string;
    description: string;
    data: {
        title: string;
        content: string;
        description: string;
    }[];
}

const icons = [
    { key: 0, icon: <GuardIcon /> },
    { key: 1, icon: <ObjectIcon /> },
    { key: 2, icon: <ReportIcon /> },
    { key: 3, icon: <DownloadIcon /> }
];

const DataExplenation = ({
    title,
    data,
    description
}: IDataExplenationProps) => (
    <section data-cy= "data-explenation">
        <SectionHeader title={title} description={description} />
        <DataWrapper>
            {((Array.isArray(data) && data) || []).map(
                ({ title, content, description }, index) => (
                    <DescriptionWrapper
                        key={`${title}${index}`}
                        height= "168px"
                        width= "24%"
                        padding= "18px"
                        margin={index === 0 ? '8px 8px 8px 0' : '8px'}
                    >
                        {icons[index].icon}
                        <Text
                            size= "24px"
                            mobileFontSize= "1.7rem"
                            bold
                            margin= "2rem 0px 1rem"
                        >
                            {title}
                        </Text>
                        <Text size= "48px" mobileFontSize= "2.5rem">
                            {content}
                        </Text>
                        <Text bold size= "14px">
                            {description}
                        </Text>
                    </DescriptionWrapper>
                )
            )}
        </DataWrapper>
    </section>
);

export default DataExplenation;
