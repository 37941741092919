import React from 'react';

const Close = () => (
    <svg
        width= "16"
        height= "16"
        viewBox= "0 0 16 16"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M13.9888 0.391477C14.4563 -0.0806275 15.1693 -0.133236 15.5732 0.27457C15.977 0.682375 15.9249 1.4023 15.4574 1.87441L9.46847 7.92168L15.6125 14.1256C16.0801 14.5977 16.1322 15.3176 15.7283 15.7254C15.3244 16.1332 14.6115 16.0806 14.1439 15.6085L7.99985 9.40461L1.85595 15.6084C1.3884 16.0805 0.675425 16.1331 0.271554 15.7253C-0.132317 15.3175 -0.0802157 14.5975 0.387334 14.1254L6.53123 7.92168L0.542469 1.87457C0.0749199 1.40246 0.0228191 0.682534 0.42669 0.274728C0.83056 -0.133078 1.54354 -0.080469 2.01109 0.391636L7.99985 6.43875L13.9888 0.391477Z"
            fill= "#031F30"
        />
    </svg>
);

export default Close;
