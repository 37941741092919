import InfoIcon from "../../icons/InfoIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconWrapper, Notification } from "./styled";
import SecuritasEmail from "../../Common/SecuritasEmail";

const SegmentNotification = () => {
  const { t } = useTranslation();

  return (
    <Notification>
      <IconWrapper>
        <InfoIcon />
      </IconWrapper>
      <div>
        {t("segment.segment_question.notification")}
        <SecuritasEmail />
      </div>
    </Notification>
  );
};

export default SegmentNotification;
