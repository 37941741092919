import gql from 'graphql-tag';

export const SET_OBJECT_PLACE_PROPERTIES = gql`
    mutation SET_OBJECT_PLACE_PROPERTIES(
        $updatedProperties: [PlacePropertyInput]
        $objectNo: String!
        $placeNamespace: String
        $placeType: String
        $accessVerb: String
        $countryCode: String
    ) {
        setPlaceProperties(
            updatedProperties: $updatedProperties
            objectNo: $objectNo
            placeNamespace: $placeNamespace
            placeType: $placeType
            accessVerb: $accessVerb
            countryCode: $countryCode
        ) {
            name
            displayName
            description
            locked
            value
            valueVersion
            answerApproach
            updatedBy
            dependenciesOk
            updatedAt
            valueOptions {
                displayName
                value
            }
        }
    }
`;

export const GET_ALL_PLACES_PROPERTIES = gql`
    query GET_ALL_PLACES_PROPERTIES(
        $objectNo: String!
        $country: String!
        $accessVerb: String!
        $placeNamespace: String
        $placeType: String
    ) {
        object: objectV2(
            objectNo: $objectNo
            country: $country
            accessVerb: $accessVerb
        ) {
            allPlaceProperties(
                placeNamespace: $placeNamespace
                placeType: $placeType
            ) {
                name
                displayName
                description
                locked
                value
                valueVersion
                answerApproach
                updatedBy
                updatedAt
                dependencies {
                    value
                    propertyName
                }
                theme
                orderIndex
                dependenciesOk
                lockValueOnUpdate
                valueOptions {
                    displayName
                    value
                }
            }
        }
    }
`;

export const GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES = gql`
    query GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES(
        $placeNamespace: String
        $placeType: String
        $latitude: Float!
        $longitude: Float!
        $country: String!
        $nonObjectId: String
    ) {
        nonObject(
            latitude: $latitude
            longitude: $longitude
            country: $country
            nonObjectId: $nonObjectId
        ) {
            allPlaceProperties(
                placeNamespace: $placeNamespace
                placeType: $placeType
            ) {
                name
                displayName
                description
                locked
                value
                valueVersion
                answerApproach
                updatedBy
                updatedAt
                dependencies {
                    value
                    propertyName
                }
                theme
                orderIndex
                dependenciesOk
                lockValueOnUpdate
                valueOptions {
                    displayName
                    value
                }
            }
        }
    }
`;

export const UNLOCK = gql`
    mutation UNCLOCK(
        $propertyName: String
        $objectNo: String!
        $placeNamespace: String
        $placeType: String
    ) {
        unlock(
            propertyName: $propertyName
            objectNo: $objectNo
            placeNamespace: $placeNamespace
            placeType: $placeType
        )
    }
`;

export const PLACE_PROPERTIES = gql`
    query PLACE_PROPERTIES(
        $custObjNo: String!
        $country: String!
        $propertyNames: [String]!
        $placeNamespace: String
        $placeType: String
        $accessVerb: String
    ) {
        object: objectV2(
            objectNo: $custObjNo
            country: $country
            accessVerb: $accessVerb
        ) {
            placeProperties(
                propertyNames: $propertyNames
                placeNamespace: $placeNamespace
                placeType: $placeType
            ) {
                name
                value
                locked
            }
        }
    }
`;
