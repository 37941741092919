import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import MapRiskLevel from "./MapRiskLevel";
import { StyledItem } from "./styled";
import { TopRiskUserSelectionContext } from "../Common";
import { useTranslation } from "react-i18next";

const { useActions } = TopRiskUserSelectionContext;
interface ItemProps {
  text: string;
  index: number;
  risk: number;
}

const ItemRow: React.FC<ItemProps> = ({ text, index, risk }) => {
  const { topRisk, setTopRisk } = useActions();
  const { t } = useTranslation();

  return (
    <Draggable draggableId={text} index={index}>
      {(provided) => (
        <StyledItem
          data-cy="selected-top-risk"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => setTopRisk(text.toLowerCase())}
          isSelected={topRisk === text.toLowerCase()}
          isLongText={t(`common.risk.${text.toLowerCase()}`).length > 10}
        >
          <Text data-cy="selected-top-risk-category" size="16px" mobileFontSize="12px" bold lineHeight="24px" color="white">
            {t(`common.risk.${text.toLowerCase()}`)}
          </Text>
          <Text data-cy="selected-top-risk-level" size="12px" mobileFontSize="11px" bold uppercase lineHeight="18px" color="white">
            {risk > 3 ? t("reportObject.topRisk.highRisk") : t("reportObject.topRisk.lowRisk")}
          </Text>
          <MapRiskLevel risk={risk} emptyRiskColor={Colors.ghostWhite}></MapRiskLevel>
        </StyledItem>
      )}
    </Draggable>
  );
};

export default ItemRow;
