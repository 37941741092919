import React, { useRef, useState } from "react";
import styled from "styled-components";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import TooltipArrow from "./TooltipArrow";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 8px;
  background: #011523;
  border-radius: 16px;
`;

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
`;
const TooltipContainer = styled.div`
  margin-top: 16px;
  svg {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
`;

const CloseIcon = styled.div`
  min-width: 27px;
  svg {
    cursor: pointer;
  }
`;
const Nearby = ({ t }) => {
  const [open, isOpen] = useState(false);

  const pathRef = useRef(null);

  const yPosition = [30, t(`faq.nearbyTooltip1`).length > 176 ? 118 : 125, t(`faq.nearbyTooltip2`).length > 206 ? 300 : 310];
  const [index, setIndex] = useState(0);

  const closeTooltip = () => {
    isOpen(false);
    setIndex(0);
  };

  useOnClickOutside(pathRef, () => {
    closeTooltip();
  });

  const setTooltip = (currIndex) => {
    setIndex(currIndex);
    isOpen(true);
  };

  return (
    <TooltipContainer>
      <svg width="982" height="641" viewBox="0 0 982 641" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M973.344 0H8.65579C3.87534 0 0 3.67319 0 8.20429V632.796C0 637.327 3.87534 641 8.65579 641H973.344C978.125 641 982 637.327 982 632.796V8.20429C982 3.67319 978.125 0 973.344 0Z"
          fill="#0E283D"
        />
        <g opacity="0.4">
          <mask id="mask0_3298_91120" maskUnits="userSpaceOnUse" x="16" y="16" width="950" height="609">
            <path
              d="M959.958 16H22.0428C18.8149 16 16.1982 18.5859 16.1982 21.7758V619.224C16.1982 622.414 18.8149 625 22.0428 625H959.958C963.186 625 965.802 622.414 965.802 619.224V21.7758C965.802 18.5859 963.186 16 959.958 16Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_3298_91120)">
            <g opacity="0.6">
              <path d="M785.412 -17.3789L-355.32 736.51" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M174.734 758.521L1291.2 23.3125" stroke="#C2B4FC" strokeWidth="20" />
              <path
                d="M-142.239 -3.47266L62.034 200.652L18.5773 232.205C4.36761 242.519 4.68598 258.327 19.3095 268.39L549.641 633.463C565.199 644.176 591.092 644.365 607.021 633.893L1465.53 69.2128"
                stroke="#C2B4FC"
                strokeWidth="20"
              />
              <path d="M450.716 203.816L735.174 389.461" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M505.284 167.746L916.282 430.489" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M642.329 77.1836L846.687 209.284L928.486 262.168" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M1068.95 169.668L735.173 -42.9062" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M1513.09 221.535L1115.1 499.666L1141.04 566.681L773.472 678.508L661.651 597.971" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M577.33 213.812L710.778 121.43" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M846.686 209.28L981.916 114.246" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M968.578 396.09L1115.1 499.667" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M310.251 296.641L716.325 562.004" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M651.102 334.598L513.293 429.328" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M387.265 346.957L254.772 430.492" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M85.8281 444.957L367.57 631.535" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M101.194 324.766L-101.731 462.4" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M76.0107 307.441L289.601 224.879L410.177 230.605" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M351.97 225.425L596.856 -21.332" stroke="#C2B4FC" strokeWidth="20" />
              <path
                d="M290.767 227.282C265.404 219.336 161.713 186.872 138.494 179.597C125.494 175.75 109.862 176.893 98.8465 185.006C94.8245 188.151 72.9635 205.447 68.517 208.959L55.5596 192.354C60.4199 188.769 82.6205 172.511 86.9503 169.345C94.5592 163.853 104.015 160.75 113.088 159.597C122.077 158.433 131.182 159.062 139.926 161.4C145.519 162.983 171.306 172.375 177.27 174.387L294.683 215.793L290.767 227.272V227.282Z"
                fill="#C2B4FC"
              />
              <path d="M-374.687 546.289L-302.481 585.724L-214.432 546.289L148.323 772.459" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M-71.7969 631.538L269.214 566.41" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M177.377 183.158L450.714 6.96875" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M811.113 499.664L981.916 607.759L958.367 772.46L773.472 678.506" stroke="#C2B4FC" strokeWidth="20" />
              <path d="M123.405 -79.6797L321.025 90.5661" stroke="#C2B4FC" strokeWidth="20" />
            </g>
          </g>
        </g>
        <ellipse opacity="0.4" cx="491" cy="334" rx="274.353" ry="271" fill="#9977FE" />
        <ellipse opacity="0.4" cx="491" cy="334" rx="181.214" ry="179" fill="#9977FE" />
        <ellipse opacity="0.4" cx="490.999" cy="334" rx="102.249" ry="101" fill="#9977FE" />
        <ellipse cx="491" cy="110" rx="8.09897" ry="8" fill="#F7F7F7" />
        <path
          d="M491 126C499.799 126 507.099 118.929 507.099 110C507.099 101.071 499.799 94 491 94C482.202 94 474.901 101.071 474.901 110C474.901 118.929 482.202 126 491 126Z"
          stroke="#F7F7F7"
          strokeOpacity="0.24"
          strokeWidth="16"
          pointerEvents="visible"
          cursor="pointer"
          ref={pathRef}
          onMouseEnter={() => setTooltip(0)}
        />
        <ellipse cx="491" cy="197" rx="8.09897" ry="8" fill="#F7F7F7" />
        <path
          d="M491 213C499.799 213 507.099 205.929 507.099 197C507.099 188.071 499.799 181 491 181C482.202 181 474.901 188.071 474.901 197C474.901 205.929 482.202 213 491 213Z"
          stroke="#F7F7F7"
          strokeOpacity="0.24"
          strokeWidth="16"
          pointerEvents="visible"
          cursor="pointer"
          ref={pathRef}
          onMouseEnter={() => setTooltip(1)}
        />
        <ellipse cx="491" cy="388" rx="8.09897" ry="8" fill="#F7F7F7" />
        <path
          d="M491 404C499.799 404 507.099 396.929 507.099 388C507.099 379.071 499.799 372 491 372C482.202 372 474.901 379.071 474.901 388C474.901 396.929 482.202 404 491 404Z"
          stroke="#F7F7F7"
          strokeOpacity="0.24"
          strokeWidth="16"
        />
        <path
          d="M514.297 388C514.297 400.691 503.878 411 491 411C478.122 411 467.703 400.691 467.703 388C467.703 375.309 478.122 365 491 365C503.878 365 514.297 375.309 514.297 388Z"
          stroke="#6C47CF"
          strokeWidth="2"
          ref={pathRef}
          pointerEvents="visible"
          cursor="pointer"
          onMouseEnter={() => setTooltip(2)}
        />
        {open && (
          <foreignObject width="400px" height="100%" x={520} y={yPosition[index]}>
            <TooltipContent>
              <div>
                <TooltipArrow />
              </div>
              <Content>
                <div>{t(`faq.nearbyTooltip${index}`)}</div>

                <CloseIcon onClick={closeTooltip}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.04785 7.00547L13.2291 1.82422C13.4729 1.58047 13.4729 1.18672 13.2291 0.942969C12.9854 0.699219 12.5916 0.699219 12.3479 0.942969L7.1666 6.12422L1.9791 0.936719C1.73535 0.692969 1.3416 0.692969 1.09785 0.936719C0.854102 1.18047 0.854102 1.57422 1.09785 1.81797L6.2791 6.99922L1.09785 12.1867C0.854102 12.4305 0.854102 12.8242 1.09785 13.068C1.22285 13.1867 1.3791 13.2492 1.5416 13.2492C1.7041 13.2492 1.86035 13.1867 1.9791 13.068L7.16035 7.88672L12.3416 13.068C12.4729 13.1867 12.6291 13.2492 12.7916 13.2492C12.9541 13.2492 13.1104 13.1867 13.2291 13.068C13.4729 12.8242 13.4729 12.4305 13.2291 12.1867L8.04785 7.00547Z"
                      fill="#F7F7F7"
                    />
                  </svg>
                </CloseIcon>
              </Content>
            </TooltipContent>
          </foreignObject>
        )}

        <path
          d="M490.638 293C482.147 293.013 474.007 296.356 468.005 302.285C462.002 308.214 458.617 316.255 458.604 324.642C458.604 332.746 461.203 340.639 466.017 347.203L488.26 373.865C488.877 374.539 489.722 374.956 490.631 375.04C491.092 375.065 491.56 374.969 491.976 374.764C492.392 374.558 492.749 374.25 493.009 373.872L515.252 346.921C520.072 340.363 522.664 332.464 522.664 324.359C522.586 316.017 519.176 308.048 513.18 302.176C507.184 296.305 499.076 293.013 490.631 293.013L490.638 293ZM490.638 336.949C488.292 336.949 485.999 336.263 484.044 334.973C482.095 333.683 480.575 331.854 479.672 329.711C478.775 327.568 478.542 325.213 478.996 322.941C479.451 320.67 480.581 318.578 482.244 316.941C483.901 315.305 486.019 314.189 488.318 313.733C490.618 313.284 493.002 313.515 495.172 314.4C497.342 315.286 499.193 316.787 500.499 318.719C501.805 320.644 502.5 322.909 502.5 325.232C502.5 328.338 501.252 331.322 499.024 333.516C496.796 335.717 493.782 336.949 490.638 336.949Z"
          fill="#F6F6F6"
        />

        <defs>
          <filter id="filter0_d_3298_91120" x="518.48" y="291" width="429.52" height="200" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3298_91120" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3298_91120" result="shape" />
          </filter>
        </defs>
      </svg>
    </TooltipContainer>
  );
};

export default Nearby;
