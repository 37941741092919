import React, { useEffect } from 'react';
import { InsightsWrapper, InsightsContainer } from '../styled';
import { getOptionalQuestionsPath } from '../../utilities/urlUtils';
import { useQuery } from '@apollo/client';
import FrequencyChartCard from './Cards/FrequencyChartCard';
import CostCard from './Cards/CostCard';

import { NON_OBJECT_INSIGHTS } from '../../queries/nonObjects';
import { getGraphData, getCardData } from '../../utilities/insightsUtils';
import InsightsHeader from './InsightsHeader';
import useQuestionnaireUpdate from '../../hooks/useQuestionnaireUpdate';

const NonObjectInsights = ({ t, latitude, longitude, objectProperties }) => {
    const { allQuestions } = useQuestionnaireUpdate(objectProperties);
    const objectQuestionsProperties = allQuestions.map(q => {
        return { name: q.name, value: q.value };
    });

    const variables = {
        objectProperties: objectQuestionsProperties,
        latitude,
        longitude,
        country: objectProperties?.country
    };

    const { data, loading, refetch, error } = useQuery(NON_OBJECT_INSIGHTS, {
        variables
    });

    const {
        loading: loadingQuestions,
        error: errorLoadingQuestions
    } = useQuestionnaireUpdate(objectProperties);

    useEffect(() => {
        if (!loadingQuestions && !errorLoadingQuestions) {
            refetch(variables);
        }
    }, [loadingQuestions]);

    const url = getOptionalQuestionsPath(
        objectProperties?.country,
        objectProperties?.objectId,
        objectProperties?.placeType
    );

    const constructionTheftFrequencyInsights = error
        ? null
        : data?.nonObject?.NonObjectPlaceInsights.find(
              placeInsight =>
                  placeInsight.name ===
                  'insights/CONSTRUCTION_FREQUENCY_OF_THEFT'
          );
    const constructionAverageCostInsights = error
        ? null
        : data?.nonObject?.NonObjectPlaceInsights.find(
              placeInsight =>
                  placeInsight.name ===
                  'insights/CONSTRUCTION_AVERAGE_COST_OF_THEFT'
          );
    return (
        <InsightsContainer data-cy= "insights-container">
            <InsightsHeader t={t} url={url} data-cy= "insights-section-header" />
            {!loading && (
                <InsightsWrapper data-cy= "insights-section">
                    <FrequencyChartCard
                        t={t}
                        placeInsight={getGraphData(
                            constructionTheftFrequencyInsights,
                            t
                        )}
                        url={url}
                        data-cy= "frequency-chart-card"
                    />
                    <CostCard
                        t={t}
                        placeInsight={getCardData(
                            constructionAverageCostInsights,
                            t
                        )}
                        url={url}
                        data-cy= "cost-card"
                    />
                </InsightsWrapper>
            )}
        </InsightsContainer>
    );
};

export default NonObjectInsights;
