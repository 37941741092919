import TextAreaIcon from '../../icons/TextAreaIcon';
import React from 'react';
import {
    InputWrapper,
    InputLabel,
    StyledInput,
    TextAreaIconWrapper,
    InputContent
} from './styled';

type Props = {
    placeholder?: string;
    value?: string;
    maxLength?: number;
    onChange?: (event) => void;
};

const TextareaWrapper = ({
    placeholder,
    value,
    onChange,
    maxLength
}: Props) => {
    const empty = !value;

    return (
        <InputWrapper data-cy= "input" className= "input" text={value}>
            <InputLabel
                data-cy= "input-label"
                className= "input-label"
                hidden={empty}
            >
                {placeholder}
            </InputLabel>
            <InputContent>
                <StyledInput
                    data-cy= "input-text"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                />
            </InputContent>
            <TextAreaIconWrapper>
                <TextAreaIcon />
            </TextAreaIconWrapper>
        </InputWrapper>
    );
};

TextareaWrapper.defaultProps = {
    placeholder: '',
    value: '',
    withoutLabel: false,
    maxLength: 10000
};

export default TextareaWrapper;
