import React from 'react';

const SearchIcon = () => (
    <svg
        width= "16"
        height= "17"
        viewBox= "0 0 16 17"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <circle cx= "7.5" cy= "7.5" r= "6.75" stroke= "#626366" strokeWidth= "1.5" />
        <rect
            x= "11"
            y= "13.4141"
            width= "2"
            height= "5"
            rx= "1"
            transform= "rotate(-45 11 13.4141)"
            fill= "#626366"
        />
    </svg>
);

export default SearchIcon;
