import React from "react";
import { GroupWrapper, OptionGroup, NotSelectedOption, SelectedOption, Selected, SegmentName } from "./styled";

type Option = { name: string; value: string };
interface SelectListProps {
  options: Option[];
  selected?: string;
  onSelect: (value: string) => void;
  name?: string;
}

const SelectList = ({ options, selected, onSelect }: SelectListProps) => {
  return (
    options &&
    !!options.length && (
      <GroupWrapper data-cy="select-list">
        {options.map((o, i) => {
          return (
            o && (
              <OptionGroup
                data-cy={`select-value-${o.value.toLowerCase()}`}
                data-testid={`select-option${i}`}
                key={"option" + o + i}
                onClick={() => {
                  onSelect(o.value);
                }}
              >
                <SegmentName data-cy="select-namevalue">{o.name}</SegmentName>
                {o.value === selected ? (
                  <SelectedOption>
                    <Selected />
                  </SelectedOption>
                ) : (
                  <NotSelectedOption />
                )}
              </OptionGroup>
            )
          );
        })}
      </GroupWrapper>
    )
  );
};

export default SelectList;
