import React from 'react';
import { ProgressBar, Header } from './../../Common';
import File from '../../icons/File';
import { ContentWrapper } from './styled';
import { useProgress } from './../../../hooks';
import { PageWrapper } from './../../../utilities/wrappers';

type Props = {
    title: string;
    description: string;
    loadingCallback: () => void;
};

const LoadingView = ({ title, description, loadingCallback }: Props) => {
    const [progressValue] = useProgress(loadingCallback);

    return (
        <PageWrapper data-cy= "essential-order-progress" color= "white">
            <Header title={title} description={description} />
            <ContentWrapper>
                <ProgressBar value={progressValue} renderIndicator={<File />} />
            </ContentWrapper>
        </PageWrapper>
    );
};

export default LoadingView;
