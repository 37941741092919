import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import ArrowPagination from './icons/ArrowPagination';
import { PaginationWrapper } from './SelectName/styled';

const pageRangeDisplayedDefault = 4;
const marginPagesDisplayedDefault = 1;
interface PaginationBarProps {
    pageCount: number;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
    onPageChange({ selected }: { selected: number }): void;
    page: number;
    isLightMode?: boolean;
}

const PaginationBar: FC<PaginationBarProps> = props => {
    const {
        pageCount,
        pageRangeDisplayed,
        marginPagesDisplayed,
        onPageChange,
        page,
        isLightMode
    } = props;

    const isFirstPage = page === 0;
    const isLastPage = page + 1 === pageCount;

    return (
        <PaginationWrapper
            data-cy= "site-pagination"
            page={page}
            isLightMode={isLightMode}
        >
            <ReactPaginate
                initialPage={-1}
                pageCount={pageCount}
                pageRangeDisplayed={
                    pageRangeDisplayed || pageRangeDisplayedDefault
                }
                marginPagesDisplayed={
                    marginPagesDisplayed || marginPagesDisplayedDefault
                }
                breakLabel= "..."
                onPageChange={onPageChange}
                previousLabel={isFirstPage ? '' : <ArrowPagination />}
                nextLabel={isLastPage ? '' : <ArrowPagination flip={true} />}
            />
        </PaginationWrapper>
    );
};

export default PaginationBar;
