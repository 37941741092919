import React, { createContext, useMemo, ReactNode, useState } from "react";
import Geocoding, { GeocodeRequest } from "@mapbox/mapbox-sdk/services/geocoding";
import { Location } from "./types";

type LocationContextType = {
  selectedLocation?: Location;
  setLocation: (location: Location | undefined) => void;
  search: (query: string, limit?: number) => Promise<Location[]>;
  getLocationDetails: (query: [number, number]) => Promise<Location>;
};

export const LocationContext = createContext<LocationContextType | undefined>(undefined);
const getLocation = (feature) => {
  if (!feature) {
    return null;
  }
  const countryContext = feature.context && feature.context.length > 1 && feature.context.find((c) => c.id.startsWith("country"));

  if (!(countryContext && countryContext.hasOwnProperty("text"))) {
    return null;
  }
  const cityContext = feature.context && feature.context.length > 1 && feature.context[1];
  const city = cityContext && cityContext.hasOwnProperty("text") ? feature.context[1].text : "";

  const country = feature.context.find((c) => c.id.startsWith("country"))?.short_code?.toUpperCase();

  const streetNo = feature.address ? " " + feature.address : "";

  return {
    address: `${feature.text || ""}${streetNo}, ${city}`,
    country,
    center: feature.center,
    id: feature.id,
  };
};

const LocationContextProvider = ({ children }: { children: ReactNode }) => {
  const [selectedLocation, setLocation] = useState<Location | undefined>(undefined);
  const geocodeClient = useMemo(() => Geocoding({ accessToken: process.env.REACT_APP_MSF_MAPBOX_TOKEN }), [process.env.REACT_APP_MSF_MAPBOX_TOKEN]);

  const getLocationDetails = (query: [number, number]) =>
    geocodeClient
      .reverseGeocode({ query, mode: "mapbox.places" })
      .send()
      .then((response) => response.body)
      .then((locations) => {
        if (locations?.features && locations?.features.length > 0 && !!locations?.features[0]) {
          const location = getLocation(locations.features[0]);

          location.center = query;
          location.id = `${location.id}${query[0]}${query[1]}`;
          return location;
        }

        console.error(`Could not find features for request: ${query}`);
      });

  const coordinatesGeocoder = async function (query): Promise<Location[]> {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
    if (!matches) {
      return null;
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(await getLocationDetails([coord1, coord2]));
    }

    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(await getLocationDetails([coord1, coord2]));
    }

    if (geocodes.length === 0) {
      // could be either lng, lat or lat, lng
      geocodes.push(await getLocationDetails([coord1, coord2]));
      geocodes.push(await getLocationDetails([coord2, coord1]));
    }

    return geocodes.map(getLocation).filter((x) => !!x);
  };

  const search = async (query: string, limit = 5): Promise<Location[]> => {
    const latLongResult = await coordinatesGeocoder(query);
    if (!!latLongResult) {
      return new Promise((resolve) => resolve(latLongResult));
    }

    return geocodeClient
      .forwardGeocode({
        query,
        limit,
      } as GeocodeRequest)
      .send()
      .then((response) => response.body)
      .then((locations) => locations.features.map(getLocation));
  };

  const value = useMemo(
    () => ({
      setLocation,
      selectedLocation,
      search,
      getLocationDetails,
    }),
    [selectedLocation?.id]
  );

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};

export default LocationContextProvider;
