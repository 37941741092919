import { createContext, useContext } from 'react';

type CategoryModalDescriptionContext = {
    setIsOpen: (isOpen: boolean) => void;
    isOpen?: boolean;
    category?: string;
    setCategory: (category: string) => void;
};

export const CategoryModalDescriptionContext = createContext<
    CategoryModalDescriptionContext
>({
    setIsOpen: () => {},
    setCategory: () => {}
});

export const useActions = () => useContext(CategoryModalDescriptionContext);
