import { useState, useCallback } from 'react';

const useToggle = (defaultValue?: boolean): [boolean, () => void] => {
    const [value, setValue] = useState<boolean>(defaultValue);

    const toggle = useCallback(() => setValue(v => !v), []);

    return [value, toggle];
};

export default useToggle;
