export const flexWrap = `
    display: flex;
    flex-wrap: wrap;
`;

export const flexColumn = `
    display: flex;
    flex-direction: column;
`;

export const flexColumnCenter = `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const flexColumnAlignCenter = `
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const flexColumnJustifyCenter = `
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const flexColumnJustifySpaceBetween = `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const flexColumnJustifyCenterAlignCenter = `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const flexRow = `
    display: flex;
    flex-direction: row;
`;
export const flexRowJustifyStart = `
    display: flex;
    flex-direction: row;
    justify-content: start;
`;

export const flexRowJustifyCenter = `
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const flexRowJustifySpaceBetween = `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const flexRowAlignCenter = `
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const flexRowAlignEnd = `
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

export const flexRowJustifyEnd = `
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const flexRowCenter = `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const flexRowAlignStart = `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;
