import React from "react";
import { Text } from "./../../utilities/typography";
import Database from "../icons/Database";
import MapLine from "../icons/MapLine";
import { Wrapper, LeftWrapper, RightWrapper } from "./styled";
import CardView from "./CardView";
import ContactSection from "./ContactSection";
import FooterLogo from "./FooterLogo";
import { ImageWrapper, RightContent, MySecuritasHeader } from "./styled";
import { HomeUrl } from "./../../constants/urls";

const segmentProblemDescription =
  "The selected business segment is not yet supported by Risk Prediction Essential. We constantly work to make more segments available. If you wish to choose a different segment, kindly contact your Securitas contact person, or reach out to us at ";
const locationProblemDescription = "The object is located in an area where data is missing to create reliable risk prediction for you";

interface IProps {
  segmentMissing?: boolean;
  locationMissing?: boolean;
}

const NoAccessView = ({ segmentMissing, locationMissing }: IProps) => {
  const goHome = () => {
    window.location.assign(HomeUrl);
  };

  return (
    <Wrapper>
      <LeftWrapper>
        <ImageWrapper></ImageWrapper>
      </LeftWrapper>
      <RightWrapper>
        <RightContent>
          <MySecuritasHeader onClick={goHome}>MySecuritas</MySecuritasHeader>
          <Text size="32px" bold margin="10px 0px">
            Ooops!
          </Text>
          <Text size="medium">We’re sorry but something went wrong. Please see the reasons below, and contact Securitas so that we can sort this out!</Text>
          <br />
          {segmentMissing && (
            <CardView icon={<Database />} title="Segment problem">
              <>
                {segmentProblemDescription}
                <a href="mailto:mysecuritas@securitas.com">mysecuritas@securitas.com</a>
              </>
            </CardView>
          )}
          {locationMissing && (
            <>
              <CardView icon={<MapLine />} title="Location problem">
                {locationProblemDescription}
              </CardView>
              <br />
            </>
          )}
          <ContactSection></ContactSection>
          <FooterLogo></FooterLogo>
        </RightContent>
      </RightWrapper>
    </Wrapper>
  );
};

export default NoAccessView;
