import styled, { css } from "styled-components";
import { Colors, Devices } from "../../../utilities/common-styles";
import { InputWrapper } from "../../Common/Input/styled";
import { SolidButton } from "../../../utilities/buttons";
import { FlexWrapper } from "../../../utilities/wrappers";

export const FloatingWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media ${Devices.mobileS} {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const FloatingContentWrapper = styled.div`
  max-width: 696px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${Devices.mobileL} {
    padding-right: 60px;
  }
`;

export const Button = styled(SolidButton)`
  margin: 8px 0;
  pointer-events: all;
  z-index: 999;

  :disabled {
    opacity: 1;
  }
`;

export const SearchWrapper = styled.div<{ inputBordered?: boolean }>`
  position: relative;
  border-radius: 4px;
  width: 100%;
  pointer-events: all;
  z-index: 999;
  cursor: default;
  border-radius: 24px;
  background-color: ${Colors.cardBackgroundSecondary};

  .input {
    border: none;
  }

  ${(props) =>
    props.inputBordered &&
    css`
      .input {
        border-bottom: 2px solid #021d2d;
        border-radius: 0px;
      }
    `}
`;

export const ResultWrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  .clear-icon {
    cursor: pointer;
  }
`;

export const ResultContentWrapper = styled(FlexWrapper)`
  flex-wrap: nowrap !important;

  svg {
    path {
      fill: white;
    }
  }
`;

export const Location = styled.label`
  font-size: 1.6rem;
  margin: 0 16px;
  color: white;
`;

export const Address = styled.label`
  font-size: 1.4rem;
  color: ${(props) => props.theme.black45};
`;

export const Ul = styled.ul`
  list-style-type: none;
  padding: 0 16px;
`;

export const Li = styled.li`
  padding: 8px 0;
  cursor: pointer;
  color: white;

  svg {
    path {
      fill: white;
    }
  }

  :hover {
    transform: scale(1.01);
    color: ${Colors.primaryPurple};

    svg {
      path {
        fill: ${Colors.primaryPurple};
      }
    }
  }

  svg {
    margin-right: 10px;
  }
`;
export const InputComponent = styled.div<{
  text?: string;
  haveResults?: boolean;
}>`
  ${InputWrapper} {
    border: 2px solid ${Colors.borderDarkMode};
    background: ${Colors.cardBackgroundSecondary};
    box-sizing: border-box;
    border-radius: ${(props) => (props.text && props.haveResults ? "24px 24px 0 0" : "24px")};

    input {
      background: ${Colors.cardBackgroundSecondary};
      color: ${(props) => (props.text ? Colors.text : Colors.greyLabel)};
    }
    label {
      background: ${Colors.borderDarkMode};
      border-radius: 11px;
      color: black;
      padding: 2px 8px;
    }
  }
`;
