import React, { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../utilities/typography";
import { FlexWrapper } from "../../../utilities/wrappers";
import { Wrapper, Description } from "./styled";

type Props = {
  title: string;
  description: string;
  loading?: boolean;
  children?: ReactNode;
};

const Header = ({ title, description, loading, children }: Props) => (
  <Wrapper>
    {loading ? (
      <>
        <div>
          <Skeleton height={38} width={250} style={{ backgroundColor: "black" }} />
        </div>
        <Skeleton height={22} width={100} style={{ backgroundColor: "black" }} />
      </>
    ) : (
      <FlexWrapper>
        <div>
          <Text bold size="36px" mobileFontSize="24px" margin="20px 0 0 0" color="white">
            {title}
          </Text>
          <Description data-cy="header-description">{description}</Description>
        </div>
        <div className="side">{children}</div>
      </FlexWrapper>
    )}
  </Wrapper>
);

export default Header;
