import React from 'react';

const TooltipInfoCloseIcon = () => (
    <svg
        width= "24"
        height= "24"
        viewBox= "0 0 24 24"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <circle cx= "12" cy= "12" r= "11.25" stroke= "#9977FE" strokeWidth= "1.5" />
        <path
            d= "M13.1047 11.9948L17.2497 16.1398C17.4447 16.3348 17.4447 16.6498 17.2497 16.8448C17.0547 17.0398 16.7397 17.0398 16.5447 16.8448L12.3997 12.6998L8.24969 16.8498C8.05469 17.0448 7.73969 17.0448 7.54469 16.8498C7.34969 16.6548 7.34969 16.3398 7.54469 16.1448L11.6897 11.9998L7.54469 7.84984C7.34969 7.65484 7.34969 7.33984 7.54469 7.14484C7.64469 7.04984 7.76969 6.99984 7.89969 6.99984C8.02969 6.99984 8.15469 7.04984 8.24969 7.14484L12.3947 11.2898L16.5397 7.14484C16.6447 7.04984 16.7697 6.99984 16.8997 6.99984C17.0297 6.99984 17.1547 7.04984 17.2497 7.14484C17.4447 7.33984 17.4447 7.65484 17.2497 7.84984L13.1047 11.9948Z"
            fill= "#9977FE"
        />
    </svg>
);

export default TooltipInfoCloseIcon;
