import React from 'react';

const Question10 = () => (
    <svg
        width= "33"
        height= "32"
        viewBox= "0 0 33 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M24.5 2.5H8.5C7.4 2.5 6.5 3.4 6.5 4.5V28.5C6.5 29.6 7.4 30.5 8.5 30.5H24.5C25.6 30.5 26.5 29.6 26.5 28.5V4.5C26.5 3.4 25.6 2.5 24.5 2.5ZM8.5 28.5V4.5H24.5V28.5H8.5Z"
            fill= "#0E283D"
        />
        <path
            d= "M13.5 22.5C13.5 21.9477 13.9477 21.5 14.5 21.5H18.5C19.0523 21.5 19.5 21.9477 19.5 22.5V29.5H13.5V22.5Z"
            stroke= "#0E283D"
            strokeWidth= "2"
        />
        <path
            d= "M10.5 7.5H14.5V12.5H10.5V7.5Z"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeMiterlimit= "10"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M18.5 7.5H22.5V12.5H18.5V7.5Z"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeMiterlimit= "10"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default Question10;
