import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedAnswers } from "../../Questions/OptionalQuestions/formatQuestionData";
import SecuritasEmail from "../SecuritasEmail";
import useQuestionnaireUpdate from "./../../../hooks/useQuestionnaireUpdate";
import { SolidButton } from "./../../../utilities/buttons";
import { Text } from "./../../../utilities/typography";
import { getOptionalQuestionsPath } from "./../../../utilities/urlUtils";
import { ObjectPropertiesContext } from "./../../Common";
import { MenuContent, QuestionsPanel } from "./styled";

const { useObjectProperties } = ObjectPropertiesContext;
interface Props {
  t: any;
  closeMenu: () => void;
}

export const QuestionsView = ({ t, closeMenu }: Props) => {
  const [questions, setQuestions] = useState([]);
  const { objectProperties } = useObjectProperties();
  const url = getOptionalQuestionsPath(objectProperties?.country, objectProperties?.objectId, objectProperties?.placeType);
  const navigate = useNavigate();

  const openOptionalQuestions = () => {
    navigate(url);
    closeMenu();
  };

  const { allQuestions, segmentQuestion, initialAnswers, isAnyQuestionAvailable, loading, error } = useQuestionnaireUpdate(objectProperties);

  useEffect(() => {
    if (!loading && !error) {
      if (isAnyQuestionAvailable) {
        setQuestions(getFormattedAnswers(initialAnswers, allQuestions, segmentQuestion, t));
      } else {
        setQuestions(getFormattedAnswers([], allQuestions, segmentQuestion, t));
      }
    }
  }, [loading, error]);

  return (
    <MenuContent>
      <Text size="medium" bold uppercase margin="0 0 2rem 0">
        {t("common.header.object-properties.button")}
      </Text>
      {questions.map((result) => (
        <QuestionsPanel key={result.key}>
          <Text bold size="medium" margin="0 0 1rem 0">
            {result.title}
          </Text>
          {result.readableAnswer ? (
            <Text size="medium">{result.readableAnswer} </Text>
          ) : (
            <Text size="medium" fontStyle="italic">
              {t("optionalQuestions.finishStep.noAnswer")}
            </Text>
          )}
        </QuestionsPanel>
      ))}
      {isAnyQuestionAvailable ? (
        <>
          <Text size="medium" margin="2rem 0 1rem 0">
            {t("common.header.object-properties.description")}
          </Text>
          <Text size="medium" margin="2rem 0 1rem 0">
            {t("common.header.object-properties.note")}
            <SecuritasEmail></SecuritasEmail>
          </Text>

          <SolidButton width={"100%"} onClick={openOptionalQuestions}>
            {t("common.header.object-properties.button")}
          </SolidButton>
        </>
      ) : (
        <Text size="medium" margin="2rem 0 1rem 0">
          {t("common.header.object-properties.note")}
          <SecuritasEmail></SecuritasEmail>
        </Text>
      )}
    </MenuContent>
  );
};
