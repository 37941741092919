import styled from "styled-components";
import { Devices } from "./common-styles";

const fontSize = {
  small: "1.2rem",
  medium: "1.4rem",
  large: "1.6rem",
};

const mobileFontSize = {
  small: "1.1rem",
  medium: "1.2rem",
  large: "1.4rem",
};

export const Text = styled.div<{
  size: "small" | "medium" | "large" | "x-large" | string;
  mobileFontSize?: string;
  mobileLineHeight?: string;
  bold?: boolean;
  margin?: string;
  textAlign?: string;
  uppercase?: boolean;
  color?: string;
  weight?: string;
  maxWidth?: string;
  fontStyle?: string;
  lineHeight?: string;
}>`
  font-size: ${(props) => fontSize[props.size] || props.size};
  font-weight: ${(props) => props.weight || (props.bold ? "bold" : "normal")};
  margin: ${(props) => props.margin || "auto"};
  text-align: ${(props) => props.textAlign || "unset"};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "normal")};
  color: ${(props) => props.color || "inherit"};
  max-width: ${(props) => props.maxWidth || ""};
  font-style: ${(props) => props.fontStyle || "normal"};
  line-height: ${(props) => props.lineHeight || ""};
  @media ${Devices.mobile} {
    font-size: ${(props) => mobileFontSize[props.size] || props.mobileFontSize || props.size};
    line-height: ${(props) => props.mobileLineHeight || ""};
  }
`;

export const InteractiveText = styled(Text)`
  color: ${(props) => props.theme.purple};
  cursor: pointer;

  a {
    color: ${(props) => props.theme.purple};
  }
`;

export const H2 = styled.h1<{
  margin?: string;
  size?: "small" | "medium" | "large" | "x-large" | string;
}>`
  margin: ${(props) => props.margin || 0};
  font-size: ${(props) => fontSize[props.size] || props.size};
  font-weight: bold;
  margin: 2rem 0 1rem 0;
  padding-bottom: 4px;
  color: white;

  @media ${Devices.mobile} {
    z-index: 999;
    font-size: 2rem;
    top: 0px;
  }
`;

export const Link = styled.a`
  font-weight: bold;
  font-size: 1.4rem;
  color: ${(props) => props.theme.purple};
`;

export default {
  Text,
  InteractiveText,
  H2,
  Link,
};
