import React from "react";

import Map from "./Map/Map";
import { useTranslation } from "react-i18next";
import CancelOrderButton from "../Order/buttons/CancelOrderButton";
import { PageWrapper } from "../../utilities/wrappers";
import { Header } from "../Common";
import { Container } from "../Order/styled";
import { useStore } from "react-context-hook";

const SelectLocationContainer = () => {
  const { t } = useTranslation();
  const [userData, _setUserData] = useStore("userData", null);

  return (
    <Container>
      <PageWrapper data-cy="esssential-order-location" color="white">
        <Header title={t("selectLocation.header.title")} description={t("selectLocation.header.description")}>
          <CancelOrderButton isInternalUser={userData?.isInternal} />
        </Header>
      </PageWrapper>
      <Map />
    </Container>
  );
};

export default SelectLocationContainer;
