import styled from 'styled-components';
import { SolidButton } from '../../utilities/buttons';
import { Colors, Devices } from '../../utilities/common-styles';
import { FlexWrapper } from '../../utilities/wrappers';

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContentWrapper = styled(FlexColumn)`
    flex: 0 1 100%;

    @media ${Devices.mobile} {
        flex: 1 1 auto;
    }

    @media ${Devices.mobileS} {
        > div:last-child {
            border-radius: 0;
        }
    }
`;

export const NotEnoughDataContainer = styled.div`
    margin: 8px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    background-color: ${Colors.primaryPurple};
    padding: 16px;
    border-radius: 4px;
`;

export const Wrapper = styled(FlexWrapper)`
    border-radius: 8px;
    padding: 0;
    max-width: ${props => props.theme.maxWidth};
    width: 100%;
    min-width: fit-content;
    background-color: ${Colors.cardBackgroundPrimary};
`;

export const TitleWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 24px 24px 0 24px;
    .desktop-title {
        margin: 0 0 0 8px;
    }

    @media ${Devices.mobileS} {
        margin: 0;
        padding: 8px 0;
        margin: 0 16px 16px 16px;

        .desktop-title {
            display: none;
        }
    }
`;

export const ButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: flex-end;
    width: 100%;
    gap: 24px;

    > div {
        cursor: pointer;
    }
`;

export const Title = styled(FlexColumn)`
    flex: 0 1;
    margin-right: 8px;

    > div {
        width: max-content;
    }
`;

export const SubTitle = styled(FlexColumn)`
    flex: 0 1;
    margin-top: 16px;

    > div {
        width: max-content;
    }
`;

export const HelpMeWrapper = styled.div`
    align-self: flex-end;
    padding-right: 24px;

    @media ${Devices.mobileS} {
        width: 100%;
        padding: 0 16px;
    }
`;

export const SolidButtonWrapper = styled(SolidButton)`
    display: flex;
    align-items: center;
    padding: 12px auto;
    height: 48px;
    width: 140px;
    justify-content: center;
    @media ${Devices.mobileS} {
        width: 100%;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconContainer = styled.div`
    padding-top: 3px;
    padding-right: 9px;
`;
