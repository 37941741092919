import { IQuestionnairePayload } from 'models/QuestionnairePayload';
import { createContext, useContext } from 'react';

type ObjectPropertiesContextType = {
    setObjectProperties: (object: IQuestionnairePayload) => void;
    objectProperties?: IQuestionnairePayload;
};

export const ObjectPropertiesContext = createContext<
    ObjectPropertiesContextType
>({
    setObjectProperties: () => {}
});

export const useObjectProperties = () => useContext(ObjectPropertiesContext);
