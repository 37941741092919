import React, { ReactNode, useState } from 'react';
import { CategoryModalDescriptionContext } from './CategoryModalDescriptionContext';

const CategoryModalDescriptionContextProvider = ({
    children
}: {
    children: ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [category, setCategory] = useState('');

    return (
        <CategoryModalDescriptionContext.Provider
            value={{
                setIsOpen,
                isOpen,
                category,
                setCategory
            }}
        >
            {children}
        </CategoryModalDescriptionContext.Provider>
    );
};

export default CategoryModalDescriptionContextProvider;
