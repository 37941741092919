import React from "react";
import { useNavigate } from "react-router-dom";
import { BackLink } from "../../../utilities/wrappers";
import { BackIcon } from "../../icons/QuestionnaireIcons";

const BackLinkComponent = ({ title }) => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/essential");
  };

  return (
    <BackLink data-cy="back-btn" onClick={handleBackButton} isEssentialReportUrl>
      <BackIcon></BackIcon>
      {title}
    </BackLink>
  );
};

export default BackLinkComponent;
