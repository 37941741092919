import { useQuery } from "@apollo/client";

import { transformTopRisks, transformWeeklyRisks } from "./../components/utils";

import { PlaceProperties } from "../utilities/placeCapabilitiesUtils";

import { BULLETIN_OBJECT_DETAILS, LIST_TOP_RISK, STATIC_RISK, WEEKLY_RISK } from "../queries/objects";
import { getPlaceNamespace, PlaceTypes } from "../utilities/gqlUtilities";
import { getSegment } from "../utilities/placePropertiesUtils";

const NA = "n/a";

const useBaldrViewData = ({ objectNumber, country }: { objectNumber: string; country: string }) => {
  const { data, loading, error } = useQuery(BULLETIN_OBJECT_DETAILS, {
    variables: {
      custObjNo: objectNumber,
      propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
      placeNamespace: getPlaceNamespace(country),
      placeType: PlaceTypes.ObjectPlaceType,
      accessVerb: "riskobject.view",
      country,
    },
  });

  const segment = getSegment(data?.object?.placeProperties);

  const { data: currentTopRisks } = useQuery(LIST_TOP_RISK, {
    skip: segment === "-/-",
    variables: {
      objectNumber,
      segment,
      placeType: PlaceTypes.ObjectPlaceType,
      objectCountry: country.toLocaleUpperCase(),
    },
  });
  const listOfCurrentSelectedTopRisks = currentTopRisks?.listTopRisks?.riskCategories?.map((risk) => risk.riskCategory.toLowerCase()) || [];

  const objectName = data?.object?.custObjName || NA;
  const objectAddress = data?.object ? `${data.object.address?.street}, ${data.object.address?.city}, ${data.object.country}` : NA;

  const objectPosition = data?.object?.position ?? {
    longitude: 0,
    latitude: 0,
  };

  const { data: staticRisk } = useQuery(STATIC_RISK, {
    variables: {
      objectNo: objectNumber,
      accessVerb: "riskobject.view",
      country,
    },
  });
  const { data: weeklyRiskData, loading: weeklyRiskDataLoading } = useQuery(WEEKLY_RISK, {
    variables: {
      objectNo: objectNumber,
      accessVerb: "riskobject.view",
      country,
    },
  });

  const weeklyRisks = transformWeeklyRisks(weeklyRiskData?.object?.hourlyStatistics);
  const topRisks = transformTopRisks(staticRisk?.object?.staticRisk);

  return {
    objectLoading: loading,
    listOfCurrentSelectedTopRisks,
    weeklyRiskDataLoading,
    topRisks,
    objectName: objectName,
    objectAddress,
    objectPosition,
    segment,
    weeklyRisks,
    errors: [error],
  };
};
export default useBaldrViewData;
