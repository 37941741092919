const EXTERNAL_ADMIN_ROLE = 'ExternalAdmin';
const EXTERNAL_VIEWER_ROLE = 'ExternalViewer';
export const transformGraphData = graphData => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getUTCFullYear();
    const data = [...graphData].sort((a, b) => {
        if (a.year !== b.year) {
            return a.year - b.year;
        } else {
            return a.month - b.month;
        }
    });

    const filterData = (elem, decreaseYear = 0) => {
        if (currentMonth <= 6) {
            return (
                (elem.year === currentYear - decreaseYear && elem.month <= 6) ||
                (elem.year === currentYear - 1 - decreaseYear && elem.month > 6)
            );
        } else {
            return elem.year === currentYear - decreaseYear;
        }
    };
    const thisYear = data
        .filter(elem => filterData(elem))
        .map((elem, i) => ({ i: i + 1, ...elem }));

    const previousYear = data
        .filter(elem => filterData(elem, 1))
        .map((elem, i) => ({ i: i + 1, ...elem }));

    const maxValue = Math.max(
        ...(thisYear.concat(previousYear) || []).map(
            elem => elem.totalEventsCount
        )
    );
    const max = Math.ceil(maxValue / 5) * 5 + 1 || 5;
    const isEnoughDataAvailable =
        thisYear?.filter(elem => elem.totalEventsCount > 0)?.length > 1;

    return { thisYear, previousYear, max, isEnoughDataAvailable };
};

export const compareNumbers = (a, b) => {
    if (a === 0 || b === 0) {
        return a > b ? `-${a - b}` : `+${b - a}`;
    }
    if (a === b) {
        return '0%';
    }
    if (a > b) {
        return `-${Number(((a - b) * 100) / a)}%`;
    }

    return `+${Number(((b - a) * 100) / a)}%`;
};

export const isMobileGuardingUser = roles => {
    return roles?.some(
        elem => elem === EXTERNAL_ADMIN_ROLE || elem === EXTERNAL_VIEWER_ROLE
    );
};
