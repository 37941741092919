import styled, { keyframes } from "styled-components";
import { Colors } from "../../../utilities/common-styles";
const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

export const InputWrapper = styled.div<{
  error?: boolean;
  open?: boolean;
}>`
  position: relative;
  height: 48px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border: 2px solid ${(props) => (props.error ? props.theme.red : Colors.darkBlue)};

  border-radius: 4px;
  border-bottom-left-radius: ${({ open }) => (open ? "0" : "4px")};
  border-bottom-right-radius: ${({ open }) => (open ? "0" : "4px")};
  padding: 0 16px;
`;

export const StyledInput = styled.input`
  width: 100%;
  font-size: 1.6rem;
  border: none;
  color: white;

  :focus {
    outline: none;
  }
`;

export const InputLabel = styled.label<{ hidden?: boolean }>`
  position: absolute;
  left: 16px;
  top: -11px;
  font-size: 1.3rem;
  background-color: ${(props) => props.theme.white};
  border-radius: 2px;
  padding: 0 8px;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

export const RightIconWrapper = styled.div`
  svg {
    margin-right: 16px;
  }
`;

export const ClearIconWrapper = styled.div<{ hidden?: boolean }>`
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  cursor: ${(props) => (props.hidden ? "default" : "pointer")};
  pointer-events: ${(props) => (props.hidden ? "none" : "all")};
  animation: ${(props) => (props.hidden ? fadeOut : fadeIn)} 0.3s linear;
  transition: visibility 0.3s linear;

  svg {
    margin-left: 8px;
  }
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.red};
  font-size: 1.3rem;
  padding-left: 16px;
  position: absolute;
  left: 0;
  top: 100%;
`;
