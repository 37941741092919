import styled, { css } from "styled-components";
import { Colors } from "./common-styles";

const actionStyle = css`
  &:hover {
    box-shadow: 0px -100px 0 inset rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    filter: grayscale();
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

interface ISolidBtnProps {
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  fontSize?: string;
  dangerous?: boolean;
}

const SolidButtonStyle = css<ISolidBtnProps>`
  font-size: ${(props) => props.fontSize || "1.4rem"};
  color: ${(props) => props.theme.white};
  border: none;
  border-radius: 32px;
  padding: ${(props) => props.padding || "16px 24px"};
  line-height: 1em;
  background-color: ${(props) => (props.dangerous ? props.theme.red : props.theme.purple)};
  font-weight: bold;
  font-style: normal;
  margin: ${(props) => props.margin || "16px 0"};
  cursor: pointer;
  box-shadow: 0 0 0 inset rgba(0, 0, 0, 0.3);
  ${actionStyle}

  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
`;

export const SolidButton = styled.button<ISolidBtnProps>`
  ${SolidButtonStyle}
`;

export const SolidButtonLink = styled.a<ISolidBtnProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${SolidButtonStyle}
  svg {
    margin-right: 1rem;
  }
`;

export const TextButton = styled.button<{
  dangerous?: boolean;
  padding?: string;
}>`
  display: flex;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: bold;
  padding: ${(props) => props.padding || "16px 24px"};
  background: inherit;
  border: none;
  color: ${(props) => (props.dangerous ? props.theme.red : props.theme.purple)};
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;

  &:active,
  &:focus {
    outline: dotted 1px ${(props) => (props.dangerous ? props.theme.red : props.theme.purple)};
  }
  svg {
    margin-right: 8px;

    path {
      fill: ${(props) => (props.dangerous ? props.theme.red : props.theme.purple)};
    }

    circle {
      stroke: ${(props) => (props.dangerous ? props.theme.red : props.theme.purple)};
    }
  }
`;

export const SendButton = styled(SolidButton)`
  border-radius: 25px;
  margin-bottom: 0px;
  background-color: ${Colors.purpleHover};
  &:hover {
    background-color: ${Colors.primaryPurple};
  }
  transition: none;
`;

export default {
  SolidButton,
  SolidButtonLink,
  TextButton,
  SendButton,
};
