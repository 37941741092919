import React from 'react';

const MarkerOutlined = ({ large }: { large?: boolean }) =>
    large ? (
        <svg
            width= "18"
            height= "22"
            viewBox= "0 0 18 22"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <path
                fillRule= "evenodd"
                clipRule= "evenodd"
                d= "M16 9C16 11.6961 14.2185 14.4585 12.0481 16.7495C11.0038 17.8518 9.95266 18.7577 9.1604 19.3886C9.10558 19.4322 9.05209 19.4745 9 19.5153C8.94791 19.4745 8.89442 19.4322 8.8396 19.3886C8.04734 18.7577 6.99622 17.8518 5.95191 16.7495C3.78147 14.4585 2 11.6961 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM18 9C18 16 9 22 9 22C9 22 0 16 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 4.25C6.92893 4.25 5.25 5.92893 5.25 8C5.25 10.0711 6.92893 11.75 9 11.75C11.0711 11.75 12.75 10.0711 12.75 8C12.75 5.92893 11.0711 4.25 9 4.25ZM6.75 8C6.75 6.75736 7.75736 5.75 9 5.75C10.2426 5.75 11.25 6.75736 11.25 8C11.25 9.24264 10.2426 10.25 9 10.25C7.75736 10.25 6.75 9.24264 6.75 8Z"
                fill= "#021D2D"
            />
        </svg>
    ) : (
        <svg
            width= "12"
            height= "16"
            viewBox= "0 0 12 16"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <path
                fillRule= "evenodd"
                clipRule= "evenodd"
                d= "M10.6667 6.66797C10.6667 8.46538 9.47902 10.307 8.03206 11.8343C7.33585 12.5692 6.63511 13.1731 6.10693 13.5937C6.07039 13.6228 6.03473 13.651 6 13.6782C5.96528 13.651 5.92961 13.6228 5.89307 13.5937C5.36489 13.1731 4.66415 12.5692 3.96794 11.8343C2.52098 10.307 1.33333 8.46538 1.33333 6.66797C1.33333 4.09064 3.42267 2.0013 6 2.0013C8.57733 2.0013 10.6667 4.09064 10.6667 6.66797ZM12 6.66797C12 11.3346 6 15.3346 6 15.3346C6 15.3346 0 11.3346 0 6.66797C0 3.35426 2.68629 0.667969 6 0.667969C9.31371 0.667969 12 3.35426 12 6.66797ZM6 3.5013C4.61929 3.5013 3.5 4.62059 3.5 6.0013C3.5 7.38201 4.61929 8.5013 6 8.5013C7.38071 8.5013 8.5 7.38201 8.5 6.0013C8.5 4.62059 7.38071 3.5013 6 3.5013ZM4.5 6.0013C4.5 5.17287 5.17157 4.5013 6 4.5013C6.82843 4.5013 7.5 5.17287 7.5 6.0013C7.5 6.82973 6.82843 7.5013 6 7.5013C5.17157 7.5013 4.5 6.82973 4.5 6.0013Z"
                fill= "#021D2D"
            />
        </svg>
    );

export default MarkerOutlined;
