// import { essentialWizardLastAccessTime } from "constants/appPropertyNames";

export const getPreference = (preferences, key) =>
  (
    preferences as {
      key: string;
      value: string;
    }[]
  )?.find((p) => p.key === key)?.value;

export const getPreferredLanguage = (preferences) => getPreference(preferences, "language");

export const getMarketingAcceptance = (preferences) => getPreference(preferences, "optInForMarketing") === "true";

export const getAnalyticsAcceptance = (preferences) => getPreference(preferences, "optInForAnalytics") === "true";

export const unsubscribeFromEmailsPreference = {
  key: "optInForEmailDigest",
  value: "false",
};
export const subscribeFromEmailsPreference = {
  key: "optInForEmailDigest",
  value: "true",
};

export const isUserSubscribedForEmail = (preferences) => getPreference(preferences, "optInForEmailDigest") === "true";

// export const getWizardLastAccessTime = preferences =>
//     Number.parseInt(getPreference(preferences, essentialWizardLastAccessTime));

// export const createWizardLastAccessTimePreferences = () =>
//     JSON.stringify({
//         preferences: [
//             {
//                 key: essentialWizardLastAccessTime,
//                 value: Date.now()
//             }
//         ]
//     });
