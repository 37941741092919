export const chunk = (items, size) => {
    const chunks = [];
    items = [].concat(...items);

    while (items.length) {
        chunks.push(items.splice(0, size));
    }

    return chunks;
};

export const transformWeeklyRisks = risks => {
    if (!risks || risks.length === 0) return null;
    return risks.reduce((result, elem) => {
        result[elem.category] = result[elem.category] || [];
        result[elem.category].push(elem);
        return result;
    }, {});
};

export const transformTopRisks = risks => {
    if (!risks || risks.length === 0) return null;
    return risks.map(elem => ({
        category: elem.category,
        risk: elem.value
    }));
};
