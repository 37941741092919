import React from "react";
import ItemRow from "./ItemRow";
import { Droppable } from "react-beautiful-dnd";
import { DragAndDropColumn } from "./styled";

const CurrentTopRiskList = ({ col: { list, id } }) => {
  return (
    <Droppable droppableId={id} direction="horizontal">
      {(provided) => (
        <DragAndDropColumn {...provided.droppableProps} ref={provided.innerRef}>
          {list.map((elem, index) => (
            <ItemRow key={elem.category} text={elem.category} risk={elem.risk} index={index} />
          ))}
          {provided.placeholder}
        </DragAndDropColumn>
      )}
    </Droppable>
  );
};

export default CurrentTopRiskList;
