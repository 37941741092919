import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useStore } from "react-context-hook";
import { useSearchParam } from "./useSearchParam";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { hasTokenExpired, hasTokenHalftimePassed } from '../utilities/TokenUtility';
export const useAuth = () => {
  const { getIdTokenClaims, logout } = useAuth0();

  const [userToken, setUserToken] = useStore("userToken", "");
  // eslint-disable-next-line no-unused-vars
  const [_userId, setUserId] = useStore("userId", "");
  const t = useSearchParam("t");
  const token = useSearchParam("token");
  useEffect(() => {

    const refreshAccessToken = async (oldAccessToken: string) => {
      const endpoint = process.env.REACT_APP_AUTHENTICATION_ENDPOINT_REFRESH_BY_TOKEN;
      const url = new URL(endpoint);
      url.searchParams.append('refresh', 'true');

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${oldAccessToken}`
        }
      });

      if (!response.ok) {
        console.log('Failed to refresh access token');
        return null;
      }

      const data = await response.json();
      const { token } = data;

      setUserToken(token);
    };

    const fetchData = async () => {
      const claims = await getIdTokenClaims();

      const result = await fetch(process.env.REACT_APP_AUTHENTICATION_ENDPOINT, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${claims.__raw}`,
        },
      });

      const resultJson = await result.json();
      const { token } = resultJson;

      setUserToken(token);
    };

    const fetchDataFromToken = async () => {
      const body = JSON.stringify({ token: t || token });

      try {
        const response = await fetch(process.env.REACT_APP_AUTHENTICATION_ENDPOINT_SIGN_BY_TOKEN, {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
          body,
          method: "POST",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const { token } = data;

        const decodedToken = jwt_decode<JwtPayload & { UserId: string }>(token);

        setUserId(decodedToken?.UserId);
        setUserToken(token);
      } catch (error) {
        logout({ returnTo: window.location.origin });
        console.log(error);
      }
    };

    if (!userToken) {
      if (t || token) {
        fetchDataFromToken().catch(console.error);
      } else {
        fetchData().catch(console.error);
      }
    } else {
      // check if token will expire soon
      if (hasTokenHalftimePassed(userToken) && !hasTokenExpired(userToken)) {
        refreshAccessToken(userToken);        
      }
    }

    // check the token every 30 minutes and refresh it if it will expire soon
    const interval = setInterval(() => {
      if (userToken && hasTokenHalftimePassed(userToken) && !hasTokenExpired(userToken)) {
        refreshAccessToken(userToken);        
      }
    }
    , 30 * 60 * 1000);

    return () => clearInterval(interval);

  


  }, [getIdTokenClaims, logout, setUserId, setUserToken, t, token, userToken]);

  return userToken;
};
