import React from 'react';
import styled from 'styled-components';

interface IconProps {
    flip?: boolean;
}
const IconCon = styled.span<IconProps>`
    cursor: pointer;
    path {
        transform: ${props => (props.flip ? 'rotate(180deg)' : 'rotate(0deg)')};
        transform-origin: 50% 50%;
    }
`;

const ArrowDown = ({ flip }: IconProps) => (
    <IconCon flip={flip}>
        <svg
            width= "20"
            height= "20"
            viewBox= "0 0 20 20"
            fill= "none"
            xmlns= "http://www.w3.org/2000/svg"
        >
            <path
                d= "M20 8L12 16L4 8"
                stroke= "white"
                strokeWidth= "2"
                strokeLinecap= "round"
                strokeLinejoin= "round"
            />
        </svg>
    </IconCon>
);

export default ArrowDown;
