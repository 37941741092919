import TooltipInfoIcon from "../../icons/TooltipInfoIcon";
import TooltipInfoCloseIcon from "../../icons/TooltipInfoCloseIcon";
import React, { useRef, useState } from "react";
import Tooltip from "react-tooltip-lite";
import { TooltipContent } from "./styled";
import useOnClickOutside from "./../../../hooks/useOnClickOutside";

const TooltipContainer = ({ text, extraSubTitle }) => {
  const [open, isOpen] = useState(false);
  const tooltipRef = useRef();

  useOnClickOutside(tooltipRef, () => {
    isOpen(false);
  });
  return (
    <div ref={tooltipRef}>
      <Tooltip
        data-cy="tooltip-text"
        tipContentClassName="tooltip"
        isOpen={open}
        direction="up-end"
        arrow={false}
        content={<div className="tooltip">{text}</div>}
      >
        <TooltipContent onClick={() => isOpen(!open)} data-cy={extraSubTitle ? `tooltip-icon-${extraSubTitle}` : "tooltip-icon"}>
          {open ? <TooltipInfoIcon /> : <TooltipInfoCloseIcon />}
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default TooltipContainer;
