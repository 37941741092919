import { Text } from '../../../utilities/typography';
import { Colors } from '../../../utilities/common-styles';
import HorizontalArrowIcon from '../../icons/HorizontalArrowIcon';
import { addDays, startOfWeek } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { IconWrapper, Switcher, DayWrapper } from './styled';
import { useTranslation } from 'react-i18next';
import { getLocal } from '../../../utilities/generalSettings';

const DaysSwitcher = ({ selectedDay, setSelectedDay, initialDay }) => {
    const [day, setDay] = useState(null);
        const {  i18n } = useTranslation();

    //calculate correct day number from O (Monday) to 6 (Sunday)
    const getCalculatedDay = day => (day === -1 ? 6 : day % 7);

    useEffect(() => {
        setDay(
            addDays(
                startOfWeek(new Date(), { weekStartsOn: 0 }),
                selectedDay + 1
            ).toLocaleString(getLocal(i18n.language).code, {
                weekday: 'long'
            })
        );
    }, [i18n.language, selectedDay]);
    return (
        <Switcher>
            <IconWrapper
                shouldBeVisible={selectedDay !== initialDay}
                onClick={() => setSelectedDay(day => getCalculatedDay(day - 1))}
                data-cy= "day-switcher-left-arrow"
            >
                <HorizontalArrowIcon flip={true} fill= "white" />
            </IconWrapper>

            <DayWrapper>
                <Text
                    size= "14px"
                    lineHeight= "21px"
                    color={Colors.white}
                    weight= "700"
                    uppercase
                    margin= "0 16px"
                    data-cy= "selected-day"
                >
                    {day}
                </Text>
            </DayWrapper>

            <IconWrapper
                shouldBeVisible={
                    selectedDay !== getCalculatedDay(initialDay - 1)
                }
                onClick={() => setSelectedDay(day => getCalculatedDay(day + 1))}
                data-cy= "day-switcher-right-arrow"
            >
                <HorizontalArrowIcon fill= "white" />
            </IconWrapper>
        </Switcher>
    );
};

export default DaysSwitcher;
