import { addDays, startOfWeek } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLocal } from '../../../utilities/generalSettings';
import { TooltipContainer, TooltipContent } from './styled';
export const RiskTooltip = props => {
            const {  i18n } = useTranslation();
    const { datum, x, setSelected } = props;
    setSelected(datum._x);
    const width = 80;
    const currentX = x < 320 ? x + 4 : x - width - 4;

    const day = addDays(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        Math.floor(datum._x / 24)
    ).toLocaleString(getLocal(i18n.language).code, {
        weekday: 'long'
    });
    

    const formatHour = (hour: number) => (hour < 10 ? `0${hour}` : hour);
    const hour = formatHour(datum.hour);

    return (
        <g>
            <TooltipContainer x={currentX} y={50} width={width} height= "14">
                <TooltipContent
                    data-cy= "essential-report-segment-risk-graph-tooltip"
                    style={{
                        fontSize: 12
                    }}
                >
                    <div>{day}</div>
                    <div>{hour}:00</div>
                </TooltipContent>
            </TooltipContainer>
        </g>
    );
};
