import styled from "styled-components";
import { Devices } from "../../../utilities/common-styles";
import { FlexWrapper } from "../../../utilities/wrappers";

export const ContentWrapper = styled.div`
  margin: 0 8px;
  width: 100%;
  max-width: 312px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Devices.mobileS} {
    margin: 0;
  }
`;

export const ButtonsWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0;
  }
`;
