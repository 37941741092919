import gql from "graphql-tag";

export const OBJECT_INSIGHTS = gql`
  query OBJECT_INSIGHTS($objectNo: String!, $placeType: String!, $accessVerb: String!, $country: String!) {
    object: objectV2(objectNo: $objectNo, accessVerb: $accessVerb, country: $country) {
      placeInsights(placeType: $placeType) {
        name
        displayName
        description
        value
      }
    }
  }
`;

export const STATIC_RISK = gql`
  query STATIC_RISK($objectNo: String!, $accessVerb: String!, $country: String!) {
    object: objectV2(objectNo: $objectNo, accessVerb: $accessVerb, country: $country) {
      staticRisk {
        value
        category
        timeStamp
      }
    }
  }
`;
export const OBJECT_PLACE_PROPERTIES_DETAILS = gql`
  query OBJECT_PLACE_PROPERTIES_DETAILS(
    $objectNo: String!
    $country: String!
    $propertyNames: [String]!
    $placeNamespace: String
    $placeType: String
    $accessVerb: String
  ) {
    object: objectV2(objectNo: $objectNo, country: $country, accessVerb: $accessVerb) {
      placeProperties(propertyNames: $propertyNames, placeNamespace: $placeNamespace, placeType: $placeType) {
        name
        value
        locked
      }
    }
  }
`;

export const WEEKLY_RISK = gql`
  query WEEKLY_RISK($objectNo: String!, $accessVerb: String!, $country: String!) {
    object: objectV2(objectNo: $objectNo, accessVerb: $accessVerb, country: $country) {
      hourlyStatistics {
        value
        hour
        category
      }
    }
  }
`;
export const BULLETIN_OBJECT_DETAILS = gql`
  query BULLETIN_OBJECT_DETAILS(
    $custObjNo: String!
    $country: String!
    $propertyNames: [String]!
    $placeNamespace: String
    $placeType: String
    $accessVerb: String
  ) {
    object: objectV2(objectNo: $custObjNo, country: $country, accessVerb: $accessVerb) {
      custObjName
      country
      address {
        city
        street
      }
      position {
        latitude
        longitude
      }
      placeProperties(propertyNames: $propertyNames, placeNamespace: $placeNamespace, placeType: $placeType) {
        name
        value
      }
    }
  }
`;
export const OBJECT_EVENTS_PAGINATED = gql`
  query OBJECT_EVENTS_PAGINATED($objectNo: String!, $country: String!, $accessVerb: String, $pageNumber: Int!, $pageSize: Int!) {
    object: objectV2(objectNo: $objectNo, country: $country, accessVerb: $accessVerb) {
      incidentsListPaginated(pageNumber: $pageNumber, pageSize: $pageSize) {
        ts
        eventCategories
        isNearby
        hadSeen
        isNotableEvent
      }
    }
  }
`;

export const YEARLY_EVENTS_COMPARISON = gql`
  query YEARLY_EVENTS_COMPARISON($objectNo: String!, $country: String!, $endDate: DateTime!, $startDate: DateTime!, $accessVerb: String) {
    object: objectV2(objectNo: $objectNo, country: $country, accessVerb: $accessVerb) {
      incidentsCount(startDate: $startDate, endDate: $endDate) {
        categoryEvents {
          eventsCount
          nonLocalizedCategory
        }
      }
    }
  }
`;
export const ESSENTIAL_LIST_QUERY = gql`
  query ESSENTIAL_LIST_QUERY($placeType: String!, $propertyNames: [String!]) {
    region {
      objects(accessVerb: "essential.view") {
        count
        elements {
          custObjId
          custObjNo
          custObjName
          country
          address {
            street
            streetNbr
            city
            postalCode
          }
          position {
            latitude
            longitude
          }
          placeProperties(placeType: $placeType, propertyNames: $propertyNames) {
            name
            locked
          }
        }
      }
    }
  }
`;

export const INCIDENTS_COUNT_BY_MONTH = gql`
  query INCIDENTS_COUNT_BY_MONTH($objectNo: String!, $accessVerb: String, $country: String!) {
    object: objectV2(objectNo: $objectNo, accessVerb: $accessVerb, country: $country) {
      incidentsCountByMonth {
        monthlyNearbyEventCounts {
          month
          year
          totalEventsCount
          nearbyEventCounts {
            eventsCount
            nonLocalizedCategory
          }
        }
      }
    }
  }
`;

export const LIST_TOP_RISK = gql`
  query LIST_TOP_RISK($segment: String!, $objectCountry: String!, $objectNumber: String!, $placeType: String!) {
    listTopRisks(segment: $segment, objectCountry: $objectCountry, objectNumber: $objectNumber, placeType: $placeType) {
      riskCategories {
        riskCategory
      }
      name
    }
  }
`;

export const SET_TOP_RISKS = gql`
  mutation SET_TOP_RISKS($objectCountry: String!, $objectNumber: String!, $placeType: String!, $topRisks: [String], $segment: String!) {
    setTopRisks(objectCountry: $objectCountry, objectNumber: $objectNumber, placeType: $placeType, topRisks: $topRisks, segment: $segment) {
      riskCategories {
        riskCategory
      }
      name
    }
  }
`;

export const SET_LAST_NEARBY_EVENT_DATE = gql`
  mutation SET_LAST_NEARBY_EVENT_DATE($customerObjNo: String!, $country: String!) {
    setLastNearbyEventDateTime(customerObjNo: $customerObjNo, countryCode: $country) {
      lastSeenEventDate
    }
  }
`;

export const TWO_WEEKS_OBJECT_EVENTS = gql`
  query TWO_WEEKS_OBJECT_EVENTS($objectNo: String!, $country: String!, $endDate: DateTime!, $startDate: DateTime!, $accessVerb: String) {
    object: objectV2(objectNo: $objectNo, country: $country, accessVerb: $accessVerb) {
      incidentsList(startDate: $startDate, endDate: $endDate) {
        ts
        eventCategories
        isNearby
        hadSeen
        isNotableEvent
      }
    }
  }
`;
