import React, { createContext, useContext, useState } from 'react';
import { IObjectPropsContextValue } from './types';

const ObjectPropsContext = createContext({} as IObjectPropsContextValue);

export const ObjectPropsContextProvider: React.FC = ({ children }) => {
    const [objectProperties, setObjectProperties] = useState(null);
    const [siteAssessment, setSiteAssessment] = useState(null);
    return (
        <ObjectPropsContext.Provider
            value={{
                objectProperties,
                setObjectProperties,
                siteAssessment,
                setSiteAssessment
            }}
        >
            {children}
        </ObjectPropsContext.Provider>
    );
};

export const useObjectPropsContext = () => useContext(ObjectPropsContext);
