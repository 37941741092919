import styled, { css } from 'styled-components';
import { Colors, Devices } from '../../../utilities/common-styles';


const Box = styled.div<{
    light?: boolean;
    padding?: string;
    justifyContent?: string;
}>`
    background-color: ${props =>
        props.light ? Colors.cardBackgroundPrimary : Colors.darkBlue};
    border-radius: 8px;
    padding: ${props => props.padding || '24px 26px'};
    display: flex;
    align-items: center;

    ${props =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}

    @media ${Devices.mobile} {
        font-size: 1.2rem;
        padding: 1rem;
    }
`;

const ResponsiveBox = styled(Box)`
    @media ${Devices.mobile} {
        border-radius: 0;
        padding: 12px 24px;
    }

    @media ${Devices.mobileS} {
        padding: 12px 16px;
    }
`;

const BorderBox = styled.div<{ padding?: string }>`
    border-bottom: 1px solid ${Colors.ghostWhite};
    display: flex;
    padding: ${props => props.padding || '16px'};
`;

export default {
    Box,
    ResponsiveBox,
    BorderBox
};
