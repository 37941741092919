import React from "react";
import { Option, OptionTitle } from "../styled";
const QuestionAnswers = ({ data, noAnswers }) => (
  <>
    {data.map(
      (answer) =>
        answer.dependenciesOk && (
          <Option data-cy={`essential-order-answer-${answer.key.toLowerCase()}`} key={answer.key}>
            <OptionTitle data-cy="essential-order-answer-title">{answer.title}</OptionTitle>
            {answer.readableAnswer ? (
              <strong data-cy="essential-order-answer-answervalue">{answer.readableAnswer}</strong>
            ) : (
              <div data-cy="essential-order-answer-noanswer" style={{ fontStyle: "italic" }}>
                {noAnswers}
              </div>
            )}
          </Option>
        )
    )}
  </>
);

export default QuestionAnswers;
