import Nearby from "./../icons/Nearby";
import Question1 from "./../icons/Question1";
import Question10 from "./../icons/Question10";
import Question11 from "./../icons/Question11";
import Question12 from "./../icons/Question12";
import Question2 from "./../icons/Question2";
import Question3 from "./../icons/Question3";
import Question4 from "./../icons/Question4";
import Question5 from "./../icons/Question5";
import Question6 from "./../icons/Question6";
import Question7 from "./../icons/Question7";
import Question8 from "./../icons/Question8";
import Question9 from "./../icons/Question9";
import FAQEmailComponent from "./../ObjectDetails/FAQEmailComponent";
import { SecuritasEmailContactOpen } from "./../../constants/trackedEvents";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DataExplenation from "./DataExplenation";
import DetailedQuestion from "./DetailedQuestion";
import FAQSection from "./FAQSection";
import { AddQuestionSection, Column, DetailedQuestionWrapper, FaqQuestionWrapper, MaxWidthWrapper, Questions, SendButton } from "./styled";
import TopSection from "./TopSection";
import { Text } from "./../../utilities/typography";
// import { useMixpanelContextV2 } from "./../BaldrView/MixpanelContextProviderV2";

const eventExplanation = (evName, idx, t) => (
  <>
    <br />
    <br />
    {idx}. {t(`categorization.${evName}`)}
    <br />
    {t(`segmentRisk.${evName}`)}
  </>
);

const NewFaqView = () => {
  const { t } = useTranslation();
  // const { track } = useMixpanelContextV2();
  const events = useMemo(
    () => ["aggression", "burglary", "intoxication", "property_damage", "theft", "trespassing", "police", "medical", "weapon", "assault", "unwarranted_people"],
    []
  );

  const [visible, setVisible] = useState(false);

  const contactOnClick = () => {
    // track(SecuritasEmailContactOpen);
  };

  const explanationDataKeys = ["numberOfEmployees", "VisitedObjects", "GuardReports", "ExternalDataSources"];
  const dataExplanationData = explanationDataKeys.reduce((prev, curr) => {
    const explanationData = {
      title: t(`faq.dataExplenantion.data.${curr}.title`),
      content: t(`faq.dataExplenantion.data.${curr}.contentNL`),
      description: t(`faq.dataExplenantion.data.${curr}.description`),
    };
    prev.push(explanationData);
    return prev;
  }, []);

  const questionsIteration = Array.from(Array(4), (_, i) => i + 1);

  const icons1 = [
    { key: 0, icon: <Question1 /> },
    { key: 1, icon: <Question2 /> },
    { key: 2, icon: <Question3 /> },
    { key: 3, icon: <Question4 /> },
  ];

  const icons2 = [
    { key: 0, icon: <Question5 /> },
    { key: 1, icon: <Question6 /> },
    { key: 2, icon: <Question7 /> },
    { key: 3, icon: <Question8 /> },
  ];

  const icons3 = [
    { key: 0, icon: <Question9 /> },
    { key: 1, icon: <Question10 /> },
    { key: 2, icon: <Question11 /> },
    { key: 3, icon: <Question12 /> },
  ];
  return (
    <MaxWidthWrapper>
      <DataExplenation title={t("faq.dataExplenantion.title")} description={t("faq.dataExplenantion.description")} data={dataExplanationData} />
      <Questions>
        <FAQSection testKey="faq-question-title-1" title={t("faq.top-section.how-to-interpret-question")}>
          <div data-cy="top-section-how-to-interpret-answer">{t("faq.top-section.how-to-interpret-answer")}</div>
          <Column noPadding>
            <TopSection t={t} />
          </Column>
        </FAQSection>
        <FAQSection testKey="faq-question-title-2" title={t("faq.internal-data.title")}>
          <div data-cy="faq-internal-data.answer">{t("faq.internal-data.answer")}</div>
          <DetailedQuestionWrapper>
            {questionsIteration.map((elem, index) => (
              <DetailedQuestion
                isInternalDataComponent={true}
                icon={icons1[elem - 1].icon}
                isFirst={elem === 1}
                key={`internal-data${elem}`}
                index={elem - 1}
                title={t(`faq.internal-data.detailedTitle${elem}`)}
                description={t(`faq.internal-data.detailedContent${elem}`)}
                name="faq.internal-data"
                i={index}
              ></DetailedQuestion>
            ))}
          </DetailedQuestionWrapper>
        </FAQSection>
        <FAQSection testKey="faq-question-title-3" title={t("faq.internal-data-conclusion.title")}>
          <div>{t("faq.internal-data-conclusion.answer")}</div>
          <DetailedQuestionWrapper>
            {questionsIteration.map((elem, index) => (
              <DetailedQuestion
                icon={icons2[elem - 1].icon}
                isInternalDataComponent={false}
                key={`internal-data-conclusion${elem}`}
                title={t(`faq.internal-data-conclusion.detailedTitle${elem}`)}
                description={t(`faq.internal-data-conclusion.detailedContent${elem}`)}
                name="faq.internal-data-conclusion"
                i={index}
              ></DetailedQuestion>
            ))}
          </DetailedQuestionWrapper>
        </FAQSection>
        <FAQSection testKey="faq-question-title-4" title={t("faq.external-data.title")}>
          <div>{t("faq.external-data.answer")}</div>
          <DetailedQuestionWrapper>
            {questionsIteration.map((elem, index) => (
              <DetailedQuestion
                icon={icons3[elem - 1].icon}
                isInternalDataComponent={false}
                key={`external-data${elem}`}
                title={t(`faq.external-data.detailedTitle${elem}`)}
                description={t(`faq.external-data.detailedContent${elem}`)}
                name="faq.external-data"
                i={index}
              ></DetailedQuestion>
            ))}
          </DetailedQuestionWrapper>
        </FAQSection>
        <FAQSection testKey="faq-question-title-5" title={t("faq.event.title")}>
          <div>{t("faq.event.subAnswer")}</div>

          <div>{events.map((ev, index) => eventExplanation(ev, index + 1, t))}</div>
        </FAQSection>
        <FAQSection testKey="faq-question-title-6" title={t("faq.event-data-source.title")}>
          <div data-cy="faq-question-content-6-1">{t("faq.event-data-source.answer")}</div>
          <br />
          <div data-cy="faq-question-content-6-2">{t("faq.event-data-source.subanswer")}</div>
          <Nearby t={t} />
        </FAQSection>
        <FAQSection testKey="faq-question-title-7" title={t("faq.nearby-activity.title")}>
          <div data-cy="faq-question-content-7">{t("faq.nearby-activity.answer")}</div>
        </FAQSection>
        <FAQSection testKey="faq-question-title-8" title={t("faq.nearby-notification.title")}>
          <div data-cy="faq-question-content-8">{t("faq.nearby-notification.answer")}</div>
        </FAQSection>
        <FAQSection testKey="faq-question-title-9" title={t("faq.risk-area.title")}>
          <div data-cy="faq-question-content-9">{t("faq.risk-area.answer")}</div>
        </FAQSection>
        <FaqQuestionWrapper onClick={contactOnClick}>
          <AddQuestionSection>
            <Text size="24px" mobileFontSize="1.7rem" margin="0 0 1em 0" maxWidth="786px">
              {t("faq.extra-questions.title")}
            </Text>
            <Text size="large" margin="0 0 1em 0" maxWidth="786px">
              {t("faq.extra-questions.description")}
            </Text>

            <SendButton onClick={() => setVisible(true)}>{t("faq.extra-questions.sendToSecuritas")}</SendButton>
          </AddQuestionSection>
        </FaqQuestionWrapper>
        <FAQEmailComponent
          onCloseClick={() => {
            setVisible(false);
          }}
          visible={visible}
        ></FAQEmailComponent>
      </Questions>
    </MaxWidthWrapper>
  );
};

export default NewFaqView;
