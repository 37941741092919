import QuestionConnector from "./../icons/QuestionConnector";
import React from "react";
import { DetailedQuestionContainer, DetailedQuestionContent, IconWrapperConnector, QuestionTitle } from "./styled";
import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
interface IHeaderProps {
  title: string;
  description: string;
  isFirst?: boolean;
  icon: React.ReactNode;
  isInternalDataComponent: boolean;
  index?: number;
  name: string;
  i: number;
}

const DetailedQuestion = ({ title, description, isFirst, icon, isInternalDataComponent, index, name, i }: IHeaderProps) => {
  const colors = ["#C2B4FC", "#9977FE", "#9977FE", "#55419E"];
  const currentColour = isInternalDataComponent ? colors[index] : Colors.borderDarkModeSelected;
  return (
    <DetailedQuestionContainer>
      <DetailedQuestionContent background={currentColour}>
        <QuestionTitle isInternalDataComponent={isInternalDataComponent}>
          {icon}
          <Text
            data-cy={`${name}-${i}-title`}
            lineHeight="24px"
            mobileLineHeight="16px"
            size="16px"
            mobileFontSize="12px"
            weight="700"
            margin="-10px 0 0 0 "
            textAlign="center"
            color={isInternalDataComponent ? (isFirst ? Colors.cardBackgroundPrimary : Colors.white) : Colors.cardBackgroundPrimary}
          >
            {title}
          </Text>
        </QuestionTitle>

        <Text
          lineHeight="24px"
          mobileLineHeight="16px"
          size="16px"
          mobileFontSize="12px"
          weight="400"
          margin="0"
          data-cy={`${name}-${i}-content`}
          color={isInternalDataComponent ? (isFirst ? Colors.cardBackgroundPrimary : Colors.white) : Colors.cardBackgroundPrimary}
        >
          {description}
        </Text>
      </DetailedQuestionContent>
      {isInternalDataComponent && (
        <IconWrapperConnector isFirst={isFirst}>
          <QuestionConnector />
        </IconWrapperConnector>
      )}
    </DetailedQuestionContainer>
  );
};

export default DetailedQuestion;
