import { Colors } from "../../utilities/common-styles";
import BackNavigationIcon from "./../icons/BackNavigationIcon";
import { BackButton, Container, InputWrapper } from "./../Order/styled";
import { useLocalStorage } from "../../hooks";
import React, { useEffect, useState } from "react";
import { useStore } from "react-context-hook";
import { useTranslation } from "react-i18next";
import CancelOrderButton from "../Order/buttons/CancelOrderButton";
import { ButtonsWrapper, ContentWrapper } from "./styled";
import { PageWrapper } from "../../utilities/wrappers";
import { SolidButton } from "../../utilities/buttons";
import { Header, Input } from "../Common";
import { NonObjectReport } from "../../models/NonObjectReport";

const SelectName = ({ isInternalUser }) => {
  const [nonObjectReports, _] = useLocalStorage<NonObjectReport[]>("nonObjectReports", []);

  const { t } = useTranslation();
  const areaString = t("area");

  const [title, setTitle] = useState<string>(``);

  const [data, setData] = useStore("data", null);
  // eslint-disable-next-line no-unused-vars
  const [_index, setIndex] = useStore("index", 0);

  const nameExists = (name: string) => !!nonObjectReports?.find((r) => r.name == name.trim());

  const getRecommendedName = (name: string): string => {
    if (nameExists(name)) {
      const regex = new RegExp(`(([a-zA-Z0-9À-ž_]+,?\\s?)+ - ${areaString}\\s*)(\\d)*`);
      const match = name.match(regex);
      let index = 2;
      if (match[3]) {
        index = 1 + Number.parseInt(match[3]);
      }
      return getRecommendedName(match[1].trim() + " " + index);
    }
    return name;
  };

  useEffect(() => {
    const name = getRecommendedName(`${data.address} - ${areaString}`);
    setTitle(name);
  }, [data.address]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const clearTitle = () => setTitle("");

  const handleNextStep = () => {
    setIndex(3);
    setData({ ...data, title });
  };

  return (
    <Container data-cy="essential-order-selectname">
      <>
        <title>{t("pageTitle")}</title>
      </>
      <PageWrapper color={Colors.backgroundDarkMode}>
        <Header title={t("selectName.header.title")} description={t("selectName.header.description")}>
          <CancelOrderButton isInternalUser={isInternalUser} />
        </Header>

        <ContentWrapper>
          <InputWrapper>
            <Input
              placeholder={t("selectName.content.placeholder")}
              value={title}
              onChange={handleTitleChange}
              onClear={clearTitle}
              errorMessage={nameExists(title) ? t("errors.nameExists") : ""}
            />
          </InputWrapper>

          <ButtonsWrapper>
            <BackButton data-cy="essential-order-back-btn" onClick={() => setIndex(1)}>
              <BackNavigationIcon></BackNavigationIcon>
              {t("common.back")}
            </BackButton>
            <SolidButton data-cy="essential-order-continue-btn" disabled={!title.replace(/\s+/, "") || nameExists(title)} onClick={handleNextStep}>
              {t("selectName.content.continue")}
            </SolidButton>
          </ButtonsWrapper>
        </ContentWrapper>
      </PageWrapper>
    </Container>
  );
};

export default SelectName;
