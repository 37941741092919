import React from "react";
import { ResultWrapper, Address, Location, ResultContentWrapper } from "./styled";
import MarkerOutlined from "../../icons/MarkerOutlined";
import ClearIcon from "../../icons/ClearIcon";

type Props = {
  address: string;
  location: string;
  onClearClick?: () => void;
};

const Result = ({ address, location, onClearClick }: Props) => (
  <ResultWrapper data-cy="essential-order-search-result">
    <ResultContentWrapper center>
      <MarkerOutlined large />
      <Location data-cy="essential-order-search-location">{location}</Location>
      <Address data-cy="essential-order-search-address">{address}</Address>
    </ResultContentWrapper>
    <div onClick={onClearClick} className="clear-icon">
      <ClearIcon />
    </div>
  </ResultWrapper>
);

export default Result;
