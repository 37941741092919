import { PlaceProperties } from "../../../utilities/placeCapabilitiesUtils";

interface SelectListElement {
  name: string;
  value: string;
}
interface Dependency {
  propertyName: string;
  value: string;
}
interface Question {
  name: string;
  displayName: string;
  description: string;
  answerApproach: string;
  valueOptions?: SelectListElement[];
  dependency?: Dependency;
  value: string;
  locked: boolean;
  valueVersion: number;
  theme: string;
  orderIndex: number;
  dependenciesOk: boolean;
  lockValueOnUpdate: boolean;
  formatAnswer: (input: string, t) => string;
  min?: number;
  validation?: RegExp;
}

const getAnswerFormatter = (name: string) => {
  if (name.match(/CONSTRUCTION_PROJECT_DURATION/gi)) {
    return (input, t) =>
      input?.length > 0 &&
      `${t("optionalQuestions.finishStep.month", {
        count: parseInt(input),
      })}`;
  }
  return (input) => input;
};

export const getQuestionsData = (data) => {
  if (data) {
    return data
      .map((definition) => {
        const {
          name,
          displayName,
          description,
          answerApproach,
          valueOptions,
          dependency,
          value,
          theme,
          locked,
          valueVersion,
          orderIndex,
          dependenciesOk,
          lockValueOnUpdate,
        } = definition;

        const values = valueOptions?.map((val) => ({
          name: val.displayName,
          value: val.value,
        }));

        const formattedName = name.match(/(.*properties\/)(.*)/)[2];

        const min = formattedName === PlaceProperties.CONSTRUCTION_PROJECT_DURATION ? 0 : null;

        const validation = formattedName === PlaceProperties.CONSTRUCTION_PROJECT_DURATION ? /^(\s*|\d+)$/ : null;

        const formatAnswer = getAnswerFormatter(name);

        const question: Question = {
          name: formattedName,
          displayName,
          description,
          answerApproach,
          valueOptions: values,
          dependency,
          value,
          locked,
          theme,
          valueVersion,
          orderIndex,
          dependenciesOk,
          lockValueOnUpdate,
          formatAnswer,
          min,
          validation,
        };

        return question;
      })
      .filter((question) => {
        const re = new RegExp(PlaceProperties.GEO_POSITION, "g");
        return !question.name.match(re);
      });
  }
  return [];
};

export const getOptionalQuestions = (allQuestions) =>
  allQuestions.filter((question) => {
    const re = new RegExp(PlaceProperties.BUSINESS_SEGMENT, "g");
    return !question.name.match(re);
  });

export const getSegmentQuestion = (allQuestions) =>
  allQuestions.find((question) => {
    const re = new RegExp(PlaceProperties.BUSINESS_SEGMENT, "g");
    return question.name.match(re);
  });

export const checkAnyQuestionAvailable = (questions) => questions.find((question) => !question.locked && question.dependenciesOk);

export const getInitialAnswers = (data) =>
  data.reduce((previousValue, currentValue) => {
    previousValue[currentValue.name] = currentValue.value;
    return previousValue;
  }, {});

export const getFormattedAnswers = (answers, formattedData, segment, t) => {
  const segmentQuestionAnswer = {};
  segmentQuestionAnswer[segment.name] = segment.value;
  return Object.entries({ ...segmentQuestionAnswer, ...answers }).map(([key, value]) => {
    const currentQuestion = formattedData.find((question) => question.name === key);
    const { locked, valueVersion, dependenciesOk } = currentQuestion;

    const readableAnswer =
      currentQuestion.answerApproach === "SELECT_ONE" && value ? currentQuestion.valueOptions.find((elem) => value === elem.value)?.name : value;

    const isChanged = currentQuestion.value !== value;

    return {
      key,
      value,
      title: currentQuestion.displayName,
      readableAnswer: currentQuestion.formatAnswer(readableAnswer, t),
      locked,
      valueVersion,
      dependenciesOk,
      isChanged,
    };
  });
};
