import { useMutation, useQuery } from "@apollo/client";

// import getConfig from 'next/config';

import { ESSENTIAL_LIST_QUERY, OBJECT_PLACE_PROPERTIES_DETAILS } from "./../queries/objects";
import { GET_ALL_PLACES_PROPERTIES, SET_OBJECT_PLACE_PROPERTIES } from "./../queries/placeProperties";
import { getPlaceNamespace, PlaceTypes } from "./../utilities/gqlUtilities";
import { IQuestionnairePayload } from "./../models/QuestionnairePayload";
import { PlaceProperties } from "./../utilities/placeCapabilitiesUtils";
import {
  checkAnyQuestionAvailable,
  getInitialAnswers,
  getOptionalQuestions,
  getQuestionsData,
  getSegmentQuestion,
} from "../components/Questions/OptionalQuestions/formatQuestionData";

const useOptionalQuestionsUpdate = ({ objectId, country, placeType }: IQuestionnairePayload) => {
  const queryVariables = {
    objectNo: objectId,
    accessVerb: "riskobject.view",
    placeNamespace: getPlaceNamespace(country),
    placeType,
    countryCode: country,
    country,
  };
  const refetchQueries = [
    {
      query: GET_ALL_PLACES_PROPERTIES,
      variables: queryVariables,
    },

    {
      query: OBJECT_PLACE_PROPERTIES_DETAILS,
      variables: {
        ...queryVariables,
        propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
      },
    },
  ];

  const { data, loading, error } = useQuery(GET_ALL_PLACES_PROPERTIES, {
    variables: queryVariables,
  });

  const allQuestions = getQuestionsData(data?.object?.allPlaceProperties);
  const optionalQuestions = getOptionalQuestions(allQuestions);

  const segmentQuestion = getSegmentQuestion(allQuestions);

  const isAnyQuestionAvailable = !!checkAnyQuestionAvailable(optionalQuestions);

  const initialAnswers = getInitialAnswers(optionalQuestions);

  const updatedProperties = (question, value) => [
    {
      nextVersion: question.valueVersion + 1,
      propertyName: question.name,
      value,
    },
  ];
  const [updateValue, { loading: updateLoading, error: updateError, called: updateCalled }] = useMutation(SET_OBJECT_PLACE_PROPERTIES, {
    refetchQueries: [
      ...refetchQueries,
      {
        query: ESSENTIAL_LIST_QUERY,

        variables: {
          propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
          placeType: PlaceTypes.ObjectPlaceType,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [updateOptionalQuestionValue] = useMutation(SET_OBJECT_PLACE_PROPERTIES, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const setAnswer = (question, value) =>
    updateOptionalQuestionValue({
      variables: {
        ...queryVariables,
        updatedProperties: updatedProperties(question, value),
      },
    });

  const setSegmentAnswer = (question, value) =>
    updateValue({
      variables: {
        ...queryVariables,
        updatedProperties: updatedProperties(question, value),
      },
    });

  return {
    questions: optionalQuestions,
    allQuestions,
    isAnyQuestionAvailable,
    segmentQuestion,
    setAnswer,
    setSegmentAnswer,
    error,
    loading,
    updateCalled,
    updateLoading,
    updateError,
    initialAnswers,
  };
};

export default useOptionalQuestionsUpdate;
