import { createContext, useContext } from 'react';

type SucceedModalDataContextType = {
    visible?: boolean;
    essentialId?: string;
};

export const SucceedModalDataContext = createContext<
    SucceedModalDataContextType
>({});

export const useModalData = () => useContext(SucceedModalDataContext);
