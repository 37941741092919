import SelectList from "../../Common/SelectList/SelectList";
import { InputWrapper } from "../../Order/styled";
import React from "react";
import { PlaceProperties } from "../../../utilities/placeCapabilitiesUtils";
import { Input } from "../../Common";

const QuestionContent = ({ currentQuestion, currentValue, updateAnswers, setValidationError = null, validationError = null }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentQuestion.validation) {
      setValidationError(!currentQuestion.validation.test(event.target.value));
    }
    updateAnswers(event.target.value);
    event.preventDefault();
  };

  return (
    <>
      {currentQuestion.answerApproach === "SELECT_ONE" ? (
        <SelectList selected={currentValue} onSelect={(value) => updateAnswers(value)} options={currentQuestion.valueOptions} name={currentQuestion.name} />
      ) : (
        <InputWrapper>
          <Input
            value={currentValue}
            onClear={() => updateAnswers("")}
            onChange={(event) => handleChange(event)}
            type="text"
            errorMessage={validationError ? "incorrect value" : ""}
            min={currentQuestion.name === PlaceProperties.CONSTRUCTION_PROJECT_DURATION ? 0 : null}
          />
        </InputWrapper>
      )}
    </>
  );
};

export default QuestionContent;
