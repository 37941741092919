import { useState, useEffect } from 'react';

type Config = {
    defaultValue: number;
    updateIntervalTimeout: number;
    incrementValue: number;
    callbackInvokeTime: number;
};

const useProgress = (callBack?: () => void, config?: Config): [number] => {
    const defaultValue = config?.defaultValue || 0;
    const updateIntervalTimeout = config?.updateIntervalTimeout || 50;
    const incrementValue = config?.defaultValue || 1;
    const callbackInvokeTime = config?.callbackInvokeTime || 90;

    const [value, setValue] = useState<number>(defaultValue || 0);

    useEffect(() => {
        const interval = setInterval(() => {
            setValue(oldValue => {
                const newValue = oldValue + incrementValue;

                if (oldValue > callbackInvokeTime) {
                    callBack();
                }

                if (oldValue > 100) {
                    clearInterval(interval);
                }

                return newValue;
            });
        }, updateIntervalTimeout);

        return () => clearInterval(interval);
    }, [updateIntervalTimeout]);

    return [value];
};

export default useProgress;
