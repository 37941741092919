import { createGlobalStyle } from "styled-components";

// tell webpack to package fonts into build folder

import NotoSansRegular from "../assets/fonts/noto/NotoSans-Regular.ttf";
import NotoSansItalic from "../assets/fonts/noto/NotoSans-Italic.ttf";
import NotoSansBold from "../assets/fonts/noto/NotoSans-Bold.ttf";
import NotoSansBoldItalic from "../assets/fonts/noto/NotoSans-BoldItalic.ttf";
import { Colors, Devices, Fonts, Sizes } from "../utilities/common-styles";
import Theme from "../utilities/Theme";

// See this link for font-display: fallback behavior
// https://www.styled-components.com/docs/faqs#how-do-i-fix-flickering-text-after-server-side-rendering

const GlobalStyles = createGlobalStyle`
html {
    box-sizing: border-box;
    font-size: 10px;
    overflow-y: auto;
    height: 100%;
    font-family: ${Fonts.SecuritasPro};
    background-color: ${Colors.backgroundDarkMode};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    height: 100%;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 1.6rem;
    font-family: ${Fonts.SecuritasPro};
     }
  }
  a {
    text-decoration: none;
    color: ${Theme.black};
  }
  h1 {
    font-size: 32px;
    margin-bottom: 0px;
    max-width: calc(100vw - 16px);
    line-height: 1.2em;
  }

  .tooltip {
    font-family: ${Fonts.SecuritasPro}; 
    font-size: 16px;
    line-height: 24px;
    line-height: 1.6;    

    .react-tooltip-lite {
       max-width: 240px !important;
      color: ${Colors.text};
      width: auto;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px;
      margin-left: -20px;     
      
      background: ${Colors.accentPurple};
      border-radius: 24px 24px 0px 24px;
      transition: all 0.5s ease;
    }


  }

  .object-tooltip {
    .react-tooltip-lite-arrow {
      border-color: #FFF;
      filter: drop-shadow(0 0 16px rgba(0,0,0,0.4));

    }

    span.react-tooltip-lite {
        padding: 0 0 10px 20px !important;
    }
  }

  button {  font-family: ${Fonts.SecuritasPro}; }

  .rc-notification {
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    pointer-events: none;
    padding: 0 16px;

    @media ${Devices.mobile} {
      padding-bottom: ${Sizes.mobileMenuMargin}px;
    }
  }

  .rc-notification > div:first-child {
        width: 100%;
        max-width: 520px;
        pointer-events: all;
    }

  .rc-notification-notice {
    min-height: 78px;
    background-color: ${Colors.cardBackgroundPrimary};
    padding: 12px 16px;
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
  }

  .rc-notification-notice-content {
    height: 100%;
    width: 100%;
    margin-left: 8px;
    overflow: hidden;
  }

  .rc-notification-notice-close {
    position: inherit;
    color: ${Theme.white};
    opacity: 1;
  }

    // @font-face {
    //     font-family: 'NotoSans';
    //     src: url('${NotoSansRegular}') format('truetype');
    //     font-weight: normal;
    //     font-style: normal;
    //     font-display: fallback;
    //     letter-spacing: 0.1px;
    // }

    // @font-face {
    //     font-family: 'NotoSans';
    //     src: url('${NotoSansItalic}') format('truetype');
    //     font-weight: normal;
    //     font-style: italic;
    //     font-display: fallback;
    //     letter-spacing: 0.1px;
    // }

    // @font-face {
    //     font-family: 'NotoSans';
    //     src: url('${NotoSansBold}') format('truetype');
    //     font-weight: bold;
    //     font-style: normal;
    //     font-display: fallback;
    //     letter-spacing: 0.1px;
    // }
    // @font-face {
    //     font-family: 'NotoSans';
    //     src: url('${NotoSansBoldItalic}') format('truetype');
    //     font-weight: bold;
    //     font-style: italic;
    //     font-display: fallback;
    //     letter-spacing: 0.1px;
    // }

     @font-face {
                font-family: ${Fonts.Noto};
                src: url('/static/fonts/NotoSans-Regular.ttf');
                font-weight: normal;
            }

            @font-face {
                font-family: ${Fonts.Noto};
                src: url('/static/fonts/NotoSans-Bold.ttf');
                font-weight: bold;
            }
            @font-face {
                font-family: ${Fonts.Noto};
                src: url('/static/fonts/NotoSans-Italic.ttf');
                font-weight: normal;
                font-style: italic;
            }
            @font-face {
                font-family: ${Fonts.Noto};
                src: url('/static/fonts/NotoSans-BoldItalic.ttf');
                font-weight: bold;
                font-style: italic;
            }

            @font-face {
                font-family: ${Fonts.SecuritasPro};
                src: url('/static/fonts/webfonts/woff/SecuritasPro-Regular.woff');
                font-weight: normal;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro};
                src: url('/static/fonts/webfonts/woff/SecuritasPro-RegularItalic.woff');
                font-weight: normal;
                font-style: italic;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro};
                src: url('/static/fonts/webfonts/woff/SecuritasPro-Bold.woff');
                font-weight: bold;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro};
                src: url('/static/fonts/webfonts/woff/SecuritasPro-BoldItalic.woff');
                font-weight: bold;
                font-style: italic;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro2};
                src: url('/static/fonts/webfonts/woff2/SecuritasPro-Regular.woff2');
                font-weight: normal;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro2};
                src: url('/static/fonts/webfonts/woff2/SecuritasPro-RegularItalic.woff2');
                font-weight: normal;
                font-style: italic;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro2};
                src: url('/static/fonts/webfonts/woff2/SecuritasPro-Bold.woff2');
                font-weight: bold;
            }
            @font-face {
                font-family: ${Fonts.SecuritasPro2};
                src: url('/static/fonts/webfonts/woff2/SecuritasPro-BoldItalic.woff2');
                font-weight: bold;
                font-style: italic;
            }

    * {
        box-sizing: border-box;
    }

    /* scrollbar */
    /* Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--silver) transparent;
    }

    /* Chrome/Edge/Safari */
    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    *::-webkit-scrollbar-track {
        background: var(--securitas-white);
    }
    *::-webkit-scrollbar-thumb {
        background-color: var(--silver);
        border-radius: 8px;
    }
    /* scrollbar end */

    html, body {
        height: 100%;
        margin: 0;
        font-family: NotoSans, OpenSans, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;

        color: var(--securitas-navy);
        overflow: hidden;
    }

    /* inherit the font in the buttons and other elements */
    input, textarea, button, h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        color: inherit }

    iframe {
        height: 100%;
        width: 100%;
        margin: 0;
        border: 0;
        display: block;
    }
`;

export default GlobalStyles;
