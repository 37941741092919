import useDebounce from "../../../hooks/useDebounce";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import React, { useContext, useRef, useState } from "react";
import { useStore } from "react-context-hook";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../LocationContextProvider";
import { Location } from "../types";
import SearchView from "./SearchView";
import { Button, FloatingContentWrapper, FloatingWrapper } from "./styled";
type Props = {
  resultSelectCallback?: (result: Location) => void;
};

const SearchContainer = ({ resultSelectCallback }: Props) => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLDivElement>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [results, setResults] = useState<Location[] | undefined>([]);
  const { selectedLocation, setLocation, search } = useContext(LocationContext);
  // eslint-disable-next-line no-unused-vars
  const [_data, setData] = useStore("data", null);
  // eslint-disable-next-line no-unused-vars
  const [_index, setIndex] = useStore("index", 0);

  const [cancellSearch] = useDebounce(searchQuery, () => {
    if (searchQuery) {
      setResults([]);
      setExpanded(false);
      search(searchQuery).then((data) => {
        setResults(data);
        setExpanded(true);
      });
    }
  });

  const handleClear = () => {
    setResults([]);
    setSearchQuery("");
    setExpanded(false);
  };

  const expand = () => setExpanded(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleResultClear = () => {
    cancellSearch();
    setLocation(undefined);
    handleClear();
  };

  const handleResultClick = (location: Location) => {
    handleClear();

    resultSelectCallback && resultSelectCallback(location);
  };

  const hanldeUseLocation = () => {
    setIndex(2);
    setData({
      location: selectedLocation.center.join(","),
      address: selectedLocation.address,
      country: selectedLocation.country,
    });
  };

  const showUseLocationButton = !expanded || !results?.length;

  useOnClickOutside(searchRef, () => setExpanded(false));

  return (
    <FloatingWrapper>
      <FloatingContentWrapper data-cy="essential-order-loc-search">
        <SearchView
          ref={searchRef}
          placeholder={t("selectLocation.search.placeholder")}
          searchQuery={searchQuery}
          results={results}
          selectedResult={selectedLocation}
          expanded={expanded}
          onInputClick={expand}
          onQueryChange={handleChange}
          onClear={handleClear}
          onResultClick={handleResultClick}
          onResultClear={handleResultClear}
        />
        {showUseLocationButton && (
          <Button data-cy="essential-order-useloc-button" disabled={!selectedLocation} onClick={hanldeUseLocation}>
            {t("selectLocation.search.useLocationText")}
          </Button>
        )}
      </FloatingContentWrapper>
    </FloatingWrapper>
  );
};

export default SearchContainer;
