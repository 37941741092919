import React from 'react';

const TooltipInfoCloseIcon = () => (
    <svg
        width= "24"
        height= "24"
        viewBox= "0 0 24 24"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M11.9922 1.5C6.20219 1.5 1.49219 6.21 1.49219 12C1.49219 17.79 6.20219 22.5 11.9922 22.5C17.7822 22.5 22.4922 17.79 22.4922 12C22.4922 6.21 17.7822 1.5 11.9922 1.5ZM11.9922 21C7.02719 21 2.99219 16.965 2.99219 12C2.99219 7.035 7.02719 3 11.9922 3C16.9572 3 20.9922 7.035 20.9922 12C20.9922 16.965 16.9497 21 11.9922 21Z"
            fill= "#9977FE"
        />
        <path
            d= "M12 10.5C11.5875 10.5 11.25 10.8375 11.25 11.25V17.25C11.25 17.6625 11.5875 18 12 18C12.4125 18 12.75 17.6625 12.75 17.25V11.25C12.75 10.8375 12.4125 10.5 12 10.5Z"
            fill= "#9977FE"
        />
        <path
            d= "M12 6C11.5875 6 11.25 6.3375 11.25 6.75V8.25C11.25 8.6625 11.5875 9 12 9C12.4125 9 12.75 8.6625 12.75 8.25V6.75C12.75 6.3375 12.4125 6 12 6Z"
            fill= "#9977FE"
        />
    </svg>
);

export default TooltipInfoCloseIcon;
