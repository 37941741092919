import React from 'react';

const ObjectPropertiesIcon = () => (
    <svg
        width= "20"
        height= "22"
        viewBox= "0 0 20 22"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M14.25 12.5C13.8 12.5 13.5 12.8 13.5 13.25V20H1.5V2H11.25C11.7 2 12 1.7 12 1.25C12 0.8 11.7 0.5 11.25 0.5H1.5C0.675 0.5 0 1.175 0 2V20C0 20.825 0.675 21.5 1.5 21.5H13.5C14.325 21.5 15 20.825 15 20V13.25C15 12.8 14.7 12.5 14.25 12.5Z"
            fill= "#031F30"
        />
        <path
            d= "M15.675 1.17344L4.725 12.1234C4.575 12.2734 4.5 12.4234 4.5 12.6484V14.7484C4.5 15.1984 4.8 15.4984 5.25 15.4984H7.35C7.575 15.4984 7.725 15.4234 7.875 15.2734L18.825 4.32344C19.65 3.42344 19.65 1.99844 18.75 1.17344C17.85 0.348438 16.5 0.348438 15.675 1.17344ZM17.775 3.27344L7.05 13.9984H6V12.9484L16.725 2.22344C17.025 1.92344 17.475 1.92344 17.775 2.22344C18.075 2.52344 18.075 2.97344 17.775 3.27344Z"
            fill= "#031F30"
        />
    </svg>
);

export default ObjectPropertiesIcon;
