import styled from "styled-components";
import { Colors } from "../../../utilities/common-styles";

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

export const OptionGroup = styled.div`
  user-select: none;
  position: relative;
  line-height: 1;
  cursor: pointer;
  height: 4rem;
  margin: 1px;
  border-radius: 4px;
  display: flex;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background-color: ${Colors.cardBackgroundPrimary};
`;

export const NotSelectedOption = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid ${Colors.borderDarkMode};
  background-color: none;
`;

export const SelectedOption = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid ${Colors.primaryPurple};
  background-color: none;
  display: flex;
  justify-content: center;
`;

export const Selected = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  align-self: center;
  background-color: ${Colors.primaryPurple};
`;

export const SegmentName = styled.div`
  font-size: 1.2rem;
  margin-right: 1rem;
  text-overflow: ellipsis;
  font-weight: bold;
  color: white;
`;
