export const HomeUrl = '/essential';
export const FaqUrl = '/faq';
export const NoAccessUrl = '/noaccess';
export const OrderReport = '/order';
export const OptionalQuestionsUrl = '/optionalQuestions';
export const QuenstionaireUrl = '/questionnaire';
export const UserPreferencesUrl = '/userPrefs';
export const GetUserAppContextUrl = '/api/getUserAppContext';
export const UnsubscribeSurveyUrl = '/essential/unsubscribe/survey';
export const UnsubscribeCompleteUrl = '/essential/unsubscribe/complete';
