import React from 'react';

const Question1 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M19.2885 2.27046C19.1031 2.0967 18.8586 2 18.6046 2H6C5.44772 2 5 2.44772 5 3V29C5 29.5523 5.44772 30 6 30H26C26.5523 30 27 29.5523 27 29V9.93323C27 9.65682 26.8856 9.39274 26.6839 9.20369L19.2885 2.27046Z"
            stroke= "black"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M19 2V10H27"
            stroke= "black"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M21 17H11"
            stroke= "black"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M21 23H11"
            stroke= "black"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default Question1;
