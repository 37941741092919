import React from 'react';

const LocationIcon = () => (
    <svg
        width= "24"
        height= "24"
        viewBox= "0 0 24 24"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M16.6667 10.668C16.6667 12.4654 15.479 14.307 14.0321 15.8343C13.3359 16.5692 12.6351 17.1731 12.1069 17.5937C12.0704 17.6228 12.0347 17.651 12 17.6782C11.9653 17.651 11.9296 17.6228 11.8931 17.5937C11.3649 17.1731 10.6641 16.5692 9.96794 15.8343C8.52098 14.307 7.33333 12.4654 7.33333 10.668C7.33333 8.09064 9.42267 6.0013 12 6.0013C14.5773 6.0013 16.6667 8.09064 16.6667 10.668ZM18 10.668C18 15.3346 12 19.3346 12 19.3346C12 19.3346 6 15.3346 6 10.668C6 7.35426 8.68629 4.66797 12 4.66797C15.3137 4.66797 18 7.35426 18 10.668ZM12 7.5013C10.6193 7.5013 9.5 8.62059 9.5 10.0013C9.5 11.382 10.6193 12.5013 12 12.5013C13.3807 12.5013 14.5 11.382 14.5 10.0013C14.5 8.62059 13.3807 7.5013 12 7.5013ZM10.5 10.0013C10.5 9.17287 11.1716 8.5013 12 8.5013C12.8284 8.5013 13.5 9.17287 13.5 10.0013C13.5 10.8297 12.8284 11.5013 12 11.5013C11.1716 11.5013 10.5 10.8297 10.5 10.0013Z"
            fill= "#F7F7F7"
        />
    </svg>
);

export default LocationIcon;
