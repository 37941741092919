import React from "react";
import { useTranslation } from "react-i18next";
import { HomeUrl, OrderReport } from "../../../constants/urls";
import { OrderCanceledEvent } from "../../../constants/trackedEvents";
import { CancelButton } from "../styled";
import { useStore } from "react-context-hook";
// import { useMixpanelContextV2 } from "../../BaldrView/MixpanelContextProviderV2";
import { useNavigate } from "react-router-dom";

const CancelOrderButton = ({ isInternalUser }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { track } = useMixpanelContextV2();
  // eslint-disable-next-line no-unused-vars
  const [_index, setIndex] = useStore("index", 0);

  const handleCancel = () => {
    // track(OrderCanceledEvent, {
    //   IsInternalUser: isInternalUser,
    //   Step: window.location.pathname,
    // });
    setIndex(0);
    navigate(isInternalUser ? OrderReport : HomeUrl);
  };

  return (
    <CancelButton data-cy="essential-order-cancel" dangerous onClick={handleCancel}>
      {t("common:cancel")}
    </CancelButton>
  );
};

export default CancelOrderButton;
