import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { ApolloError } from 'apollo-client';

import ErrorIcon from '../ErrorIcon';
import { useTranslation } from 'react-i18next';

interface ChartProps {
    errorOccured?: boolean;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const InfoWrapper = styled.div`
    position: absolute;
    align-self: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 100;
`;

const Chart = styled.div<ChartProps>`
    ${props =>
        props.errorOccured &&
        css`
            color: ${lighten(0.3, props.theme.black75)};
            p {
                color: ${lighten(0.3, props.theme.black75)};
            }
        `}
`;

interface ErrorWrapperProps {
    message?: string;
    error?: ApolloError;
}

const ErrorWrapper: FC<ErrorWrapperProps> = ({ children, message, error }) => {
    const [t] = useTranslation();

    useEffect(() => {
        !!error &&
            (error.graphQLErrors || []).map(({ message }) =>
                console.log(message)
            );
    }, [error]);

    return (
        <Wrapper>
            {!!error && (
                <InfoWrapper className= "error-wrapper">
                    {!!message && <ErrorIcon />}
                    <strong>{message || t('noDataError')}</strong>
                </InfoWrapper>
            )}
            <Chart errorOccured={!!error}>{children}</Chart>
        </Wrapper>
    );
};

export default ErrorWrapper;
