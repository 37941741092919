import { Input } from "./../Common";
import { Text } from "../../utilities/typography";

import { CALL_ME } from "./../../queries/emailQueries";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ModalItems } from "./ContactUsModal";
import { ContactButton, ContentPage, DescriptionWrapper, InputComponent } from "./styled";
import { isCorrectPhoneNumber } from "../shared/validationsUtils";

type Props = {
  setIndex: (index: ModalItems) => void;
  objectAddress: string;
};

const CallUsModalContent = ({ setIndex, objectAddress }: Props) => {
  const { t } = useTranslation();

  const [firstname, setFirstname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberFormatError, setPhoneNumberFormatError] = useState(false);

  const [updateValue] = useMutation(CALL_ME, {
    onError: () => {
      setIndex(ModalItems.Error);
    },
    onCompleted: () => {
      setIndex(ModalItems.End);
    },
  });

  const changePhoneNumber = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setPhoneNumberFormatError(!isCorrectPhoneNumber(phoneNumber));
  };

  return (
    <ContentPage data-cy="callback-modal-content">
      <DescriptionWrapper>
        <Text weight="700" size="24px" mobileFontSize="18px" lineHeight="36px" margin="0 0 8px 0" data-cy="callback-wrapper-header">
          {t("common.contactUs.callBackPage.title")}
        </Text>
        <Text weight="400" size="16px" mobileFontSize="12px" lineHeight="24px" margin="0" data-cy="callback-wrapper-description">
          {t("common.contactUs.callBackPage.subtitle")}
        </Text>
      </DescriptionWrapper>
      <Text weight="700" size="16px" mobileFontSize="12px" lineHeight="24px" margin="0" data-cy="callback-contact-info-header">
        {t("common.contactUs.callBackPage.question")}
      </Text>
      <Text weight="400" size="16px" mobileFontSize="12px" lineHeight="24px" margin="0 0 24px 0" data-cy="callback-contact-info-description">
        {t("common.contactUs.callBackPage.questionDescription")}
      </Text>
      <InputComponent text={firstname}>
        <Input
          value={firstname}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            setFirstname(event.target.value);
          }}
          placeholder={t("common.contactUs.callBackPage.yourName")}
          onClear={() => setFirstname("")}
          maxLength={200}
        />
      </InputComponent>
      <InputComponent text={phoneNumber}>
        <Input
          value={phoneNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            changePhoneNumber(event.target.value);
          }}
          errorMessage={phoneNumberFormatError ? t("common.contactUs.incorrectFormat") : ""}
          placeholder={t("common.contactUs.yourNumber")}
          onClear={() => changePhoneNumber("")}
          maxLength={20}
        />
      </InputComponent>

      <ContactButton
        onClick={async () => {
          const variables = {
            firstname,
            objectAddress,
            phoneNumber,
          };
          await updateValue({ variables });
        }}
        disabled={!phoneNumber || phoneNumberFormatError}
        data-cy="callback-get-contacted-button"
      >
        {t("common.contactUs.nextButton")}
      </ContactButton>
    </ContentPage>
  );
};

export default CallUsModalContent;
