import styled, { css } from 'styled-components';

import { Colors, Devices } from '../../../utilities/common-styles';

export const Wrapper = styled.div<{ loading?: boolean }>`
    max-width: 900px;
    width: 100%;
    padding: 22px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;

    ${props =>
        !props.loading &&
        css`
            cursor: pointer;

            :hover {
                transform: scale(1.01);

                path.arrow-icon {
                    fill: ${props => props.theme.purple};
                }
            }
        `}
`;

export const Name = styled.h4`
     color: ${Colors.white};
    margin: 0;
        &:hover {
      
            color: ${Colors.primaryPurple};
        
    }
`;

export const AddressWrapper = styled.div`
    align-items: center;
    margin-top: 8px;
    display: flex;
    align-items: baseline;
    color: ${Colors.borderDarkMode};

    svg {
        margin-right: 8px;
        path {
            fill: ${Colors.borderDarkMode};
        }
    }

    &:hover {
        color: ${Colors.primaryPurple};
        div {
            color: ${Colors.primaryPurple};
        }

        svg {
            path {
                fill: ${Colors.primaryPurple};
            }
        }
    }
`;

export const StatusInfo = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 0.4px;
    padding: 0px 8px;
    color: black !important;
    background: ${Colors.orangeDarkMode};
    border-radius: 24px;
`;

export const StatusWrapper = styled.div`
    flex-grow: 0;
    display: flex;
    padding-right: 40px;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    @media ${Devices.mobile} {
        grid-row: 2;
        padding-top: 2px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const ListItemWrapper = styled.div<{ loading?: boolean }>`
    width: 100%;
    border: 2px solid ${Colors.borderDarkMode};
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 1rem 0;
    box-shadow: 0 0 0 inset rgba(0, 0, 0, 0);
    padding: 22px 24px;
    cursor: pointer;
    background-color: ${Colors.backgroundDarkMode};
    display: grid;
    grid-template-rows: 2;

    grid-template-columns: auto 160px 20px;
    @media ${Devices.mobile} {
        grid-template-columns: auto 20px;
    }

    &:hover {
        div {
            color: ${Colors.primaryPurple} ;
            h4 {
            color: ${Colors.primaryPurple} ;
        }
        }
    }
    ${props =>
        !props.loading &&
        css`
            cursor: pointer;

            :hover {
                path.arrow-icon {
                    fill: ${Colors.primaryPurple};
                }
                svg {
                    path {
                        fill: ${Colors.primaryPurple};
                    }
                }
                color: ${Colors.primaryPurple};
                border: 2px solid ${Colors.primaryPurple};
            }

            :active {
                path.arrow-icon {
                    fill: ${Colors.accentPurple};
                }
                svg {
                    path {
                        fill: ${Colors.accentPurple};
                    }
                }
                color: ${Colors.accentPurple};
                border: 2px solid ${Colors.accentPurple};
            }
        `};
`;
