import { format } from "date-fns";
import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import React from "react";
import { formatLocale } from "../../utilities/dateUtils";
import { useTranslation } from "react-i18next";
import { CategoriesList, DescriptionItem, DescriptionItemTitle, EventCategory, IncidentsListItem, NotSeenDot, ObjectTag } from "./styled";
import { CategoryModalDescriptionContext } from "../Common";
import NearbyExclamationMarkIcon from "../icons/NearbyExclamationMarkIcon";

type Props = {
  event: {
    eventCategories: string[];
    isNearby: boolean;
    hadSeen: boolean;
    date: Date;
  };
};

const { useActions } = CategoryModalDescriptionContext;

const IncidentsList = ({ event }: Props) => {
  const { setIsOpen, setCategory } = useActions();
  const { i18n } = useTranslation();

  const { t } = useTranslation();
  return (
    <IncidentsListItem>
      <DescriptionItem>
        <div>
          <DescriptionItemTitle>
            {!event.hadSeen && <NotSeenDot />}
            <Text data-cy="event-category" size="16px" mobileFontSize="14px" mobileLineHeight="21px" lineHeight="24px" weight="700" color="white" margin="0">
              {t(`categorization.${event.eventCategories[0]?.toLowerCase()}`)}
            </Text>
          </DescriptionItemTitle>
          <Text
            data-cy="nearby-event-subtitle"
            size="14px"
            mobileFontSize="12px"
            mobileLineHeight="16px"
            lineHeight="21px"
            weight="400"
            color={event.isNearby ? Colors.borderDarkMode : Colors.redDarkMode}
          >
            {event.isNearby ? (
              t("segmentRisk.nearbyEventTitle")
            ) : (
              <ObjectTag>
                <NearbyExclamationMarkIcon />
                {t("segmentRisk.objectEventTitleAtYourSite")}
              </ObjectTag>
            )}
          </Text>
        </div>
        <div>
          <Text data-cy="event-date" size="12px" mobileFontSize="11px" lineHeight="18px" mobileLineHeight="16px" color={Colors.borderDarkMode}>
            {formatLocale(event.date, i18n.language, "dd MMM yyyy")}
          </Text>
          <Text
            data-cy="event-time"
            size="12px"
            mobileFontSize="11px"
            mobileLineHeight="16px"
            lineHeight="18px"
            bold
            color={Colors.borderDarkMode}
            textAlign="right"
            margin="5px 0 0 0"
          >
            {format(event.date, "HH:mm")}
          </Text>
        </div>
      </DescriptionItem>

      <CategoriesList>
        {event.eventCategories.map((elem) => (
          <EventCategory
            key={elem}
            isNearbyEvent={event.isNearby}
            onClick={() => {
              setCategory(elem?.toLowerCase());
              setIsOpen(true);
            }}
          >
            <Text
              size="12px"
              mobileFontSize="11px"
              mobileLineHeight="16px"
              lineHeight="18px"
              uppercase
              color={event.isNearby ? Colors.redDarkMode : Colors.cardBackgroundPrimary}
              textAlign="right"
              weight="700"
            >
              {t(`categorization.${elem?.toLowerCase()}`)}
            </Text>
          </EventCategory>
        ))}
      </CategoriesList>
    </IncidentsListItem>
  );
};

export default IncidentsList;
