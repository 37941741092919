import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Colors } from "../../utilities/common-styles";
import { RiskDistributionId, scrollToSection } from "../../utilities/scrollUtils";
import { Text } from "../../utilities/typography";
import DayGraphIcon from "./../icons/DayGraphIcon";
import WeekGraphIcon from "./../icons/WeekGraphIcon";
import ContactUsModal from "./../ObjectDetails/ContactUsModal";
import DailyGraphWrapper from "./Graph/DailyGraphWrapper";
import WeeklyGraphWrapper from "./Graph/WeeklyGraphWrapper";
import { ButtonsWrapper, ContentWrapper, HelpMeWrapper, SolidButtonWrapper, Title, TitleWrapper, Wrapper } from "./styled";
import { Views, WeekRisks } from "./types";

type Props = {
  topRiskList: string[];
  address: string;
  buttonText: string;
  weeklyRisks: WeekRisks;
};

const ActOnYourRiskStatistics = ({ buttonText, address, topRiskList, weeklyRisks }: Props) => {
  const SectionRef = useRef();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState(Views.DayGraph);
  const { category } = useParams();

  useEffect(() => {
    const sectionId = category;
    if (sectionId === RiskDistributionId && SectionRef?.current) {
      scrollToSection(RiskDistributionId);
    }
  }, [SectionRef, category]);

  return (
    <Wrapper>
      <ContentWrapper id={RiskDistributionId} ref={SectionRef}>
        <TitleWrapper data-cy="act-on-your-risk-title">
          <Title>
            <Text size="24px" lineHeight="36px" mobileFontSize="18px" mobileLineHeight="27px" weight="700" bold color={Colors.grey}>
              {t("reportObject.actOnYourRisk.title")}
            </Text>
          </Title>
          <ButtonsWrapper>
            <div onClick={() => setActiveView(Views.DayGraph)} data-cy="calendar-icon">
              <DayGraphIcon isActive={activeView === Views.DayGraph} />
            </div>
            <div onClick={() => setActiveView(Views.WeekGraph)} data-cy="graph-icon">
              <WeekGraphIcon isActive={activeView === Views.WeekGraph} />
            </div>
          </ButtonsWrapper>
        </TitleWrapper>

        {activeView === Views.DayGraph ? (
          <DailyGraphWrapper risksTypes={topRiskList} weeklyRisks={weeklyRisks} text={t("reportObject.graph.allWeek")} setActiveView={setActiveView} />
        ) : (
          <WeeklyGraphWrapper risksTypes={topRiskList} weeklyRisks={weeklyRisks} text={t("reportObject.graph.dayByday")} setActiveView={setActiveView} />
        )}

        <HelpMeWrapper>
          <SolidButtonWrapper
            onClick={() => {
              setVisible(true);
            }}
            data-cy="help-button"
          >
            <Text size="16px" lineHeight="24px" color={Colors.text} margin="0">
              {buttonText}
            </Text>
          </SolidButtonWrapper>
        </HelpMeWrapper>
      </ContentWrapper>
      <ContactUsModal
        onCloseClick={() => {
          setVisible(false);
        }}
        visible={visible}
        objectAddress={address}
      ></ContactUsModal>
    </Wrapper>
  );
};

export default ActOnYourRiskStatistics;
