import React, { useEffect } from "react";
import { useStore } from "react-context-hook";
import { useNavigate } from "react-router";
import OrderContainer from "./OrderContainer";

const Order = () => {
  const [userData, _setUserData] = useStore("userData", null);
  const navigate = useNavigate();
  useEffect(() => {
    if (userData && !userData?.hasOrderReportAccess) {
      navigate("/");
    }
  }, [userData]);

  return userData?.hasOrderReportAccess ? (
    <OrderContainer isInternalUser={userData?.isInternal} hasOrderReportAccess={userData?.hasOrderReportAccess} />
  ) : (
    <></>
  );
};

export default Order;
