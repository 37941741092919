import { IExpirationTokenDecoded, ITokenDecoded } from '../models/TokenModel';
import JWT from 'jwt-decode';

export const tokenExpiresInSeconds = (decodedToken: IExpirationTokenDecoded) => {
    return decodedToken.exp - Math.floor(Date.now() / 1000);
}

export const hasTokenExpired = (token: string) => {
    const tokenDecoded = JWT<ITokenDecoded>(token);
    return tokenExpiresInSeconds(tokenDecoded) < 0;
}

export const hasTokenHalftimePassed = (token: string) => {
    const tokenDecoded = JWT<ITokenDecoded>(token);
    const threshold = (tokenDecoded.exp - tokenDecoded.iat) / 2; // refresh threshold is half the time of token lifespan
    const expiresInSeconds = tokenDecoded.exp - Math.floor(Date.now() / 1000);
    const tokenNeedsRefresh = expiresInSeconds < threshold;
    return tokenNeedsRefresh;
}