import styled, { css } from "styled-components";
import { Colors, Devices, Sizes } from "./common-styles";

import Theme from "./Theme";

export const BackLink = styled.div<{ isEssentialReportUrl?: boolean }>`
  text-transform: capitalize;
  align-items: center;
  height: ${Sizes.header}px;
  color: ${(props) => (props.isEssentialReportUrl ? Colors.text : Colors.darkBlue)};
  font-weight: 700;
  transition: all 0.3s ease;
  display: inline-flex;
  padding: 4px 0;
  display: "flex";
  cursor: pointer;
  &:hover {
    color: ${Colors.primaryPurple};
  }
  img {
    margin-right: 1rem;
  }
  svg {
    margin-right: 1rem;

    path {
      stroke: ${(props) => (props.isEssentialReportUrl ? Colors.text : Colors.darkBlue)};
    }
  }

  @media ${Devices.mobile} {
    display: none;
  }
`;

export const chartOverlay = css`
  &:after {
    content: " ";
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const FlexWrapper = styled.div<{ center?: boolean; column?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.center ? "center" : "initial")};
  justify-content: space-between;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  grid-gap: 8px;

  @media ${Devices.mobile} {
    flex-wrap: wrap;
  }
`;

export const ErrorWrapper = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.red};
`;

export const HeaderWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${Colors.white};
`;

export const BaldrHeaderWrapper = styled.div<{
  withBorder?: boolean;
  isEmbeded?: boolean;
  preventBack?: boolean;
  isEssentialReportUrl?: boolean;
}>`
  background: ${(props) => (props.isEssentialReportUrl ? Colors.darkBlue : Colors.white)};
  color: ${(props) => (props.isEssentialReportUrl ? Colors.text : Colors.darkBlue)};
  ${(props) =>
    props.withBorder &&
    css`
      border-bottom: 1px solid #ebebeb;
    `}
  height: ${(props) => (!props.isEmbeded ? `${Sizes.headerWithActions}px` : `${Sizes.header}px`)};

  @media ${Devices.mobile} {
    height: ${(props) =>
      props.isEmbeded ? (props.preventBack ? `0px` : `${Sizes.header}px`) : props.preventBack ? `${Sizes.header}px` : `${Sizes.headerWithActions}px`};
  }
`;

const ScrollableChartWrapper = styled.div<{ disabledScroll?: boolean }>`
  background-color: ${Colors.white};
  position: relative;
  width: 100%;
  min-width: ${(props) => (props.disabledScroll ? "auto" : "1100px")};
  flex: 1;
  padding-bottom: 1em;

  @media ${Devices.mobile} {
    min-width: ${(props) => (props.disabledScroll ? "auto" : "1100px")};
  }
`;

const BaseShadowWrapper = styled.div<{
  width?: string;
  height?: string;
  padding?: string;
}>`
  border-radius: 4px;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.border};
  flex: 0 1 ${(props) => props.width || "auto"};
  min-height: ${(props) => props.height || "auto"};
  padding: ${(props) => props.padding || "0"};
  margin: 0 8px;
`;

export const ShadowWrapper = styled(BaseShadowWrapper)<{
  margin?: string;
  isEssential?: boolean;
}>`
  background-color: ${(props) => (props.isEssential ? Colors.backgroundDarkMode : Colors.white)};
  flex: 0 1 ${(props) => props.width || "auto"};
  @media ${Devices.mobile} {
    flex: 1 1 ${(props) => props.width || "auto"};
    min-width: 45vw;
    margin: 8px;
    min-height: auto;
  }
`;

export const ResponsiveShadowWrapper = styled(ShadowWrapper)`
  @media ${Devices.mobileS} {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid ${(props) => props.theme.ghostWhite};
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    padding: 0 10px;
    margin: 0;
    height: 48px;
    min-height: auto;

    div {
      font-size: 1.4rem;
      margin: 0;
      font-weight: bold;
    }
  }
`;

export const PageWrapper = styled.div<{ color?: string; margin?: string }>`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  margin: ${(props) => props.margin || "auto"};
  padding: 0 40px;
  position: relative;
  background-color: ${(props) => props.color || ""};

  @media (max-width: ${Theme.maxWidth}px) {
    max-width: 100vw;
  }

  @media ${Devices.mobileS} {
    padding: 0 16px;
  }
`;
export const Container = styled.div`
  background-color: white;
  flex: 1;
  @media ${Devices.mobile} {
    padding-bottom: ${Sizes.mobileMenuMargin}px;
  }
`;

export const ContentWrapper = styled.div<{
  row?: boolean;
  padding?: string;
  background?: string;
}>`
  margin: 34px auto 0;
  display: flex;
  color: white;
  background-color: ${(props) => props.background || Colors.white};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  align-items: ${(props) => (props.row ? "stretch" : "center")};
  align-content: center;
  justify-content: stretch;
  max-width: 900px;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : "1rem")};
  ${(props) =>
    props.row &&
    css`
      justify-content: center;
    `}
  @media ${Devices.mobile} {
    flex-direction: column;
    padding: 1rem 0;
    margin-top: 0;
  }
`;

export const ActionPageWrapper = styled(PageWrapper)<{ preventBack: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.preventBack ? "flex-end" : "space-between")};
  min-height: ${Sizes.header}px;
  @media ${Devices.mobile} {
    bottom: 0;
    position: fixed;
    background: white;
    z-index: 9999;
    justify-content: center;
    padding: 0;
  }
`;

export const BackLinkheader = styled(PageWrapper)`
  ${BackLink} {
    display: none;

    @media ${Devices.mobile} {
      display: flex;
      min-height: ${Sizes.header}px;
    }
  }
`;

export default {
  FlexWrapper,
  ErrorWrapper,
  HeaderWrapper,
  BaldrHeaderWrapper,
  ScrollableChartWrapper,
  ShadowWrapper,
  ResponsiveShadowWrapper,
  PageWrapper,
  ContentWrapper,
  Container,
  ActionPageWrapper,
  BackLinkheader,
  BackLink,
};
