import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import useAccessGuard from "./../hooks/useAccessGuard";
import useLocalStorage from "./../hooks/useLocalStorage";
import { NonObjectReport } from "./../models/NonObjectReport";
import { GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES } from "./../queries/placeProperties";
import { getPlaceNamespace, PlaceTypes } from "./../utilities/gqlUtilities";
import LoadingIndicator from "./Common/LoadingIndicator";
import NewBaldrView from "./NewBaldrView";

const NewNonObjectBaldr = () => {
  const [nonObjectReports, _] = useLocalStorage<NonObjectReport[]>("nonObjectReports", []);

  const { id } = useParams<{ country: string; id: string }>();
  const report = nonObjectReports?.find((report) => report.id === id);
  const country = report?.country;

  const useQueryCb = () =>
    useQuery(GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES, {
      variables: {
        placeNamespace: getPlaceNamespace(country),
        placeType: PlaceTypes.NewLocationPlaceType,
        country: country?.toLowerCase(),
        nonObjectId: id,
        latitude: report?.position?.latitude,
        longitude: report?.position?.longitude,
      },
    });

  const { loading, canAccess } = useAccessGuard({
    placeId: id,
    placeType: PlaceTypes.NewLocationPlaceType,
    getPlaceProperties: (data) => data?.nonObject?.allPlaceProperties,
    useDefinedQuery: useQueryCb,
    country,
  });
  return loading ? (
    <LoadingIndicator />
  ) : (
    canAccess && <>{canAccess && <NewBaldrView report={report} placeType={PlaceTypes.NewLocationPlaceType} objectNumber={id} country={country} />}</>
  );
};

export default NewNonObjectBaldr;
