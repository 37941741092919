import WrappedLoader from "../../WrappedLoader";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getOptionalQuestionsPath } from "../../../utilities/urlUtils";
import QuestionContent from "../OptionalQuestions/QuestionContent";
import { Content, Description, FormWrapper, Header, Wrapper } from "../styled";
import MorphSaveButton from "./MorphSaveButton";
import useQuestionnaireUpdate from "../../../hooks/useQuestionnaireUpdate";
import { IQuestionnairePayload } from "../../../models/QuestionnairePayload";
import { BusinessSegmentSelected } from "../../../constants/trackedEvents";
import SegmentNotification from "./SegmentNotification";
import GraphQlErrorView from "../../Common/GraphQlErrorView";
// import { useMixpanelContextV2 } from "../../BaldrView/MixpanelContextProviderV2";

import { useNavigate } from "react-router-dom";

const SegmentForm = (payload: IQuestionnairePayload) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const { track } = useMixpanelContextV2();
  const [active, setActive] = useState("");

  const { segmentQuestion, error, loading, updateCalled, updateLoading, updateError, setSegmentAnswer } = useQuestionnaireUpdate(payload);

  const setAnswerCb = useCallback(() => {
    // track(BusinessSegmentSelected, {
    //   BusinessSegment: active,
    // });
    return setSegmentAnswer(segmentQuestion, active);
  }, [setSegmentAnswer]);

  useEffect(() => {
    if (updateCalled && !updateLoading && !updateError) {
      const url = getOptionalQuestionsPath(payload.country, payload.objectId, payload.placeType);
      navigate(url);
    }
  }, [updateCalled, updateLoading, updateError]);

  if (loading) return <WrappedLoader />;

  if (!!error || !!updateError) {
    return (
      <GraphQlErrorView
        errors={[error]}
        messages={{
          errorTitle: t("common:error"),
          genericMessage: t("common:noDataError"),
          noPermission: t("common:no-permissions"),
        }}
      />
    );
  }

  return (
    <FormWrapper>
      {segmentQuestion && (
        <Wrapper data-cy="essential-order-segment">
          <Header data-cy="essential-order-segment-header">
            <h2 data-cy="essential-order-segment-title">{segmentQuestion.displayName}</h2>
          </Header>
          <Content>
            <Description data-cy="essential-order-segment-description">{segmentQuestion.description}</Description>
            <QuestionContent currentQuestion={segmentQuestion} currentValue={active} updateAnswers={setActive}></QuestionContent>
          </Content>
          <SegmentNotification />
          <MorphSaveButton
            currentValue={active}
            title={t("segment.segment_question.button")}
            updateSegment={setAnswerCb}
            updateCalled={updateCalled}
            updateLoading={updateLoading}
            updateError={updateError}
          />
        </Wrapper>
      )}
    </FormWrapper>
  );
};

export default SegmentForm;
