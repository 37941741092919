import React from 'react';

const NearbyExclamationMarkIcon = () => (
    <svg
        width= "16"
        height= "17"
        viewBox= "0 0 16 17"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M8.00391 15.5C11.8639 15.5 15.0039 12.36 15.0039 8.5C15.0039 4.64 11.8639 1.5 8.00391 1.5C4.14391 1.5 1.00391 4.64 1.00391 8.5C1.00391 12.36 4.14391 15.5 8.00391 15.5ZM8.00391 2.5C11.3139 2.5 14.0039 5.19 14.0039 8.5C14.0039 11.81 11.3139 14.5 8.00391 14.5C4.69391 14.5 2.00391 11.81 2.00391 8.5C2.00391 5.19 4.6989 2.5 8.00391 2.5Z"
            fill= "#FD566C"
        />
        <path
            d= "M8 5L8 10"
            stroke= "#FD566C"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M8 12H8.00667"
            stroke= "#FD566C"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default NearbyExclamationMarkIcon;
