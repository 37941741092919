import { useEffect, useRef } from 'react';

const useDebounce = (
    value: string,
    callback: () => void,
    delay: number = 800
): [() => void] => {
    const callbackRef = useRef<() => void>();
    const timeoutRef = useRef<number>();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (!!value) {
            timeoutRef.current = setTimeout(() => {
                !!callbackRef.current && callbackRef.current();
            }, delay);

            return () => {
                clearTimeout(timeoutRef.current);
            };
        }
    }, [value, delay]);

    const cancellCallback = () => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
    };

    return [cancellCallback];
};

export default useDebounce;
