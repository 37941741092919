import { TFunction } from "next-i18next";
import React from "react";
import FAQBorderBox from "./FAQBorderBox";
import { RowTopSection, TopSectionWrapper } from "./styled";
interface Props {
  t: TFunction;
}

const TopSection = ({ t }: Props) => {
  const risksLevels = ["1", "2", "3", "4", "5"];
  const data =
    risksLevels.reverse().reduce((prev, curr) => {
      const riskLevel = {
        riskLevel: t(`faq.top-section.risk-levels.${curr}.riskLevel`),
        title: t(`faq.top-section.risk-levels.${curr}.newTitle`, {
          returnObjects: true,
        }),
        color: t(`faq.top-section.risk-levels.${curr}.color`),
        description: t(`faq.top-section.risk-levels.${curr}.newdescription`),
      };
      prev.push(riskLevel);
      return prev;
    }, []) || [];

  if (typeof data === "string") return <div>No data</div>;

  return (
    <>
      <RowTopSection>
        <TopSectionWrapper>
          {data.map((data, i) => (
            <FAQBorderBox key={i} border={false} data={data} index={i} />
          ))}
        </TopSectionWrapper>
      </RowTopSection>
    </>
  );
};

export default TopSection;
