import { ObjectType, Type } from "./../utilities/types";
import { ApolloError } from "apollo-client";
import { useQuery } from "@apollo/client";

import { NonObjectReport } from "./../models/NonObjectReport";

import { sortAscByName } from "./../utilities/arrayUtils";
import { NonObjectType } from "./../utilities/types";
import { getAbbreviatedLocation } from "./../utilities/addressFormatter";

import { PlaceTypes } from "./../utilities/gqlUtilities";
import { ESSENTIAL_LIST_QUERY } from "./../queries/objects";
import usePagination from "./usePagination";
import useLocalStorage from "./useLocalStorage";
import { PlaceProperties } from "./../utilities/placeCapabilitiesUtils";

export enum ObjectStatus {
  OK,
  NOT_SUPPORTED,
  PICK_SEGMENT,
  NOT_SET,
}

export const calculateStatusFromPlaceProperties = (placeProperties) => {
  let extraInfo = ObjectStatus.OK;
  if (placeProperties?.length > 0 && placeProperties[0].locked === false) {
    extraInfo = ObjectStatus.PICK_SEGMENT;
  }

  return extraInfo;
};

type Report = {
  type: Type;
  name: string;
  id: string;
  address: string;
  country: string;
  status?: number;
  longitude?: number;
  latitude?: number;
};

const useReports = (): {
  reports: Report[];
  loading: boolean;
  error: ApolloError;
  pageChangeHandler: ({ selected }: { selected: number }) => void;
  nmbOfPages: number;
  allReportsCount: number;
  page: number;
} => {
  const { pageChangeHandler, getNumberOfPages, page, size } = usePagination();

  const [nonObjectReports, _] = useLocalStorage<NonObjectReport[]>("nonObjectReports", []);

  const { data, error, loading } = useQuery(ESSENTIAL_LIST_QUERY, {
    variables: {
      propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
      placeType: PlaceTypes.ObjectPlaceType,
    },
  });

  const reports: Report[] = [
    ...(data?.region?.objects?.elements || []).map((object) => ({
      id: object.custObjNo,
      name: object.custObjName,
      address: `${object.address?.street}, ${object.address?.city}`,
      country: object.country,
      type: ObjectType,
      status: calculateStatusFromPlaceProperties(object.placeProperties),
    })),
    ...(nonObjectReports || []).map((report) => ({
      ...report,
      address: getAbbreviatedLocation([report?.position?.longitude, report?.position?.latitude]),
      longitude: report?.position?.longitude,
      latitude: report?.position?.latitude,
      type: NonObjectType,
      status: ObjectStatus.NOT_SET,
    })),
  ].sort(sortAscByName);

  const nmbOfPages = !!reports.length ? getNumberOfPages(reports.length) : 0;

  const begin = page * size;
  const end = begin + size;

  return {
    reports: reports.slice(begin, end),
    loading,
    error,
    pageChangeHandler,
    nmbOfPages,
    allReportsCount: reports.length,
    page,
  };
};

export default useReports;
