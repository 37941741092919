import { useQuery } from "@apollo/client";
import { useLocalStorage } from "../../../hooks";
import GraphQlErrorView from "../../Common/GraphQlErrorView";
import SegmentForm from "./SegmentForm";
import WrappedLoader from "../../WrappedLoader";
import { nonObjectStorageName } from "../../../constants/storageNames";
import useCheckBusinessSegmentIsSet from "../../../hooks/useCheckBusinessSegmentIsSet";
import { NonObjectReport } from "../../../models/NonObjectReport";
import { NON_OBJECT } from "../../../queries/nonObjects";
import React from "react";
import { useParams } from "react-router-dom";

import { isSegmentLocked } from "../../../utilities/placePropertiesUtils";
import { getPlaceNamespace, PlaceTypes } from "../../../utilities/gqlUtilities";
import { PlaceProperties } from "../../../utilities/placeCapabilitiesUtils";
import { useTranslation } from "react-i18next";

const NonObjectQuestionnaire = () => {
  const [nonObjectReports, _] = useLocalStorage<NonObjectReport[]>(nonObjectStorageName, []);
  const { t } = useTranslation();

  const { id, country } = useParams<{ country: string; id: string }>();
  const report = nonObjectReports?.find((report) => report.id === id);

  const useQueryCb = () =>
    useQuery(NON_OBJECT, {
      variables: {
        latitude: report?.position?.latitude,
        longitude: report?.position?.longitude,
        country,
        nonObjectId: id,
        propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
        placeNamespace: getPlaceNamespace(country),
        placeType: PlaceTypes.NewLocationPlaceType,
      },
      ssr: false,
    });
  const isBusinessSegmentLocked = (data) => isSegmentLocked(data?.nonObject?.placeProperties);

  const { loading, error } = useCheckBusinessSegmentIsSet({
    invokeQuery: useQueryCb,
    isBusinessSegmentLocked,
    country,
    placeId: id,
    placeType: PlaceTypes.NewLocationPlaceType,
  });

  if (error) {
    return (
      <GraphQlErrorView
        errors={[error]}
        messages={{
          errorTitle: t("common.error"),
          genericMessage: t("common.noDataError"),
          noPermission: t("common.no-permissions"),
        }}
      />
    );
  }

  return loading ? (
    <WrappedLoader />
  ) : (
    report && (
      <SegmentForm
        country={country}
        objectId={id}
        placeType={PlaceTypes.NewLocationPlaceType}
        latitude={report?.position?.latitude}
        longitude={report?.position?.longitude}
      />
    )
  );
};

export default NonObjectQuestionnaire;
