import React from 'react';

const Question12 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M13.0005 23.0003C12.7405 23.0003 12.4905 22.9003 12.2905 22.7103L6.29055 16.7103C5.90055 16.3203 5.90055 15.6903 6.29055 15.3003C6.68055 14.9103 7.31055 14.9103 7.70055 15.3003L13.0005 20.6003L24.2905 9.30031C24.6805 8.91031 25.3105 8.91031 25.7005 9.30031C26.0905 9.69031 26.0905 10.3203 25.7005 10.7103L13.7005 22.7103C13.5205 22.9003 13.2605 23.0003 13.0005 23.0003Z"
            fill= "#0E283D"
        />
    </svg>
);

export default Question12;
