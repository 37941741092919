import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParam } from "../hooks/useSearchParam";
import { getIsEmbeddedInCorporate } from "../utilities/queryUtils";
import useProperties from "./../hooks/useProperties";
import { extractErrorCodes } from "./../utilities/errorParser";
import { PlaceTypes } from "./../utilities/gqlUtilities";
import { GeoRiskId } from "./../utilities/scrollUtils";
import ActOnYourRiskStatistics from "./ActOnYourRisk/ActOnYourRiskStatistics";
import { ContentWrapper } from "./ActOnYourRisk/styled";
import { CheckListWrapper } from "./CheckList/CheckListWrapper";
import { CategoryModalDescriptionContext, ObjectPropertiesContext, TopRiskUserSelectionContext } from "./Common";
import DownloadBanner from "./Common/DownloadBanner/DownloadBanner";
import GraphQlErrorView from "./Common/GraphQlErrorView";
import TopRiskMap from "./GeographicalRisk/TopRiskMap";
import Insights from "./Insights/Insights";
import NonObjectInsights from "./Insights/NonObjectInsights";
import CategoryDescriptionModal from "./NearbyIncidents/CategoryDescriptionModal";
import NearbyIncidents from "./NearbyIncidents/NearbyIncidents";

import { useStore } from "react-context-hook";
import { IsPeabUser } from "../utilities/userUtils";
import NonObjectsNearbyIncidents from "./NearbyIncidents/NonObjectsNearbyIncidents";
import ObjectDetails from "./ObjectDetails/ObjectDetails";
import WrappedLoader from "./WrappedLoader";
import { GridFlexSection, LoadingScreen, MainContainer, WizardColumnContainer, WizardContainer, WizardWrapper, Wrapper } from "./styled";

const { useActions } = TopRiskUserSelectionContext;
const { useActions: useActions2 } = CategoryModalDescriptionContext;

const GqlErrorTypeUnauthorized = "UNAUTHORIZED";
const { useObjectProperties } = ObjectPropertiesContext;

const NewBaldrView = ({ objectNumber, country, report, placeType }: { objectNumber?: string; country?: string; report?: any; placeType: any }) => {
  const { t } = useTranslation();
  const isObject = placeType === PlaceTypes.ObjectPlaceType;

  const { isOpen, setIsOpen, category } = useActions2();
  const a = useSearchParam("a");

  const { setObjectProperties, objectProperties } = useObjectProperties();
  const isEmbedInRiskPrediction = getIsEmbeddedInCorporate(a);
  const { topRiskList } = useActions();

  const [userData, _setUserData] = useStore("userData", {
    name: "",
    isInternal: false,
    preferences: [],
    isEmbedded: true,
    isEmbeddedInMobile: true,
    roles: [],
    hasOrderReportAccess: false,
  });

  const {
    objectLoading,
    objectName,
    objectAddress,
    objectPosition,
    segment,
    errors,
    listOfCurrentSelectedTopRisks,
    topRisks,
    weeklyRisks,
    weeklyRiskDataLoading,
  } = useProperties({ objectNumber, country, placeType, report });

  useEffect(() => {
    if (isObject) {
      setObjectProperties({
        objectId: objectNumber,
        country,
        placeType: PlaceTypes.ObjectPlaceType,
        segment,
      });
    } else {
      setObjectProperties({
        country,
        placeType: PlaceTypes.NewLocationPlaceType,
        latitude: report?.position?.latitude,
        longitude: report?.position?.longitude,
        objectId: report?.id,
        segment,
      });
    }
  }, []);

  useEffect(() => {
    if (isObject) {
      setObjectProperties({
        objectId: objectNumber,
        country,
        placeType: PlaceTypes.ObjectPlaceType,
        segment,
      });
    } else {
      setObjectProperties({
        country,
        placeType: PlaceTypes.NewLocationPlaceType,
        latitude: report?.position?.latitude,
        longitude: report?.position?.longitude,
        objectId: report?.id,
        segment,
      });
    }
  }, [segment]);

  useEffect(() => {
    (errors || []).forEach((er) => er && console.error(er));
  }, [errors]);

  const errorCodes = extractErrorCodes(errors);
  if (errorCodes.includes(GqlErrorTypeUnauthorized)) {
    return (
      <GraphQlErrorView
        errors={errors}
        messages={{
          errorTitle: t("common.error"),
          genericMessage: t("common.noDataError"),
          noPermission: t("common.no-permissions"),
        }}
      />
    );
  }

  if (objectLoading)
    return (
      <LoadingScreen>
        <WrappedLoader />
      </LoadingScreen>
    );

  const pdfName = segment === "CONSTRUCTION" ? (IsPeabUser(userData.name) ? "construction_peab_sv.pdf" : "construction_sv.pdf") : null;

  return (
    <MainContainer>
      {!isEmbedInRiskPrediction && (
        <>
          <Wrapper>
            <div>
              <title>{t("reportObject.title")}</title>
            </div>
            <ObjectDetails
              title={t("reportObject.title")}
              objectName={objectName}
              address={isObject ? objectAddress : objectPosition}
              buttonText={t("common.contactUs.buttonTitle")}
            />
          </Wrapper>
          {segment === "CONSTRUCTION" && (
            <DownloadBanner
              title={t("common.download.title")}
              label={t("common.download.label")}
              description={t("common.download.description")}
              showMore={t("common.download.showMore")}
              showLess={t("common.download.showLess")}
              url={`pdf/${pdfName}`}
            />
          )}
        </>
      )}

      <WizardWrapper>
        <WizardColumnContainer>
          <GridFlexSection gap={24} id={GeoRiskId} />
          {topRisks?.length > 0 && listOfCurrentSelectedTopRisks.length > 0 && (
            <TopRiskMap
              title={t("reportObject.topRisk.title")}
              description={t("reportObject.topRisk.description")}
              position={
                isObject
                  ? objectPosition
                  : {
                      latitude: report?.position?.latitude,
                      longitude: report?.position?.longitude,
                    }
              }
              risks={topRisks}
              topRisks={listOfCurrentSelectedTopRisks}
              tooltipText={t("reportObject.topRisk.tooltip")}
              segment={segment}
              country={country}
            />
          )}
          <GridFlexSection gap={24} />
          {objectProperties &&
            (isObject ? <NearbyIncidents objectProperties={objectProperties} /> : <NonObjectsNearbyIncidents objectProperties={objectProperties} />)}
        </WizardColumnContainer>
        <WizardColumnContainer>
          <WizardContainer>
            {weeklyRiskDataLoading ? (
              <WrappedLoader />
            ) : (
              <>
                {topRiskList?.length > 0 && weeklyRisks && (
                  <ActOnYourRiskStatistics
                    buttonText={t("common.helpMe.buttonTitle")}
                    topRiskList={topRiskList.map((tr) => tr.category).slice(0, 3)}
                    address={`${objectName}, ${isObject ? objectAddress : objectPosition}`}
                    weeklyRisks={weeklyRisks}
                  />
                )}
              </>
            )}
          </WizardContainer>
          <WizardContainer>
            <ContentWrapper>
              <CheckListWrapper />
            </ContentWrapper>
          </WizardContainer>
          {segment === "CONSTRUCTION" &&
            objectProperties &&
            (isObject ? (
              <Insights t={t} objectProperties={objectProperties}></Insights>
            ) : (
              <NonObjectInsights
                t={t}
                latitude={report?.position?.latitude}
                longitude={report?.position?.longitude}
                objectProperties={objectProperties}
              ></NonObjectInsights>
            ))}
        </WizardColumnContainer>
        <CategoryDescriptionModal
          onCloseClick={() => {
            setIsOpen(false);
          }}
          t={t}
          category={category}
          visible={isOpen}
        ></CategoryDescriptionModal>
      </WizardWrapper>
    </MainContainer>
  );
};

export default NewBaldrView;
