import gql from 'graphql-tag';

export const GET_CHECKLIST = gql`
    query GET_CHECKLIST($objectNo: String!, $country: String!) {
        placeChecklist(objectNo: $objectNo, country: $country) {
            name
            checked
            contents
            available
            orderIndex
            title
        }
    }
`;

export const UPDATE_CHECKLIST = gql`
    mutation UPDATE_CHECKLIST($name: String!, $checkState: Boolean!) {
        checkStateUpdate(name: $name, checkState: $checkState) {
            name
            checked
            contents
            available
            orderIndex
        }
    }
`;
