import { Input } from "./../Common";
import { Text } from "../../utilities/typography";

import TextareaWrapper from "./../Common/TextArea/TextareaWrapper";
import ModalInfoIcon from "../icons/ModalInfoIcon";
import { EMAIL_US } from "../../queries/emailQueries";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { isCorrectEmail, isCorrectPhoneNumber } from "../../utilities/validationUtils";
import { ModalItems } from "./ContactUsModal";
import { ContactButton, ContentPage, InputComponent, InfoWrapper } from "./styled";
import { useStore } from "react-context-hook";

type Props = {
  setIndex: (index: ModalItems) => void;
  objectAddress: string;
};

const EmailModalComponent = ({ setIndex, objectAddress }: Props) => {
  const { t } = useTranslation();
  const [userData, _setUserData] = useStore("userData", null);

  const [email, setEmail] = useState(userData?.name);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [userName, setUserName] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [phoneNumberFormatError, setPhoneNumberFormatError] = useState(false);

  const changeEmail = (email) => {
    setEmailFormatError(!isCorrectEmail(email));
    setEmail(email);
  };

  const changePhoneNumber = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setPhoneNumberFormatError(!isCorrectPhoneNumber(phoneNumber));
  };

  const [updateValue] = useMutation(EMAIL_US, {
    onError: () => {
      setIndex(ModalItems.Error);
    },
    onCompleted: () => {
      setIndex(ModalItems.End);
    },
  });

  return (
    <ContentPage>
      <Text weight="700" size="24px" mobileFontSize="18px" lineHeight="36px" margin="0" data-cy="write-to-us-header">
        {t("common.contactUs.contactUsPage.title")}
      </Text>
      <TextareaWrapper
        value={emailContent}
        onChange={(event) => {
          event.preventDefault();
          setEmailContent(event.target.value);
        }}
        placeholder={t("common.contactUs.contactUsPage.messagePlaceholder")}
        data-cy="email-content"
      ></TextareaWrapper>
      <InfoWrapper>
        <ModalInfoIcon data-cy="modal-info-icon" />
        <Text size="14px" mobileFontSize="12px" lineHeight="21px" margin="0" data-cy="modal-info-wrapper">
          {t("common.contactUs.contactUsPage.info")}
        </Text>
      </InfoWrapper>
      <InputComponent text={email}>
        <Input
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            changeEmail(event.target.value);
          }}
          errorMessage={emailFormatError ? t("common.contactUs.incorrectEmailFormat") : ""}
          placeholder={t("common.contactUs.contactUsPage.yourEmail")}
          onClear={() => changeEmail("")}
        />
      </InputComponent>
      <InputComponent text={userName}>
        <Input
          value={userName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            setUserName(event.target.value);
          }}
          placeholder={t("common.contactUs.callBackPage.yourName")}
          onClear={() => setUserName("")}
          maxLength={200}
        />
      </InputComponent>
      <InputComponent text={phoneNumber}>
        <Input
          value={phoneNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            changePhoneNumber(event.target.value);
          }}
          placeholder={t("common.contactUs.yourNumber")}
          errorMessage={phoneNumberFormatError ? t("common.contactUs.incorrectFormat") : ""}
          onClear={() => changePhoneNumber("")}
          maxLength={20}
        />
      </InputComponent>

      <ContactButton
        onClick={async () => {
          const variables = {
            emailAddress: email || null,
            objectAddress,
            phoneNumber: phoneNumber || null,
            message: emailContent,
            firstName: userName,
          };

          await updateValue({ variables });
        }}
        disabled={(!email && !phoneNumber) || emailFormatError || phoneNumberFormatError}
        data-cy="send-email-button"
      >
        {t("common.contactUs.writeMessageButton")}
      </ContactButton>
    </ContentPage>
  );
};

export default EmailModalComponent;
