import React from "react";
import { useQuery } from "@apollo/client";

import NewBaldrView from "./NewBaldrView";
import { LoadingIndicator } from "./Common/ProgressBar/styled";
import { getCountry, getObjectNo } from "./../utilities/queryUtils";
import { getPlaceNamespace, PlaceTypes } from "./../utilities/gqlUtilities";
import { PLACE_PROPERTIES } from "./../queries/placeProperties";
import useAccessGuard from "./../hooks/useAccessGuard";
import { PlaceProperties } from "./../utilities/placeCapabilitiesUtils";
import { useParams } from "react-router-dom";

const NewBaldr = () => {
  const { country, id, category } = useParams<{ country: string; id: string ; category:string}>();
  const useQueryCb = () =>
    useQuery(PLACE_PROPERTIES, {
      variables: {
        custObjNo: id,
        accessVerb: "riskobject.view",
        propertyNames: [PlaceProperties.BUSINESS_SEGMENT],
        placeNamespace: getPlaceNamespace(country),
        placeType: PlaceTypes.ObjectPlaceType,
        country: country,
      },
    });

  const { loading, canAccess } = useAccessGuard({
    placeId: id,
    placeType: PlaceTypes.ObjectPlaceType,
    getPlaceProperties: (data) => data?.object?.placeProperties,
    useDefinedQuery: useQueryCb,
    country,
  });
  return loading ? (
    <LoadingIndicator />
  ) : (
    canAccess && <>{canAccess && <NewBaldrView objectNumber={id} country={country} placeType={PlaceTypes.ObjectPlaceType} />}</>
  );
};

export default NewBaldr;
