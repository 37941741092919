import { ApolloClient, HttpLink, InMemoryCache, split, useMutation, useQuery, useSubscription } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";

const createApolloClient = (token, lng = "en") => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GQL_URL,
    credentials: "include",
  });

  const authLink = setContext((_, { headers }) => {
    const appContext = "OWNER";

    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        AppContext: appContext,
        authorization: token ? `Bearer ${token}` : "",
        "Accept-Language": lng,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const link = authLink.concat(errorLink).concat(httpLink);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          nonObject: {
            merge(existing, incoming) {
              // If the incoming object has an nonObjectId, use it to merge the objects
              if (incoming && incoming.nonObjectId) {
                return incoming;
              }
              // If the incoming object doesn't have an objectNo, use the existing object
              return existing;
            },
          },
          objectV2: {
            merge(existing, incoming) {
              // If the incoming object has an objectNo, use it to merge the objects
              if (incoming && incoming.objectNo) {
                return incoming;
              }
              // If the incoming object doesn't have an objectNo, use the existing object
              return existing;
            },
          },
        },
      },
    },
  });

  const client = new ApolloClient({
    link,
    cache: cache,
  });

  return client;
};

export { useQuery, useMutation, useSubscription, createApolloClient };
