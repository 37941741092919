import React from 'react';

const ReportIcon = () => (
    <svg
        width= "40"
        height= "40"
        viewBox= "0 0 40 40"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M33.3333 8.33203C34.2538 8.33203 35 9.07822 35 9.9987C35 10.9192 34.2538 11.6654 33.3333 11.6654L6.66667 11.6654C5.74619 11.6654 5 10.9192 5 9.9987C5 9.07822 5.74619 8.33203 6.66667 8.33203L33.3333 8.33203ZM20 28.332C20.9205 28.332 21.6667 29.0782 21.6667 29.9987C21.6667 30.9192 20.9205 31.6654 20 31.6654L6.66667 31.6654C5.74619 31.6654 5 30.9192 5 29.9987C5 29.0782 5.74619 28.332 6.66667 28.332H20ZM35 19.9987C35 19.0782 34.2538 18.332 33.3333 18.332L6.66667 18.332C5.74619 18.332 5 19.0782 5 19.9987C5 20.9192 5.74619 21.6654 6.66667 21.6654L33.3333 21.6654C34.2538 21.6654 35 20.9192 35 19.9987Z"
            fill= "#031F30"
        />
    </svg>
);

export default ReportIcon;
