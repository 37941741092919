import React from "react";

const Database = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 24C33.1127 24 40.5 19.9706 40.5 15C40.5 10.0294 33.1127 6 24 6C14.8873 6 7.5 10.0294 7.5 15C7.5 19.9706 14.8873 24 24 24Z"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 15V24C7.5 28.9706 14.8873 33 24 33C33.1127 33 40.5 28.9706 40.5 24V15"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 24V33C7.5 37.9706 14.8873 42 24 42C33.1127 42 40.5 37.9706 40.5 33V24"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Database;
