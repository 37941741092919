import React from 'react';
import { Colors } from '../../utilities/common-styles';

const CallUsIcon = () => (
    <svg
        width= "18"
        height= "18"
        viewBox= "0 0 18 18"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M16.4997 13.0257L16.4995 13.0412L16.4995 15.4875L16.4995 15.4901C16.5001 15.63 16.4714 15.7684 16.4152 15.8967C16.359 16.0249 16.2765 16.1402 16.1731 16.2349C16.0696 16.3297 15.9473 16.4019 15.8141 16.4469C15.6824 16.4913 15.543 16.5081 15.4045 16.4964C12.9895 16.2335 10.6699 15.4094 8.63182 14.0903L8.63184 14.0903L8.62696 14.0872C6.73026 12.8844 5.12229 11.2795 3.91717 9.38679L3.91719 9.38677L3.9142 9.38217C2.58787 7.33883 1.76214 5.01271 1.5037 2.592C1.49186 2.45453 1.50851 2.31607 1.55266 2.18523C1.59728 2.05301 1.66903 1.93143 1.7634 1.82825C1.85778 1.72507 1.97272 1.64255 2.10095 1.58602C2.22912 1.52952 2.36772 1.50024 2.50791 1.50008H4.95869V1.50011L4.96483 1.50005C5.21004 1.49764 5.44759 1.58432 5.6332 1.74363C5.81815 1.90237 5.93894 2.12237 5.9736 2.36275C6.08334 3.19131 6.28653 4.00484 6.57929 4.78785L6.57982 4.78926C6.64756 4.9691 6.66221 5.1645 6.62207 5.35235C6.58203 5.53971 6.48912 5.7119 6.35413 5.84854L5.83671 6.36493C5.63727 6.56398 5.59568 6.87188 5.73517 7.11671C6.95403 9.25602 8.72877 11.0272 10.872 12.2434C11.1163 12.3821 11.4232 12.3407 11.622 12.1423L12.1383 11.6269C12.1387 11.6266 12.1391 11.6262 12.1395 11.6258C12.2764 11.4911 12.4492 11.3981 12.6376 11.358C12.8265 11.3178 13.023 11.3325 13.2037 11.4003L13.2052 11.4008C13.9896 11.693 14.8045 11.8957 15.6345 12.0052C15.8789 12.0402 16.1019 12.1633 16.2612 12.351C16.421 12.5393 16.5058 12.7794 16.4997 13.0257Z"
            stroke={Colors.text}
            strokeWidth= "1.25"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default CallUsIcon;
