import React from 'react';
import { SolidButton } from '../../utilities/buttons';
import { TileWrapper } from './styled';
interface ImageTileProps {
    label?: string;
    title: string;
    description?: string;
    buttonText: string;
    buttonAction(): void;
}

const ImageTile = ({
    label,
    title,
    description,
    buttonAction,
    buttonText
}: ImageTileProps) => {
    return (
        <TileWrapper>
            <h3>
                <span data-cy= "essential-report-order-label">{label}</span>
                {title}
            </h3>
            <p data-cy= "essential-report-order-description">{description}</p>
            <SolidButton
                data-cy= "essential-report-order-next-button"
                onClick={buttonAction}
            >
                {buttonText}
            </SolidButton>
        </TileWrapper>
    );
};

export default ImageTile;
