import React from 'react';

const Question7 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <rect
            x= "5"
            y= "6.5"
            width= "22"
            height= "20.5"
            rx= "1"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinejoin= "round"
        />
        <line
            x1= "5.5"
            y1= "13.5"
            x2= "26.5"
            y2= "13.5"
            stroke= "#0E283D"
            strokeWidth= "2"
        />
        <line
            x1= "11"
            y1= "5"
            x2= "11"
            y2= "9"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
        />
        <line
            x1= "20.75"
            y1= "5"
            x2= "20.75"
            y2= "9"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
        />
    </svg>
);

export default Question7;
