import { useQuery } from "@apollo/client";
import { OBJECT_INSIGHTS } from "../../queries/objects";
import React, { useEffect } from "react";
import { getCardData, getGraphData } from "../../utilities/insightsUtils";
import { getOptionalQuestionsPath } from "../../utilities/urlUtils";
import { InsightsContainer, InsightsWrapper } from "../styled";
import CostCard from "./Cards/CostCard";
import FrequencyChartCard from "./Cards/FrequencyChartCard";
import InsightsHeader from "./InsightsHeader";
import useQuestionnaireUpdate from "../../hooks/useQuestionnaireUpdate";

const Insights = ({ t, objectProperties }) => {
  const variables = {
    objectNo: objectProperties?.objectId,
    placeType: objectProperties?.placeType,
    accessVerb: "riskobject.view",
    country: objectProperties?.country,
  };
  const { data, loading, refetch, error } = useQuery(OBJECT_INSIGHTS, {
    variables,
  });
  const { loading: loadingQuestions, error: errorLoadingQuestions } = useQuestionnaireUpdate(objectProperties);

  useEffect(() => {
    if (!loadingQuestions && !errorLoadingQuestions) {
      refetch(variables);
    }
  }, [loadingQuestions]);

  const url = getOptionalQuestionsPath(objectProperties?.country, objectProperties?.objectId, objectProperties?.placeType);

  const constructionTheftFrequencyInsights = error
    ? null
    : data?.object?.placeInsights.find((placeInsight) => placeInsight.name === "insights/CONSTRUCTION_FREQUENCY_OF_THEFT");
  const constructionAverageCostInsights = error
    ? null
    : data?.object?.placeInsights.find((placeInsight) => placeInsight.name === "insights/CONSTRUCTION_AVERAGE_COST_OF_THEFT");

  return (
    <InsightsContainer data-cy="insights-container">
      <InsightsHeader t={t} url={url} data-cy="insights-section-header" />
      {!loading && (
        <InsightsWrapper data-cy="insights-section">
          <FrequencyChartCard t={t} placeInsight={getGraphData(constructionTheftFrequencyInsights, t)} url={url} data-cy="frequency-chart-card" />
          <CostCard t={t} placeInsight={getCardData(constructionAverageCostInsights, t)} url={url} data-cy="cost-card" />
        </InsightsWrapper>
      )}
    </InsightsContainer>
  );
};

export default Insights;
