import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useStore } from "react-context-hook";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { BackLinkheader, PageWrapper } from "../../utilities/wrappers";
import MySecuritasLogo from "../icons/MySecuritasIcon";
import ActionWrapper from "./../Common/ActionWrapper/ActionWrapper";
import BackLinkComponent from "./../Common/ActionWrapper/BackLinkComponent";
import { Contact, Header } from "./../Common/styled";
import { BaldrHeaderWrapper } from "./styled";

const { HeaderContent } = Header;

const BaldrHeader = () => {
  const [userData, _setUserData] = useStore("userData", null);
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();

  const isList = location.pathname === "/essential" || location.pathname === "/";

  return (
    <BaldrHeaderWrapper withBorder isEmbedded={userData?.isEmbedded} preventBack={isList}>
      {!userData?.isEmbedded && (
        <PageWrapper>
          <HeaderContent center>
            <MySecuritasLogo color={"white"} />
            <Contact>
              {isList && (
                <div data-cy="essential-list-logout" onClick={() => logout({ returnTo: window.location.origin })}>
                  {t("common.logout")}
                </div>
              )}
            </Contact>
          </HeaderContent>
        </PageWrapper>
      )}
      {!isList && (
        <BackLinkheader>
          <BackLinkComponent title={t("common.back")}></BackLinkComponent>
        </BackLinkheader>
      )}
      <ActionWrapper preventBack={isList} />
    </BaldrHeaderWrapper>
  );
};

export default BaldrHeader;
