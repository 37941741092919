import styled from 'styled-components';
import { Devices } from '../../../utilities/common-styles';

const Contact = styled.div`
    display: flex;
    padding: 20px 0;
    align-items: center;

    @media ${Devices.mobile} {
        padding: 0;
        width: 100%;
        justify-content: flex-end;

        div {
            margin: 0 4px;
        }
    }
`;

export default Contact;
