import { Text, InteractiveText } from "../../../utilities/typography";
import { Colors } from "../../../utilities/common-styles";
import { addDays, startOfWeek } from "date-fns";

import React, { useState } from "react";
import { getLocal } from "../../../utilities/generalSettings";
import { useTranslation } from "react-i18next";
import { Views } from "../types";
import RisksLegend from "./RisksLegend";
import { HourlyGraphWrapper, RiskDistributionContainer, SwitchWrapper } from "./styled";
import WeeklyGraph from "./WeeklyGraph";

const WeeklyGraphWrapper = ({ risksTypes, weeklyRisks, text, setActiveView }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const { i18n } = useTranslation();

  const formatHours = (hour: number) => {
    if (hour % 12 === 0 && hour % 24 !== 0) {
      return addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), Math.floor(hour / 24)).toLocaleString(getLocal(i18n.language).code, {
        weekday: "narrow",
      });
    }
  };

  const selectRisksData = () => {
    const riskByCategory = weeklyRisks[risksTypes[activeIndex]];
    if (riskByCategory) {
      return riskByCategory;
    }

    return null;
  };

  return (
    <HourlyGraphWrapper>
      <RisksLegend activeIndex={activeIndex} setActiveIndex={setActiveIndex} risksTypes={risksTypes} />
      <Text
        size="16px"
        lineHeight="24px"
        mobileFontSize="14px"
        mobileLineHeight="18px"
        weight="400"
        margin="20px 0 20px 0"
        color={Colors.white}
        data-cy="risk-distribution-text"
      >
        {t("reportObject.graph.subdescription")}
      </Text>
      <RiskDistributionContainer data-cy="essential-report-risk-distribution-graph">
        {selectRisksData() && (
          <WeeklyGraph xTicks={[...Array(selectRisksData()?.length).keys()]} xTickFormater={formatHours} data={selectRisksData()} labelSize={"12px"} />
        )}
      </RiskDistributionContainer>
      <SwitchWrapper>
        <InteractiveText
          size="14px"
          lineHeight="21px"
          mobileFontSize="12px"
          mobileLineHeight="18px"
          weight="700"
          margin="0 0 0 5px"
          color={Colors.primaryPurple}
          onClick={() => setActiveView(Views.DayGraph)}
          data-cy="graph-week-view"
        >
          {text}
        </InteractiveText>
      </SwitchWrapper>
    </HourlyGraphWrapper>
  );
};

export default WeeklyGraphWrapper;
