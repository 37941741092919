import React from "react";
import SecuritasLogo from "../icons/SecuritasLogo";
import { FooterWrapper } from "./styled";

const FooterLogo = () => {
  return (
    <FooterWrapper>
      <SecuritasLogo />
    </FooterWrapper>
  );
};

export default FooterLogo;
