import { createContext, useContext } from 'react';

type SucceedModalActionsContextType = {
    setEssentialId: (essentialId?: string) => void;
    toggleModal: () => void;
};

export const SucceedModalActionsContext = createContext<
    SucceedModalActionsContextType
>({
    setEssentialId: () => {},
    toggleModal: () => {}
});

export const useModalActions = () => useContext(SucceedModalActionsContext);
