import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import { addDays, addMonths, formatISO } from "date-fns";

import {
  NON_OBJECT_EVENTS_PAGINATED,
  NON_OBJECT_INCIDENTS_COUNT_BY_MONTH,
  NON_OBJECT_TWO_WEEKS_OBJECT_EVENTS,
  NON_OBJECT_YEARLY_EVENTS_COMPARISON,
} from "./../../queries/nonObjects";

import React from "react";
import { useQuery } from "@apollo/client";
import { getStartOfDayUTC } from "../../utilities/dateUtils";
import { useTranslation } from "react-i18next";
import ComparisonGraph from "./ComparisonGraph";
import IncidentsList from "./IncidentsList";
import { ComparisonWrapper, ContentWrapper, GraphHeader, MapWrapper } from "./styled";
import TwoWeeksComparison from "./TwoWeeksComparison";
import YearlyComparison from "./YearlyComparison";
import WrappedLoader from "../WrappedLoader";
import { IQuestionnairePayload } from "../../models/QuestionnairePayload";

type Props = {
  objectProperties: IQuestionnairePayload;
};

const NonObjectsNearbyIncidents = ({ objectProperties }: Props) => {
  const { objectId, country, latitude, longitude, placeType } = objectProperties;

  const { t } = useTranslation();

  const today = getStartOfDayUTC();

  const past12months = addMonths(today, -12);
  const past2weeks = addDays(today, -13);

  const { data: monthlyIncidents, loading: graphDataLoading } = useQuery(NON_OBJECT_INCIDENTS_COUNT_BY_MONTH, {
    variables: {
      latitude,
      longitude,
      nonObjectId: objectId,
      country,
    },
  });
  const {
    data: eventData,
    fetchMore,
    loading,
  } = useQuery(NON_OBJECT_EVENTS_PAGINATED, {
    variables: {
      latitude,
      longitude,
      nonObjectId: objectId,
      pageNumber: 1,
      pageSize: 20,
      country,
    },
  });

  const graphData = monthlyIncidents?.nonObject?.incidentsCountByMonth?.monthlyNearbyEventCounts;

  const { data: yearlyEventsData, loading: yearlyEventsDataLoading } = useQuery(NON_OBJECT_YEARLY_EVENTS_COMPARISON, {
    variables: {
      latitude,
      longitude,
      nonObjectId: objectId,
      endDate: formatISO(today),
      startDate: formatISO(past12months),
      country,
    },
  });

  const { data: twoWeeksEvents, loading: twoWeeksLoading } = useQuery(NON_OBJECT_TWO_WEEKS_OBJECT_EVENTS, {
    variables: {
      latitude,
      longitude,
      nonObjectId: objectId,
      endDate: formatISO(addDays(today, 1)),
      startDate: formatISO(past2weeks),
      country,
    },
  });

  return (
    <MapWrapper>
      <Text data-cy="sharing-info" size="24px" mobileFontSize="21px" lineHeight="36px" weight="700" color={Colors.white} textAlign="left" margin="0">
        {t("segmentRisk.nearbyTitle")}
      </Text>
      <Text
        data-cy="sharing-info"
        size="16px"
        mobileFontSize="12px"
        lineHeight="24px"
        color={Colors.white}
        textAlign="left"
        margin="0 0 24px 0 "
        maxWidth="800px"
      >
        {t("segmentRisk.nearbyDescription")}
      </Text>
      <ComparisonWrapper>
        <div>
          <Text
            data-cy="sharing-info"
            size="12px"
            mobileFontSize="11px"
            weight="700"
            color={Colors.borderDarkMode}
            textAlign="left"
            margin="0 0 16px 0"
            uppercase
          >
            {t("segmentRisk.twoWeeksEventsTitle")}
          </Text>
          {twoWeeksLoading && <WrappedLoader />}
          {twoWeeksEvents && twoWeeksEvents?.nonObject?.incidentsList && !twoWeeksLoading && (
            <TwoWeeksComparison t={t} last2weeksIncidents={twoWeeksEvents?.nonObject?.incidentsList}></TwoWeeksComparison>
          )}
        </div>
        <div>
          <GraphHeader>
            <Text
              data-cy="sharing-info"
              size="12px"
              mobileFontSize="11px"
              weight="700"
              color={Colors.borderDarkMode}
              textAlign="left"
              margin="0 0 16px 0"
              uppercase
            >
              {t("segmentRisk.eventGraphTitle")}
            </Text>
          </GraphHeader>
          {graphDataLoading && <WrappedLoader />}
          {graphData?.length > 0 && graphData?.length === 12 && !graphDataLoading && <ComparisonGraph t={t} graphData={graphData}></ComparisonGraph>}
        </div>
      </ComparisonWrapper>

      {yearlyEventsDataLoading && <WrappedLoader />}
      {yearlyEventsData && !yearlyEventsDataLoading && <YearlyComparison t={t} yearlyEventsData={yearlyEventsData} placeType={placeType} />}

      <ContentWrapper>
        {loading && <WrappedLoader />}
        {eventData && !loading && <IncidentsList eventData={eventData} fetchMore={fetchMore} objectProperties={objectProperties} />}
      </ContentWrapper>
    </MapWrapper>
  );
};

export default NonObjectsNearbyIncidents;
