import styled from 'styled-components';

export const StyledText = styled.div<{
    fontSize?: 'small' | 'medium' | 'large' | 'x-large' | string;
    mobileFontSize?: string;
    mobileLineHeight?: string;
    bold?: boolean;
    margin?: string;
    textAlign?: string;
    uppercase?: boolean;
    color?: string;
    weight?: string;
    maxWidth?: string;
    fontStyle?: string;
    lineHeight?: string;
}>`
    font-size: ${props => props.fontSize || 'regular'};
    font-weight: ${props => props.weight || (props.bold ? 'bold' : 'normal')};
    margin: ${props => props.margin || 'auto'};
    text-align: ${props => props.textAlign || 'unset'};
    text-transform: ${props => (props.uppercase ? 'uppercase' : 'normal')};
    color: ${props => props.color || 'inherit'};
    max-width: ${props => props.maxWidth || ''};
    font-style: ${props => props.fontStyle || 'normal'};
    line-height: ${props => props.lineHeight || ''};
`;
