import React from 'react';
import { useTranslation } from 'react-i18next';


import { CheckListComponent } from './CheckListComponent';
import { ObjectPropertiesContext } from '../Common';
import useCheckListData from '../../hooks/useCheckListData';
import WrappedLoader from '../WrappedLoader';
import GraphQlErrorView from '../Common/GraphQlErrorView';
const { useObjectProperties } = ObjectPropertiesContext;

export const CheckListWrapper = () => {
    const { t } = useTranslation();
    const { objectProperties } = useObjectProperties();

    const {
        checkedList,
        singleUpdate,
        loading,
        error,
        data,
        updateValue
    } = useCheckListData(objectProperties?.objectId, objectProperties?.country);

    if (loading) return <WrappedLoader />;

    if (!!error) {
        return (
            <GraphQlErrorView
                errors={[error]}
                messages={{
                    errorTitle: t('common:error'),
                    genericMessage: t('common:noDataError'),
                    noPermission: t('common:no-permissions')
                }}
            />
        );
    }

    return (
        <CheckListComponent
            checkedList={checkedList}
            singleUpdate={singleUpdate}
            data={data}
            updateValue={updateValue}
        ></CheckListComponent>
    );
};
