import { useQuery } from '@apollo/client';

import { GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES } from './../queries/placeProperties';
import { getPlaceNamespace, PlaceTypes } from './../utilities/gqlUtilities';
import { NonObjectType } from '../utilities/types';
import { calculateStatusFromPlaceProperties } from './useReports';

const useNewPlaceProperties = ({
    country,
    latitude,
    longitude,
    id,
    type,
    status
}): {
    recalculatedStatus: number;
    statusLoading: boolean;
} => {
   const { data, loading: statusLoading } = useQuery(
    GET_VIRTUAL_OBJECT_ALL_PLACE_PROPERTIES,
    {
      skip: type !== NonObjectType,
      variables: {
        placeNamespace: getPlaceNamespace(country),
        placeType: PlaceTypes.NewLocationPlaceType,
        latitude,
        longitude,
        country: country,
        nonObjectId: id,
      },
    }
  );

  if (type !== NonObjectType) {
    return {
      recalculatedStatus: status,
      statusLoading: false,
    };
  }

  return {
    recalculatedStatus: calculateStatusFromPlaceProperties(
      data?.nonObject?.allPlaceProperties
    ),
    statusLoading,
  };
};

export default useNewPlaceProperties;