import React from "react";
import { Text } from "./../../utilities/typography";
import { ContentWrapper, MailAddress } from "./styled";
import { getSecuritasEmail } from "./../../utilities/securitasMailUtil";
import { ObjectPropertiesContext } from "../Common";

const { useObjectProperties } = ObjectPropertiesContext;

const ContactSection = () => {
  const { objectProperties } = useObjectProperties();
  const securitasEmail = getSecuritasEmail(objectProperties?.country);

  const emailClickHandler = () => {
    window.location.href = `mailto:${securitasEmail}`;
  };

  return (
    <ContentWrapper>
      <Text size="large" bold margin="0 0 10px 0">
        Contact Securitas
      </Text>
      <Text size="medium">
        We continue to learn more about all businesses to be able to support more segments in the future. Please contact Securitas at{" "}
        <MailAddress onClick={emailClickHandler}>{securitasEmail}</MailAddress> if you want to select another segment for the object or correct the address.
      </Text>
    </ContentWrapper>
  );
};

export default ContactSection;
