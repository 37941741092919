import { RISK_COLORS } from '../../utilities/common-styles';
import React from 'react';
import { OverallRiskBar, RiskLevels } from './styled';

const MapRiskLevel = ({ risk, emptyRiskColor }) => {
    return (
        <RiskLevels>
            {RISK_COLORS.map((color, index) => {
                const backgroundColor = index < risk ? color : emptyRiskColor;
                return (
                    <OverallRiskBar
                        data-cy= "essential-report-geographical-risk-overall-bar"
                        key={`${index}`}
                        backgroundColor={backgroundColor}
                    ></OverallRiskBar>
                );
            })}
        </RiskLevels>
    );
};

export default MapRiskLevel;
