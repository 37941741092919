import React from 'react';
import styled from 'styled-components';

interface HorizontalArrowIconProps {
    flip?: boolean;
    small?: boolean;
    fill?: string;
    onClick?: () => void;
}

const IconCon = styled.span<HorizontalArrowIconProps>`
    path {
        transform: ${props => (props.flip ? 'rotate(0deg)' : 'rotate(180deg)')};
        transform-origin: 50% 50%;
        transition: fill 0.3s ease;
        cursor: pointer;
        fill: ${props => props.fill || props.theme.black45};
    }
`;

const HorizontalArrowIcon = (props: HorizontalArrowIconProps) => {
    return (
        <IconCon data-cy= "horizontal-arrow" flip={props.flip} fill={props.fill}>
            <svg
                width={props.small ? 7 : 9}
                height={props.small ? 12 : 16}
                viewBox= "0 0 9 16"
                fill={props.fill || '#031F30'}
                xmlns= "http://www.w3.org/2000/svg"
            >
                <path
                    className= "arrow-icon"
                    d= "M8.6 13.548L3.297 7.993 8.6 2.438c.256-.268.4-.631.4-1.01S8.856.686 8.6.418a1.318 1.318 0 0 0-1.927 0L.4 6.992a1.474 1.474 0 0 0 0 2.018l6.273 6.572a1.318 1.318 0 0 0 1.927 0c.52-.558.533-1.474 0-2.033z"
                />
            </svg>
        </IconCon>
    );
};

export default HorizontalArrowIcon;
