
import { ApolloError } from 'apollo-client';

export const getPlaceNamespace = country => `GRS${country?.toUpperCase()}`;
export const PlaceTypes = {
    ObjectPlaceType: 'custobj',
    NewLocationPlaceType: 'newloc'
} as const;
const extractNumber = (message: string) => {
    const matches = /(\d+)/.exec(message);
    if (matches) {
        return matches[0];
    }
    return '-/-';
};

export const parseGqlError = (gqlError: ApolloError, t): string => {
    if (/invalidargument/gi.test(gqlError.message)) {
        if (/only contain numbers/gi.test(gqlError.message)) {
            const maxNumLength = extractNumber(gqlError.message);
            return t('common:errors.maximum-length-exceeded', {
                count: maxNumLength
            });
        }
        return t('common:errors.invalid-argument');
    }
    return t('common:errors.server-error');
};
export const parseGqlErrors = (gqlErrors: ApolloError[], t): string[] =>
    (gqlErrors || []).map(gqlError => parseGqlError(gqlError, t));
