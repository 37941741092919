import FaqIcon from "./../icons/FaqIcon";
import PlusIcon from "./../icons/PlusIcon";
import React, { useState } from "react";
import AnimateHeight, { Height } from "react-animate-height";
import { FAQSectionContent, FAQSectionTitle, FAQSectionWrapper, TitleSection } from "./styled";

const FAQSection = ({ title, children, testKey }: { title: string; children?: React.ReactNode; testKey?: string }) => {
  const [height, setHeight] = useState<Height>(0);

  return (
    <FAQSectionWrapper data-cy={`FAQSectionWrapper-${testKey}`} active={height !== 0}>
      <FAQSectionTitle data-cy={`FAQSectionTitle-${testKey}`} onClick={() => setHeight(height === 0 ? "auto" : 0)}>
        <TitleSection>
          <FaqIcon></FaqIcon>
          <div data-cy={`faq-question-title-${testKey}`}> {title} </div>
        </TitleSection>
        <PlusIcon flip={height !== 0} />
      </FAQSectionTitle>
      <AnimateHeight duration={500} height={height}>
        <FAQSectionContent data-cy={`FAQSectionContent-${testKey}`}>{children}</FAQSectionContent>
      </AnimateHeight>
    </FAQSectionWrapper>
  );
};

export default FAQSection;
