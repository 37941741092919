import React from 'react';

const AddReport = () => (
    <svg
        width= "24"
        height= "24"
        viewBox= "0 0 24 24"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
            stroke= "white"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M14 2V8H20"
            stroke= "white"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M16 13H8"
            stroke= "white"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M16 17H8"
            stroke= "white"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M10 9H9H8"
            stroke= "white"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <circle
            cx= "15.5"
            cy= "8.5"
            r= "7.5"
            fill= "white"
            stroke= "#8D5FFF"
            strokeWidth= "2"
        />
        <path
            fillRule= "evenodd"
            clipRule= "evenodd"
            d= "M15.5 4.25C14.9132 4.25 14.4375 4.7257 14.4375 5.3125V7.4375H12.3125C11.7257 7.4375 11.25 7.9132 11.25 8.5C11.25 9.0868 11.7257 9.5625 12.3125 9.5625H14.4375V11.6875C14.4375 12.2743 14.9132 12.75 15.5 12.75C16.0868 12.75 16.5625 12.2743 16.5625 11.6875V9.5625H18.6875C19.2743 9.5625 19.75 9.0868 19.75 8.5C19.75 7.9132 19.2743 7.4375 18.6875 7.4375H16.5625V5.3125C16.5625 4.7257 16.0868 4.25 15.5 4.25Z"
            fill= "#8D5FFF"
        />
    </svg>
);

export default AddReport;
