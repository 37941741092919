import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { SET_LAST_NEARBY_EVENT_DATE } from "../../queries/objects";
import { Colors } from "../../utilities/common-styles";
import { PlaceTypes } from "../../utilities/gqlUtilities";
import { NearbyEventsSectionId, scrollToSection } from "../../utilities/scrollUtils";
import { Text } from "../../utilities/typography";
import LoadingIndicator from "./../Common/LoadingIndicator";
import BellIcon from "./../icons/Bell";
import EventsFilter from "./EventsFilter";
import IncidentsListItem from "./IncidentsListItem";
import { BellIconWrapper, DateRow, EmptyIncidentsListWrapper, EventsHeader, IncidentsListContainer, IncidentsListPadding, RowElement } from "./styled";

const IncidentsList = ({ eventData, fetchMore, objectProperties }) => {
  const { category } = useParams();

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const { t } = useTranslation();
  const [showAllIncidents, setShowAllIncidents] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [currentIncidents, setCurrentIncidents] = useState(list);
  const { placeType, country, objectId } = objectProperties;
  const [updateValue] = useMutation(SET_LAST_NEARBY_EVENT_DATE, {});

  const variables = {
    customerObjNo: objectId,
    country,
  };

  const getIncidents = (eventData) => {
    const dataPath = placeType === PlaceTypes.NewLocationPlaceType ? eventData?.nonObject?.incidentsListPaginated : eventData?.object?.incidentsListPaginated;
    if (dataPath) {
      return dataPath
        .filter((elem) => elem.isNotableEvent)
        .map((event) => {
          return {
            date: new Date(event.ts),
            eventCategories: event.eventCategories,
            isNearby: event.isNearby,
            hadSeen: event.hadSeen,
          };
        });
    }
    return [];
  };
  const areAllEventsLoaded = (events) => events.length === 0 || events.length < 20;

  const updateIncidents = () => {
    showAllIncidents ? setCurrentIncidents(list) : setCurrentIncidents(list.filter((elem) => !elem.isNearby));
  };

  useEffect(() => {
    const sectionId = category;

    if (sectionId === NearbyEventsSectionId) {
      scrollToSection(NearbyEventsSectionId);
    }
  }, []);

  useEffect(() => {
    const filteredEvents = showAllIncidents ? list : list.filter((elem) => !elem.isNearby);
    updateIncidents();
    setShowFilter(false);

    setHasMore(!areAllEventsLoaded(filteredEvents));
  }, [showAllIncidents]);

  useEffect(() => {
    updateIncidents();
  }, [list]);

  useEffect(() => {
    const incidents = getIncidents(eventData);

    setList(incidents);

    updateValue({ variables });

    const filteredEvents = showAllIncidents ? incidents : incidents.filter((elem) => !elem.isNearby);

    setHasMore(!areAllEventsLoaded(filteredEvents));
  }, [eventData]);

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        pageNumber: page + 1,
      },
      updateQuery: (_previousResult, { fetchMoreResult }) => {
        const fetchedData = getIncidents(fetchMoreResult);

        setList(list.concat(fetchedData));

        if (areAllEventsLoaded(fetchedData)) {
          setHasMore(false);
        }
      },
    });

    setPage(page + 1);
  };

  return (
    <IncidentsListPadding id={NearbyEventsSectionId}>
      {list.length > 0 && placeType !== PlaceTypes.NewLocationPlaceType && (
        <EventsFilter showFilter={showFilter} setShowFilter={setShowFilter} showAllIncidents={showAllIncidents} setShowAllIncidents={setShowAllIncidents} />
      )}
      <EventsHeader>
        <Text
          data-cy="list-of-notable-events-header"
          size="12px"
          mobileFontSize="11px"
          weight="700"
          color={Colors.borderDarkMode}
          textAlign="left"
          margin="0 0 16px 0"
          uppercase
        >
          {t("segmentRisk.eventsTitle")}
        </Text>
        <DateRow>
          <RowElement>
            <Text data-cy="sharing-info" size="14px" mobileFontSize="11px" lineHeight="18px" color={Colors.borderDarkMode} textAlign="left" margin="0 5px 0 0 ">
              {t("segmentRisk.number")}:
            </Text>
            <Text data-cy="sharing-info" size="14px" mobileFontSize="11px" color={Colors.white} textAlign="left" margin="0">
              {list.length}
            </Text>
          </RowElement>
        </DateRow>
      </EventsHeader>

      <IncidentsListContainer id="scrollableDiv">
        <InfiniteScroll
          dataLength={list?.length || 0}
          next={() => fetchMoreData()}
          hasMore={hasMore}
          loader={<LoadingIndicator marginTop="0" />}
          scrollableTarget="scrollableDiv"
        >
          {currentIncidents.length > 0 ? (
            <>
              {currentIncidents.map((event) => (
                <IncidentsListItem key={event.date.getTime()} event={event} />
              ))}
            </>
          ) : (
            <EmptyIncidentsListWrapper>
              <BellIconWrapper>
                <BellIcon />
              </BellIconWrapper>
              <Text data-cy="no-events-info" size="14px" mobileFontSize="12px" weight="400" color={Colors.borderDarkMode} margin="0">
                {t("reportObject.geoRisk.place.noEvents")}
              </Text>
            </EmptyIncidentsListWrapper>
          )}
        </InfiniteScroll>
      </IncidentsListContainer>
    </IncidentsListPadding>
  );
};

export default IncidentsList;
