import React from 'react';

const Question9 = () => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M10 25C12.2091 25 14 23.2091 14 21C14 18.7909 12.2091 17 10 17C7.79086 17 6 18.7909 6 21C6 23.2091 7.79086 25 10 25Z"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M4 28C4.6986 27.0685 5.60448 26.3125 6.6459 25.7918C7.68731 25.2711 8.83566 25 10 25C11.1643 25 12.3127 25.2711 13.3541 25.7918C14.3955 26.3125 15.3014 27.0685 16 28"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M4 15C4.6986 14.0685 5.60448 13.3125 6.6459 12.7918C7.68731 12.2711 8.83566 12 10 12C11.1643 12 12.3127 12.2711 13.3541 12.7918C14.3955 13.3125 15.3014 14.0685 16 15"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M22 25C24.2091 25 26 23.2091 26 21C26 18.7909 24.2091 17 22 17C19.7909 17 18 18.7909 18 21C18 23.2091 19.7909 25 22 25Z"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M16 28C16.6986 27.0685 17.6045 26.3125 18.6459 25.7918C19.6873 25.2711 20.8357 25 22 25C23.1643 25 24.3127 25.2711 25.3541 25.7918C26.3955 26.3125 27.3014 27.0685 28 28"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M22 12C24.2091 12 26 10.2091 26 8C26 5.79086 24.2091 4 22 4C19.7909 4 18 5.79086 18 8C18 10.2091 19.7909 12 22 12Z"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M16 15C16.6986 14.0685 17.6045 13.3125 18.6459 12.7918C19.6873 12.2711 20.8357 12 22 12C23.1643 12 24.3127 12.2711 25.3541 12.7918C26.3955 13.3125 27.3014 14.0685 28 15"
            stroke= "#0E283D"
            strokeWidth= "2"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default Question9;
