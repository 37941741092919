import React from "react";
import { useStore } from "react-context-hook";
import { ReportOrderConfirmation } from "../ReportOrderConfirmation";
import { SelectLocation } from "../SelectLocation";
import SelectName from "../SelectName/SelectName";
import SelectOrderType from "./SelectOrderType";

const OrderContainer = ({ isInternalUser, hasOrderReportAccess }) => {
  const [index, _setIndex] = useStore("index", 0);

  return (
    <>
      {index === 0 && <SelectOrderType isInternalUser={isInternalUser} />}
      {index === 1 && <SelectLocation />}
      {index === 2 && <SelectName isInternalUser={isInternalUser} />}
      {index === 3 && <ReportOrderConfirmation isInternalUser={isInternalUser} createOrderAccess={hasOrderReportAccess} />}
    </>
  );
};

export default OrderContainer;
