import { corporateAppName, mobileAppName, platformAppName } from "./generalSettings";
import { HomeUrl } from "./../constants/urls";
import { defaultCountry } from "./../utilities/generalSettings";
import { NearbyEventsSectionId } from "./scrollUtils";

export const getCountry = (query) => (typeof query.country === "string" ? query.country.toLowerCase() : defaultCountry);

export const getObjectNo = (query) => (typeof query.oid === "string" ? query.oid : query.oid[0]);

export const getNonObjectNo = (query) => (typeof query.id === "string" ? query.id : query.id[0]);

export const getPlaceProperties = (query) => (typeof query.placeProperties == "object" ? query.placeProperties : [query.placeProperties]);

export const getLocation = (query) =>
  typeof query.location === "string"
    ? (query.location as string)?.split(",").map(Number.parseFloat) || [0, 0]
    : (query.location || [0, 0]).map((loc) => Number.parseFloat(loc));

export const getIsEmbedded = (a) => a === platformAppName || a === mobileAppName;
export const getIsEmbeddedInMobile = (a) => a === mobileAppName;
export const getIsEmbeddedInCorporate = (a) => a === corporateAppName;
export const getNotificationQuery = (query) => (typeof query.c === "string" ? query.c : query.c[0]);

export const getPlaceType = (query) => (typeof query.placetype === "string" ? query.placetype : query.placetype[0]);

export const getCategory = (query) => query?.category as string;

export const getObjectUrlFromNotification = (contextParams) => {
  return contextParams ? `${HomeUrl}/${contextParams.replace(/{CountryCode:(.*),ObjectNumber:(.*)}/g, `$1/$2/${NearbyEventsSectionId}`)}` : "";
};
