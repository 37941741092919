import { useMutation, useQuery } from '@apollo/client';
import { GET_CHECKLIST, UPDATE_CHECKLIST } from '../queries/checkList';

const useCheckListData = (objectNumber, country) => {
    const variables = {
        objectNo: objectNumber,
        country
    };
    const { data, loading, error } = useQuery(GET_CHECKLIST, {
        variables
    });

    const [updateValue] = useMutation(UPDATE_CHECKLIST, {
        refetchQueries: [
            {
                query: GET_CHECKLIST,
                variables
            }
        ]
    });

    const checkedList = data?.placeChecklist?.map(
        singleQuestion => singleQuestion.checked
    );
    const numberOfQuestions = data?.placeChecklist?.length;
    const singleUpdate = 100 / numberOfQuestions;

    return {
        checkedList,
        data: data?.placeChecklist,
        singleUpdate,
        loading,
        error,
        updateValue
    };
};
export default useCheckListData;
