import React from "react";

const DownloadPdfIcon = () => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 0C9.55228 0 10 0.447715 10 1L10 12.5858L12.2929 10.2929C12.6834 9.90237 13.3166 9.90237 13.7071 10.2929C14.0976 10.6834 14.0976 11.3166 13.7071 11.7071L9.70711 15.7071L9.69627 15.7178C9.51623 15.8925 9.27067 16 9 16C8.72713 16 8.47979 15.8907 8.29936 15.7135L8.29289 15.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L8 12.5858L8 1C8 0.447715 8.44772 0 9 0ZM2 16C2 15.4477 1.55228 15 1 15C0.447715 15 0 15.4477 0 16L0 20C0 20.5523 0.447715 21 1 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15C16.4477 15 16 15.4477 16 16V19L2 19L2 16Z"
      fill="white"
    />
  </svg>
);

export default DownloadPdfIcon;
