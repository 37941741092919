import React from "react";
import { ContentHeader, ContentDescription, ButtonsSection, ConfirmationWrapper, ReportName, Card } from "./styled";
import { BackButton } from "../../Order/styled";
import BackNavigationIcon from "../../icons/BackNavigationIcon";
import { Text } from "../../../utilities/typography";
import { Header } from "../../Common";
import { SolidButton } from "../../../utilities/buttons";
type Props = {
  headerTitle: string;
  headerDescription: string;
  contentTitle: string;
  contentDescription: string;
  reportInformation: string;
  reporDescription: string;
  reportName: string;
  locationDescription: string;
  location?: string[] | number[];
  cancelText: string;
  createText: string;
  createDisabled?: boolean;
  onCancelClick: () => void;
  onReportCreate: () => void;
};

const OrderConfirmationView = ({
  headerTitle,
  headerDescription,
  contentTitle,
  contentDescription,
  reportInformation,
  reporDescription,
  reportName,
  locationDescription,
  location,
  onCancelClick,
  onReportCreate,
  createDisabled,
  cancelText,
  createText,
}: Props) => (
  <ConfirmationWrapper data-cy="essential-order-confirmation">
    <Header title={headerTitle} description={headerDescription} />
    <Card>
      <ContentHeader>
        <Text data-cy="essential-order-header" size="2.4rem" mobileFontSize="1.8rem" bold margin="0">
          {contentTitle}
        </Text>
        <Text data-cy="essential-order-description" size="large" margin="0">
          {contentDescription}
        </Text>
      </ContentHeader>
      <ContentDescription>
        <Text data-cy="essential-order-reportinfo" size="medium" bold margin="0 0 16px 0">
          {reportInformation}
        </Text>
        <Text data-cy="essential-order-reportdesc" size="small" bold margin="0">
          {reporDescription}
        </Text>
        <ReportName>{reportName || "-"}</ReportName>
        <Text data-cy="essential-order-locationdesc" size="small" bold margin="0">
          {locationDescription}
        </Text>
        <Text data-cy="essential-order-location" size="medium" margin="0">
          {location ? `${location[0] || "-"}, ${location[1] || "-"}` : "-"}
        </Text>
      </ContentDescription>
      <ButtonsSection>
        <BackButton data-cy="essential-order-cancel-btn" dangerous onClick={onCancelClick}>
          <BackNavigationIcon></BackNavigationIcon>
          {cancelText}
        </BackButton>
        <SolidButton data-cy="essential-order-next-btn" disabled={createDisabled} onClick={onReportCreate}>
          {createText}
        </SolidButton>
      </ButtonsSection>
    </Card>
  </ConfirmationWrapper>
);

export default OrderConfirmationView;
