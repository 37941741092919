import React, { useState, useCallback, useContext, useRef } from "react";
import ReactMapGL, { Marker, NavigationControl, PointerEvent } from "react-map-gl";
import { NavigationControlWrapper, HiddenMap } from "./styled";
import ObjectPin from "../../icons/ObjectPin";
import Search from "../Search/SearchContainer";
import { LocationContext } from "../LocationContextProvider";
import { Location } from "../types";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../Common/Notifications";

const Map = () => {
  const mapRefNL = useRef<ReactMapGL>();
  const mapRefSE = useRef<ReactMapGL>();
  const mapRefNO = useRef<ReactMapGL>();
  const { selectedLocation, setLocation, getLocationDetails } = useContext(LocationContext);
  const [viewport, setViewport] = useState({
    latitude: 59.3293,
    longitude: 18.0686,
    zoom: 14,
    bearing: -2,
    pitch: 0,
  });
  const { t } = useTranslation();
  const notify = useNotifications();

  const isRisky = (ref, lngLat) => {
    const map = ref.current.getMap();
    const features = map.queryRenderedFeatures(lngLat);
    return (features || []).some((feature) => feature.layer.id.includes("srds"));
  };

  const handleMapClick = useCallback(async (event: PointerEvent) => {
    const { lngLat } = event;
    const isWaterLayer = (event.features || []).some((feature) => feature.sourceLayer === "water");
    const isRiskLayerMainMap = (event.features || []).some((feature) => feature.layer.id.includes("srds"));

    const isRiskLayer = isRiskLayerMainMap || isRisky(mapRefSE, lngLat) || isRisky(mapRefNO, lngLat);

    const isMap = event.target && event.target.className === "overlays";

    const zoom = mapRefNL.current.getMap().getZoom();
    if (isMap && !isRiskLayer && !isWaterLayer && zoom < 7) {
      notify({
        description: t("zoomIn"),
      });
    } else if (isMap && isRiskLayer && !isWaterLayer) {
      const details = await getLocationDetails(lngLat);
      setLocation(details);
    } else if (isMap && !isWaterLayer) {
      notify({
        description: t("notSupportedLocation"),
      });
    }
  }, []);

  const handleResultSelect = (location: Location) => {
    const map = mapRefNL.current.getMap();

    map.once("idle", () => {
      const point = map.project(location.center);

      const features = map.queryRenderedFeatures(point);

      const isRiskLayerMainMap = (features || []).some((feature) => feature.layer.id.includes("srds"));

      const isRiskLayer = isRiskLayerMainMap || isRisky(mapRefSE, location.center) || isRisky(mapRefNO, location.center);

      if (!isRiskLayer) {
        setLocation(undefined);
        notify({
          description: t("supportedLocationNotFound"),
        });
      } else {
        setLocation(location);
      }

      setViewport((vp) => ({
        ...vp,
        longitude: mapRefNL.current.getMap().getCenter().lng,
        latitude: mapRefNL.current.getMap().getCenter().lat,
        zoom: mapRefNL.current.getMap().getZoom(),
      }));
    });

    map.flyTo({
      center: location.center,
      speed: 2,
      curve: 0.5,
      essential: true,
      zoom: 14,
    });
  };

  const handleMapLoad = () => {
    const map = mapRefNL.current.getMap();

    if (map && map.loaded()) {
      map.setPaintProperty("risk_area", "fill-opacity", 1);
      map.setLayoutProperty("risk_area", "visibility", "visible");
    }
  };

  return (
    <>
      <ReactMapGL
        ref={mapRefNL}
        longitude={viewport.longitude}
        latitude={viewport.latitude}
        zoom={viewport.zoom}
        pitch={viewport.pitch}
        bearing={viewport.bearing}
        width="100%"
        height="calc(100vh - 195px)"
        mapboxApiAccessToken={process.env.REACT_APP_MSF_MAPBOX_TOKEN}
        mapStyle={process.env.REACT_APP_MAP_STYLE_ORDER_NL}
        onClick={handleMapClick}
        onLoad={handleMapLoad}
        onViewportChange={setViewport}
        maxZoom={16.8}
        minZoom={6}
      >
        <NavigationControlWrapper data-cy="essential-order-map-navigation">
          <NavigationControl />
        </NavigationControlWrapper>
        <Search resultSelectCallback={handleResultSelect} />
        {!!selectedLocation && (
          <Marker latitude={selectedLocation.center[1]} longitude={selectedLocation.center[0]} offsetTop={-20}>
            <ObjectPin />
          </Marker>
        )}
      </ReactMapGL>
      <HiddenMap>
        <ReactMapGL
          ref={mapRefSE}
          longitude={viewport.longitude}
          latitude={viewport.latitude}
          zoom={viewport.zoom}
          pitch={viewport.pitch}
          bearing={viewport.bearing}
          width="100%"
          height="calc(100vh - 195px)"
          mapboxApiAccessToken={process.env.REACT_APP_MSF_MAPBOX_TOKEN}
          mapStyle={process.env.REACT_APP_MAP_STYLE_ORDER_SE}
          onClick={handleMapClick}
          onLoad={handleMapLoad}
          onViewportChange={setViewport}
          maxZoom={16.8}
          minZoom={6}
        ></ReactMapGL>
        <ReactMapGL
          ref={mapRefNO}
          longitude={viewport.longitude}
          latitude={viewport.latitude}
          zoom={viewport.zoom}
          pitch={viewport.pitch}
          bearing={viewport.bearing}
          width="100%"
          height="calc(100vh - 195px)"
          mapboxApiAccessToken={process.env.REACT_APP_MSF_MAPBOX_TOKEN}
          mapStyle={process.env.REACT_APP_MAP_STYLE_ORDER_NO}
          onClick={handleMapClick}
          onLoad={handleMapLoad}
          onViewportChange={setViewport}
          maxZoom={16.8}
          minZoom={6}
        ></ReactMapGL>
      </HiddenMap>
    </>
  );
};

export default Map;
