import React, { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import CurrentTopRiskList from "./CurrentTopRiskList";
import RiskColumn from "./RiskColumn";
import { DragAndDropColumn, RowRisks, RiskList, MenuComponent } from "./styled";
import { LIST_TOP_RISK, SET_TOP_RISKS } from "../../queries/objects";
import { useMutation } from "@apollo/client";
import { ALL_RISKS_COUNT, RISK_IN_ROW } from "../../constants/TopRisks";
import { ObjectPropertiesContext, TopRiskUserSelectionContext } from "../Common";
import OpenCategories from "../icons/OpenCategories";

const { useActions } = TopRiskUserSelectionContext;
const { useObjectProperties } = ObjectPropertiesContext;

type ResultType = {
  name: string;
  listTopRisks: {
    riskCategories: {
      riskCategory: string;
      __typename: string;
    }[];
  };
  __typename: string;
};

const currentList = "currentList";
const nextRisksList = "nextRisksList";

const DragAndDrop = ({ risks }) => {
  const initialColumns = {
    currentList: {
      id: currentList,
      list: risks?.slice(0, RISK_IN_ROW),
    },
    nextRisksList: {
      id: nextRisksList,
      list: risks?.slice(RISK_IN_ROW, ALL_RISKS_COUNT),
    },
  };
  const { topRisk, topRiskList, setTopRisk, updateTopRiskList } = useActions();

  const { objectProperties } = useObjectProperties();

  const { objectId, country, placeType, segment } = objectProperties;

  const [isOpen, setIsOpen] = useState(false);

  const [columns, setColumns] = useState(initialColumns);

  const [updateValue] = useMutation(SET_TOP_RISKS, {});

  const updateTopRisks = async (currentList) => {
    const topRisks = currentList.map((risk) => risk.category.toUpperCase());
    const variables = {
      objectNumber: objectId,
      placeType,
      objectCountry: country.toLocaleUpperCase(),
      topRisks,
      segment,
    };

    await updateValue({
      variables,
      update: (cache) => {
        const result: ResultType = cache.readQuery({
          query: LIST_TOP_RISK,
          variables,
        });
        const { listTopRisks } = result;
        listTopRisks.riskCategories = listTopRisks.riskCategories.map((risk, index) => {
          return {
            riskCategory: topRisks[index],
            __typename: risk.__typename,
          };
        });
        cache.writeQuery({
          query: LIST_TOP_RISK,
          variables,
          data: {
            listTopRisks,
          },
        });
      },
    });
  };

  const onDragEnd = async ({ source, destination }: DropResult) => {
    if (destination === undefined || destination === null || (source.droppableId === destination.droppableId && destination.index === source.index)) {
      return;
    }
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    if (start === end) {
      const newList = start.list.filter((_, idx: number) => idx !== source.index);

      newList.splice(destination.index, 0, start.list[source.index]);

      const newCol = {
        id: start.id,
        list: newList,
      };

      setColumns((state) => ({ ...state, [newCol.id]: newCol }));

      // handle changing order in select top risks section
      if (newCol.id === currentList) {
        await updateTopRisks(newList);
      }

      return;
    } else if (start.id === currentList && end.id === nextRisksList) {
      return;
    } else {
      const newStartList = start.list.filter((_, idx: number) => idx !== source.index);

      const newStartCol = {
        id: start.id,
        list: newStartList,
      };

      const newEndList = end.list;
      newEndList.splice(destination.index, 0, start.list[source.index]);

      const newEndCol = {
        id: end.id,
        list: newEndList,
      };

      const news = {
        ...columns,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      };

      const currentList = news.currentList.list;
      const nextRisksList = news.nextRisksList.list;

      if (currentList.length > RISK_IN_ROW) {
        const last = currentList.at(-1);
        if (last.category.toLowerCase() === topRisk) {
          setTopRisk(topRiskList[0].category.toLowerCase());
        }

        currentList.pop();
        nextRisksList.push(last);
      }

      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));

      if (currentList.length >= RISK_IN_ROW) {
        await updateTopRisks(currentList);
      }

      updateTopRiskList(currentList.concat(nextRisksList));

      return;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {columns && (
        <DragAndDropColumn>
          <RowRisks>
            <CurrentTopRiskList col={columns.currentList} key={columns.currentList.id} />
          </RowRisks>
          {risks.length > RISK_IN_ROW && (
            <div>
              <RiskList>
                <MenuComponent onClick={() => setIsOpen(!isOpen)}>
                  <OpenCategories flip={isOpen} />
                </MenuComponent>
                {isOpen && <RiskColumn col={columns.nextRisksList} key={columns.nextRisksList.id} />}
              </RiskList>
            </div>
          )}
        </DragAndDropColumn>
      )}
    </DragDropContext>
  );
};

export default DragAndDrop;
