import React, { useRef, useState } from "react";
import { useStore } from "react-context-hook";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import FAQHeaderIcon from "../../icons/FAQHeaderIcon";
import ObjectPropertiesIcon from "../../icons/ObjectPropertiesIcon";
import useOnClickOutside from "./../../../hooks/useOnClickOutside";
import useWindowSize from "./../../../hooks/useWindowSize";
import { Sizes } from "./../../../utilities/common-styles";
import BackLinkComponent from "./BackLinkComponent";
import { MenuItems, SidebarMenu } from "./SideBarMenu";
import { ActionPageWrapper, Actions, MenuContainer } from "./styled";

const ActionWrapper = ({ preventBack }) => {
  const [userData, _setUserData] = useStore("userData", null);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(MenuItems.Close);
  const node = useRef();

  const isEmbedded = userData?.isEmbedded;

  const navigate = useNavigate();

  const location = useLocation();

  const isObjectView4 = useMatch("/essential/:country/:id");
  const isObjectView2 = useMatch("/essential/:country/:id/:category");
  const isObjectView3 = useMatch("/essential/non-object/:id");
  const { t } = useTranslation();

  const isObjectView = !!(isObjectView2 || isObjectView3 || isObjectView4);
  const { width } = useWindowSize();

  const isMobile = width <= Sizes.mobile;

  const redirectToFaq = () => {
    if (location.pathname !== "/faq") navigate("faq");
  };

  useOnClickOutside(node, () => {
    setOpen(false);
    setIndex(-1);
  });

  return (
    <>
      <ActionPageWrapper preventBack={preventBack}>
        {!preventBack && <BackLinkComponent title={t("common.back")}></BackLinkComponent>}
        <Actions data-cy="actions" isObjectView={isObjectView}>
          <MenuContainer
            data-cy="faq"
            onClick={redirectToFaq}
            isFaqPage={false} //isFaqUrl(router.route)}
          >
            <FAQHeaderIcon />
            {isMobile ? <div>{t("common.header.faq.title-mobile")}</div> : <div>{t("common.header.faq.title")}</div>}
          </MenuContainer>
          {isObjectView && (
            <>
              <MenuContainer
                data-cy="properties"
                onClick={() => {
                  setOpen(true);
                  setIndex(MenuItems.ObjectPropperties);
                }}
              >
                <ObjectPropertiesIcon />
                {isMobile ? <div>{t("common.header.object-properties.title-mobile")}</div> : <div>{t("common.header.object-properties.title")}</div>}
              </MenuContainer>
            </>
          )}
        </Actions>
      </ActionPageWrapper>
      <div ref={node}>
        <SidebarMenu open={open} setOpen={setOpen} setIndex={setIndex} index={index} t={t} isObjectView={isObjectView} isEmbedded={isEmbedded} />
      </div>
    </>
  );
};

export default ActionWrapper;
