import Close from "../../icons/Close";
import React from "react";
import { QuestionsView } from "./QuestionsView";
import { CloseWrapper, HeaderElem, MenuCard, MenuHeader, StyledMenu } from "./styled";

export enum MenuItems {
  Close = -1,
  ObjectPropperties,
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  setIndex: (index: MenuItems) => void;
  index: MenuItems;
  t: any;
  isObjectView: boolean;
  isEmbedded: boolean;
}

export const SidebarMenu = ({ open, setOpen, setIndex, index, t, isObjectView, isEmbedded }: Props) => {
  const titles = [t("common.header.object-properties.title")];

  const closeMenu = () => {
    setOpen(false);
    setIndex(MenuItems.Close);
  };
  return (
    <StyledMenu open={open} isEmbedded={isEmbedded}>
      <MenuCard data-cy="settings-menu">
        <MenuHeader data-cy="settings-header">
          <HeaderElem>
            <div> {titles[index]}</div>
          </HeaderElem>

          <CloseWrapper onClick={closeMenu}>
            <Close />
          </CloseWrapper>
        </MenuHeader>
        {index === MenuItems.ObjectPropperties && isObjectView && <QuestionsView t={t} closeMenu={closeMenu}></QuestionsView>}
      </MenuCard>
    </StyledMenu>
  );
};
