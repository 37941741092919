import React from 'react';

const GoToMobileGuardingIcon = () => (
    <svg
        width= "8"
        height= "13"
        viewBox= "0 0 8 13"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M1.51328 12.7442C1.35078 12.7442 1.19453 12.6817 1.06953 12.5629C0.825781 12.3192 0.825781 11.9254 1.06953 11.6817L6.24453 6.50044L1.06953 1.31919C0.825781 1.07544 0.825781 0.681689 1.06953 0.437939C1.31328 0.194189 1.70703 0.194189 1.95078 0.437939L7.56328 6.05669C7.80703 6.30044 7.80703 6.69419 7.56328 6.93794L1.95078 12.5567C1.83203 12.6817 1.66953 12.7442 1.51328 12.7442Z"
            fill= "#F7F7F7"
        />
    </svg>
);

export default GoToMobileGuardingIcon;
