import styled from 'styled-components';
import { PageWrapper } from '../../../utilities/wrappers';
import { Colors, Sizes, Devices } from '../../../utilities/common-styles';


export const SettingsMenu = styled.div`
    width: 327px;
    @media ${Devices.mobile} {
        width: auto;
    }
`;

export const ActionPageWrapper = styled(PageWrapper)<{
    preventBack: boolean;
}>`
    display: flex;
    justify-content: ${props =>
        props.preventBack ? 'flex-end' : 'space-between'};
    min-height: ${Sizes.header}px;
    @media ${Devices.mobile} {
        bottom: 0;
        position: fixed;
        background-color: ${Colors.darkBlue};
        z-index: 9999;
        justify-content: center;
        padding: 0;
    }
`;

export const Actions = styled.div<{
    isObjectView?: boolean;
}>`
    display: flex;

    div {
        div {
            color: white;
            &:hover {
                color: ${Colors.primaryPurple};
            }
        }
        svg {
            path {
                fill: white;
            }
        }
    }

    @media ${Devices.mobile} {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 8px 8px 0px 0px;
        flex: 1;
        justify-content: ${props =>
            props.isObjectView ? 'center' : 'space-between'};
        padding: ${props => (props.isObjectView ? '0' : '0px 2rem')};
        gap: 3.5rem;
    }
`;

export const MenuContainer = styled.div<{ isFaqPage?: boolean }>`
    display: flex;
    text-overflow: clip;
    overflow: hidden;
    height: 25px;
    line-height: 20px;
    position: relative;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    color: ${props => (props.isFaqPage ? Colors.primaryPurple : 'black')};
    align-self: center;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: bold;

    display: flex;
    div {
        margin-left: 0.5rem;
    }

    svg {
        path {
            fill: black;
        }
    }

    &:hover {
        color: ${Colors.primaryPurple};
    }

    @media ${Devices.mobile} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: ${Sizes.mobileMenu}px;
        font-size: 11px;

        svg {
            width: 24px;
            height: 24px;
            margin-bottom: 0.2rem;
        }
        div {
            margin-left: 0;
        }
    }
`;

export const MenuPanelContainer = styled.div`
    text-overflow: clip;
    overflow: hidden;
    height: 25px;
    line-height: 20px;
    position: relative;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    color: ${Colors.text};
    align-self: center;
    font-weight: bold;
    display: flex;
    div {
        margin-left: 0.5rem;
    }
`;

export const ActionPanel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 2rem 0;
    border-bottom: 1px solid ${Colors.borderDarkMode};

    svg {
        path {
            fill: ${Colors.text};
        }
    }
`;

export const QuestionsPanel = styled.div`
    padding: 1rem 0;
    border-bottom: 1px solid ${Colors.borderDarkMode};
`;

export const BackArrow = styled.div`
    cursor: pointer;
`;
export const MenuContent = styled.div`
    max-width: 327px;
`;

export const MenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    font-weight: bold;
`;

export const HeaderElem = styled.div`
    display: flex;
    flex-direction: row;
    svg {
        margin-right: 1rem;
    }

    svg {
        path {
            fill: ${Colors.text};
        }
    }
`;

export const MenuCard = styled.div`
    padding: 20px 24px 0 24px;
`;
export const CloseWrapper = styled.div`
    cursor: pointer;

    svg {
        path {
            fill: ${Colors.text};
        }
    }
`;
export const StyledMenu = styled.nav<{
    open?: boolean;
    isEmbedded: boolean;
}>`
    background-color: ${Colors.darkBlue};
    color: ${Colors.text};
    display: flex;
    flex-direction: column;
    visibility: visible;
    right: 0;
    width: ${({ open }) => (open ? '422px' : '0')};
    transition: width 0.2s ease-out;
    height: 100vh;
    text-align: left;

    overflow: hidden;
    top: ${({ isEmbedded }) =>
        isEmbedded ? `${Sizes.header}px` : `${Sizes.headerWithActions}px`};
    position: absolute;

    @media ${Devices.mobile} {
        bottom: ${Sizes.mobileMenu}px;
        position: fixed;
        top: 0;
        font-size: 11px;
        overflow: auto;
        height: auto;
    }
    z-index: 99999;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

    @media ${Devices.mobileS} {
        width: ${({ open }) => (open ? '100%' : '0px')};
    }
`;

export const NotificationsTitle = styled.div`
    width: 255px;
`;

export const NotificationsComponent = styled.div`
    display: flex;
`;

export const NotificationsToggle = styled.div`
    align-self: center;
`;

export const CheckboxField = styled.div`
    input {
        margin-right: 12px;
        flex: 0 0 32px;
        width: 16px;
        height: 16px;
        position: relative;
    }
`;

export const CheckBoxWrapper = styled.div`
    position: relative;
`;
export const CheckBoxLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    width: 42px;
    height: 25px;
    background: #bebebe;
    cursor: pointer;
    &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        margin: 2px;
        background: #ffffff;
    }
`;
export const CheckBox = styled.input`
    &:checked + ${CheckBoxLabel} {
        background: ${Colors.primaryPurple};
        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 21px;
            height: 21px;
            margin-left: 19px;
            transition: 0.2s;
        }
    }
`;
