import { useNewPlaceProperties } from "../../../hooks";
import LoadingIndicator from "../../Common/LoadingIndicator";
import EssentialListIcon from "../../icons/EssentialListIcon";
import { ObjectStatus } from "../../../hooks/useReports";

import React from "react";
import Skeleton from "react-loading-skeleton";
import { Type } from "../../../utilities/types";
import MarkerOutlined from "../../icons/MarkerOutlined";
import StatusComponent from "./StatusComponent";
import { AddressWrapper, IconContainer, ListItemWrapper, Name, StatusContainer } from "./styled";

export type Position = {
  latitude: string;
  longitude: string;
};

type Props = {
  id?: string;
  name?: string;
  address?: string;
  country?: string;
  loading?: boolean;
  type?: Type;
  onClick?: (spatialId: string, country: string, type?: Type) => void;
  status?: number;

  pickSegmentText?: string;
  longitude?: number;
  latitude?: number;
};

const BaldrItem = ({
  name,
  address,
  country,
  loading,
  id,
  onClick,
  type,
  status,
  longitude,
  latitude,

  pickSegmentText,
}: Props) => {
  const handleClick = () => {
    if (!loading && onClick) {
      onClick(id, country, type);
    }
  };

  const { recalculatedStatus, statusLoading } = useNewPlaceProperties({
    country,
    latitude,
    longitude,
    type,
    id,
    status,
  });

  return (
    <ListItemWrapper data-cy="essential-list-report-item" onClick={handleClick} {...(loading ? { loading: true } : {})}>
      {loading ? (
        <>
          <div>
            <div>
              <Skeleton height={24} width={100} style={{ backgroundColor: "black" }} />
            </div>
            <Skeleton height={24} width={200} style={{ backgroundColor: "black" }} />
          </div>
          <Skeleton height={24} width={24} style={{ backgroundColor: "black" }} />
        </>
      ) : (
        <>
          <div title={`${id} - ${name}`}>
            <Name data-cy="essential-list-report-item-name">
              <b>{name}</b>
            </Name>
            <AddressWrapper data-cy="essential-list-report-item-address">
              <MarkerOutlined />
              <div>
                {address}, {country.toUpperCase()}
              </div>
            </AddressWrapper>
          </div>
          <StatusContainer>
            {statusLoading ? (
              <LoadingIndicator marginTop="0" />
            ) : (
              recalculatedStatus === ObjectStatus.PICK_SEGMENT && <StatusComponent pickSegmentText={pickSegmentText}></StatusComponent>
            )}
          </StatusContainer>
          <IconContainer>
            <EssentialListIcon />
          </IconContainer>
        </>
      )}
    </ListItemWrapper>
  );
};

export default BaldrItem;
