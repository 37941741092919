import React from 'react';

const WeekGraphIcon = ({ isActive }) => (
    <svg
        width= "32"
        height= "32"
        viewBox= "0 0 32 32"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <rect
            width= "32"
            height= "32"
            rx= "16"
            fill={isActive ? '#9977FE' : '#6C47CF'}
        />
        <rect
            x= "7.75"
            y= "8.875"
            width= "16.5"
            height= "15.375"
            rx= "1.25"
            stroke= "#F7F7F7"
            strokeWidth= "1.5"
            strokeLinejoin= "round"
        />
        <line
            x1= "8.125"
            y1= "13.25"
            x2= "23.875"
            y2= "13.25"
            stroke= "#F7F7F7"
            strokeWidth= "1.5"
            strokeLinejoin= "round"
        />
        <line
            x1= "12.25"
            y1= "7.75"
            x2= "12.25"
            y2= "10.75"
            stroke= "#F7F7F7"
            strokeWidth= "1.5"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <line
            x1= "19.5625"
            y1= "7.75"
            x2= "19.5625"
            y2= "10.75"
            stroke= "#F7F7F7"
            strokeWidth= "1.5"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
        <path
            d= "M13 16.5H16L13.5 22H15.2532L18 16.5V15H13V16.5Z"
            fill= "#F7F7F7"
        />
    </svg>
);

export default WeekGraphIcon;
