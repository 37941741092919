import { sv, enGB, nb, nl } from "date-fns/locale";

export const getLocal = (language) => ({ en: enGB, sv, nb, nl }[language] || enGB);

export const getSupportedLocales = (lng: string) => ({ en: "en", sv: "sv", nb: "nb", no: "nb", nn: "nb", nl: "nl" }[lng] || "en");
export const platformAppName = "mys_web";
export const mobileAppName = "mys_app";
export const corporateAppName = "mys_corporate";
export const embedInRiskPredictionParameter = "embedInRiskPrediction";
export const preventBackParameter = "preventback";
export const defaultCountry = "se";
export const embedApp = "a";
