import React from 'react';

const BackNavigationIcon = () => (
    <svg
        width= "16"
        height= "16"
        viewBox= "0 0 16 16"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <path
            d= "M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
            fill= "#F7F7F7"
        />
        <path
            d= "M9.16583 11.3308C9.2525 11.3308 9.33583 11.2974 9.4025 11.2341C9.5325 11.1041 9.5325 10.8941 9.4025 10.7641L6.40244 8L9.4025 5.23742C9.5325 5.10742 9.5325 4.89742 9.4025 4.76742C9.2725 4.63742 9.0625 4.63742 8.9325 4.76742L5.59994 7.76409C5.53471 7.82932 5.50221 7.9147 5.50244 8C5.50267 8.0847 5.53517 8.16932 5.59994 8.23409L8.9325 11.2308C8.99583 11.2974 9.0825 11.3308 9.16583 11.3308Z"
            fill= "#031F30"
        />
    </svg>
);

export default BackNavigationIcon;
