import React from 'react';

const ContactFinalStepIcon = () => (
    <svg
        width= "42"
        height= "42"
        viewBox= "0 0 42 42"
        fill= "none"
        xmlns= "http://www.w3.org/2000/svg"
    >
        <circle cx= "21" cy= "21" r= "19.5" stroke= "#14A665" strokeWidth= "3" />
        <path
            d= "M12 20.6842L18.3158 27L30.3158 15"
            stroke= "#14A665"
            strokeWidth= "3"
            strokeLinecap= "round"
            strokeLinejoin= "round"
        />
    </svg>
);

export default ContactFinalStepIcon;
