import { EmptyWrapper, QuestionsButtonWrapper } from "../../BaldrView/styled";
import React from "react";
import { SolidButton } from "../../../utilities/buttons";
import { Text } from "../../../utilities/typography";
import { useNavigate } from "react-router";

interface IProps {
  t: any;
  url: string;
}

const EmptyInsightsDescription = ({ t, url }: IProps) => {
  const navigate = useNavigate();
  const openOptionalQuestions = () => {
    navigate(url);
  };
  return (
    <EmptyWrapper data-cy="empty-wrapper">
      <div>
        <Text size="16px" mobileFontSize="14px" margin="0" color="white" lineHeight="24px" weight="700" data-cy="empty-wrapper-header">
          {t("common.insights.noDataHeader")}
        </Text>
        <Text size="16px" mobileFontSize="14px" margin="0" color="white" lineHeight="24px" weight="400" data-cy="empty-wrapper-description">
          {t("common.insights.noDataDescription")}
        </Text>
      </div>
      <QuestionsButtonWrapper>
        <SolidButton onClick={openOptionalQuestions} data-cy="answer-questions-button">
          {t("common.insights.button")}
        </SolidButton>
      </QuestionsButtonWrapper>
    </EmptyWrapper>
  );
};

export default EmptyInsightsDescription;
