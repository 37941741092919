import React from 'react';
import styled from 'styled-components';

import LoadingIndicator from './Common/LoadingIndicator';
import { Colors } from './../utilities/common-styles';

const Wrapper = styled.div<{
    height?: string;
    width?: string;
}>`
    height: ${props => props.height || 'auto'};
    width: ${props => props.width || '100%'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.backgroundDarkMode};
`;

const WrappedLoader = ({
    height,
    marginTop,
    width
}: {
    height?: string;
    marginTop?: string;
    width?: string;
}) => (
    <Wrapper height={height} width={width}>
        <LoadingIndicator marginTop={marginTop} />
    </Wrapper>
);

export default WrappedLoader;
