import React, { ReactElement } from "react";

import { Card, IconWrapper } from "./styled";

import { Text } from "./../../utilities/typography";

interface IProps {
  icon: any;
  title: string;
  children: ReactElement | string;
}

const CardView = ({ icon, title, children }: IProps) => {
  return (
    <Card>
      <IconWrapper>{icon}</IconWrapper>
      <Text size="large" bold margin="0 0 10px 0">
        {title}
      </Text>
      <Text size="medium" margin="0">
        {children}
      </Text>
    </Card>
  );
};

export default CardView;
