import styled from 'styled-components';
import { Colors, Devices } from '../../utilities/common-styles';
import { ShadowWrapper } from '../../utilities/wrappers';
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
export const TextRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const GraphContainer = styled.div`
    border-radius: 4px;
    background-color: ${Colors.cardBackgroundPrimary};
    height: 318px !important;
    padding-top: 10px;

    @media ${Devices.mobile} {
        height: 280px !important;
    }
    @media ${Devices.mobileS} {
        height: 210px !important;
    }
`;
export const YearCompare = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 24px;

    @media ${Devices.mobile} {
        padding-bottom: 24px;
    }
`;

export const GraphTooltip = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
`;

export const WeeklyCompare = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media ${Devices.mobile} {
        .VictoryContainer {
            touch-action: auto !important;
        }
    }

    @media ${Devices.mobile} {
        padding-bottom: 24px;
    }
`;

export const CriticalEvents = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const Badge = styled.div`
    background-color: ${Colors.redAlert};
    border-radius: 12px;
    height: 20px;
    min-width: 20px;
    margin-top: 17px;
    text-align: center;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
`;
export const TextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const ContentWrapper = styled(FlexColumn)`
    display: flex;
    margin: 0;
    padding: 0;
    max-width: ${props => props.theme.maxWidth};
    flex: 0 1 100%;
    max-height: 707px;

    > div:last-child {
        overflow: hidden;
    }

    @media ${Devices.mobile} {
        flex: 1 1 auto;
    }

    @media ${Devices.mobileS} {
        > div:last-child {
            border-radius: 0;
        }
    }
`;

export const Title = styled(FlexColumn)`
    flex: 0 1;
    margin-right: 8px;

    > div {
        width: max-content;
    }
`;

export const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${Colors.borderDarkMode};
    display: flex;
    padding: 16px;

    @media ${Devices.mobile} {
        padding: 0;
    }
`;

export const IncidentsDropdown = styled.div`
    cursor: pointer;
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    align-self: center;
    > div {
        flex: 0 1;
        width: max-content;
    }
`;

export const RiskContainer = styled(ShadowWrapper)`
    box-shadow: none;
    border: 0px;
`;

export const EventsCard = styled.div<{ marginBottom?: string }>`
    padding: 16px;
    border-radius: 4px;
    height: 155px;
    background-color: ${Colors.cardBackgroundPrimary};
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : '0px'};
`;
export const IconWrapper = styled.div<{ expanded?: boolean }>`
    display: flex;
    align-self: center;

    transform: ${props =>
        props.expanded ? 'rotate(180deg) scale(0.5)' : 'scale(0.5)'};

    path {
        fill: ${Colors.borderDarkMode};
    }
`;

export const IconMobileGuardingWrapper = styled.div`
    height: 21px;
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: 5px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
        outline: none !important;
    }

    svg {
        margin-left: 5px;
        height: 10px;
        margin: 2px;
    }
`;

export const IconWrapperFilter = styled.div<{ expanded?: boolean }>`
    transform: ${props =>
        props.expanded
            ? 'rotate(180deg) scale(0.5)'
            : 'rotate(0deg) scale(0.5)'};
`;

export const FilterContent = styled.div`
    z-index: 11;
    position: absolute;
    background-color: ${Colors.cardBackgroundPrimary};
    padding: 16px;
    border: 2px solid ${Colors.primaryPurple};
    border-radius: 24px;    
    right: 0;
    padding-right: 60px;

    div {
        &:hover {
            color: ${Colors.primaryPurple} !important;    
            
        }
    }}
`;

export const LinkContent = styled.div`
    max-width: 80%;
    @media ${Devices.mobileL} {
        max-width: 100%;
    }
`;

export const FilterContainer = styled.div<{ expanded?: boolean }>`
    color: ${props =>
        props.expanded ? Colors.purpleHover : Colors.borderDarkMode};

    cursor: pointer;
    position: relative;
    margin-right: 10px;

    &:hover {
        color: ${Colors.primaryPurple};

        path {
            fill: ${Colors.primaryPurple};
        }
    }

    path {
        fill: ${props =>
            props.expanded ? Colors.purpleHover : Colors.borderDarkMode};
    }
`;

export const ShowAllContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const IncidentsListContainer = styled.div`
    height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${Colors.mediumGrey};
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    svg {
        cursor: pointer;
    }

    display: flex;
`;

export const FormContent = styled.div`
    padding: 24px;
    border-radius: 16px;

    height: 100%;
    display: flex;
    flex-direction: column;
    @media ${Devices.mobileS} {
        padding: 16px;
        max-height: 400px;
    }
`;

export const ContentText = styled.div`
    overflow-y: auto;
    padding-right: 10px;
`;
export const IncidentsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 10;
    padding: 8px 8px 0 0;
`;
export const EventsHeader = styled.div`
    margin: 0 20px 0 0;
`;
export const DateRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
`;
export const RowElement = styled.div`
    display: flex;
    align-items: center;
`;
export const ComparisonList = styled.div`
    display: flex;
    scroll-snap-type: x mandatory;
    gap: 24px;
    width: 100%;
    position: absolute;
    flex: 0 0 100%;
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${Colors.mediumGrey};
    }
`;
export const ComparisonElement = styled.div`
    border-radius: 4px;
    background: ${Colors.cardBackgroundPrimary};
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 16px;
    min-width: 240px;
`;
export const ComparisonContainer = styled.div`
    margin-bottom: 100px;
`;

export const ComparisonRow = styled.div`
    display: flex;
    align-items: center;
`;
export const LineElement = styled.div`
    height: 1px;
    border-bottom: 1px dashed ${Colors.secondaryPurple};
    grid-column: 1 / 5;
    margin-bottom: 8px;
`;
export const TooltipContent = styled.div`
    background-color: #233c59;
    justify-content: end;
    overflow: visible;
    display: grid;
    grid-template-columns: 45fr 18fr 18fr 19fr;
    grid-template-rows: auto;

    color: ${Colors.white};
    border-radius: 2px;
    padding: 8px;
    z-index: 100000;
    opacity: 0.95;
    div {
        font-size: 0.5 !important;
    }
`;

export const TooltipContainer = styled.foreignObject`
    overflow: visible;
    z-index: 100000;
`;
export const IncidentsListPadding = styled.div`
    padding-top: 32px;

    @media ${Devices.mobile} {
        padding: 0;
    }
`;

export const IncidentsListItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    background: ${Colors.cardBackgroundPrimary};
`;

export const NotSeenDot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
    background-color: ${Colors.primaryPurple};
`;

export const DescriptionItemTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ObjectTag = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin: 5px;
    }
`;
export const DescriptionItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const CategoriesList = styled.div`
    display: flex;
    margin-top: 7px;
    flex-wrap: wrap;
`;

export const EventCategory = styled.div<{ isNearbyEvent?: boolean }>`
    border: 1px ${Colors.redDarkMode} solid;
    border-radius: 24px;
    padding: 0px 8px;
    letter-spacing: 0.3px;
    margin-right: 5px;
    margin-bottom: 3px;
    cursor: pointer;
    background-color: ${props =>
        props.isNearbyEvent ? 'none' : Colors.redDarkMode};
`;
export const CategoryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const EventsCategory = styled.div<{ color: string }>`
    border-radius: 24px;
    padding: 0px 8px;
    letter-spacing: 0.3px;
    margin-right: 5px;
    margin-bottom: 3px;
    cursor: pointer;
    background-color: ${props => props.color};
`;

export const LoadOlderButton = styled.div<{ isDisabled?: boolean }>`
    border-radius: 24px;
    padding: 0px 8px;
    letter-spacing: 0.3px;
    margin-right: 5px;
    margin-bottom: 14px;
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
    opacity: ${props => (props.isDisabled ? '0.5' : '1')};
    display: flex;
`;

export const LoadOlderTag = styled.div`
    color: ${Colors.borderDarkModeSelected};
    border: ${Colors.borderDarkModeSelected} 1px solid;
    border-radius: 24px;
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    width: 232px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    @media ${Devices.mobileS} {
        font-size: 12px;
        line-height: 21px;
    }
`;

export const LoadOlderContainer = styled.div`
    margin-top: 8px;
    display: flex;
    justify-content: center;
`;

export const EmptyIncidentsListWrapper = styled.div`
    height: 300px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 14px;
`;

export const BellIconWrapper = styled.div`
    display: flex;
    align-self: center;
`;
export const MapWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    border-radius: 0 0 8px 8px;
`;

export const GraphHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ComparisonWrapper = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 30fr 70fr;

    @media ${Devices.mobileL} {
        display: flex;
        flex-direction: column;
    }
`;

export const ScrollWrapper = styled.div`
    overflow-y: auto;
    height: 200px;
`;

export const BottomBarPadding = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    z-index: 9;
    padding: 24px;
`;

export const BottomBarWrapper = styled.div<{ shrinkWidthToContent: boolean }>`
    width: ${props => (props.shrinkWidthToContent ? 'max-content' : '100%')};
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 16px;
    background: ${Colors.cardBackgroundPrimary};
    border-radius: 4px;
    gap: 16px;

    @media ${Devices.mobileS} {
        svg {
            display: ${props =>
                props.shrinkWidthToContent ? 'block' : 'none'};
        }
        flex-direction: column;
        align-items: flex-start;
        gap: 1;
    }
`;
