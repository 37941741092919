import React from 'react';

import { VictoryLabel, VictoryPie } from 'victory';
import { ChartSection } from './styled';
import { useTranslation } from 'react-i18next';
import { Colors, Fonts } from '../utilities/common-styles';

const FrequencyChart = ({ data, isEmpty }) => {
    const { t } = useTranslation();
    const text = isEmpty ? t('common.insights.noData') : `${data}%`;
    return (
        <ChartSection>
            <svg viewBox= "50 50 300 300">
                <VictoryPie
                    standalone={false}
                    width={400}
                    height={400}
                    data={[
                        { x: 1, y: data },
                        { x: 2, y: 100 - data }
                    ]}
                    innerRadius={({ datum }) => (datum.x > 1 ? 130 : 120)}
                    labelRadius={100}
                    style={{
                        labels: { fontSize: 20, fill: 'none' },
                        data: {
                            fill: Colors.pieChartRed,
                            opacity: ({ datum }) => (datum.x > 1 ? 0.3 : 1)
                        }
                    }}
                />
                <VictoryLabel
                    textAnchor= "middle"
                    style={{
                        fontSize: isEmpty ? 35 : 100,
                        fontFamily: Fonts.SecuritasPro,
                        fill: isEmpty ? Colors.redDarkMode : 'white'
                    }}
                    x={200}
                    y={200}
                    text={text}
                />
            </svg>
        </ChartSection>
    );
};

export default FrequencyChart;
