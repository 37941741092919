import styled, { css } from "styled-components";
import { SolidButton } from "../../utilities/buttons";
import { Devices, Colors, Sizes } from "../../utilities/common-styles";
import { PageWrapper, ResponsiveShadowWrapper } from "../../utilities/wrappers";

export const Wrapper = styled.div`
  &:last-of-type {
    padding-bottom: 80px;
  }
`;

export const CheckAllQuestionsContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 10px;
  svg {
    align-self: center;
    margin-right: 4px;
  }
`;

export const CheckToggle = styled.div<{
  off: boolean;
  color: string;
}>`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.off === true ? props.color : Colors.cardBackgroundSecondary)};
  border: solid 2px ${(props) => (props.off === false ? Colors.borderDarkMode : "transparent")};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 12px;
  margin-top: 2px;
`;
export const CheckListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const CheckListContainer = styled.div`
  padding: 24px;
`;

export const CheckList = styled.div<{ expanded: boolean }>`
  max-height: ${(props) => (props.expanded ? "100%" : "0")};
  opacity: ${(props) => (props.expanded ? "1" : "0")};
  transition: all 0.5s ease-out;
`;

export const CollapsedPanel = styled.div`
  display: flex;
  margin: 0 5px;
  background: #031f30;
  border: 2px solid ${Colors.borderDarkMode};
  box-sizing: border-box;
  height: 20px;
  border-radius: 4px;
  position: relative;
  top: -10px;
  z-index: 2;
`;

export const CollapsedPanelTopMost = styled(CollapsedPanel)`
  top: -20px;
  z-index: 1;
`;

export const QuestionContainer = styled.div<{
  marginBottom: string;
  checked: boolean;
}>`
  display: flex;
  padding: 8px;
  background: #031f30;
  border: 2px solid ${Colors.borderDarkMode};
  background-color: ${(props) => (props.checked ? Colors.cardSelected : Colors.cardBackgroundPrimary)};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: ${(props) => props.marginBottom};
  position: relative;
  z-index: 9;
`;

export const AllQuestionSetContainer = styled(QuestionContainer)`
  svg {
    align-self: center;
    margin-right: 4px;
  }
`;

export const ProgressCircle = styled.div<{ top?: string }>`
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  top: ${(props) => (props.top ? props.top : "-4px")};
  background: ${Colors.purpleHover};
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 24px;
`;
export const InfoTooltip = styled.div`
  border-radius: 4px 4px 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: ${Colors.purpleHover};
  top: -36px;
  left: 8px;
  position: relative;
`;

export const InfoTooltip0 = styled.div`
  border-radius: 4px 4px 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: ${Colors.purpleHover};
  top: -8px;
  left: 8px;
  width: 42px;
  position: relative;
`;

export const FillerComponent = styled.div`
  background: ${Colors.primaryPurple};
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
`;

export const ProgressBarComponent = styled.div`
  position: relative;
  height: 8px;
  width: 100%;
  border-radius: 24px;
  background-color: ${Colors.accentPurple2};
  margin-bottom: 16px;
`;

export const ProgressElem = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProgressElem0 = styled.div`
  position: relative;
  top: -30px;
`;

export const DataWrapper = styled.div`
  grid-gap: 8px;

  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;

  margin-bottom: 40px;

  @media ${Devices.mobileL} {
    grid-template-columns: 49% 49%;
    margin-bottom: 10px;
  }
`;

export const InsightsFlexWrapper = styled.div`
  grid-gap: 8px;

  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr;

  @media ${Devices.mobileL} {
    grid-template-columns: 49% 49%;
    display: block;
  }
`;

export const DescriptionWrapper = styled(ResponsiveShadowWrapper)`
  background-color: ${Colors.cardBackgroundPrimary};
  border: none;
  svg {
    path {
      fill: ${Colors.borderDarkMode};
    }
  }
  @media ${Devices.mobileS} {
    height: 120px;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

export const LoadingScreen = styled.div`
  height: 100vh;
  background: ${Colors.backgroundDarkMode};
`;

export const WizardWrapper = styled(PageWrapper)`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 24px;
  margin-top: 20px;

  @media ${Devices.tablet} {
    grid-template-columns: 1fr;
    margin-top: 10px;
  }

  @media ${Devices.mobile} {
    grid-template-columns: 1fr;
    padding: 0;
    margin-top: 10px;
  }
`;

export const WizardColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
`;

export const GridFlexSection = styled.section<{ gap?: number }>`
  margin-bottom: ${(props) => "-" + props.gap + "px" || 0};
`;

export const WizardContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  max-width: ${(props) => props.theme.maxWidth};
  background-color: ${Colors.cardBackgroundPrimary};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
`;

export const WizardHighRiskPeriodContainer = styled(WizardContainer)`
  background-color: ${Colors.backgroundDarkMode};
`;

export const TopRiskWrapper = styled(ResponsiveShadowWrapper)`
  background: ${Colors.cardBackgroundPrimary};
  color: ${Colors.text};
  border: 2px solid ${Colors.borderDarkMode};
`;

export const BaldrHeaderWrapper = styled.div<{
  withBorder?: boolean;
  isEmbedded?: boolean;
  preventBack?: boolean;
}>`
  background-color: ${Colors.cardBackgroundSecondary};
  color: ${Colors.text};

  ${(props) =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${Colors.borderDarkMode};
    `}
  height: ${(props) => (!props.isEmbedded ? `${Sizes.headerWithActions}px` : `${Sizes.header}px`)};

  @media ${Devices.mobile} {
    height: ${(props) =>
      props.isEmbedded ? (props.preventBack ? `0px` : `${Sizes.header}px`) : props.preventBack ? `${Sizes.header}px` : `${Sizes.headerWithActions}px`};
  }
`;

export const DetailsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SolidButtonWrapper = styled(SolidButton)`
  display: flex;
  align-items: center;
  padding: 12px auto;
  width: 250px;
  height: 48px;
  justify-content: center;
  @media ${Devices.mobileS} {
    width: 100%;
  }
`;

export const ContactUsWrapper = styled.div`
  align-self: center;

  @media ${Devices.mobileS} {
    width: 100%;
  }
`;

export const ChartSection = styled.div`
  width: 150px;
`;

export const InsightsWrapper = styled(InsightsFlexWrapper)`
  display: flex;
  flex-direction: column;
`;

export const InsightsContainer = styled.div`
  background-color: ${Colors.cardBackgroundPrimary};
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  @media ${Devices.mobileL} {
    margin-bottom: 0;
  }
`;

export const LinkContainer = styled.div`
  cursor: pointer;
  color: ${Colors.primaryPurple};
  display: inline;
`;

export const InsightsCard = styled.div<{
  margin?: string;
}>`
  width: 100%;
  margin: 0;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const FrequencyChartWrapper = styled.div<{ isEmpty?: boolean }>`
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid ${Colors.borderDarkMode};
    svg {
        min-width: 150px;
    }

    ${EmptyWrapper} {
        margin-left: 24px;

        @media ${Devices.mobileS} {
            margin-left: 0;
        }
    }

    @media ${Devices.mobileS} {
        flex-direction: ${(props) => (props.isEmpty ? "column" : "row")};
        svg {
           margin-bottom: ${(props) => (props.isEmpty ? "10px" : "0")}
        }
`;

export const HeaderCard = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin: 24px 0;
  font-weight: 700;
  @media ${Devices.mobile} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const CostSection = styled.div`
  display: flex;
  align-items: baseline;
`;

export const QuestionsButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;

  @media ${Devices.mobileS} {
    button {
      width: 100%;
    }
    padding-top: 12px;
  }
`;
