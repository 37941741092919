const countryEmail = {
    no: 'mysecuritas@securitas.no',
    se: 'mysecuritas@securitas.se',
    global: 'mysecuritas@securitas.com'
};

export const getSecuritasEmail = (objectCountry: string): string => {
    if (!objectCountry) {
        return countryEmail['global'];
    }

    return countryEmail[objectCountry.toLowerCase()] || countryEmail['global'];
};
