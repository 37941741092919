import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import { ModalWrapper } from "./../Common/Modal/styled";
import React, { useRef } from "react";
import { Content } from "../ObjectDetails/styled";
import { ModalHeader, FormContent, ContentText } from "./styled";
import CloseIcon from "../icons/CloseIcon";

type Props = {
  t: any;
  category: string;
  visible: boolean;
  onCloseClick: () => void;
};

const CategoryDescriptionModal = ({ t, category, onCloseClick, visible }: Props) => {
  const modalRef = useRef();

  useOnClickOutside(modalRef, () => {
    onCloseClick();
  });

  const closeAndCleanup = () => {
    onCloseClick();
  };
  return visible ? (
    <ModalWrapper>
      <Content ref={modalRef}>
        <FormContent>
          <ModalHeader>
            <div onClick={closeAndCleanup}>
              <CloseIcon size={16} color="white" />
            </div>
          </ModalHeader>
          <Text weight="700" size="24px" mobileFontSize="18px" lineHeight="36px" margin="0 0 8px 0 " color={Colors.text}>
            {t(`categorization.${category}`)}
          </Text>
          <ContentText>
            <Text size="16px" mobileFontSize="14px" lineHeight="24px" color={Colors.text}>
              {t(`categorization.description.${category}.description`)}
            </Text>
            <Text size="16px" mobileFontSize="14px" lineHeight="24px" color={Colors.text} margin="16px 0 0 0">
              {t(`categorization.description.${category}.subdescription`)}
            </Text>
          </ContentText>
        </FormContent>
      </Content>
    </ModalWrapper>
  ) : null;
};

export default CategoryDescriptionModal;
