import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  formatISO,
  getISOWeek,
  getTime,
  getYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { getLocal } from "./generalSettings";

const getISOString = (date: number | Date) => format(date, "yyyy-MM-dd T HH:mm:ssX");
const getUnixTimestamp = (date: number | Date) => Math.trunc(getTime(date) / 1000);

const getWeekFromDate = (unix: number) => {
  const parsedDate = new Date(unix * 1000);
  return getISOWeek(parsedDate);
};

const getYearFromUnix = (unix: number) => {
  const parsedDate = new Date(unix * 1000);
  return getYear(parsedDate);
};

const formatLocale = (date: number | Date, language: string, formatStr: string) => format(date, formatStr, { locale: getLocal(language) });

const getStartOfDay = (date: number | Date) => startOfDay(date);

const getStartOfDayUnix = (date: number | Date) => getUnixTimestamp(getStartOfDay(date));

const getEndOfDay = (date: number | Date) => endOfDay(date);

const getEndOfDayUnix = (date: number | Date) => getUnixTimestamp(getEndOfDay(date));

const getStartOfWeek = (date: number | Date) => startOfWeek(date, { weekStartsOn: 1 });
const getStartOfWeekUnix = (date: number | Date) => getUnixTimestamp(getStartOfWeek(date));

const getEndOfWeek = (date: number | Date) => addDays(getStartOfWeek(date), 6);
const getEndOfWeekUnix = (date: number | Date) => getUnixTimestamp(getEndOfWeek(date));

const getStartOfYear = (date: number | Date) => startOfYear(date);
const getStartOfYearUnix = (date: number | Date) => getUnixTimestamp(getStartOfYear(date));

const getEndOfYear = (date: number | Date) => endOfYear(date);
const getEndOfYearUnix = (date: number | Date) => getUnixTimestamp(getEndOfYear(date));

const getEndOfMonth = (date: number | Date) => endOfMonth(date);
const getEndOfMonthUnix = (date: number | Date) => getUnixTimestamp(getEndOfMonth(date));

const getStartOfMonth = (date: number | Date) => startOfMonth(date);
const getStartOfMonthUnix = (date: number | Date) => getUnixTimestamp(getStartOfMonth(date));

const getStartOfMonthUTC: (date?: Date) => Date = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 0, 0, 0, 0, 0));
};

const getStartOfYearUTC: (date?: Date) => Date = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), 0, 1, 0, 0, 0, 0));
};

const getEndOfMonthUTC: (date?: Date) => Date = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999));
};

const getEndOfYearUTC: (date?: Date) => Date = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), 12, 31, 23, 59, 59, 999));
};

const getStartOfDayUTC: (date?: Date) => Date = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const getEndOfDayUTC: (date?: Date) => Date = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
};

// Polyfill for Math.trunc, which is not supported by IE
Math.trunc =
  Math.trunc ||
  function (x) {
    if (isNaN(x)) {
      return NaN;
    }
    if (x > 0) {
      return Math.floor(x);
    }
    return Math.ceil(x);
  };

const getDateFromUnix = (epoch: number) => new Date(epoch * 1000);

const unixToISO = (epoch: number) => formatISO(new Date(epoch * 1000));

const monthString = (date: Date | number, langauge: string, form?: string) => {
  let asDate = date;
  if (typeof date === "number") {
    asDate = new Date(0, date);
  }

  return format(asDate, form || "MMMM", {
    locale: getLocal(langauge),
  });
};

const get30DaysBefore = (date?: number | Date) => {
  if (!date) {
    date = new Date();
  }

  return addDays(date, -30);
};

export {
  getWeekFromDate,
  getStartOfWeekUnix,
  getEndOfWeekUnix,
  getStartOfYearUnix,
  getEndOfYearUnix,
  getUnixTimestamp,
  getYearFromUnix,
  getStartOfDay,
  getStartOfDayUnix,
  getEndOfDayUnix,
  getEndOfDay,
  getStartOfWeek,
  getEndOfWeek,
  getISOString,
  getStartOfMonth,
  getEndOfMonthUnix,
  getEndOfMonth,
  getStartOfMonthUnix,
  getDateFromUnix,
  unixToISO,
  getEndOfYear,
  getStartOfYear,
  monthString,
  getStartOfDayUTC,
  getStartOfMonthUTC,
  getStartOfYearUTC,
  getEndOfYearUTC,
  getEndOfMonthUTC,
  getEndOfDayUTC,
  get30DaysBefore,
  formatLocale,
};
