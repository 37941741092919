import styled from 'styled-components';
import { Colors, Devices } from '../../utilities/common-styles';
import { Wrapper } from './../Common/Header/styled';
import { FlexWrapper } from '../../utilities/wrappers';

export const ContentWrapper = styled(Wrapper)`
    padding-top: 2rem;
    max-width: 850px;
`;

export const ButtonsWrapper = styled(FlexWrapper)`
    justify-content: space-between;
    margin-top: 16px;

    button:first-of-type {
        margin: 16px 24px 16px 0;
    }

    @media ${Devices.mobileS} {
        margin-top: 8px;
        flex-direction: column-reverse;

        button:nth-of-type(2) {
            width: 100%;
        }

        button:first-of-type {
            margin: 0 0 24px 0;
            padding: 0;
        }
    }
`;

export const PaginationWrapper = styled.div<{ page; isLightMode }>`
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        list-style-type: none;
        padding-inline-start: 0;
    }

    li {
        padding: 2px 8px;
        display: flex;
        justify-content: center;

        a {
            font-weight: bold;
            color: ${Colors.white};
            cursor: pointer;
            font-size: 14px;
            align-self: center;

            :focus {
                outline: none;
            }
        }
    }
    .selected {
        background: none;
        background: ${props =>
            props.isLightMode ? Colors.black : Colors.white};
        border-radius: 50%;
        width: 24px;
        height: 24px;

        a {
            text-decoration: none;
            color: ${Colors.black};
            cursor: default;
            cursor: pointer;
        }
    }

    .next {
        background: none;
    }
`;
