import React from "react";

import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";

const container = document.getElementById("root");
const root = createRoot(container!);

declare let window;

// initailize MixPanel
// TODO enable mixpanel
// window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
//     api_host: 'https://api-eu.mixpanel.com'
// });

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    responseType="id_token"
    scope="openid profile email"
  >
    <App />
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
