import styled from "styled-components";
import { Sizes, Devices, Colors } from "../../utilities/common-styles";

export const ListContainer = styled.div`
  @media ${Devices.mobile} {
    padding-bottom: ${Sizes.mobileMenuMargin}px;
    position: relative;
    display: inline-block;
  }
`;

export const PaginationWrapper = styled.div`
  li {
    a {
      color: ${Colors.text};
    }
  }

  .selected a {
    color: ${Colors.black};
  }

  .selected {
    background: ${Colors.text};
  }
  svg {
    path {
      fill: white;
    }
  }
`;
