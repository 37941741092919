import { Text } from "../../utilities/typography";
import { Colors } from "../../utilities/common-styles";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import MapRiskLevel from "./MapRiskLevel";
import { IconContainer, StyledColumnItem } from "./styled";
import DragAndDropIcon from "../icons/DragAndDropIcon";

interface ItemProps {
  text: string;
  index: number;
  risk: number;
}

const ColumnItem: React.FC<ItemProps> = ({ text, index, risk }) => {
  const { t } = useTranslation();

  return (
    <Draggable draggableId={text} index={index}>
      {(provided) => (
        <StyledColumnItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div>
            <Text data-cy="risk-category" size="14px" mobileFontSize="12px" bold lineHeight="21px" color="white">
              {t(`common.risk.${text.toLowerCase()}`)}
            </Text>
            <MapRiskLevel risk={risk} emptyRiskColor={Colors.darkBlue}></MapRiskLevel>
          </div>
          <IconContainer>
            <DragAndDropIcon />
          </IconContainer>
        </StyledColumnItem>
      )}
    </Draggable>
  );
};

export default ColumnItem;
