import { useNavigate } from "react-router-dom";
import { ObjectType, NonObjectType } from "../../../utilities/types";
import { getReportUrl } from "../../../utilities/urlUtils";
import { PlaceTypes } from "../../../utilities/gqlUtilities";

type IResult = () => void;

const useRedirectToReport = (country: string, locationId: string, placeType: string): IResult => {
  const navigate = useNavigate();

  const redirectToReport = () => {
    const objectType = placeType === PlaceTypes.ObjectPlaceType ? ObjectType : NonObjectType;
    const url = getReportUrl(objectType, country, locationId);
    navigate(url);
  };

  return redirectToReport;
};

export default useRedirectToReport;
