import styled from 'styled-components';
import { Colors } from '../../../utilities/common-styles';

export const BarWrapper = styled.div`
    width: 100%;
    position: relative;

    progress[value] {
        width: 100%;
        appearance: none;

        ::-webkit-progress-bar {
            height: 8px;
            border-radius: 8px;
            background-color: ${Colors.purpleHover};
        }

        ::-webkit-progress-value {
            height: 8px;
            border-radius: 8px;
            background-color: ${props => props.theme.purple};
            transition: all 0.1s ease;
        }
    }
`;

export const LoadingIndicator = styled.div`
    position: absolute;
    top: -28px;
    transition: all 0.1s ease;
`;
