import { InteractiveText } from "../../utilities/typography";
import React from "react";

import { getSecuritasEmail } from "../../utilities/securitasMailUtil";
import { SecuritasEmailContactOpen } from "../../constants/trackedEvents";
// import { useMixpanelContextV2 } from "../BaldrView/MixpanelContextProviderV2";
import { ObjectPropertiesContext } from ".";

const { useObjectProperties } = ObjectPropertiesContext;

const SecuritasEmail = () => {
  // const { track } = useMixpanelContextV2();
  const { objectProperties } = useObjectProperties();
  const securitasEmail = getSecuritasEmail(objectProperties?.country);

  const emailClickHandler = () => {
    // track(SecuritasEmailContactOpen);
    window.location.href = `mailto:${securitasEmail}`;
  };

  return (
    <InteractiveText bold size="medium" onClick={emailClickHandler}>
      {securitasEmail}
    </InteractiveText>
  );
};

export default SecuritasEmail;
