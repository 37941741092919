export enum IntercomTopic {
    REDIRECT = 'REDIRECT'
}

export enum AppsEnum {
    MobileGuarding = 'MobileGuarding'
}

export interface IntercomPayload {
    topic: IntercomTopic;
    timestamp: Date;
    message?: string;
}

export interface IntercomReceivedMessage {
    origin: string;
    data: IntercomPayload;
}

export const useIntercom = () => {
    const postMessage = (
        topic: IntercomTopic,
        message: string,
        isEmbeddedInMobile: boolean
    ) => {
        const targetOrigin = '*';
        if (!isEmbeddedInMobile) {
            //targetOrigin = process.env.REACT_APP_WEB_HUB_ENDPOINT;
        }
        const payload = {
            topic,
            message,
            timestamp: new Date()
        };
        window.parent.postMessage(payload, targetOrigin);
    };

    return { postMessage };
};
