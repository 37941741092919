import React from 'react';
import { Header } from './styled';

import Skeleton from 'react-loading-skeleton';
import { Text, H2 } from '../../utilities/typography';
const { InteractiveText } = Header;
interface IHeaderProps {
    title: string;
    description: string;
    loading?: boolean;
    headerFontSize?: string;
    isDescriptionLink?: boolean;
    maxWidth?: string;
    margin?: string;
}

const SectionHeader = ({
    title,
    description,
    loading,
    headerFontSize = '24px',
    isDescriptionLink = false,
    maxWidth = '786px',
    margin = '0 0 40px 0'
}: IHeaderProps) => (
    <>
        <H2 data-cy= "section-header" size={headerFontSize}>
            {loading ? <Skeleton width={250} /> : title}
        </H2>
        {isDescriptionLink ? (
            <InteractiveText
                data-cy= "section-header-description"
                size= "large"
                margin={margin}
                maxWidth={maxWidth}
                dangerouslySetInnerHTML={{
                    __html: description
                }}
            ></InteractiveText>
        ) : (
            <Text
                data-cy= "section-header-description"
                size= "large"
                margin={margin}
                maxWidth={maxWidth}
            >
                {loading ? <Skeleton count={1} /> : description}
            </Text>
        )}
    </>
);

export default SectionHeader;
