import styled from 'styled-components';
import { Colors } from '../../../utilities/common-styles';
export const NotificationContentWrapper = styled.div<{ top?: boolean }>`
    height: 100%;
    display: flex;
    margin-right: 8px;
    flex-direction: column;
    justify-content: ${props => (props.top ? 'flex-start' : 'center')};
    background-color: ${Colors.cardBackgroundPrimary};
`;
