import useOnClickOutside from "../../hooks/useOnClickOutside";
import useWindowSize from "../../hooks/useWindowSize";
import { Text } from "../../utilities/typography";
import { Colors, Sizes } from "../../utilities/common-styles";
import { ModalWrapper } from "./../Common/Modal/styled";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CallUsContent,
  CallUsDetails,
  CallUsNumberContent,
  Content,
  DescriptionContent,
  FormContent,
  ModalHeader,
  SendButton,
  FinalStepContent,
  ContactButton,
  BackArrow,
} from "./styled";
import ContactFinalStepIcon from "../icons/ContactFinalStepIcon";
import HorizontalArrowIcon from "../icons/HorizontalArrowIcon";
import CallUsModalContent from "./CallUsModalContent";
import EmailModalComponent from "./EmailModalComponent";
import CloseIcon from "../icons/CloseIcon";

export enum ModalItems {
  Start,
  CallMe,
  EmailMe,
  End,
  Error,
}

type Props = {
  visible: boolean;
  onCloseClick: () => void;
  objectAddress: string;
};

const ContactUsModal = ({ visible, onCloseClick, objectAddress }: Props) => {
  const modalRef = useRef();
  const { t } = useTranslation();
  const [index, setIndex] = useState(ModalItems.Start);

  useOnClickOutside(modalRef, () => {
    onCloseClick();
    setIndex(ModalItems.Start);
  });

  const closeAndCleanup = () => {
    onCloseClick();
    setIndex(ModalItems.Start);
  };

  const isContentPage = Object.values([ModalItems.CallMe, ModalItems.EmailMe]).includes(index);

  const phoneText = `tel:+46${process.env.REACT_APP_PHONE.replace(/[\s-]+/g, "")}`;

  const { width } = useWindowSize();

  const isMobile = width <= Sizes.mobile;

  return visible ? (
    <ModalWrapper>
      <Content ref={modalRef}>
        <FormContent data-cy="contact-securitas-modal">
          <ModalHeader isContentPage={isContentPage}>
            {isContentPage && (
              <BackArrow
                onClick={() => {
                  setIndex(ModalItems.Start);
                }}
              >
                <HorizontalArrowIcon small fill="white" flip={true} onClick={() => setIndex(ModalItems.Start)} />
              </BackArrow>
            )}

            <div onClick={closeAndCleanup} data-cy="contact-securitas-modal-close-icon">
              <CloseIcon size={16} color="white" />
            </div>
          </ModalHeader>
          {index === ModalItems.Start && (
            <>
              <Text weight="700" size="24px" lineHeight="36px" margin="0 0 26px 0 " color={Colors.text} data-cy="contact-securitas-modal-header">
                {t("common.contactUs.modalTitle")}
              </Text>
              <Text weight="400" size="16px" lineHeight="24px" color={Colors.text} margin="0 0 48px 0 " data-cy="contact-securitas-modal-description">
                {t("common.contactUs.modalSubtitle")}
              </Text>
              <DescriptionContent>
                <CallUsContent data-cy="modal-we-call-you-content">
                  <CallUsDetails>
                    <Text weight="700" size="16px" lineHeight="24px" margin="0" data-cy="modal-we-call-you-details">
                      {t("common.contactUs.callBack")}
                    </Text>
                  </CallUsDetails>

                  <Text weight="400" size="14px" lineHeight="21px" margin="0" data-cy="modal-we-call-you-description">
                    {t("common.contactUs.callBackDescription")}
                  </Text>
                </CallUsContent>
                <CallUsNumberContent>
                  <SendButton onClick={() => setIndex(ModalItems.CallMe)} data-cy="modal-call-me-button">
                    {t("common.contactUs.callBackButton")}
                  </SendButton>
                </CallUsNumberContent>
              </DescriptionContent>
              <DescriptionContent>
                <CallUsContent data-cy="modal-call-us-content">
                  <CallUsDetails>
                    <Text weight="700" size="16px" lineHeight="24px" margin="0" data-cy="modal-call-us-details">
                      {t("common.contactUs.callUs")}
                    </Text>
                  </CallUsDetails>

                  <Text weight="400" size="14px" lineHeight="21px" data-cy="modal-call-us-description">
                    {t("common.contactUs.callUsDescription")}
                  </Text>
                </CallUsContent>
                <CallUsNumberContent>
                  <Text weight="700" size="16px" lineHeight="24px" color={Colors.text}>
                    <a data-cy="contact-securitas-modal-phone-number" href={phoneText}>
                      {process.env.REACT_APP_PHONE}
                    </a>
                  </Text>
                </CallUsNumberContent>
              </DescriptionContent>
              <DescriptionContent>
                <CallUsContent data-cy="write-to-us-content">
                  <CallUsDetails>
                    <Text weight="700" size="16px" lineHeight="24px" margin="0" data-cy="write-to-us-details">
                      {t("common.contactUs.writeEmail")}
                    </Text>
                  </CallUsDetails>

                  <Text weight="400" size="14px" lineHeight="21px" margin="0" data-cy="write-to-us-description">
                    {t("common.contactUs.writeEmailDescription")}
                  </Text>
                </CallUsContent>
                <CallUsNumberContent>
                  <SendButton onClick={() => setIndex(ModalItems.EmailMe)} data-cy="write-to-us-button">
                    {isMobile ? t("common.contactUs.writeEmailButtonMobile") : t("common.contactUs.writeEmailButton")}
                  </SendButton>
                </CallUsNumberContent>
              </DescriptionContent>
            </>
          )}
          {index === ModalItems.CallMe && <CallUsModalContent objectAddress={objectAddress} setIndex={setIndex} />}
          {index === ModalItems.EmailMe && <EmailModalComponent objectAddress={objectAddress} setIndex={setIndex}></EmailModalComponent>}
          {index === ModalItems.End && (
            <FinalStepContent>
              <ContactFinalStepIcon data-cy="success-icon" />
              <Text weight="700" size="16px" lineHeight="24px" margin="20px 0 0 0" data-cy="success-description">
                {t("common.contactUs.finalPageTitle")}
              </Text>
              <ContactButton onClick={closeAndCleanup} data-cy="close-button">
                {t("common.contactUs.finalPageButton")}
              </ContactButton>
            </FinalStepContent>
          )}
          {index === ModalItems.Error && (
            <FinalStepContent>
              <Text weight="700" size="16px" lineHeight="24px" margin="20px 0 0 0" data-cy="error-message">
                {t("common.contactUs.errorPageTitle")}
              </Text>
              <ContactButton onClick={closeAndCleanup} data-cy="close-button">
                {t("common.contactUs.finalPageButton")}
              </ContactButton>
            </FinalStepContent>
          )}
        </FormContent>
      </Content>
    </ModalWrapper>
  ) : null;
};

export default ContactUsModal;
