import { OrderCanceledEvent } from "./../../constants/trackedEvents";
import { HomeUrl, OrderReport } from "./../../constants/urls";
import { useLocalStorage, useToggle } from "./../../hooks";
import React, { useEffect, useState } from "react";
import { useStore } from "react-context-hook";
import { PlaceTypes } from "../../utilities/gqlUtilities";
import { getSegmentQuestionPath } from "../../utilities/urlUtils";
import { NonObjectReport } from "./../../models/NonObjectReport";
import { useTranslation } from "react-i18next";
import { uuidv4 } from "../../utilities/uuidv4";
import ConfirmationModalView from "./ConfirmationModalView/ConfirmationModalView";
import LoadingView from "./LoadingView/LoadingView";
import OrderConfirmationView from "./OrderConfirmationView/OrderConfirmationView";

import { useNavigate } from "react-router";
import { Modal, SucceedModalContext } from "../Common";
// import { useMixpanelContextV2 } from "../BaldrView/MixpanelContextProviderV2";
import { Container } from "../Order/styled";

const { useModalActions } = SucceedModalContext;

const getLocation = (location) =>
  typeof location === "string" ? (location as string)?.split(",").map(Number.parseFloat) || [0, 0] : (location || [0, 0]).map((loc) => Number.parseFloat(loc));

const getCountry = (country) => (typeof country === "string" ? country.toLowerCase() : "se");

const OrderConfirmationContainer = ({ createOrderAccess, isInternalUser }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const { track } = useMixpanelContextV2();
  const [data, _setData] = useStore("data", null);
  const [_index, setIndex] = useStore("index", null);
  const [modalVisible, toggleModalVisible] = useToggle();
  const [loading, setLoading] = useState<boolean>();
  const [_, setReports] = useLocalStorage<NonObjectReport[]>("nonObjectReports", []);
  const [location, setLocation] = useState(null);
  const [country, setCountry] = useState(null);
  const [title, setTitle] = useState(null);
  const [address, setAddress] = useState(null);
  const { toggleModal, setEssentialId } = useModalActions();
  useEffect(() => {
    setLocation(getLocation(data.location));
    setCountry(getCountry(data.country));
    setTitle(data.title);
    setAddress(data.address);
  }, [data]);

  const handleNextStep = (reportId: string) => {
    const redirectUrl = getSegmentQuestionPath(country, reportId, PlaceTypes.NewLocationPlaceType);
    navigate(redirectUrl);
    setIndex(0);
  };

  const handleCancel = () => {
    setIndex(0);
    // track(OrderCanceledEvent, {
    //   IsInternalUser: isInternalUser,
    //   Location: window.location.pathname,
    // });
    navigate(isInternalUser ? OrderReport : HomeUrl);
  };

  const handleReportCreation = () => {
    const newReportId = uuidv4();

    setReports((oldReports) => {
      const newReport = {
        id: newReportId,
        country,
        address,
        position: {
          latitude: location[1],
          longitude: location[0],
        },
        name: title,
      };

      return [...oldReports, newReport];
    });

    setEssentialId(newReportId);
    toggleModal();
    handleNextStep(newReportId);
  };

  const showLoading = () => setLoading(true);

  const disableCreation = !title || !address || !location || !location[0] || !location[1];

  return (
    <Container>
      <div>
        <title>{t("pageTitle")}</title>
      </div>
      {loading ? (
        <LoadingView
          title={t("orderConfirmation.loading.title")}
          description={t("orderConfirmation.loading.description")}
          loadingCallback={handleReportCreation}
        />
      ) : (
        <OrderConfirmationView
          headerTitle={t("orderConfirmation.header.title")}
          headerDescription={t("orderConfirmation.header.description")}
          contentTitle={t("orderConfirmation.content.title")}
          contentDescription={t("orderConfirmation.content.description")}
          reportInformation={t("orderConfirmation.content.reportInformation")}
          reporDescription={t("orderConfirmation.content.reportName")}
          reportName={title}
          locationDescription={t("orderConfirmation.content.coordinates")}
          location={location}
          onCancelClick={toggleModalVisible}
          onReportCreate={showLoading}
          cancelText={t("orderConfirmation.content.cancelText")}
          createText={t("orderConfirmation.content.generateText")}
          createDisabled={disableCreation}
        />
      )}
      <Modal visible={modalVisible} onCloseClick={toggleModalVisible} hideHeader>
        <ConfirmationModalView
          title={t("orderConfirmation.modal.title")}
          description={t("orderConfirmation.modal.description")}
          okText={t("orderConfirmation.modal.okText")}
          cancelText={t("orderConfirmation.modal.cancelText")}
          onOkClick={handleCancel}
          onCancelClick={toggleModalVisible}
        />
      </Modal>
    </Container>
  );
};

export default OrderConfirmationContainer;
