import { useToggle } from './../../../hooks';
import React, { useState, ReactNode, useMemo } from 'react';
import { SucceedModalActionsContext } from './SucceedModalActionsContext';
import { SucceedModalDataContext } from './SucceedModalDataContext';

const SucceedModalContextProvider = ({ children }: { children: ReactNode }) => {
    const [visible, toggleModal] = useToggle();
    const [essentialId, setEssentialId] = useState<string | undefined>(
        undefined
    );

    const actions = useMemo(
        () => ({
            setEssentialId,
            toggleModal
        }),
        []
    );

    const data = useMemo(
        () => ({
            visible,
            essentialId
        }),
        [visible, essentialId]
    );

    return (
        <SucceedModalActionsContext.Provider value={actions}>
            <SucceedModalDataContext.Provider value={data}>
                {children}
            </SucceedModalDataContext.Provider>
        </SucceedModalActionsContext.Provider>
    );
};

export default SucceedModalContextProvider;
