import React from 'react';

const BellIcon = () => (
    <svg width= "55" height= "75" xmlns= "http://www.w3.org/2000/svg" fill= "none">
        <g>
            <path d= "m31.00035,11.46501l0,-8.27751c0,-0.71277 -0.2897,-1.3963 -0.80539,-1.90031c-0.5157,-0.504 -1.2152,-0.78719 -1.9446,-0.78719c-0.7293,0 -1.4288,0.28319 -1.9445,0.78719c-0.51569,0.50401 -0.8055,1.18754 -0.8055,1.90031l0,8.27751c-5.3083,0.6536 -10.19105,3.1752 -13.73429,7.093c-3.54324,3.9179 -5.50412,8.9633 -5.51567,14.192l0,18.8125c-0.00493,0.3542 0.06273,0.7059 0.19918,1.0341c0.13646,0.3283 0.33895,0.6265 0.5953,0.877c0.25635,0.2505 0.56154,0.4483 0.89742,0.5816c0.33587,0.1334 0.6956,0.1996 1.0581,0.1948l38.50006,0c0.7293,0 1.4288,-0.2831 1.9445,-0.7872c0.5157,-0.504 0.8055,-1.1876 0.8055,-1.9003l0,-18.8483c-0.02049,-5.2226 -1.9854,-10.2593 -5.52781,-14.1696c-3.54239,-3.9103 -8.42009,-6.4268 -13.7223,-7.0796z" fill= "#42638C" id= "svg_1" />
            <g id= "svg_2">
                <path id= "svg_3" d= "m52.50015,58.5l-49.50001,0c-0.72934,0 -1.42883,0.28317 -1.94456,0.7872c-0.51572,0.504 -0.80544,1.18753 -0.80544,1.9003c0,0.71277 0.28972,1.39636 0.80544,1.90036c0.51573,0.504 1.21522,0.78714 1.94456,0.78714l13.75001,0c0,2.85107 1.1589,5.58541 3.2218,7.60141c2.0629,2.01599 4.8608,3.14859 7.7782,3.14859c2.9174,0 5.7153,-1.1326 7.77821,-3.14859c2.06289,-2.01599 3.22179,-4.75034 3.22179,-7.60141l13.75,0c0.7293,0 1.4288,-0.28314 1.9446,-0.78714c0.5157,-0.504 0.80541,-1.18759 0.80541,-1.90036c0,-0.71277 -0.28971,-1.3963 -0.80541,-1.9003c-0.51579,-0.50403 -1.21529,-0.7872 -1.9446,-0.7872zm-24.75,10.75c-1.45869,0 -2.8577,-0.56628 -3.8891,-1.57428c-1.0315,-1.00799 -1.6109,-2.37518 -1.6109,-3.80072l11,0c0.0001,0.71191 -0.1447,1.41681 -0.4257,2.07364c-0.2809,0.65683 -0.6926,1.25256 -1.21111,1.75262c-0.51859,0.50005 -1.13339,0.89444 -1.8092,1.16034c-0.6758,0.2659 -1.399,0.39792 -2.1274,0.3884l0.07339,0l0.00002,0z" fill= "#42638C" />
            </g>
        </g>
    </svg>
);

export default BellIcon;