import { PlaceTypes } from "./../utilities/gqlUtilities";
import useBaldrViewData from "./useBaldrViewData";
import useNonObjectBaldrView from "./useNonObjectBaldrView";

const useProperties = ({ objectNumber, country, placeType, report }) => {
  let hookToUse;

  switch (placeType) {
    case PlaceTypes.NewLocationPlaceType:
      hookToUse = useNonObjectBaldrView;
      break;
    case PlaceTypes.ObjectPlaceType:
    default:
      hookToUse = useBaldrViewData;
  }

  const properties = hookToUse({ objectNumber, country, report });
  return properties;
};

export default useProperties;
