import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Position from "../../models/Position";
import { Colors } from "../../utilities/common-styles";
import { GeoRiskId, scrollToSection } from "../../utilities/scrollUtils";
import { Text } from "../../utilities/typography";
import { TopRiskUserSelectionContext } from "../Common";
import TooltipContainer from "../Common/Tooltip/TooltipContainer";
import { WizardContainer } from "../styled";
import GeographicalMap from "./GeographicalMap";
import Risk from "./RiskBars/risk";
import { HeaderWrapper, TitleWrapperTopRisks, TopRiskMapWrapper } from "./styled";

const { useActions } = TopRiskUserSelectionContext;

interface ITopRiskMapProps {
  title: string;
  description: string;
  position: Position;
  risks: Risk[];
  tooltipText: string;
  topRisks: string[];
  segment: string;
  country?: string;
}

const TopRiskMap = ({ position, risks, topRisks, title, description, tooltipText, segment, country }: ITopRiskMapProps) => {
  const { setTopRisk, updateTopRiskList } = useActions();
  const TopRiskRef = useRef();
  const { category } = useParams();

  const mapRisks = (topRisksArray) =>
    topRisksArray
      .map((currentRisk) => risks.find((elem) => elem.category === currentRisk))
      .filter((elem) => elem !== undefined)
      .concat(risks);
  useEffect(() => {
    const topRiskSet: Set<Risk> = new Set(mapRisks(topRisks));

    const topRisksList: Risk[] = [...topRiskSet];

    updateTopRiskList(topRisksList);
    setTopRisk(topRisksList[0].category.toLowerCase());

    return () => {
      updateTopRiskList([]);
    };
  }, []);
  useEffect(() => {
    const sectionId = category;
    if (sectionId === GeoRiskId && TopRiskRef?.current) {
      scrollToSection(sectionId);
    }
  }, [TopRiskRef]);

  return (
    <>
      <WizardContainer>
        <TopRiskMapWrapper>
          <TitleWrapperTopRisks ref={TopRiskRef}>
            <HeaderWrapper>
              <Text
                data-cy="top-risk-title"
                className="desktop-title"
                size="24px"
                lineHeight="36px"
                mobileFontSize="18px"
                mobileLineHeight="27px"
                weight="700"
                margin="8px 0 0 0"
                color={Colors.text}
              >
                {title}
              </Text>
              <TooltipContainer data-cy="top-risk-tooltip" text={tooltipText} extraSubTitle="top-risk-map" />
            </HeaderWrapper>
            <Text
              data-cy="top-risk-description"
              className="desktop-title"
              size="16px"
              mobileFontSize="14px"
              mobileLineHeight="21px"
              margin="0"
              lineHeight="24px"
              color={Colors.text}
            >
              {description}
            </Text>
          </TitleWrapperTopRisks>

          <GeographicalMap position={position} width="100%" height="100%" segment={segment} country={country} />
        </TopRiskMapWrapper>
      </WizardContainer>
    </>
  );
};

export default TopRiskMap;
