import React from 'react';
import { StatusInfo, StatusWrapper } from './styled';

export type Position = {
    latitude: string;
    longitude: string;
};

type Props = {
    pickSegmentText;
};

const StatusComponent = ({ pickSegmentText }: Props) => {
    return (
        <StatusWrapper>
            <StatusInfo>{pickSegmentText}</StatusInfo>
        </StatusWrapper>
    );
};

export default StatusComponent;
